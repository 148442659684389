import { ReactNode } from 'react';
import { ScreenContainer, MidLane, BottomLane } from './AppLayout';
import { Navigation } from './Navigation';

export type AppScreenProps = {
    HeaderLeft?: ReactNode | ReactNode[];
    HeaderCenter?: ReactNode | ReactNode[];
    HeaderRight?: ReactNode | ReactNode[];
    SubHeader?: ReactNode | ReactNode[];
    NavItems?: ReactNode | ReactNode[];
    ContentHeader?: ReactNode | ReactNode[];
    Footer?: ReactNode;
    Selector?: ReactNode;

    navOnTheRight?: boolean;
    sidebarIsNextStep?: boolean;
    children?: ReactNode | ReactNode[];
};
export const AppScreen = ({
    children,
    HeaderLeft,
    HeaderCenter,
    HeaderRight,
    NavItems,
    Selector,
    Footer,
    navOnTheRight = false,
    SubHeader,
    ContentHeader,
    sidebarIsNextStep = false,
}: AppScreenProps) => {
    return (
        <ScreenContainer>
            <MidLane>
                <Navigation
                    right={navOnTheRight}
                    NavItems={NavItems}
                    Selector={Selector}
                    HeaderLeft={HeaderLeft}
                    HeaderCenter={HeaderCenter}
                    HeaderRight={HeaderRight}
                    ContentHeader={ContentHeader}
                    SubHeader={SubHeader}
                    sidebarIsNextStep={sidebarIsNextStep}
                >
                    {children}
                </Navigation>
            </MidLane>

            <BottomLane>{Footer}</BottomLane>
        </ScreenContainer>
    );
};
