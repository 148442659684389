import { PlaybackMode } from 'src/domain/session';
import { SessionFormActionTypes } from './actions';
import { SessionFormStoreType, SessionFormTypeKeys } from './interfaces';
import { omit } from 'lodash';

export const initialState: SessionFormStoreType = {
    name: '',
    trackSelection: [],
    images: [],
    title: '',
    recipient: undefined,
    step: 1,
    duration: 0,
    incompleteSessionId: undefined,
    playbackMode: PlaybackMode.NORMAL,
    imageDuration: 0,
    editMode: false,
    id: undefined,
};

export const SessionFormReducer = (
    state: SessionFormStoreType,
    action: SessionFormActionTypes,
): SessionFormStoreType => {
    switch (action.type) {
        case SessionFormTypeKeys.updateName:
            return {
                ...state,
                name: action.name,
            };
        case SessionFormTypeKeys.updateDuration:
            return {
                ...state,
                duration: action.duration,
            };
        case SessionFormTypeKeys.updateStep:
            return {
                ...state,
                step: action.step,
            };
        case SessionFormTypeKeys.updateImages:
            return {
                ...state,
                images: [...action.images],
            };
        case SessionFormTypeKeys.updateMusic:
            return {
                ...state,
                trackSelection: [...action.trackSelection],
            };
        case SessionFormTypeKeys.updateTitle:
            return {
                ...state,
                title: action.title,
            };
        case SessionFormTypeKeys.updateRecipient:
            return {
                ...state,
                recipient: action.recipient,
            };
        case SessionFormTypeKeys.removeImage:
            return {
                ...state,
                images: state.images.filter(image => image.id !== action.id),
            };
        case SessionFormTypeKeys.removeMusicTrack:
            return {
                ...state,
                trackSelection: state.trackSelection.filter(
                    musicTrack => musicTrack.id.toString() !== action.id,
                ),
            };
        case SessionFormTypeKeys.resetSessionForm:
            sessionStorage.removeItem('SessionFormState');
            return initialState;
        case SessionFormTypeKeys.setSessionForm:
            return {
                ...state,
                ...action.session.sessionForm,
                incompleteSessionId: action.session.id,
            };
        case SessionFormTypeKeys.setSnapshot:
            return {
                ...state,
                ...action.session,
                snapShot: omit(action.session, 'snapShot'),
            };
        case SessionFormTypeKeys.togglePlaybackMode:
            return {
                ...state,
                playbackMode:
                    state.playbackMode === PlaybackMode.NORMAL
                        ? PlaybackMode.LOOP
                        : PlaybackMode.NORMAL,
            };
        case SessionFormTypeKeys.updateImageDuration:
            return {
                ...state,
                imageDuration: action.imageDuration,
            };
        default:
            return state;
    }
};
