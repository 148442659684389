import { useCurrentConsumerContext } from 'src/view/screens/Home/NewHome/reducer';
import { useSurveyContext } from 'src/view/screens/MusicalSurvey/reducer';
import { Link, useRouteMatch } from 'react-router-dom';
import { useFavoritesPlaylist } from 'src/view/hooks/useFavoritesPlaylist';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { NavItem } from 'src/components/Buttons/NavItem';
import { HeartIcon } from 'src/components/Icons/icons';
import cx from 'classnames';

type Props = {
    to: string;
    type?: 'NAV' | 'TAB';
};

export const FavoritesNavItem = ({ to, type = 'NAV' }: Props) => {
    const isActive = useRouteMatch(to);
    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();
    const { dispatch } = useSurveyContext();
    const favoritesPlaylist = useFavoritesPlaylist(
        currentConsumer?.id,
        playlist => dispatch({ type: 'SET_PLAYLIST', payload: playlist }),
    );

    return (
        <>
            {type === 'TAB' ? (
                <Link to={to}>
                    <button
                        className={cx(
                            'flex group text-sm h-12 ml-1 rounded-md hover:bg-woodyBrown hover:text-lightPeach items-center stroke-[1.8px] justify-between pl-1 disabled:pointer-events-none ',
                            isActive &&
                                ' bg-woodyBrown disabled:text-lightPeach',
                        )}
                        disabled={!favoritesPlaylist || isActive?.isExact}
                    >
                        <div className="flex items-center pr-3">
                            <TextIcon SVG={HeartIcon} />
                            <div>Favoritter</div>
                        </div>
                    </button>
                </Link>
            ) : (
                <NavItem screen={to} icon={HeartIcon} label="Favoritter" />
            )}
        </>
    );
};
