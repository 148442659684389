import useSWR from 'swr';
import container from 'src/container';
import { ImageSize } from 'src/api/music.params';

const {
    cradle: { musicService },
} = container;

export const useReleaseTracks = (
    releaseId?: number,
    imageSize: ImageSize = 180,
) => {
    const { data, mutate } = useSWR(
        releaseId ? [releaseId, imageSize, 'release/tracks'] : null,
        musicService.listReleaseTracks.bind(musicService),
    );
    return {
        tracks: data?.tracks,
        refresh: mutate,
    };
};
