import Lottie from 'react-lottie';
import animationData from 'src/assets/lotties/cloud.json';
export const CloudAnimation = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return <Lottie options={defaultOptions} />;
};
