import { Configuration, LogLevel } from '@azure/msal-browser';
import {
    captureException as reportToSentry,
    BrowserOptions,
} from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Config } from 'mixpanel-browser';

// Mundu
export const ENVIRONMENT = process.env.NODE_ENV;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IS_DEV_ENVIRONMENT = !ENVIRONMENT || ENVIRONMENT === 'development';
export const DEFAULT_VOLUME = 0.4;
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;
export const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL;
export const COMPANY_NAME = process.env.REACT_APP_COMPANY;

// Service endpoints
export const SERVER_URL = `${process.env.REACT_APP_SERVER_HOST}/api/v1`;
export const PROXY_URL = `${process.env.REACT_APP_PROXY_HOST}`;
export const AZURE_STORAGE_URL = process.env.REACT_APP_AZURE_STORAGE_URL;
const AZURE_AD_B2C_SERVICE_URL = process.env.REACT_APP_AD_B2C_SERVICE_URL || '';

// Azure AD B2C
const AD_B2C_POLICY_NAME = process.env.REACT_APP_AD_B2C_POLICY_NAME || '';
export const AD_B2C_PASSWORDLESS_SIGNUP_URL = `${AZURE_AD_B2C_SERVICE_URL}/B2C_1A_PasswordlessSignup`;

const AD_B2C_SERVER_SCOPE = process.env.REACT_APP_AD_B2C_SERVER_SCOPE || '';
const AD_B2C_OPEN_ID_SCOPE = process.env.REACT_APP_AD_B2C_OPEN_ID_SCOPE || '';

// MSAL
export const MSAL_LOGIN_REQUEST = {
    prompt: 'login',
    scopes: [AD_B2C_SERVER_SCOPE, AD_B2C_OPEN_ID_SCOPE, 'profile'],
};
export const MSAL_TOKEN_REQUEST = {
    scopes: [AD_B2C_SERVER_SCOPE, AD_B2C_OPEN_ID_SCOPE, 'profile'],
    forceRefresh: false,
};
const MSAL_AUTHORITY = `${AZURE_AD_B2C_SERVICE_URL}/${AD_B2C_POLICY_NAME}`;
export const MSAL_CONFIG: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AD_B2C_CLIENT_ID || '',
        authority: MSAL_AUTHORITY,
        redirectUri: `${process.env.REACT_APP_HOST}/login` || '',
        postLogoutRedirectUri: `${process.env.REACT_APP_HOST}` || '',
        knownAuthorities: ['SamlaB2C.b2clogin.com'],
    },
    system: {
        loggerOptions: {
            loggerCallback: (
                level: LogLevel,
                message: string,
                containsPii: boolean,
            ): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        reportToSentry(message);
                        return;
                }
            },
            piiLoggingEnabled: false,
        },
        allowRedirectInIframe: true,
    },
};
// 7digital
export const SEVEN_DIGITAL_PARAMS = {
    formatId: 56, // AAC 160 kbps / sound quality
    usageTypes: 'subscriptionstreaming',
    country: 'NO',
    playMode: 'online',
    playType: 'creation',
};

// Sentry
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const SENTRY_TRACES_SAMPLE_RATE = 1.0;

export const SENTRY_CONFIG: BrowserOptions = {
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
};

// MIX_PANEL
export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || '';
export const MIX_PANEL_CONFIG: Partial<Config> = {
    // Use EU server
    api_host: 'https://api-eu.mixpanel.com',
    // HTTP method for tracking requests
    api_method: 'POST',

    // transport for sending requests ('XHR' or 'sendBeacon')
    // NB: sendBeacon should only be used for scenarios such as
    // page unload where a &quot;best-effort&quot; attempt to send is
    // acceptable; the sendBeacon API does not support callbacks
    // or any way to know the result of the request. Mixpanel
    // tracking via sendBeacon will not support any event-
    // batching or retry mechanisms.
    api_transport: 'XHR',

    // turn on request-batching/queueing/retry
    batch_requests: false,

    // maximum number of events/updates to send in a single
    // network request
    batch_size: 50,

    // milliseconds to wait between sending batch requests
    batch_flush_interval_ms: 5000,

    // milliseconds to wait for network responses to batch requests
    // before they are considered timed-out and retried
    batch_request_timeout_ms: 90000,

    // override value for cookie domain, only useful for ensuring
    // correct cross-subdomain cookies on unusual domains like
    // subdomain.mainsite.avocat.fr; NB this cannot be used to
    // set cookies on a different domain than the current origin
    cookie_domain: '',

    // super properties cookie expiration (in days)
    cookie_expiration: 365,

    // if true, cookie will be set with SameSite=None; Secure
    // this is only useful in special situations, like embedded
    // 3rd-party iframes that set up a Mixpanel instance
    cross_site_cookie: false,

    // super properties span subdomains
    cross_subdomain_cookie: true,

    // debug mode
    debug: false,

    // if this is true, the mixpanel cookie or localStorage entry
    // will be deleted, and no user persistence will take place
    disable_persistence: false,

    // if this is true, Mixpanel will automatically determine
    // City, Region and Country data using the IP address of
    //the client
    ip: false,

    // opt users out of tracking by this Mixpanel instance by default
    opt_out_tracking_by_default: false,

    // opt users out of browser data storage by this Mixpanel instance by default
    opt_out_persistence_by_default: false,

    // persistence mechanism used by opt-in/opt-out methods - cookie
    // or localStorage - falls back to cookie if localStorage is unavailable
    opt_out_tracking_persistence_type: 'localStorage',

    // customize the name of cookie/localStorage set by opt-in/opt-out methods
    //opt_out_tracking_cookie_prefix: null,

    // type of persistent store for super properties (cookie/
    // localStorage) if set to 'localStorage', any existing
    // mixpanel cookie value with the same persistence_name
    // will be transferred to localStorage and deleted
    persistence: 'cookie',

    // name for super properties persistent store
    persistence_name: '',

    // names of properties/superproperties which should never
    // be sent with track() calls
    property_blacklist: [],

    // if this is true, mixpanel cookies will be marked as
    // secure, meaning they will only be transmitted over https
    secure_cookie: false,

    // the amount of time track_links will
    // wait for Mixpanel's servers to respond
    track_links_timeout: 300,

    // if you set upgrade to be true, the library will check for
    // a cookie from our old js library and import super
    // properties from it, then the old cookie is deleted
    // The upgrade config option only works in the initialization,
    // so make sure you set it when you create the library.
    upgrade: false,

    // extra HTTP request headers to set for each API request, in
    // the format {'Header-Name': value}
    xhr_headers: {},

    // protocol for fetching in-app message resources, e.g.
    // 'https://' or 'http://'; defaults to '//' (which defers to the
    // current page's protocol)
    inapp_protocol: '//',

    // whether to open in-app message link in new tab/window
    inapp_link_new_window: false,

    // whether to ignore or respect the web browser's Do Not Track setting
    ignore_dnt: false,
};
