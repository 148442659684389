import { UserRole } from 'src/domain/user';
import { useSignedInUser } from './useSignedInUser';
export const useHomePath = () => {
    const user = useSignedInUser();
    if (user)
        switch (user.role) {
            case UserRole.admin:
                return '/app/admin';
            case UserRole.nextOfKin:
                return '/app/nextofkin-image-list';
            case UserRole.creator:
            case UserRole.organization:
                return '/app/home';
        }
    return '/';
};
