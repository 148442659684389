import { Artist, Playlist, Release, Track } from 'src/domain/music';
import { createContext, ReactNode, useContext, useMemo } from 'react';
import Denque from 'denque';
import produce from 'immer';
import { useImmerReducer } from 'use-immer';
import { SessionFormTrack } from 'src/domain/sessionForm';
type AudioPlayerAction =
    | { type: 'PLAY_TRACK'; payload: number }
    | { type: 'PLAY_FIRST' }
    | { type: 'PREV' }
    | { type: 'NEXT' }
    | { type: 'SET_PLAYLIST'; payload: Playlist }
    | { type: 'SET_ARTIST'; payload: Artist }
    | { type: 'SET_RELEASE'; payload: Release }
    | { type: 'TOGGLE_SELECTED'; payload: SessionFormTrack }
    | { type: 'SET_INITIAL_SELECTION'; payload: SessionFormTrack[] }
    | { type: 'SET_QUEUE'; payload: Track[] }
    | { type: 'SET_IS_PLAYING'; payload: boolean }
    | { type: 'TOGGLE_PLAY' }
    | { type: 'DESELECT'; payload: Track['id'] }
    | { type: 'ADD_UNAVAILABLE'; payload: Track['id'] }
    | { type: 'REMOVE_CURRENT_TRACK' };

type Dispatch = (action: AudioPlayerAction) => void;
type ContextProps = {
    state: AudioPlayerState;
    dispatch: Dispatch;
};

export enum SelectedList {
    playlist = 'playlist',
    search = 'search',
}
type AudioPlayerState = {
    currentTrack?: Track;
    queue: Denque<Track>;
    isPlaying: boolean;
    selection: SessionFormTrack[];
    index: number;
    selectedList?: SelectedList;
    unavailableTracks: Track['id'][];
    playlist?: Playlist;
    artist?: Artist;
    release?: Release;
};
const initialState: AudioPlayerState = {
    currentTrack: undefined,
    queue: new Denque(),
    isPlaying: false,
    selection: [],
    index: 0,
    selectedList: undefined,
    unavailableTracks: [],
    playlist: undefined,
    artist: undefined,
    release: undefined,
};
const AudioPlayerContext = createContext<ContextProps>({
    state: initialState,
    dispatch: () => null,
});

export const useAudioPlayerContext = () => useContext(AudioPlayerContext);

export const AudioPlayerStore = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => {
    const [state, dispatch] = useImmerReducer(AudioPlayerReducer, initialState);
    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return (
        <AudioPlayerContext.Provider value={contextValue}>
            {children}
        </AudioPlayerContext.Provider>
    );
};

const AudioPlayerReducer = produce(
    (draft: AudioPlayerState = initialState, action: AudioPlayerAction) => {
        switch (action.type) {
            case 'PLAY_TRACK':
                draft.currentTrack = draft.queue.peekAt(action.payload);
                draft.index = action.payload;
                draft.isPlaying = true;
                return;

            case 'PREV':
                if (draft.index === 0) {
                    draft.currentTrack = undefined;
                    draft.index = -1;
                    draft.isPlaying = false;
                    return;
                }
                draft.currentTrack = draft.queue.peekAt(draft.index - 1);
                draft.index = draft.index - 1;
                draft.isPlaying = true;
                return;

            case 'NEXT':
                if (draft.index === draft.queue.size() - 1) {
                    draft.currentTrack = undefined;
                    draft.index = draft.queue.size();
                    draft.isPlaying = false;
                    return;
                }
                draft.currentTrack = draft.queue.peekAt(draft.index + 1);
                draft.index = draft.index + 1;
                draft.isPlaying = true;
                return;

            case 'TOGGLE_PLAY':
                draft.isPlaying = !draft.isPlaying;
                return;

            case 'SET_IS_PLAYING':
                draft.isPlaying = action.payload;
                return;

            case 'SET_PLAYLIST':
                draft.playlist = action.payload;
                return;

            case 'SET_ARTIST':
                draft.artist = action.payload;
                return;

            case 'SET_RELEASE':
                draft.release = action.payload;
                return;

            case 'SET_QUEUE':
                draft.queue = new Denque(action.payload);
                return;

            case 'TOGGLE_SELECTED':
                if (
                    draft.selection.some(
                        selectedTrack => selectedTrack.id === action.payload.id,
                    )
                ) {
                    draft.selection = draft.selection.filter(
                        selectedTracks =>
                            selectedTracks.id !== action.payload.id,
                    );
                    return;
                }
                draft.selection = [...draft.selection, action.payload];

                return;
            case 'DESELECT':
                const inSelection = draft.selection.some(
                    selectedTrack => selectedTrack.id === action.payload,
                );
                if (inSelection) {
                    draft.selection = draft.selection.filter(
                        selectedTracks => selectedTracks.id !== action.payload,
                    );
                    return;
                }

                return;

            case 'SET_INITIAL_SELECTION':
                draft.selection = action.payload;
                return;

            case 'ADD_UNAVAILABLE':
                if (!draft.unavailableTracks.includes(action.payload))
                    draft.unavailableTracks = [
                        ...draft.unavailableTracks,
                        action.payload,
                    ];

                return;
            case 'REMOVE_CURRENT_TRACK':
                draft.currentTrack = undefined;
                return;
            default:
                return;
        }
    },
);
