import { ReactNode, useEffect, useRef, useState } from 'react';
import { Dots } from './Dots';
import cx from 'classnames';

type ButtonProps = {
    className: string;
    children?: ReactNode;
    disabled?: boolean;
    onClick?: () => void;
    activeOpacity?: number;
    isScrolling: boolean;
};
const BottombarButton = ({
    className,
    children,
    disabled,
    isScrolling,
    onClick,
}: ButtonProps) => {
    const [fadeClassName, setFadeClassName] = useState('opacity-0');
    const [isFocused, setIsFocused] = useState(false);

    const ref = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        const timeOut = setTimeout(() => {
            setFadeClassName('');
        }, 300);
        return () => {
            clearTimeout(timeOut);
        };
    }, []);

    useEffect(() => {
        if (!isFocused) return;
        const timeOut = setTimeout(() => {
            ref.current?.blur();
            setIsFocused(false);
        }, 300);
        return () => clearTimeout(timeOut);
    }, [isFocused]);

    return (
        <button
            id=""
            ref={ref}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className={cx(
                'duration-300 transition-all isFocused:pointer-events-none ',
                fadeClassName
                    ? fadeClassName
                    : isFocused
                    ? 'opacity-20'
                    : disabled
                    ? 'opacity-0'
                    : 'opacity-100',

                className,
            )}
            disabled={disabled || isScrolling}
            onClick={onClick}
        >
            <label className="px-10 text-md whitespace-nowrap">
                {children}
            </label>
        </button>
    );
};

type Props = {
    isLight: boolean;
    numPages: number;
    currentPage: number;
    canNext?: boolean;
    prevLabel?: string;
    nextLabel?: string;
    finalLabel?: string;
    isScrolling?: boolean;
    onPreviousPage: () => void;
    onNextPage: () => void;
    onClose: () => void;
};

export const BottomBar = ({
    isLight,
    numPages,
    currentPage,
    isScrolling = false,
    canNext = true,
    prevLabel = 'Forrige',
    nextLabel = 'Neste',
    finalLabel = 'Kom i gang!',
    onPreviousPage,
    onNextPage,
    onClose,
}: Props) => {
    const isFinalPage = currentPage === numPages - 1;
    const isFirstPage = currentPage === 0;

    return (
        <div
            className={cx(
                'flex min-h-32  h-24 w-full bottom-0 justify-between items-center',
                isLight
                    ? 'bg-peachOrange text-woodyBrown bg-opacity-90'
                    : 'bg-black  text-starkWhite bg-opacity-[0.15]',
            )}
        >
            {isFirstPage ? (
                <BottombarButton
                    isScrolling={isScrolling}
                    key={'First'}
                    className="w-full h-full flex-shrink-1 items-start justify-start text-left"
                    disabled={true}
                    onClick={onPreviousPage}
                >
                    {prevLabel}
                </BottombarButton>
            ) : (
                <BottombarButton
                    isScrolling={isScrolling}
                    key={'Prev'}
                    className={cx(
                        'w-full h-full flex-shrink-1 items-start justify-start text-left ',
                    )}
                    onClick={onPreviousPage}
                >
                    {prevLabel}
                </BottombarButton>
            )}
            <div key={'Mid'} className="w-1/3  flex-shrink-0 flex-1  ">
                <Dots
                    isLight={isLight}
                    numPages={numPages}
                    currentPage={currentPage}
                />
            </div>
            {isFinalPage ? (
                <BottombarButton
                    isScrolling={isScrolling}
                    className=" w-full h-full flex-shrink-1 items-center text-right justify-end "
                    key={'Final'}
                    disabled={!canNext}
                    onClick={onClose}
                >
                    {finalLabel}
                </BottombarButton>
            ) : (
                <BottombarButton
                    isScrolling={isScrolling}
                    className=" w-full h-full flex-shrink-1 items-center text-right justify-end "
                    key={'Next'}
                    disabled={!canNext}
                    onClick={onNextPage}
                >
                    {nextLabel}
                </BottombarButton>
            )}
        </div>
    );
};
