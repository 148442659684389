import useSWR from 'swr';
import container from 'src/container';
import { ImageSize } from 'src/api/music.params';

const {
    cradle: { musicService },
} = container;

export const useReleaseDetails = (
    releaseId?: number,
    imageSize: ImageSize = 180,
) => {
    const { data, mutate } = useSWR(
        releaseId ? [releaseId, imageSize, 'release/details'] : null,
        musicService.getReleaseDetails.bind(musicService),
    );

    return {
        release: data?.release,
        refresh: mutate,
    };
};
