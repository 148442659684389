import { ReactNode } from 'react';
import cx from 'classnames';
import { CloseIcon } from 'src/components/Icons/icons';
import { LogoLink } from 'src/components/Buttons/LogoLink';
import { TextIcon } from 'src/components/Icons/TextIcon';

type SidebarProps = {
    children?: ReactNode;
    avoidFooter?: boolean;
    right?: boolean;
    isOpen: boolean;
    closed?: boolean;
    toggle: () => void;
};

export const Sidebar = ({
    children,
    right = false,
    closed = false,
    isOpen,
    toggle,
}: SidebarProps) => {
    return (
        <div
            id="sidebar"
            className={cx(
                'flex flex-col flex-shrink-0 w-64 h-full shadow-md animate-slide-in  md:shadow-none transition-transform duration-300 ',
                // avoidFooter && isOpen && 'pb-24 md:pb-0',
                right && 'bg-lightPeach text-woodyBrown z-30',
                !right && 'bg-woodyBrown text-lightPeach z-50',
                right && closed && 'left-0 -translate-x-full ',
                right &&
                    !closed &&
                    'right-0 animate-slide-in-reverse translate-x-full md:translate-x-0 md:shadow-inner',
                right && isOpen && '-translate-x-0',
                !right && closed && 'left-0 -translate-x-full',
                !right &&
                    !closed &&
                    'left-0 -translate-x-full  md:translate-x-0  ',
                !right && isOpen && !closed && '-translate-x-0 shadow-md',
                !closed ? 'absolute md:relative' : 'absolute',
            )}
        >
            <LogoLink
                className={cx(
                    'flex items-center space-x-2 px-4 pt-7 pb-6 text-[32px]',
                    right && 'hidden',
                )}
            />
            {isOpen && (
                <button
                    onClick={toggle}
                    className={cx(
                        'absolute md:hidden top-2  p-1  text-xs  group bg-transparent transition-all rounded-lg',
                        right && 'left-2',
                        !right && 'right-2',

                        right
                            ? 'text-woodyBrown hover:bg-woodyBrown hover:text-lightPeach'
                            : 'text-lightPeach hover:bg-lightPeach hover:text-woodyBrown',
                    )}
                >
                    <TextIcon SVG={CloseIcon} />
                </button>
            )}

            <nav
                id="side-nav"
                className="flex flex-col h-full w-full overflow-y-hidden items-center "
            >
                {children}
            </nav>
        </div>
    );
};
