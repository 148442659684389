import container from 'src/container';
import useSWR from 'swr';

const {
    cradle: { munduClient },
} = container;

export const useLicenceInfo = () => {
    const { data, error } = useSWR(
        ['organization/licence'],
        munduClient.getNumberOfLicences.bind(munduClient),
    );

    return {
        licenceInfo: data,
        licenceLimitReached: data && data.active === data.total,
        isLoading: !data && !error,
    };
};
