import { CardGrid } from 'src/components/AppScreen/AppLayout';
import { Release } from 'src/domain/music';

import { ReleaseItem } from './item';

export const ReleaseList = ({
    releases,
    onSelect,
}: {
    releases: Release[];
    onSelect?: (release: Release) => void;
}) => {
    return (
        <CardGrid>
            {releases.map(release => (
                <ReleaseItem
                    key={release.id}
                    release={release}
                    onSelect={onSelect ? () => onSelect(release) : undefined}
                />
            ))}
        </CardGrid>
    );
};
