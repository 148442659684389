import { TrashIconSVG } from 'src/assets/svg';
import { Track } from 'src/domain/music';
import { SessionFormTrack } from 'src/domain/sessionForm';
import { Duration } from 'src/components/Duration';

type Props = {
    item: SessionFormTrack | Track;
    index: number;
    onRemove: (id: string) => void;
};

export const TrackSelectionItem = ({ item, onRemove }: Props) => {
    const { title, artist } = item;
    const artistName = typeof artist === 'string' ? artist : artist.appearsAs;
    const cover = 'cover' in item ? item.cover : item.release.image;

    return (
        <div className="inline-flex items-center gap-2 w-60">
            <div className="inline-flex w-4/6 flex-shrink-0 flex-grow-0 items-center">
                <div>
                    <div className="relative h-[48px] w-[48px] flex-shrink-0">
                        <button>
                            <img
                                className="absolute inset-0 rounded-sm"
                                src={cover}
                                alt={`albumcover for ${title} by ${artistName}`}
                            />
                        </button>
                    </div>
                </div>
                <div className="pl-2 truncate w-3/4">
                    <span className="font-bold text-sm ">{title}</span> <br />
                    <span className="text-xs">{artistName}</span>
                </div>
            </div>

            <div className="w-1/6 tabular-nums text-sm font-matterSQ ">
                <Duration value={item.duration} />
            </div>
            <button
                className="w-1/6 h-full "
                onClick={() => onRemove(String(item.id))}
            >
                <TrashIconSVG className="mx-auto" />
            </button>
        </div>
    );
};
