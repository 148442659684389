import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { TrashIconSVG } from 'src/assets/svg';
import { formatDuration } from 'src/utils/stringUtils';
import {
    useCommonMediaFetch,
    usePersonalMediaFetch,
} from 'src/view/hooks/useImageFetch';

import { updateImages } from 'src/view/screens/NewVideo/SessionFormState/actions';
import { useSessionForm } from 'src/view/screens/NewVideo/SessionFormState/hooks';
import { useImageSelectorContext } from '../../../../state/reducers/ImageSelectorReducer';

type SelectedImageProps = {
    description?: string;
    src: string;
    onDelete?: () => void;
};

const SelectedImage = ({ description, src, onDelete }: SelectedImageProps) => {
    return (
        <div className="flex w-full justify-between items-center px-2">
            <img className="h-12 w-12 rounded-sm object-cover" src={src} />

            <p className="italic">
                {description && <>&quot;{description}&quot;</>}
            </p>
            <button
                type="button"
                onClick={onDelete}
                className="p-4 items-center rounded-md text-sm outline-none focus:outline-none  hover:bg-woodyBrown hover:text-lightPeach transition-all"
            >
                <TrashIconSVG />
            </button>
        </div>
    );
};

export const ImageSelector = () => {
    const {
        dispatch: sessionFormDispatch,
        state: { recipient, duration },
    } = useSessionForm();

    const {
        state: { selection },
        dispatch,
    } = useImageSelectorContext();
    const history = useHistory();

    const updateSelection = useCallback(() => {
        sessionFormDispatch(updateImages(selection));
        history.replace('/app/new-video/step2');
    }, [sessionFormDispatch, history, selection]);

    const selectionSize = selection.length;

    const fetchPersonalImage = usePersonalMediaFetch(recipient?.id);
    const fetchCommonImage = useCommonMediaFetch();

    return (
        <div className="flex flex-col flex-1 h-full w-full text-left items-center bg-lightPeach text-woodyBrown ">
            <h1 className="text-xl font-bold font-logo text-center w-full py-4 ">
                Valgte bilder
            </h1>

            <div className="flex flex-col flex-1 overflow-y-scroll pt-4 pl-3 shadow-scrollTop w-full h-full ">
                <div className="relative text-left ">
                    {selectionSize === 0 && (
                        <p className="text-sm whitespace-nowrap text-center justify-self-center h-full w-full absolute inset-0">
                            Seleksjonen er tom...
                        </p>
                    )}

                    <div className="flex flex-col gap-2 items-center h-full ">
                        {selectionSize > 0 &&
                            selection.map(image => {
                                return (
                                    <SelectedImage
                                        key={image.id}
                                        description={image.description}
                                        src={
                                            image.containerType === 'pm'
                                                ? fetchPersonalImage(image.id)
                                                      .src
                                                : fetchCommonImage(image.id).src
                                        }
                                        onDelete={() =>
                                            dispatch({
                                                type: 'DESELECT',
                                                payload: image.id,
                                            })
                                        }
                                    />
                                );
                            })}
                    </div>
                </div>
            </div>
            {selectionSize > 0 && (
                <div className="inline-grid grid-cols-12 items-center gap-2 px-2 w-full pt-2 ">
                    <div className="col-span-8 text-sm font-bold text-right ">
                        Varighet per bilde:
                    </div>
                    <div className="col-span-4 tabular-nums text-sm font-matterSQ ">
                        {formatDuration(duration / selection.length)}
                    </div>
                </div>
            )}

            {selectionSize > 0 ? (
                <button
                    type="button"
                    onClick={updateSelection}
                    className="secondary-btn-small my-4 bg-peachOrange "
                >
                    {'Legg til'}
                    <span className="font-bold">
                        &nbsp;{selection.length}
                        &nbsp;
                    </span>
                    bilder
                </button>
            ) : (
                <button
                    type="button"
                    onClick={updateSelection}
                    className="secondary-btn-small my-4 "
                >
                    Avbryt og gå tilbake
                </button>
            )}
        </div>
    );
};
