import { FieldError } from 'react-hook-form';
import cx from 'classnames';
import { InputIcon } from 'src/components/Icons/InputIcon';
import { IconComponent } from 'src/components/Icons/TextIcon';
import _ from 'lodash';
import { forwardRef } from 'react';

export const Error = ({ error }: { error?: FieldError }) => {
    if (!error) return null;
    return (
        <p className="text-venetianRed text-sm italic mt-3 ">{error.message}</p>
    );
};

export const Label = ({ label }: { label?: string }) => {
    if (!label) return null;
    return (
        <label className="text-sm mb-1 block leading-4 whitespace-nowrap truncate">
            {label}
        </label>
    );
};

type InputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    label?: string;
    error?: FieldError;
    leadingIcon?: IconComponent;
    trailingIcon?: IconComponent;
    onLeadingClick?: () => void;
    onTrailingClick?: () => void;
    textColor?: 'lightPeach' | 'woodyBrown';
    debounce?: number;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            label,
            onLeadingClick,
            onTrailingClick,
            className,
            error,
            leadingIcon,
            trailingIcon,
            disabled,
            readOnly,
            textColor = 'lightPeach',
            debounce = 0,
            onChange,
            ...props
        },
        ref,
    ) => {
        const handleChange:
            | React.ChangeEventHandler<HTMLInputElement>
            | undefined =
            debounce && onChange
                ? _.debounce(async event => onChange?.(event), debounce)
                : onChange;

        return (
            <div>
                <Label label={label} />
                <div
                    className={cx(
                        `relative flex justify-start items-center bg-transparent`,
                        disabled || readOnly
                            ? 'text-mongooseGray'
                            : `text-${textColor}`,
                    )}
                >
                    <input
                        className={cx(
                            className,
                            leadingIcon && 'pl-[2.2em]',
                            trailingIcon && 'pr-[2.2em]',
                        )}
                        disabled={disabled || readOnly}
                        ref={ref}
                        {...props}
                        onChange={handleChange}
                    />
                    {leadingIcon && (
                        <InputIcon
                            type="leading"
                            onClick={onLeadingClick}
                            SVG={leadingIcon}
                        />
                    )}

                    {trailingIcon && (
                        <InputIcon
                            type="trailing"
                            onClick={onTrailingClick}
                            SVG={trailingIcon}
                        />
                    )}
                </div>

                <Error error={error} />
            </div>
        );
    },
);

Input.displayName = 'Input';
export default Input;
