import { createContext, ReactNode, useContext, useMemo } from 'react';
import Denque from 'denque';
import produce from 'immer';
import { useImmerReducer } from 'use-immer';
import { SessionFormMedia } from 'src/domain/sessionForm';

type ImageSelectorAction =
    | { type: 'PREV' }
    | { type: 'NEXT' }
    | { type: 'SET_INITIAL_SELECTION'; payload: SessionFormMedia[] }
    | { type: 'TOGGLE_SELECTED'; payload: SessionFormMedia }
    | { type: 'SET_QUEUE'; payload: SessionFormMedia[] }
    | { type: 'TOGGLE_PREVIEW'; payload: SessionFormMedia }
    | { type: 'DESELECT'; payload: SessionFormMedia['id'] }
    | { type: 'ADD_UNAVAILABLE'; payload: SessionFormMedia['id'] };

type Dispatch = (action: ImageSelectorAction) => void;
type ContextProps = {
    state: ImageSelectorState;
    dispatch: Dispatch;
};

type ImageSelectorState = {
    currentImage?: SessionFormMedia;
    index: number;
    queue: Denque<SessionFormMedia>;
    selection: SessionFormMedia[];
    unavailableImages: SessionFormMedia['id'][];
    isPreview: boolean;
};
const initialState: ImageSelectorState = {
    currentImage: undefined,
    index: 0,
    selection: [],
    unavailableImages: [],
    isPreview: false,
    queue: new Denque(),
};
const ImageSelectorContext = createContext<ContextProps>({
    state: initialState,
    dispatch: () => null,
});

export const useImageSelectorContext = () => useContext(ImageSelectorContext);

export const ImageSelectorStore = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => {
    const [state, dispatch] = useImmerReducer(
        ImageSelectorReducer,
        initialState,
    );
    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return (
        <ImageSelectorContext.Provider value={contextValue}>
            {children}
        </ImageSelectorContext.Provider>
    );
};

const ImageSelectorReducer = produce(
    (draft: ImageSelectorState = initialState, action: ImageSelectorAction) => {
        switch (action.type) {
            case 'PREV':
                if (draft.index === 0) {
                    draft.currentImage = undefined;
                    draft.index = -1;
                    draft.isPreview = false;
                    return;
                }
                draft.currentImage = draft.queue.peekAt(draft.index - 1);
                draft.index = draft.index - 1;
                return;

            case 'NEXT':
                if (draft.index === draft.queue.size() - 1) {
                    draft.currentImage = undefined;
                    draft.index = draft.queue.size();
                    draft.isPreview = false;
                    return;
                }
                draft.currentImage = draft.queue.peekAt(draft.index + 1);
                draft.index = draft.index + 1;

                return;

            case 'TOGGLE_PREVIEW':
                draft.isPreview = !draft.isPreview;
                draft.currentImage = action.payload;
                return;

            case 'SET_QUEUE':
                draft.queue = new Denque(action.payload);
                return;

            case 'TOGGLE_SELECTED':
                if (
                    draft.selection.some(
                        image => image.id === action.payload.id,
                    )
                ) {
                    draft.selection = draft.selection.filter(
                        image => image.id !== action.payload.id,
                    );
                    return;
                }
                draft.selection = [...draft.selection, action.payload];
                return;

            case 'DESELECT':
                const inSelection = draft.selection.some(
                    image => image.id === action.payload,
                );
                if (inSelection) {
                    draft.selection = draft.selection.filter(
                        image => image.id !== action.payload,
                    );
                    return;
                }

                return;

            case 'SET_INITIAL_SELECTION':
                draft.selection = action.payload;
                return;

            case 'ADD_UNAVAILABLE':
                if (!draft.unavailableImages.includes(action.payload))
                    draft.unavailableImages = [
                        ...draft.unavailableImages,
                        action.payload,
                    ];

                return;
            default:
                return;
        }
    },
);
