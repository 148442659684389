import { useEffect } from 'react';
import { SessionFormMedia } from 'src/domain/sessionForm';
import { PicturePopup } from '../NewVideo/step2/components/PicturePopup';
import { useImageSelectorContext } from '../../../state/reducers/ImageSelectorReducer';

type Props = {
    initialSelection?: SessionFormMedia[];
};

export const BrowseImages = ({ initialSelection }: Props) => {
    const { dispatch } = useImageSelectorContext();

    useEffect(() => {
        if (!initialSelection) return;
        dispatch({ type: 'SET_INITIAL_SELECTION', payload: initialSelection });
    }, [initialSelection, dispatch]);

    return <PicturePopup />;
};
