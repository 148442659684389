import { addSeconds, format } from 'date-fns';

export const formatDuration = (seconds: number): string => {
    const helperDate = addSeconds(new Date(0), seconds);
    if (seconds > 3600) {
        return helperDate.toISOString().substr(11, 8);
    }
    return format(helperDate, 'mm:ss');
};

export const formatDate = (str: string): string =>
    format(new Date(str), 'dd/MM/yyyy');

export const convertURLToHttps = (url: string) => {
    const urlObj = new URL(url);
    if (urlObj.protocol === 'https:') {
        return url;
    } else {
        urlObj.protocol = 'https:';
        return urlObj.toString();
    }
};

export const toTitleCase = function (str: string) {
    return str.replace(/\w\S*/g, function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
    });
};

export const formatPhoneNumber = function (str: string) {
    const phone = str.replace(/\D/g, '');
    const match = phone.match(/^(\d{1,3})(\d{0,2})(\d{0,3})$/);
    if (match) {
        return ` +47 ${match[1]}${match[2] ? ' ' : ''}${match[2]}${
            match[3] ? ' ' : ''
        }${match[3]}`;
    }
};
