import { LoadingCircle } from 'src/view/components/loading/Circle';

type Props = {
    opacity?: number;
};

const CardLoading = ({ opacity = 1 }: Props) => {
    return (
        <div
            style={{ opacity: opacity }}
            className="lg:w-40 w-35 h-40 lg:h-48 flex gap-2 flex-col "
        >
            <div className="relative w-full h-full">
                <LoadingCircle loading={true} isGlobal={false} />
                <div className="bg-woodyBrown"></div>
            </div>
            <div className="h-4 animate-pulse bg-woodyBrown bg-opacity-50 rounded w-2/3" />
        </div>
    );
};

export const PicturePopupLoading = () => {
    return (
        <>
            <CardLoading />
            <CardLoading opacity={0.75} />
            <CardLoading opacity={0.5} />
            <CardLoading opacity={0.25} />
        </>
    );
};
