import container from 'src/container';
import { Artist } from 'src/domain/music';
import useSWR from 'swr';

const {
    cradle: { munduClient, musicService },
} = container;

const getFavoriteArtists = async (consumerId: string): Promise<Artist[]> => {
    const { artists } = await munduClient.getMusicalProfile(consumerId);
    return artists ? musicService.listArtists(artists) : [];
};

export const useFavoriteArtists = (consumerId?: string) => {
    const { data, error } = useSWR(
        consumerId ? [consumerId, 'musicalProfile'] : null,
        getFavoriteArtists,
    );

    return {
        isLoading: !data && !error,
        artists: data,
    };
};
