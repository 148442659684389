export const NextIcon = () => {
    return (
        <svg className="" height="100%" width="100%" viewBox="0 0 100 100">
            <polygon
                points="0,0 0,100 100,50"
                className="stroke-current fill-current"
            />
            <line
                className="stroke-current"
                strokeLinecap="round"
                strokeWidth="40"
                x1="100"
                y1="0"
                x2="100"
                y2="100"
            />
        </svg>
    );
};
