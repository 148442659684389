import { useHistory } from 'react-router-dom';
import container from 'src/container';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import {
    checkIfEmailExist,
    checkIfPhoneNumberExist,
    getOrganizationId,
    UserRole,
} from 'src/domain/user';
import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { useNotification } from 'src/components/Notification/useNotfication';
import { Section } from 'src/components/Section';
import {
    IsString,
    IsNotEmpty,
    IsPhoneNumber,
    IsOptional,
    IsEmail,
    ValidateIf,
} from 'class-validator';
import Input from 'src/components/FormComponents/Input';
import { PeopleSVG } from 'src/assets/svg';
import Form from 'src/components/FormComponents/Form';
import { MessageIcon } from 'src/components/Icons/icons';
import PhoneInput from 'src/components/FormComponents/PhoneInput';

const {
    cradle: { munduClient },
} = container;

class NextOfKinSchema {
    @IsString()
    @IsNotEmpty({ message: 'Fornavn må fylles ut.' })
    firstName: string;

    @IsString()
    @IsNotEmpty({ message: 'Etternavn må fylles ut.' })
    lastName: string;

    @IsNotEmpty({ message: 'Telefonnr må fylles ut' })
    @IsPhoneNumber('NO', { message: 'Telefonnr er ikke gyldig' })
    phoneNumber: string;

    @IsOptional()
    @ValidateIf(o => o.email?.length !== 0)
    @IsEmail({}, { message: 'Epost er ikke gyldig' })
    email?: string;
}

export const NewNextOfKin = () => {
    const history = useHistory();
    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();
    const notify = useNotification();
    const user = useSignedInUser();
    if (!user || !currentConsumer) return null;

    const handleSubmit = async ({
        firstName,
        lastName,
        phoneNumber,
        email,
    }: NextOfKinSchema) => {
        try {
            if (email) await checkIfEmailExist(email);
            await checkIfPhoneNumberExist(phoneNumber);

            await munduClient.createNextofKin({
                organizationId: getOrganizationId(user),
                email: email ? email : undefined,
                profile: { firstName, lastName, phoneNumber },
                followers: {
                    consumerIds: [currentConsumer.id],
                    creatorIds:
                        user.role === UserRole.creator ? [user.id] : undefined,
                },
            });

            notify('Pårørende ble opprettet');
            history.goBack();
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return (
        <div className="flex flex-col max-w-3xl mx-auto">
            <Section title="Inviter pårørende">
                <Form
                    className="bg-lightPeach text-woodyBrown"
                    schema={NextOfKinSchema}
                    onSubmit={handleSubmit}
                    mode="onChange"
                >
                    {({
                        register,
                        formState: { isValid, errors, isSubmitting },
                    }) => (
                        <div className="grid grid-cols-2 gap-4 h-full">
                            <div className="col-span-full flex justify-center">
                                {/* TODO: Replace with Role icon when ready */}
                                <div className="rounded-full w-36 h-36 bg-starkWhite flex justify-center items-center mb-4">
                                    <PeopleSVG className="w-[18px] h-[18px]" />
                                </div>
                            </div>
                            <Input
                                className="text-input"
                                type="text"
                                label="Fornavn*"
                                error={errors.firstName}
                                {...register('firstName')}
                            />
                            <Input
                                className="text-input"
                                type="text"
                                label="Etternavn*"
                                error={errors.lastName}
                                {...register('lastName')}
                            />
                            <PhoneInput
                                className="text-input"
                                type="tel"
                                error={errors.phoneNumber}
                                label={'Telefonnummer*'}
                                {...register('phoneNumber')}
                            />
                            <Input
                                className="text-input"
                                type="email"
                                label="Epost"
                                error={errors.email}
                                leadingIcon={MessageIcon}
                                {...register('email')}
                            />

                            <div className="flex items-center gap-6">
                                <Input
                                    value="Send invitasjon"
                                    className="primary-btn-small"
                                    disabled={!isValid || isSubmitting}
                                    type="submit"
                                />
                            </div>
                        </div>
                    )}
                </Form>
            </Section>
        </div>
    );
};
