export class AnimatedValue {
    value: number;
    constructor(value: number) {
        this.value = value;
    }
    setValue = (value: number) => {
        this.value = value;
    };

    interpolate({
        inputRange,
        outputRange,
    }: {
        inputRange: number[];
        outputRange: number[];
    }): number {
        let output = 0;
        if (this.value < inputRange[0]) {
            output = outputRange[0];
        } else if (this.value > inputRange[-1]) {
            output = outputRange[-1];
        } else {
            for (let i = 0; i < inputRange.length; i++) {
                const y1 = outputRange[i];
                const y2 = outputRange[i + 1];
                const x1 = inputRange[i];
                const x2 = inputRange[i + 1];

                if (x1 <= this.value && this.value <= x2) {
                    output = y1 + ((y2 - y1) * (this.value - x1)) / (x2 - x1);
                    break;
                }
            }
        }
        return parseFloat(output.toFixed(4));
    }
}
