import { useHistory } from 'react-router-dom';
import { ArrowLeftIcon } from 'src/components/Icons/icons';
import { useHomePath } from 'src/view/hooks/useHomePath';

type Props = {
    onClick?: () => void;
    title?: string;
};

export const HomeButton = ({ onClick, title }: Props) => {
    const home = useHomePath();
    const history = useHistory();
    function GoHome() {
        history.push(home);
    }
    return (
        <button
            className="bg-lightPeach border-1.6px flex-shrink-0 border-woodyBrown text-woodyBrown rounded-full h-8 px-4 group relative flex items-center overflow-hidden whitespace-nowrap"
            type="submit"
            onClick={onClick || GoHome}
        >
            <div className="absolute top-0 h-full w-full left-1/2 translate-x-1/2  group-hover:-left-1/2 duration-300 ">
                <ArrowLeftIcon />
            </div>
            <span className="group-hover:-translate-x-[150%] duration-500">
                {title}
            </span>
        </button>
    );
};
