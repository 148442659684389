import { AnyAction, Dispatch } from 'redux';
import { RootAction } from './_actions';
import { OverridedMixpanel } from 'mixpanel-browser';
import { RootState } from '.';
import { getOrganizationId, User } from 'src/domain/user';

interface MiddlewareAPI<S, A extends AnyAction> {
    dispatch: Dispatch<A>;
    getState(): S;
}
type MyMiddleware<S, A extends AnyAction = AnyAction> = (
    store: MiddlewareAPI<S, A>,
) => (next: Dispatch<A>) => (action: A) => ReturnType<Dispatch<A>>;

class Mixpanel {
    private mixpanel: OverridedMixpanel;
    constructor(mixpanel: OverridedMixpanel) {
        this.mixpanel = mixpanel;
    }

    private onLogin(user: User) {
        this.mixpanel.identify(user.id);
        this.mixpanel.set_group('organization', getOrganizationId(user));
        this.mixpanel.set_group('role', user.role);
        this.mixpanel.register_once({
            FIRST_LOGIN: new Date().toISOString(),
        });
        this.mixpanel.track('LOGIN');
        this.mixpanel.time_event('LOGOUT');
    }
    private onLogout() {
        this.mixpanel.track('LOGOUT');
        this.mixpanel.reset();
    }
    private onRefresh() {
        this.mixpanel.track('LOGOUT');
    }

    public MixpanelMiddleWare: MyMiddleware<RootState, RootAction> =
        store => next => action => {
            const { getState } = store;
            const {
                user: { user },
            } = getState();

            if (user) {
                switch (action.type) {
                    case 'MUNDU/APP/INITIALIZE_APP':
                        break;
                    case 'MUNDU/USERINFO/LOGIN':
                        this.onLogin(user);
                        break;
                    case 'MUNDU/USERINFO/LOGOUT':
                        this.onLogout();
                        break;
                    case 'MUNDU/USERINFO/REFRESH':
                        this.onRefresh();
                        break;
                    default:
                        break;
                }
            }
            return next(action);
        };
}

export default Mixpanel;
