import avatarDefault from 'src/assets/images/client_default.png';

type Props = {
    opacity?: number;
};

export const CardUserLoading = ({ opacity = 1 }: Props) => {
    return (
        <div style={{ opacity: opacity }} className="text-center mt-4">
            <div className="flex items-center justify-center">
                <img
                    className="w-35 opacity-25 rounded-full h-35 object-cover"
                    src={avatarDefault}
                    alt="avatar consumer"
                />
            </div>
            <p className="mt-4 font-medium">...</p>
        </div>
    );
};
