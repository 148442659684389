import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFullName } from 'src/domain/user';
import { useAvatar } from 'src/view/hooks/useAvatar';
import { ProfileBanner } from '../../Users/Profile/ProfileBanner';
import { useCreator } from 'src/view/hooks/creator';
import { CreatorProfileEdit } from './CreatorProfileEdit';
import { ConsumerList } from './ConsumerList';
import { ScrollableModal } from 'src/components/Modals/ScrollableModal';
import { useSignInIdentities } from 'src/view/hooks/useSignInIdentities';

export const CreatorProfile = () => {
    const { id } = useParams<{ id: string }>();
    const { data: creator } = useCreator(id);
    const { src, isDefault } = useAvatar(creator?.profile.avatar);
    const [editing, setEditing] = useState(false);
    const { phoneNumber, emailAddress, isLoading } = useSignInIdentities(id);

    if (!creator) return null;

    return (
        <div className="flex flex-col gap-4">
            <ScrollableModal
                visible={editing}
                onClose={() => setEditing(false)}
            >
                <CreatorProfileEdit creator={creator} />
            </ScrollableModal>
            <ProfileBanner
                name={getFullName(creator)}
                username={isLoading ? '...' : phoneNumber || emailAddress || ''}
                entity="BRUKERKONTAKT"
                onClick={() => setEditing(true)}
                avatar={src}
                rounded={isDefault}
            />

            <ConsumerList creator={creator} />
        </div>
    );
};
