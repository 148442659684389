import { ArrowLeftIcon } from '../Icons/icons';

type Props = {
    onClick?: () => void;
    className?: string;
    disabled?: boolean;
};
export const BackButton = ({ onClick, className, disabled = false }: Props) => {
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={`group  flex-shrink-0 border-1.6px overflow-hidden w-16 h-8
            border-woodyBrown text-woodyBrown hover:border-peachOrange disabled:border-starkWhite disabled:pointer-events-none hover:text-woodyBrown hover:bg-peachOrange rounded-full text-base px-4 transition-all duration-150 focus:outline-none hover:outline-none outline-none relative  ${className}`}
        >
            <div className="absolute top-0 h-full w-full left-1/2 translate-x-1/2  group-hover:-left-1/2 duration-500  ">
                <ArrowLeftIcon />
            </div>
            <div className="absolute top-0 h-full w-full -right-1/2 -translate-x-1/2  group-hover:right-1/2 duration-500 ">
                <ArrowLeftIcon />
            </div>
        </button>
    );
};
