import { differenceInMilliseconds, parseISO } from 'date-fns';
import container from 'src/container';
import { IncompleteSession } from 'src/domain/sessionForm';
import useSWR from 'swr';
import { useSignedInUser } from '../useSignedInUser';

const {
    cradle: { munduClient },
} = container;

export const useSessions = (consumerId: string) => {
    const { data, ...rest } = useSWR([consumerId, 'sessions'], () =>
        munduClient.listSessions({ consumerId }),
    );
    const sessions = data || [];
    const isLoading = !data && !rest.error;
    const sortedSessions = sessions.sort((a, b) =>
        differenceInMilliseconds(
            parseISO(b.createdDate),
            parseISO(a.createdDate),
        ),
    );
    return { sessions: sortedSessions, isLoading, ...rest };
};

export const useIncompleteSessions = (consumerId?: string) => {
    const user = useSignedInUser();

    const { data, mutate, error } = useSWR<IncompleteSession[]>(
        consumerId && user ? [user.id, consumerId, 'IncompleteSessions'] : null,
        () => munduClient.listIncompleteSessions(user?.id, consumerId),
    );

    return {
        incompleteSessions: data || [],
        isLoading: !data && !error,
        refresh: mutate,
        error,
    };
};
