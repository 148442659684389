import { useHistory } from 'react-router-dom';
import { Consumer } from 'src/domain/consumer';
import { useConsumers } from 'src/view/hooks';
import { useCurrentConsumerContext } from '../../Home/NewHome/reducer';
import { UserSelectorOption } from '../buttons/SelectConsumer/SelectorOption';

export const ConsumerSelector = () => {
    const { consumers } = useConsumers();
    const {
        dispatch,
        state: { currentConsumer },
    } = useCurrentConsumerContext();

    const history = useHistory();
    const onSelect = (consumer: Consumer) => {
        dispatch({ type: 'SELECT_CONSUMER', payload: consumer });
        history.push(`/app/users/profile/${consumer.id}`);
    };
    return (
        <div className="overflow-y-auto shadow-scrollTop w-full">
            {consumers.map(consumer => {
                return (
                    <UserSelectorOption
                        key={consumer.id}
                        user={consumer}
                        onClick={() => onSelect(consumer)}
                        isSelected={currentConsumer?.id === consumer.id}
                    />
                );
            })}
        </div>
    );
};
