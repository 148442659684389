import { forwardRef, memo } from 'react';
import { OutBoundLink } from '../../components/outboundLink';
type AboutProps = {
    bgColor?: string;
    id: string;
};

const About = forwardRef<HTMLElement, AboutProps>(
    ({ bgColor = 'woodyBrown', id }: AboutProps, ref) => (
        <section
            ref={ref}
            id={id}
            className={`relative z-10 grid justify-center px-6 sm:px-32 xl:px-72 py-16 bg-${bgColor}`}
        >
            <div className="prose sm:prose-lg md:prose-xl xl:prose-xl xl:max-w-none">
                <h1>Om Mundu</h1>
                <p className="lead">
                    Demens blir ansett som en større samfunnsmessig utfordring
                    enn Covid-19 i framtiden. Allerede har vi passert{' '}
                    <OutBoundLink href="https://demenskartet.no">
                        100.000
                    </OutBoundLink>{' '}
                    som er rammet – og med dem – ca 400.000 pårørende.
                </p>
                <div className="grid xl:grid-cols-2 xl:gap-8">
                    <div>
                        <p>
                            Det bor mye livsglede i et menneske, også hos dem
                            med demens. Det kan være litt lett å overse, og
                            veldig vanskelig å oppdage. Hemmeligheten er musikk
                            fra eget liv. Musikk vekker gleden og skaper lyse
                            øyeblikk for den som er rammet og alle som står
                            rundt.
                        </p>
                        <p>
                            Mundu bygges i samarbeid med NKS Olaviken
                            alderspsykiatriske sykehus, musikkterapeuter og
                            helsepersonell.
                        </p>
                    </div>
                    <div>
                        <p>
                            En validert opplevelsesteknologi som tilbyr musikk
                            for helse – i institusjon og hjemme.
                        </p>
                        <p>
                            Mundu er egnet for eldre med mild kognitiv svikt
                            eller mild til moderat demens, gjerne med
                            tilleggssymptomer og plager i form av angst, uro og
                            depresjon. Mundu samler pasienten og pårørende i
                            felles aktivitet, kan motivere til sykebesøk og
                            bidra til å bryte den store tausheten pårørende ofte
                            opplever.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    ),
);
About.displayName = 'About';

export default About;
