import { useCallback } from 'react';
import { useDropzone, FileRejection, ErrorCode } from 'react-dropzone';
import { clearItems, useImageUploadContext } from './state';
import { FolderIcon, TrashIconSVG } from 'src/assets/svg';
import { IconButton } from 'src/components/Buttons/IconButton';
import { ForbiddenIcon, ImagesIcon } from 'src/components/Icons/icons';
import { useNotification } from 'src/components/Notification/useNotfication';
import _ from 'lodash';
import cx from 'classnames';

interface Props {
    handleAcceptedFiles: (files: File[]) => void;
    children: React.ReactNode | React.ReactNode[];
}

export const FileDrop = ({ handleAcceptedFiles, children }: Props) => {
    const { state, dispatch } = useImageUploadContext();
    const notify = useNotification();
    const onDropRejected = useCallback(
        (fileRejections: FileRejection[]) => {
            const errors = fileRejections.flatMap(x => x.errors);
            const invalidTypes = _.countBy(errors, x => x.code)[
                ErrorCode.FileInvalidType
            ];
            const unknownErrors = errors.length - invalidTypes;

            if (invalidTypes > 0) {
                notify(
                    `${invalidTypes} ${
                        invalidTypes === 1 ? 'fil' : 'filer'
                    }  av ustøttet format. Mundu støtter kun JPEG, JPG, PNG og WebP`,
                    true,
                );
            }
            if (unknownErrors > 0) {
                notify(
                    `${unknownErrors} ${
                        unknownErrors === 1 ? 'fil' : 'filer'
                    }ble avvist.`,
                    true,
                );
            }
        },
        [notify],
    );

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        open,
    } = useDropzone({
        onDropRejected,
        onDropAccepted: handleAcceptedFiles,
        multiple: true,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png'],
            'image/webp': ['.webp'],
        },
        noClick: true,
    });

    return (
        <div
            {...getRootProps()}
            className={cx(
                isDragAccept &&
                    'border-2 border-palmleafGreen  bg-palmeleafGreen bg-opacity-10 border-opacity-80',
                isDragReject &&
                    'border-2 border-venetianRed bg-venetianRed bg-opacity-10 border-opacity-80',
                isDragActive && 'border-2 border-woodyBrown border-opacity-80',
                !(isDragAccept || isDragReject || isDragActive) &&
                    'border-2 border-transparent border-opacity-[0.02]',

                'w-full h-full flex flex-col  relative rounded-lg transition-all duration-200 ',
            )}
        >
            <div className="flex py-2 px-6 gap-3 z-10 ">
                <IconButton
                    Icon={FolderIcon}
                    type="button"
                    label="Legg til bilder"
                    onClick={open}
                />
                <IconButton
                    Icon={TrashIconSVG}
                    onClick={() => dispatch(clearItems())}
                    label="Forkast"
                    disabled={state.items.length === 0}
                />
            </div>
            <input {...getInputProps()} name="imageFiles" />
            <div className=" text-woodyBrown  w-full h-full justify-center items-center absolute inset-0">
                <div
                    className={cx(
                        'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  text-[400] w-1/4 h-1/4 opacity-10 transition-all duration-200',
                        isDragReject && 'text-venetianRed',
                        isDragAccept && 'text-palmleafGreen',
                        !isDragActive && 'text-woodyBrown',
                    )}
                >
                    {isDragReject ? <ForbiddenIcon /> : <ImagesIcon />}
                </div>
            </div>
            {children}
        </div>
    );
};
