import cx from 'classnames';
import { IconComponent } from './TextIcon';

type Props = {
    SVG: IconComponent;
    onClick?: () => void;
    type?: 'leading' | 'trailing' | 'center';
};
export const InputIcon = ({ SVG, onClick, type = 'leading' }: Props) => {
    return (
        <div
            className={cx(
                'absolute flex items-center justify-center',
                type === 'leading' && 'left-0 px-[0.8em]',
                type === 'trailing' && 'right-0 px-[0.8em]',
                type === 'center' &&
                    'top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  ',
            )}
        >
            <div
                onClick={onClick}
                className={cx(
                    'relative pb-[1em] w-[1em]  z-10',
                    !onClick && 'pointer-events-none',
                )}
            >
                {<SVG />}
            </div>
        </div>
    );
};
