import { Footer } from '../components/footer';
import ReactPlayer from 'react-player';
import { ScrollToTopOnMount, LandingPageHeader } from '../components';
import { Screen } from 'src/view/routes/Router';
import { MediaIconSVG } from 'src/assets/svg';
import { AZURE_STORAGE_URL } from 'src/config';

export const Tippetue = () => {
    const headerColors = {
        textColor: 'woodyBrown',
        activeTextColor: 'mongooseGray',
        backgroundColor: 'lightPeach',
    };
    const thumbnailUrl = `${AZURE_STORAGE_URL}/landingpage-content/tippetue_premiere.jpg`;
    const royOgVigdisUrl = `${AZURE_STORAGE_URL}/landingpage-content/RoyOgVigdis.png`;
    const videoUrl = `${AZURE_STORAGE_URL}/landingpage-content/Vigdis%20-%20webside.mp4`;
    return (
        <div id="tippeTue">
            <ScrollToTopOnMount />
            <LandingPageHeader
                colors={headerColors}
                scrollItems={false}
                navItems={[{ id: Screen.Mundu, name: 'Hjem' }]}
            />
            <div className="bg-lightPeach">
                <div className="grid justify-center w-full pt-32 pb-16 px-6 sm:px-32 xl:px-72">
                    <div className="prose prose-light sm:prose-lg md:prose-xl xl:prose-xl">
                        <div className="relative pb-thumbHeight not-prose">
                            <div className="absolute rounded-2xl shadow inset-0 overflow-hidden  group">
                                <ReactPlayer
                                    id="video"
                                    controls
                                    url={videoUrl}
                                    light={thumbnailUrl}
                                    width="100%"
                                    height="100%"
                                    playIcon={<MediaIconSVG />}
                                />
                            </div>
                        </div>
                        <h1 className="pt-16">Vigdis, Roy og Tippetue</h1>
                        <p className="lead">
                            Vigdis og Roy. Et sammensveiset par, helt siden de
                            unge og forelsket falt for hverandre på 70-tallet.
                            De fikk etterhvert to flotte barn og bygget en
                            familie sammen, og har hatt et aktivt og godt liv,
                            rikt på øyeblikk og opplevelser.
                        </p>
                        <p>
                            Begge elsker musikk. Det ble timesvis med gode
                            stunder over platespilleren og minneverdige
                            konserter i Grieghallen såvel som New Orleans.
                        </p>
                        <p>
                            Vigdis var alltid en aktiv og sprudlende kvinne.
                            Venninnegjengen, som har holdt sammen siden
                            ungdommen, har hatt faste turer i den vakre naturen
                            rundt Bergen. De kalte seg for Tippetue, et
                            utkikkspunkt fra Fløyen. Men navnet kom opprinnelig
                            ikke derfra. De leverte inn tippekupongen i håp om
                            gevinst og fellesturer utenlands, men Roy fikk dem
                            vekk fra dette. Han åpnet heller en sparekonto med
                            navnet Tippetue. Og til utlandet kom de, med Roy som
                            reisearrangør for fire herlige venninner.
                        </p>
                        <div className="float-left px-16 pb-4">
                            <img
                                src={royOgVigdisUrl}
                                alt="vigdis_and_roy"
                                className="shadow rounded-2xl max-w-full h-auto align-middle border-none"
                            />
                        </div>
                        <p>
                            Så fikk Vigdis påvist demens i 2015. Minnet ble
                            svakere og aktiviteten lavere. Hun kunne ikke lenger
                            bli med på Tippetue-turene, men venninnene har sendt
                            henne postkort annenhver uke i fem år, gjerne med
                            selvskrevne dikt, for å ta Vigdis med på
                            opplevelsene.
                        </p>
                        <p>
                            Filmen du ser her er et utdrag av Tippetue, en
                            Mundu-film med fotos fra Vigdis og Roy sitt
                            minnerike liv og musikk Vigdis valgte ut ved å vise
                            tommel opp på forslag, siden det ikke var like lett
                            å prate lenger. Premiére-visning for venninnegjengen
                            i Tippetue var en selvfølge!
                        </p>
                        <p>
                            Roy og Vigdis så filmen flere ganger i uken på
                            nettbrettet da Vigdis fortsatt bodde hjemme. Nå bor
                            Vigdis på Domkirkehjemmet. Hun har det godt der. Roy
                            besøker Vigdis ofte, en rolig spasertur fra hjemmet.
                            Hver gang har han med seg nettbrettet og viser
                            Tippetue-filmen. Da spør Roy henne om det var fint å
                            se den igjen. Får han en tommel opp, er alt bra.
                        </p>
                        <p className="float-left italic opacity-80">
                            Historien, bilde og video er gitt med godkjennelse
                            fra Roy og Tippetue
                        </p>
                    </div>
                </div>
                <Footer textColor="woodyBrown" backgroundColor="transparent" />
            </div>
        </div>
    );
};
