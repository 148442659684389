import { useMemo } from 'react';
import container from 'src/container';
import useSWR from 'swr';
import { useSignedInUser } from '../useSignedInUser';

const {
    cradle: { munduClient },
} = container;

export const useCreators = () => {
    const user = useSignedInUser();

    const organizationId = useMemo(() => {
        if (!user) {
            return;
        }
        return user.id;
    }, [user]);

    const { data, error, mutate } = useSWR(
        [organizationId, 'creators/organizationId'],
        () => munduClient.listCreators({ organizationId }),
    );

    return {
        creators: data || [],
        mutate: mutate,
        isLoading: !error && !data,
        isError: error,
    };
};

export const useConsumersFollowers = (consumerId: string) => {
    return useSWR([consumerId, 'creators/consumerId'], () =>
        munduClient.listCreators({ consumerId }),
    );
};

export const useCreator = (creatorId: string) => {
    return useSWR([creatorId, 'creators/creatorId'], () =>
        munduClient.getCreator(creatorId),
    );
};
