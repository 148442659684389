import {
    checkIfEmailExist,
    checkIfPhoneNumberExist,
    getOrganizationId,
} from 'src/domain/user';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import container from 'src/container';
import { useHistory } from 'react-router-dom';
import { useNotification } from 'src/components/Notification/useNotfication';
import {
    IsString,
    Length,
    IsNotEmpty,
    IsPhoneNumber,
    IsEmail,
    IsOptional,
    ValidateIf,
} from 'class-validator';
import Input from 'src/components/FormComponents/Input';
import Form from 'src/components/FormComponents/Form';
import { PeopleSVG } from 'src/assets/svg';
import { MessageIcon } from 'src/components/Icons/icons';
import PhoneInput from 'src/components/FormComponents/PhoneInput';

const {
    cradle: { munduClient },
} = container;

class CreatorSchema {
    @IsString()
    @Length(2, 30, { message: 'Må være mellom 2 og 30 tegn' })
    @IsNotEmpty({ message: 'Må fylles ut.' })
    firstName: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    lastName: string;

    @IsString()
    @IsPhoneNumber('NO', { message: 'Telefonnr er ikke gyldig' })
    phoneNumber: string;

    @IsOptional()
    @ValidateIf(o => o.email?.length !== 0)
    @IsEmail({}, { message: 'Epost er ikke gyldig' })
    email?: string;
}

export const NewCreator = () => {
    const history = useHistory();
    const user = useSignedInUser();
    const notify = useNotification();
    if (!user) return null;
    const organizationId = getOrganizationId(user);

    const handleSubmit = async (data: CreatorSchema) => {
        try {
            if (data.email) await checkIfEmailExist(data.email);
            await checkIfPhoneNumberExist(data.phoneNumber);

            await munduClient.createCreator({
                email: data.email ? data.email : undefined, //If user has written an email and then removed it, email will be empty string, which server does not like
                profile: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    phoneNumber: data.phoneNumber,
                },
                organizationId,
                followers: {
                    consumerIds: [],
                },
            });
            notify('Brukerkontakt opprettet');
            history.push('/app/creators');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return (
        <div className="flex flex-col max-w-3xl mx-auto">
            <Form
                className="bg-lightPeach text-woodyBrown"
                schema={CreatorSchema}
                onSubmit={handleSubmit}
                mode="onChange"
            >
                {({
                    register,
                    formState: { isValid, errors, isSubmitting },
                }) => (
                    <div className="grid grid-cols-2 gap-4 h-full">
                        <div className="col-span-full flex justify-center">
                            {/* TODO: Replace with Role icon when ready */}
                            <div className="rounded-full w-36 h-36 bg-starkWhite flex justify-center items-center mb-4">
                                <PeopleSVG className="w-[18px] h-[18px]" />
                            </div>
                        </div>

                        <Input
                            className="text-input"
                            type="text"
                            label="Fornavn*"
                            error={errors.firstName}
                            {...register('firstName')}
                        />
                        <Input
                            className="text-input"
                            type="text"
                            label="Etternavn*"
                            error={errors.lastName}
                            {...register('lastName')}
                        />
                        <PhoneInput
                            className="text-input"
                            type="tel"
                            error={errors.phoneNumber}
                            label={'Telefonnummer*'}
                            {...register('phoneNumber')}
                        />
                        <Input
                            className="text-input"
                            type="email"
                            label="Epost"
                            error={errors.email}
                            leadingIcon={MessageIcon}
                            {...register('email')}
                        />
                        <div className="flex items-center gap-6">
                            <Input
                                value={'Send invitasjon'}
                                className="primary-btn-small"
                                disabled={!isValid || isSubmitting}
                                type="submit"
                            />
                        </div>
                    </div>
                )}
            </Form>
        </div>
    );
};
