import { useEffect } from 'react';
import { useRef } from 'react';
import { CloseIconSVG } from 'src/assets/svg';
import { useClickOutside } from 'src/view/hooks';
import cx from 'classnames';
import { createPortal } from 'react-dom';
import { Transition, TransitionStatus } from 'react-transition-group';

type ModalProps = {
    children: React.ReactNode | React.ReactNode[];
    visible: boolean;
    dark?: boolean;
    fadeDuration?: number;
    appear?: boolean;
    closeOnOutsideClick?: boolean;
    onClose: () => void;
};

const transitionStyles = {
    entering: {
        opacity: 0,
        background: 'rgba(0, 0, 0, 0)',
        backdropFilter: 'blur(0px)',
    },
    entered: {
        opacity: 1,
        background: 'rgba(0, 0, 0, 0.2)',
        backdropFilter: 'blur(2px)',
    },
    exiting: {
        opacity: 0,
        background: 'rgba(0, 0, 0, 0)',
        backdropFilter: 'blur(2px)',
    },
    exited: {
        opacity: 0,
        background: 'rgba(0, 0, 0, 0)',
        backdropFilter: 'blur(2px)',
    },
    unmounted: {},
};

export const ScrollableModal = ({
    children,
    visible,
    dark = false,
    appear = true,
    fadeDuration = 150,
    closeOnOutsideClick = true,
    onClose,
}: ModalProps) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    useClickOutside(
        wrapperRef,
        closeOnOutsideClick ? () => onClose() : () => null,
    );

    const wrapperElement = useRef(document.createElement('div'));

    useEffect(() => {
        const current = wrapperElement.current;
        const modalRoot = document.querySelector('#modal-root') as HTMLElement;
        modalRoot.appendChild(current);
        return () => void modalRoot.removeChild(current);
    }, []);

    const Modal = (
        <Transition
            in={visible}
            appear={appear}
            timeout={{
                appear: 0,
                enter: fadeDuration,
                exit: fadeDuration,
            }}
            unmountOnExit={true}
        >
            {(state: TransitionStatus) => (
                <div
                    style={{
                        transition: `opacity ${fadeDuration}ms ease-in-out, background ${fadeDuration}ms ease-in-out, backdropFilter ${fadeDuration}ms ease-in-out`,
                        ...transitionStyles[state],
                    }}
                    className={cx(
                        'fixed h-full w-full inset-0 z-70 flex items-center justify-center',
                    )}
                >
                    <div
                        className={cx(
                            'relative rounded-xl max-h-[80%] max-w-2xl overflow-y-auto',
                            dark ? 'bg-woodyBrown' : 'bg-lightPeach',
                        )}
                        ref={wrapperRef}
                    >
                        <button
                            onClick={onClose}
                            className={cx(
                                'sticky float-right right-2 top-2 p-4 group bg-transparent transition-all rounded-lg',
                                dark
                                    ? 'text-lightPeach hover:bg-lightPeach hover:text-woodyBrown'
                                    : 'text-woodyBrown hover:bg-woodyBrown hover:text-lightPeach',
                            )}
                        >
                            <CloseIconSVG />
                        </button>
                        <div className="px-10 py-16">{children}</div>
                    </div>
                </div>
            )}
        </Transition>
    );

    return createPortal(Modal, wrapperElement.current);
};
