import { produce } from 'immer';
import { Consumer } from 'src/domain/consumer';
import { SASInfo, User } from 'src/domain/user';
import { createReducer, getType } from 'typesafe-actions';
import { RootState } from '..';
import { UserActions } from '../_actions';
import { SASTokens, UserState } from './types';

const stateKey = 'user';

/* ------------- Initial State ------------- */
const INITIAL_STATE: UserState = {
    user: undefined,
    sasTokens: {
        avatarSAS: undefined,
        commonMediaSAS: undefined,
        personalMediaSAS: {},
    },
};

/* ------------- Reducers ------------- */
const setUser = (
    state: UserState,
    { payload: { user } }: ReturnType<typeof UserActions.setUser>,
): UserState =>
    produce(state, draft => {
        draft.user = user;
    });

const login = (state: UserState): UserState => state;
const logout = (state: UserState): UserState => state;
const refresh = (state: UserState): UserState => state;

const clearUserState = (state: UserState): UserState =>
    produce(state, draft => {
        draft.user = INITIAL_STATE.user;
        draft.sasTokens = INITIAL_STATE.sasTokens;
    });

const setAvatarSAS = (
    state: UserState,
    { payload: { sas } }: ReturnType<typeof UserActions.setAvatarSAS>,
): UserState =>
    produce(state, draft => {
        draft.sasTokens.avatarSAS = sas;
    });

const setPersonalMediaSAS = (
    state: UserState,
    {
        payload: { sas, consumerId },
    }: ReturnType<typeof UserActions.setPersonalMediaSAS>,
): UserState =>
    produce(state, draft => {
        draft.sasTokens.personalMediaSAS[consumerId] = sas;
    });

const setCommonMediaSAS = (
    state: UserState,
    { payload: { sas } }: ReturnType<typeof UserActions.setCommonMediaSAS>,
): UserState =>
    produce(state, draft => {
        draft.sasTokens.commonMediaSAS = sas;
    });

/* ------------- Hookup Reducers To Types ------------- */
const reducer = createReducer(INITIAL_STATE, {
    [getType(UserActions.setUser)]: setUser,
    [getType(UserActions.login)]: login,
    [getType(UserActions.logout)]: logout,
    [getType(UserActions.refresh)]: refresh,
    [getType(UserActions.setAvatarSAS)]: setAvatarSAS,
    [getType(UserActions.setPersonalMediaSAS)]: setPersonalMediaSAS,
    [getType(UserActions.setCommonMediaSAS)]: setCommonMediaSAS,
    [getType(UserActions.clearUserState)]: clearUserState,
});

const reducerMap = { [stateKey]: reducer };

/* ------------- Selectors ------------- */

export const getReducerState = (state: RootState): UserState => state[stateKey];

const selectors = {
    getUser: (state: UserState): User | undefined => state.user,
    getSASTokens: (state: UserState): SASTokens => state.sasTokens,
    getPersonalMediaSas: (
        state: UserState,
        consumerId?: Consumer['id'],
    ): SASInfo | undefined =>
        state.sasTokens.personalMediaSAS[consumerId || ''],

    getCommonMediaSas: (state: UserState): SASInfo | undefined =>
        state.sasTokens.commonMediaSAS,
    getAvatarSas: (state: UserState): SASInfo | undefined =>
        state.sasTokens.avatarSAS,
};

/* ------------- Export ------------- */
export default {
    // default export
    selectors,
    INITIAL_STATE,
    stateKey,
    reducer,
    reducerMap,
};
