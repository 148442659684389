import { createRef, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UploadIcon } from 'src/assets/svg';
import CheckBox from '../../../components/FormComponents/CheckBox';
import { toggleConsent } from './state';
import { useImageUpload } from './state/hooks';
import cx from 'classnames';
import { PrivacyScreen } from '../Mundu';
import { IconButton } from 'src/components/Buttons/IconButton';
import { ProgressBar } from 'src/components/ProgressBar';
import { ScrollableModal } from 'src/components/Modals/ScrollableModal';

export const SubmitPanel = ({
    isNextofkin = false,
}: {
    isNextofkin?: boolean;
}) => {
    const {
        state: { items, consent, recipient },
        dispatch,
        submit,
        submitting,
        submitted,
        uploadPercentage,
    } = useImageUpload();
    const submitRef = createRef<HTMLButtonElement>();
    const history = useHistory();
    const [showPrivacy, setShowPrivacy] = useState(false);

    const handleConsent = useCallback(() => {
        dispatch(toggleConsent());
        submitRef.current?.focus();
    }, [dispatch, submitRef]);

    useEffect(() => {
        if (submitted) {
            setTimeout(
                () =>
                    isNextofkin
                        ? history.push('/app/nextofkin-image-list')
                        : history.push(`/app/users/profile/${recipient?.id}`),
                1000,
            );
        }
    }, [history, recipient, submitted, isNextofkin]);

    return (
        <>
            <ScrollableModal
                visible={showPrivacy}
                onClose={() => setShowPrivacy(false)}
                dark
            >
                <PrivacyScreen showHeader={false} showFooter={false} />
            </ScrollableModal>
            <div className="flex flex-col flex-1 h-full w-full text-left items-center bg-lightPeach  text-woodyBrown pb-4 px-4 gap-4 ">
                <h1 className="text-xl font-bold font-logo text-center w-full  ">
                    Samtykke
                </h1>
                <div className="flex flex-col overflow-y-scroll w-full items-center">
                    <div className="text-xs sm:text-sm text-center">
                        {' '}
                        Hos oss i Mundu tar vi personvern på alvor. Det er
                        derfor viktig at alle avbildede personer samtykker,
                        eller at du på vegne av avbildede personer kan samtykke
                        til at disse bildene kan benyttes i forbindelse med vår
                        tjeneste. Les mer om Mundus personvernerklæring{' '}
                        <button
                            className="text-sm md:text-md font-semibold underline"
                            onClick={() => setShowPrivacy(true)}
                        >
                            her.
                        </button>
                    </div>
                </div>
                <div className="flex flex-col justify-between items-center flex-1">
                    <CheckBox
                        value={consent}
                        disabled={items.length === 0}
                        onClick={handleConsent}
                        label="Jeg samtykker"
                    />
                    <div
                        className={cx(
                            'w-full flex flex-col items-center gap-5 mb-6  visible translate-all opacity-100',
                            submitting || (submitted && 'hidden opacity-0'),
                        )}
                    >
                        <IconButton
                            ref={submitRef}
                            Icon={UploadIcon}
                            type="button"
                            label={`Last opp ${items.length} bilder`}
                            onClick={submit}
                            disabled={!consent || submitting || submitted}
                            color={consent ? 'orange' : 'dark'}
                        />

                        {(submitting || submitted) && (
                            <ProgressBar percentage={uploadPercentage} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
