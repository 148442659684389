import { applyMiddleware, compose, createStore, Reducer, Store } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import Mixpanel from './middleware';
import thunk from 'redux-thunk';
import container from 'src/container';
import mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKEN, MIX_PANEL_CONFIG } from 'src/config';

mixpanel.init(MIXPANEL_TOKEN, MIX_PANEL_CONFIG);

interface CreatedStore {
    store: Store;
}

// creates the store
export default (rootReducer: Reducer): CreatedStore => {
    /* ------------- Redux Configuration ------------- */

    const middleware = [];
    const enhancers = [];

    /* ------------- Thunk Middleware ------------- */
    // inject container to thunk's extra argument

    const thunkMiddleWare = thunk.withExtraArgument(container);
    const mixpanelMiddleware = new Mixpanel(mixpanel).MixpanelMiddleWare;
    middleware.push(thunkMiddleWare);
    middleware.push(mixpanelMiddleware);

    /* ------------- Assemble Middleware ------------- */

    enhancers.push(composeWithDevTools(applyMiddleware(...middleware)));

    /* ------------- createStore ------------- */

    const store = createStore(rootReducer, compose(...enhancers));

    return { store };
};
