import { useEffect, useState } from 'react';
import sanityClient from 'src/sanityClient';
import { captureException as reportToSentry } from '@sentry/react';

export interface Post {
    body: [];
    mainImage: string;
    slug: {
        current: string;
        _type: string;
    };
    title: string;
}

export const useNewsPosts = (numberOfPosts?: number) => {
    const [newsPosts, setAllNewsPosts] = useState<Post[]>([]);

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "post"] | order(_createdAt desc){
            title,
            slug,
            body[0...1],
            "mainImage": mainImage.asset -> url
        }${numberOfPosts ? `[0...${numberOfPosts}]` : ''}`,
            )
            .then(data => setAllNewsPosts(data))
            .catch(reportToSentry);
    }, [numberOfPosts]);

    return newsPosts;
};
