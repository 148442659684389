/* eslint-disable react/no-children-prop */
import { useCallback, useEffect } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { Consumer } from 'src/domain/consumer';
import { Recipient, SessionFormTrack } from 'src/domain/sessionForm';
import { removeElementWithId } from 'src/utils/generalUtils';
import { ButtonAddMedia } from '../components/ButtonAddMedia';
import {
    resetSessionForm,
    updateRecipient,
    updateMusic,
    updateStep,
    updateDuration,
} from '../SessionFormState/actions';
import { useSessionForm } from '../SessionFormState/hooks';
import { BrowseMusic } from '../../BrowseMusic';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styles from '../sortable.module.css';
import { SessionTrackCard } from 'src/components/Cards/SessionTrackCard';
import { NotFound } from '../../NotFound';

type IStateLocation = {
    consumer: Consumer;
};

type IPropsSortableList = {
    list: SessionFormTrack[];
    onRemove: (id: number) => void;
    openPopup: () => void;
};

type IPropsSortableItem = {
    track: SessionFormTrack;
    onRemove: (id: number) => void;
};

const SortableItem = SortableElement<IPropsSortableItem>(
    ({ track, onRemove }: IPropsSortableItem) => (
        <div className={styles.sortableItem} key={track.id}>
            <SessionTrackCard
                item={track}
                onRemove={() => onRemove(track.id)}
            />
        </div>
    ),
);

const SortableList = SortableContainer<IPropsSortableList>(
    ({ list, onRemove, openPopup }: IPropsSortableList) => {
        return (
            <div className="flex mx-auto gap-x-5 gap-y-8 items-center flex-wrap">
                <ButtonAddMedia active={!!list} onClick={openPopup} />
                {list.map((track: SessionFormTrack, index: number) => (
                    <SortableItem
                        key={track.id}
                        index={index}
                        track={track}
                        onRemove={onRemove}
                    />
                ))}
            </div>
        );
    },
);
export const CreateNewVideoStep1 = () => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const { state, dispatch } = useSessionForm();
    const { trackSelection } = state;

    const amount = trackSelection.length;
    const totalTime = trackSelection.reduce(
        (prev, current) => prev + current.duration,
        0,
    );

    useEffect(() => {
        // if the user goes to from the "MyClientDetail" page
        const stateLocation = history.location.state as IStateLocation;
        if (stateLocation && stateLocation.consumer) {
            const { consumer } = stateLocation;
            // Filter recipient data needed for storage
            const recipient: Recipient = {
                id: consumer.id,
                profile: {
                    firstName: consumer.profile.firstName,
                    lastName: consumer.profile.lastName,
                    avatar: consumer.profile.avatar,
                },
            };
            dispatch(resetSessionForm());
            dispatch(updateRecipient(recipient));
            dispatch(updateStep(1));
            return;
        }
        // If one of the previous steps has not been completed,
        // The user will be redirected to that step
        if (state.step < 1) {
            history.push(`./step${state.step}`);
        }
    }, [dispatch, history, state.step]);

    const onRemoveMusic = useCallback(
        (id: number | string) => {
            const newList = removeElementWithId(id, trackSelection);
            dispatch(updateMusic(newList));
            if (newList.length === 0) dispatch(updateStep(1));
        },
        [dispatch, trackSelection],
    );

    const openPopup = useCallback(() => {
        history.push(`${path}/browse`);
    }, [history, path]);

    useEffect(() => {
        dispatch(updateDuration(totalTime));
    }, [dispatch, totalTime]);

    const onSortEnd = useCallback(
        ({
            oldIndex,
            newIndex,
        }: {
            oldIndex: number;
            newIndex: number;
        }): void => {
            const element = trackSelection[oldIndex];
            trackSelection.splice(oldIndex, 1);
            trackSelection.splice(newIndex, 0, element);
            dispatch(updateMusic(trackSelection));
        },
        [dispatch, trackSelection],
    );

    return (
        <Switch>
            <Route exact path={path}>
                <>
                    {amount === 0 ? (
                        <div className="flex gap-y-10 gap-x-3 items-start content-start flex-wrap h-full w-full ">
                            <ButtonAddMedia
                                disabled={!state.recipient}
                                onClick={openPopup}
                                label="Legg til musikk"
                            />
                        </div>
                    ) : (
                        <SortableList
                            list={trackSelection}
                            onRemove={onRemoveMusic}
                            axis="xy"
                            onSortEnd={onSortEnd}
                            openPopup={openPopup}
                        />
                    )}
                </>
            </Route>
            <Route
                path={`${path}/browse`}
                children={({ match }) =>
                    match && <BrowseMusic initialSelection={trackSelection} />
                }
            />
            <Route path="*" component={NotFound} />
        </Switch>
    );
};
