import { FunctionComponent, useEffect, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { CSSProperties } from 'styled-components';
import { BottomBar } from './BottomBar';
import { FixedSizeList as List } from 'react-window';
import qrCode from 'src/assets/images/qr_code.png';
import { useAnimatedPagination } from 'src/view/hooks/useAnimatedPagination';
import { AnimatedValue } from './AnimatedValue';
import { useKeyboard } from 'src/view/hooks/useKeyboard/useKeyboard';
import cx from 'classnames';
import { ForwardIcon, BackIcon, CloseIcon } from 'src/components/Icons/icons';
import { useCursorIsIdle } from 'src/view/hooks/useCursorIsIdle';
import { getFullName, UserRole } from 'src/domain/user';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import container from 'src/container';
import { LogoLink } from 'src/components/Buttons/LogoLink';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { CloudAnimation } from './CloudAnimation';

type Item = {
    id: string;
    title: string;
    description: string;
    src?: string;
    Component?: FunctionComponent<any>;
};
type SlideProps = { index: number; style?: CSSProperties; item: Item };
type SlideListProps = {
    items?: Item[];
    onComplete: () => void;
    cancelable?: boolean;
};

const getData = (
    organizationName = '<ORGANISASJON>',
    username = '<BRUKERNAVN>',
    role = '<ROLLE>',
    consumerName = '<BRUKER>',
) => {
    switch (role) {
        case UserRole.organization:
            return [
                {
                    id: '1',
                    title: 'Velkommen til Mundu!',
                    description: `Hei, ${username}! Du er registrert som Superbruker for ${organizationName} i Mundu.
					 I denne veiledning vil du få litt informasjon om hvordan du kan bidra til at ${organizationName} kommer best mulig i gang med Mundu. I tillegg vil du få et kjapt overblikk over mulighetene du har tilgang til gjennom Mundu.`,
                },

                {
                    id: '2',
                    title: 'Superbruker',
                    description: `Superbrukerrollen innebærer at du sitter med det overordnede administrative ansvaret for bruken av Mundu i ${organizationName}.
					I tillegg har du mulighent til full tilgangstyring for alle Mundukontoer i ${organizationName}, og kan både opprette og slette andre Brukerere, Brukerkontakter og Pårørende.
					Som superbruker har du i tillegg muligheten til å opptre som Brukerkontakt og Pårørende for alle aktive lisenser i ${organizationName}.`,
                },

                {
                    id: '3',
                    title: 'Opprett Brukere',
                    description:
                        'Opprett Brukere, slik at du har noen å lage video til. Brukere kan logge inn på nettbrett eller mobil for å motta og spille av videoene du lager til dem.',
                },

                {
                    id: '4',
                    title: 'Inviter pårørende',
                    description:
                        'Inviter brukerens pårørende, slik at de kan dele bilder som du kan bruke når du lager videoer til brukeren.',
                },

                {
                    id: '5',
                    title: 'Last opp bilder',
                    description: `Du kan også selv opptre på vegne av Pårørende, og laste opp bilder til alle Brukerene i  ${organizationName}. Bildene du laster opp vil dukke opp når du skal lage videoer til brukeren.`,
                },
                {
                    id: '6',
                    title: 'Kartlegg den musikalske livshistorien',
                    description:
                        'Utforsk Mundus musikkbibliotek og kartlegg sanger og artister som har fulgt brukeren gjennom ulike faser av livet. Et godt forarbeid her, gjør det enklere å velge musikk som er betydningsfull for brukeren når du senere skal lage video. Artistene og sangene du registrer under kartleggingen vil dukke opp igjen når du skal lage video til brukeren.',
                },
                {
                    id: '7',
                    title: 'Lag video',
                    description:
                        'Sammenstill video til brukeren av bilder fra Pårørende og musikk fra musikkbiblioteket. Når du er fornøyd med videoen, kan du sende denne til Brukers nettbrett.',
                },
                {
                    id: '8',
                    title: 'Mundu finnes også på mobil!',
                    src: qrCode,
                    description: `Ønsker du å skanne fysiske bilder eller dele bilder fra mobil eller nettbrett, så anbefaler vi deg å laste ned Mundu-appen. Skann QR-koden for å laste ned Mundu fra Appstore eller Google Play.`,
                },
            ];
        case UserRole.creator:
            return [
                {
                    id: '1',
                    title: 'Velkommen til Mundu!',
                    description: `Hei, ${username}! Du er registrert deg som Brukerkontakt for ${organizationName} i Mundu. I denne veiledning vil du få litt informasjon hvordan du best mulig kommer i gang med Mundu.`,
                },
                {
                    id: '2',
                    title: 'Brukerkontakt',
                    description: `Som brukerkontakt kan du opprette Brukere for ${organizationName}, invitere Pårørende, lage videoer og laste opp bilder til Brukerene du er Brukerkontakt for.`,
                },
                {
                    id: '3',
                    title: 'Opprett Brukere',
                    description:
                        'Opprett Brukere, slik at du har noen å lage video til. Brukere kan logge inn på nettbrett eller mobil for å motta og spille av videoene du lager til dem.',
                },

                {
                    id: '4',
                    title: 'Inviter pårørende',
                    description:
                        'Inviter brukerens pårørende, slik at de kan dele bilder som du kan bruke når du lager videoer til brukeren.',
                },

                {
                    id: '5',
                    title: 'Last opp bilder',
                    description: `Du kan også selv opptre på vegne av Pårørende, og laste opp bilder til Brukere du er Brukerkontakt for. Bildene du laster opp vil dukke opp når du skal lage videoer til brukeren.`,
                },
                {
                    id: '6',
                    title: 'Kartlegg den musikalske livshistorien',
                    description:
                        'Utforsk Mundus musikkbibliotek og kartlegg sanger og artister som har fulgt brukeren gjennom ulike faser av livet. Et godt forarbeid her, gjør det enklere å velge musikk som er betydningsfull for brukeren når du senere skal lage video. Artistene og sangene du registrer under kartleggingen vil dukke opp igjen når du skal lage video til brukeren.',
                },
                {
                    id: '7',
                    title: 'Lag video',
                    description:
                        'Sammenstill video til brukeren av bilder fra Pårørende og musikk fra musikkbiblioteket. Når du er fornøyd med videoen, kan du sende denne til Brukers nettbrett.',
                },
                {
                    id: '8',
                    title: 'Mundu finnes også på mobil!',
                    src: qrCode,
                    description: `Ønsker du å skanne fysiske bilder eller dele bilder fra mobil eller nettbrett, så anbefaler vi deg å laste ned Mundu-appen. Skann QR-koden for å laste ned Mundu fra Appstore eller Google Play.`,
                },
            ];
        case UserRole.nextOfKin:
            return [
                {
                    id: '1',
                    title: 'Velkommen til Mundu på web!',
                    description: `Hei, ${username}! Du er registrert av ${organizationName} som  pårørende for ${consumerName}. I denne veiledning vil du få litt informasjon hvordan du best mulig kommer i gang med Mundu.`,
                },
                {
                    id: '2',
                    title: 'Pårørende',
                    description: `Som pårørende har du muligheten til å dele bilder med ${organizationName}. Bildene du laster opp kan helsepersonell bruke til å lage personlige videoer med musikk til ${consumerName}.`,
                },
                {
                    id: '3',
                    title: 'Legg til bilder',
                    description: `For å laste opp bilder trykker du på "Last opp bilder"-knappen. Du kan legge bilder til utvalget ved å trykke på "Legg til bilder" og velge bilder fra filmenyen, eller "dra og slippe" bildefilene direkte inn i utvalgsområde.`,
                },

                {
                    id: '5',
                    title: 'Legg til en bildetekst!',
                    description: `En kort og oppklarende bildetekst gjør det enklere for de ansatte i ${organizationName} å lage meningsfulle videoer. Bildetekstene du legger til, vil kun være synlige for helsepersonell når de lager videoer til ${consumerName}.`,
                },
                {
                    id: '6',
                    Component: CloudAnimation,
                    title: 'Last opp i skyen',
                    description: `Når du er fornøyd med utvalget, kan du gå videre til sidepanelet, bekrefte samtykke og til slutt laste opp. Brukerkontakten til ${consumerName} vil bli varslet så snart bildene er tilgjengelige.`,
                },
                {
                    id: '7',
                    title: 'Mundu på mobil',
                    src: qrCode,
                    description: `Ønsker du å skanne fysiske bilder eller dele bilder fra mobil eller nettbrett, så anbefaler vi deg å laste ned Mundu-appen. Skann QR-koden for å laste ned Mundu fra Appstore eller Google Play.`,
                },
            ];
        default:
            return [
                {
                    id: '1',
                    title: 'Velkommen til mundu på web!',
                    description: 'Trykk enter for å komme i gang',
                },
            ];
    }
};

const Slide = ({ style, item }: SlideProps) => {
    const Component = item.Component;
    return (
        <div
            className="text-center text-woodyBrown object-contain"
            style={style}
        >
            <div className="">
                <div className="flex flex-col-reverse md:flex-row items-center md:items-start gap-6 md:gap-10 w-full pt-6 px-10 md:px-20">
                    <div className="flex-1 max-w-prose">
                        <h1 className="font-logo md:text-left text-lightPeachMonoChrome text-2xl pb-4  text-center">
                            {item.title}
                        </h1>
                        <p className="text-center text-lightPeach md:text-lg md:text-left">
                            {item.description}
                        </p>
                    </div>

                    {Component && (
                        <div className="relative flex-none w-24 md:w-44 aspect-square">
                            <div className="absolute w-full">
                                <Component />
                            </div>
                        </div>
                    )}
                    {item.src && (
                        <div className="relative flex-none w-24 md:w-44 aspect-square ">
                            <div className="absolute w-full p-4 bg-lightPeach rounded-xl ">
                                <img className="w-full h-full" src={item.src} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
const useOnboardingInfo = () => {
    const user = useSignedInUser();
    const consumerId =
        user?.role === UserRole.nextOfKin
            ? user.followers?.consumerIds?.[0]
            : '';
    const [organizationName, setOrganizationName] = useState('');
    const [consumerName, setConsumerName] = useState('');
    const username = user ? getFullName(user) : '';
    useEffect(() => {
        munduClient.getOrgDisplayName().then(orgname => {
            setOrganizationName(orgname);
        });
    }, []);
    useEffect(() => {
        if (!consumerId) return;
        munduClient.getConsumer(consumerId).then(consumer => {
            setConsumerName(getFullName(consumer));
        });
    }, [consumerId]);
    return {
        organizationName: organizationName || undefined,
        username: username || undefined,
        role: user?.role || undefined,
        consumerName: consumerName || undefined,
    };
};

const { munduClient } = container.cradle;
export const SlideList = ({ onComplete, cancelable }: SlideListProps) => {
    const { organizationName, username, role, consumerName } =
        useOnboardingInfo();
    const items = getData(organizationName, username, role, consumerName);
    const scrollX = useRef(new AnimatedValue(0)).current;

    const { ref, isScrolling, currentIndex, onNextPage, onPreviousPage } =
        useAnimatedPagination(items.length - 1);

    const props = useKeyboard({
        ArrowLeft: () => (!isScrolling ? onPreviousPage() : null),
        ArrowRight: () => (!isScrolling ? onNextPage() : null),
        Enter: () =>
            !isScrolling && currentIndex === items.length - 1
                ? onComplete()
                : null,
    });
    const { isIdle } = useCursorIsIdle(2000);

    return (
        <div className="flex flex-col w-screen h-full ">
            <div className="w-full sticky left-0 right-0  py-3 px-4 text-[32px] text-lightPeachMonoChrome flex justify-between">
                <div className="pt-4 text-[32px] text-lightPeachMonoChrome ">
                    <LogoLink />
                </div>
                {cancelable && (
                    <button
                        onClick={onComplete}
                        className={cx(
                            ' text-xs p-3 group bg-transparent transition-all rounded-lg',
                            'text-lightPeach hover:bg-lightPeach hover:text-woodyBrown',
                        )}
                    >
                        <TextIcon SVG={CloseIcon} />
                    </button>
                )}
            </div>
            <div
                {...props}
                id="slides"
                className="flex-1 bg-woodyBrown focus:outline-none"
            >
                <div
                    id="right"
                    className={cx(
                        'absolute w-1/3 h-full top-0 right-0 z-80 opacity-0 rounded-l-3xl transition-all duration-300',
                        isIdle
                            ? 'cursor-none'
                            : currentIndex !== items.length - 1 &&
                                  'hover:opacity-50 cursor-pointer',
                    )}
                    onClick={!isScrolling ? onNextPage : undefined}
                >
                    <div className="absolute transform top-1/2 -translate-y-1/2  right-4 w-1/5 aspect-square text-lightPeach ">
                        <ForwardIcon />
                    </div>
                </div>
                <div
                    className={cx(
                        'absolute w-1/3 h-full left-0 z-80  opacity-0 transition-all ',
                        isIdle
                            ? 'cursor-none opacity-0'
                            : currentIndex !== 0 &&
                                  'hover:opacity-50 cursor-pointer',
                    )}
                    onClick={!isScrolling ? onPreviousPage : undefined}
                >
                    <div className="absolute transform top-1/2 -translate-y-1/2  left-4 w-1/5 aspect-square text-lightPeach">
                        <BackIcon />
                    </div>
                </div>
                <AutoSizer
                    onResize={size => {
                        if (ref.current)
                            ref.current.scrollLeft = size.width * currentIndex;
                    }}
                >
                    {({ height, width }) => {
                        return (
                            <List
                                className="overflow-hidden"
                                outerRef={ref}
                                onScroll={({ scrollOffset }) => {
                                    scrollX.setValue(scrollOffset);
                                }}
                                width={width}
                                height={height}
                                layout="horizontal"
                                itemCount={items.length}
                                itemSize={width}
                            >
                                {({ style, index }) => {
                                    const inputRange = [
                                        (index - 0.5) * width,
                                        index * width,
                                        (index + 0.5) * width,
                                    ];

                                    const scale = scrollX.interpolate({
                                        inputRange,
                                        outputRange: [0.8, 1, 0.8],
                                    });

                                    const opacity = scrollX.interpolate({
                                        inputRange: inputRange,
                                        outputRange: [0, 1, 0],
                                    });

                                    return (
                                        <Slide
                                            style={{
                                                ...style,
                                                opacity,
                                                transform: `scale(${scale}, ${scale})`,
                                            }}
                                            item={items[index]}
                                            index={index}
                                        />
                                    );
                                }}
                            </List>
                        );
                    }}
                </AutoSizer>
            </div>
            <BottomBar
                isLight={false}
                isScrolling={isScrolling}
                numPages={items.length}
                canNext
                currentPage={currentIndex}
                onNextPage={onNextPage}
                onPreviousPage={onPreviousPage}
                onClose={onComplete}
            />
        </div>
    );
};
