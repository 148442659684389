import { useCallback, useContext } from 'react';
import { NotificationContext } from './NotificationProvider';

export const useNotification = () => {
    const { dispatch } = useContext(NotificationContext);

    const notify = useCallback(
        (message: string, isError = false) => {
            dispatch({ message, isError, notify: true });
            setTimeout(
                () => dispatch({ message, isError, notify: false }),
                5000,
            );
        },
        [dispatch],
    );

    return notify;
};
