type Props = {
    title: string;
    active?: boolean;
    submit?: boolean;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    color?: string;
};
export const Button = ({
    title,
    active,
    submit,
    className,
    onClick,
    disabled,
    color = 'woodyBrown',
}: Props) => {
    return (
        // The button has 3 states: Active, Disabled and Normal
        <button
            type={submit ? 'submit' : 'button'}
            onClick={onClick}
            disabled={disabled}
            className={`whitespace-nowrap ${className} ${
                disabled &&
                'border-vanillaWhite text-mongooseGray pointer-events-none'
            } ${
                active &&
                `bg-${color} text-${
                    color === 'peachOrange' ? 'woodyBrown' : 'white'
                } border-${color}`
            }
                ${
                    !active &&
                    !disabled &&
                    `border-woodyBrown text-woodyBrown hover:border-${color} hover:text-${
                        color === 'peachOrange' ? 'woodyBrown' : 'white'
                    } hover:bg-${color}`
                } border-[1px]  sm:border-1.6px rounded-full text-[12px] md:text-base  py-6px px-2  lg:px-17px lg:text-xl leading-none align-middle transition-all duration-150 focus:outline-none hover:outline-none outline-none `}
        >
            {title}
        </button>
    );
};
