import { useCallback, useState } from 'react';
import container from 'src/container';
import { getFullName, UserRole } from 'src/domain/user';
import { useConsumer } from 'src/view/hooks/consumer';
import { NextOfKin } from 'src/domain/next-of-kin';
import { useAvatar } from 'src/view/hooks/useAvatar';
import { ProfileBanner } from './ProfileBanner';
import { ImageList } from '../Image/list';
import { NextOfKinList } from '../NextOfKin/list';
import { SessionList } from '../Session/list';
import { ConsumerProfileEdit } from './ConsumerProfileEdit';
import { useHistory, useParams } from 'react-router-dom';
import { mutate } from 'swr';
import { useNotification } from 'src/components/Notification/useNotfication';
import { ScrollableModal } from 'src/components/Modals/ScrollableModal';
import Tabs from 'src/components/Navigation/Tabs/Tabs';
import { CreatorsList } from '../creators/list';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';

const {
    cradle: { munduClient },
} = container;

export const UserProfile = () => {
    const { id } = useParams<{ id: string }>();
    const { consumer, refresh } = useConsumer(id);
    const { src, isDefault } = useAvatar(consumer?.profile.avatar);
    const [editing, setIsEditing] = useState(false);
    const history = useHistory();
    const user = useSignedInUser();
    const notify = useNotification();

    const onDeleteSession = useCallback(
        async (id: string) => {
            munduClient
                .deleteSession(id)
                .then(() => {
                    notify('Video ble slettet!');
                    mutate([consumer?.id, 'sessions']);
                })
                .catch((error: Error) => {
                    notify(error.message, true);
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [refresh],
    );
    if (!consumer) return null;

    const onDeleteNextOfKin = async (id: NextOfKin['id']): Promise<void> => {
        munduClient
            .deleteNextOfKin(id)
            .then(() => {
                notify('Pårørende ble slettet!');
                mutate([consumer.id, 'nextOfKin/consumerId']);
            })
            .catch((error: Error) => notify(error.message, true));
    };

    return (
        <div className="flex flex-col h-full w-full relative text-woodyBrown ">
            <ScrollableModal
                visible={editing}
                onClose={() => setIsEditing(false)}
            >
                <ConsumerProfileEdit consumer={consumer} />
            </ScrollableModal>
            <ProfileBanner
                name={`${getFullName(consumer)}`}
                username={consumer.username}
                entity="BRUKER"
                avatar={src}
                rounded={isDefault}
                onClick={() => setIsEditing(true)}
            />
            <Tabs>
                <div id="Bildebibliotek" className="space-y-4">
                    <ImageList
                        consumerId={consumer.id}
                        onAddImages={() => history.push('/app/image-upload')}
                    />
                </div>
                <div id="Pårørende" className="space-y-4">
                    <NextOfKinList
                        consumerId={consumer.id}
                        onDelete={onDeleteNextOfKin}
                    />
                </div>
                <div id="Videoer" className="space-y-4">
                    <SessionList
                        recipientId={consumer.id}
                        onDelete={onDeleteSession}
                    />
                </div>
                {user?.role === UserRole.organization ? (
                    <div id="Brukerkontakter" className="space-y-4">
                        <CreatorsList consumer={consumer} />
                    </div>
                ) : (
                    <></>
                )}
            </Tabs>
        </div>
    );
};
