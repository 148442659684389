import { LocalImage } from 'src/domain/personalMedia';
import { v4 as generateId } from 'uuid';

export const generateThumbnail = async (
    imageFile: File,
): Promise<LocalImage> => {
    const canvas = await cropImage(imageFile);
    return {
        id: generateId(),
        file: canvas.toDataURL('image/jpeg', 0.85),
        width: canvas.width,
        height: canvas.height,
        mime: 'image/jpeg',
    };
};

export const optimizeImage = async (imageFile: File): Promise<LocalImage> => {
    const canvas = await fitImageToVideo(imageFile);
    let compressedImage: string;
    if (imageFile.size <= 300000) {
        compressedImage = canvas.toDataURL('image/jpeg', 1);
    } else {
        compressedImage = canvas.toDataURL('image/jpeg', 0.85);
    }
    return {
        id: generateId(),
        file: compressedImage,
        width: canvas.width,
        height: canvas.height,
        mime: 'image/jpeg',
    };
};

export const convertBase64ToBlob = async (file: string) => {
    const base64res = await fetch(file);
    return base64res.blob();
};

/**
    Function to crop image 
    @param {File} imageFile - input file
    @param {number} [maxWidth=400] - output width
    @param {number} [maxHeight=400] - output height
    @returns {HTMLCanvasElement}  HTMLCanvasElement with the resized image
*/

const cropImage = (
    imageFile: File,
    maxWidth = 400,
    maxHeight = 400,
): Promise<HTMLCanvasElement> => {
    if (!imageFile.type.includes('image')) {
        throw new Error('File must be of type image');
    }

    const getCropCoords = (width: number, height: number) => {
        let sx = 0;
        let sy = 0;
        if (width === height) return { sx, sy, sw: width, sh: height };
        const longSide = Math.max(width, height);
        const shortSide = Math.min(width, height);
        const cropLength = longSide / 2 - shortSide / 2;
        if (width > height) {
            sx = cropLength;
        } else if (height > width) {
            sy = cropLength;
        }
        return { sx, sy, sw: shortSide, sh: shortSide };
    };

    return new Promise(resolve => {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);
        img.onload = async () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
            const { sx, sy, sw, sh } = getCropCoords(img.width, img.height);
            canvas.width = maxWidth;
            canvas.height = maxHeight;
            ctx.drawImage(
                img,
                sx,
                sy,
                sw,
                sh,
                0,
                0,
                canvas.width,
                canvas.height,
            );

            resolve(ctx.canvas);
            URL.revokeObjectURL(img.src);
        };
    });
};

/**
    Function to fit image to video with aspectRatio 16/9
    @param {File} imageFile - input file
    @returns {HTMLCanvasElement}  HTMLCanvasElement with the resized image
*/

const fitImageToVideo = async (imageFile: File): Promise<HTMLCanvasElement> => {
    if (!imageFile.type.includes('image')) {
        throw new Error('File must be of type image');
    }

    const getNewWidthAndHeight = (width: number, height: number) => {
        const maxWidth = 1280;
        const maxHeight = 720;
        const aspectRatio = Math.max(width, height) / Math.min(width, height);

        if (aspectRatio > maxWidth / maxHeight) {
            if (width > height) {
                return { width: maxWidth, height: maxWidth / aspectRatio };
            }
            return { width: maxWidth / aspectRatio, height: maxWidth };
        }

        if (width > height) {
            return { width: maxHeight * aspectRatio, height: maxHeight };
        }
        return { width: maxHeight, height: maxHeight * aspectRatio };
    };

    return new Promise(resolve => {
        const img = new Image();
        img.src = URL.createObjectURL(imageFile);
        img.onload = async () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

            const { width, height } = getNewWidthAndHeight(
                img.width,
                img.height,
            );
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            resolve(ctx.canvas);
            URL.revokeObjectURL(img.src);
        };
    });
};
