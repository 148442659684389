import { omit } from 'lodash';
import { useContext, useState, useEffect, useCallback } from 'react';
import { setSnapshot } from './actions';
import { SessionFormStoreType } from './interfaces';
import {
    Dispatch,
    SessionFormDispatchContext,
    SessionFormStateContext,
} from './store';
import container from 'src/container';
import { PlaybackMode, SessionPayload } from 'src/domain/session';
import { useNotification } from 'src/components/Notification/useNotfication';
import { mutate } from 'swr';

function useSessionFormState(): SessionFormStoreType {
    const context = useContext(SessionFormStateContext);

    if (context === undefined) {
        throw new Error(
            'sessionFormState must be used within a SessionFormStore',
        );
    }
    return context;
}

function useSessionFormDispatch(): Dispatch {
    const context = useContext(SessionFormDispatchContext);

    if (context === undefined) {
        throw new Error(
            'useSessionFormDispatch must be used within a SessionFormStore',
        );
    }
    return context;
}

interface SessionFormStatus {
    getMissingContent: () => string[];
    formValid: boolean;
    isEmpty: boolean;
    hasUnsavedChanges: boolean;
}

const {
    cradle: { munduClient },
} = container;

function useSessionForm(): {
    state: SessionFormStoreType;
    dispatch: Dispatch;
    submit: () => Promise<void>;
    submitting: boolean;
    submitted: boolean;
    error: string | undefined;
    sessionStatus: SessionFormStatus;
} {
    const state = useSessionFormState();
    const {
        id,
        incompleteSessionId,
        recipient,
        title,
        trackSelection,
        images,
        playbackMode,
        imageDuration,
        duration,
        editMode,
    } = state;
    const dispatch = useSessionFormDispatch();
    const sessionStatus = useSessionStatus();
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const notify = useNotification();

    const submit = useCallback(async () => {
        if (!recipient) return;
        setSubmitting(true);
        const payload: SessionPayload = {
            consumerIds: [recipient.id],
            title,
            media: {
                images,
                audios: trackSelection.map(track => ({
                    trackId: track.id.toString(),
                    releaseId: track.releaseId,
                })),
                imageDuration:
                    playbackMode === PlaybackMode.LOOP
                        ? imageDuration
                        : duration / images.length,
            },
            playbackMode: playbackMode,
            duration: duration,
        };
        try {
            if (editMode && id) {
                await munduClient.updateSession(id, payload);
                notify('video er oppdatert!');
                dispatch(setSnapshot(state));
                mutate([recipient.id, 'sessions']);
            } else {
                await munduClient.createSession(payload);
                notify('video ble opprettet!');
            }
            setSubmitted(true);
        } catch (e) {
            if (e instanceof Error) {
                setError(e.message);
                notify(e.message, true);
            }
        } finally {
            setSubmitting(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dispatch,
        duration,
        editMode,
        id,
        imageDuration,
        images,
        playbackMode,
        recipient,
        state,
        title,
        trackSelection,
    ]);

    useEffect(() => {
        if (!incompleteSessionId || !submitted) return;
        (async () => {
            try {
                await munduClient.deleteIncompleteSession(incompleteSessionId);
            } catch (e) {
                console.log(e);
            }
        })();
    }, [incompleteSessionId, submitted]);

    return {
        state,
        dispatch,
        submit,
        submitting,
        submitted,
        error,
        sessionStatus,
    };
}
function useSessionStatus(): SessionFormStatus {
    const state = useSessionFormState();
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

    const { recipient, trackSelection, images, title } = state;

    useEffect(() => {
        const formStateString = JSON.stringify(
            omit(state, ['snapShot', 'step']),
        );
        const snapShotString = JSON.stringify(omit(state.snapShot, ['step']));
        setHasUnsavedChanges(formStateString !== snapShotString);
    }, [state]);

    const getMissingContent = useCallback((): string[] => {
        const contentStatus = {
            mottaker: !!recipient,
            musikk: !!trackSelection.length,
            bilder: !!images.length,
            tittel: !!title.length,
        };

        const missingContent: string[] = [];
        for (const [key, value] of Object.entries(contentStatus)) {
            if (!value) {
                missingContent.push(key);
            }
        }
        return missingContent;
    }, [images.length, recipient, title.length, trackSelection.length]);

    return {
        getMissingContent,
        formValid:
            !!recipient &&
            !!trackSelection.length &&
            !!images.length &&
            !!title.length,
        isEmpty: !trackSelection.length && !images.length && !title.length,
        hasUnsavedChanges,
    };
}

export { useSessionFormState, useSessionForm };
