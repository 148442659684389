import { ReactElement } from 'react';

type Props = {
    children: ReactElement | null;
    condition: boolean;
    wrapper: (children: ReactElement | null) => JSX.Element | null;
};

export const WrapIf = ({ condition, wrapper, children }: Props) =>
    condition ? wrapper(children) : children;
