import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Transition, TransitionStatus } from 'react-transition-group';
import { useClickOutside } from 'src/view/hooks';

type Props = {
    show: boolean;
    onClose?: () => void;
    onOutsideClick?: () => void;
    fadeDuration?: number;
    appear?: boolean;
} & Omit<
    React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >,
    'style'
>;
const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: {},
};
export const Modal = ({
    show,
    onClose,
    onOutsideClick,
    fadeDuration = 200,
    appear = true,
    ...props
}: Props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);

    useClickOutside(wrapperRef, () =>
        onOutsideClick ? onOutsideClick() : null,
    );

    const wrapperElement = useRef(document.createElement('div'));

    useEffect(() => {
        const current = wrapperElement.current;
        const modalRoot = document.querySelector('#modal-root') as HTMLElement;
        modalRoot.appendChild(current);
        return () => void modalRoot.removeChild(current);
    }, []);

    const Modal = (
        <Transition
            in={show}
            appear={appear}
            timeout={{
                appear: 0,
                enter: fadeDuration,
                exit: fadeDuration,
            }}
            unmountOnExit={true}
            onExited={onClose}
        >
            {(state: TransitionStatus) => {
                return (
                    <div
                        ref={wrapperRef}
                        style={{
                            transition: `opacity ${fadeDuration}ms ease-in-out`,
                            ...transitionStyles[state],
                        }}
                        {...props}
                    />
                );
            }}
        </Transition>
    );
    return createPortal(Modal, wrapperElement.current);
};
