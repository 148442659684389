import container from 'src/container';
import useSWR from 'swr';

const {
    cradle: { munduClient },
} = container;

export const useOrganizations = () => {
    const { data, error, mutate } = useSWR('organizations', () =>
        munduClient.listOrganizations(),
    );

    return {
        organizations: data || [],
        mutate: mutate,
        isLoading: !error && !data,
        isError: error,
    };
};
