import { useState } from 'react';
import { CheckIconSVG, SearchIconSVG } from 'src/assets/svg';
import { Media } from 'src/domain/personalMedia';
import { LoadingCircle } from 'src/view/components/loading/Circle';

type Props = {
    src: string;
    picture: Media;
    isSelected: boolean;
    onSelected: (picture: Media, isSelected: boolean) => void;
    index: number;
    onPicturePreview: (index: number) => void;
};

export const CardPicturePopup = ({
    src,
    picture,
    isSelected,
    onSelected,
    onPicturePreview,
    index,
}: Props) => {
    const [loaded, setLoaded] = useState<boolean>(false);

    const onLoadImage = (): void => {
        if (!loaded) setLoaded(true);
    };

    return (
        <div className="flex flex-col h-[9.5rem] xl:h-44 w-32 xl:w-36">
            <div
                className={`relative overflow-hidden w-32 h-32 xl:w-36 shadow-xl xl:h-36 aspect-sqaure flex rounded-lg xl:rounded-2xl items-center justify-center ${
                    isSelected ? 'ring ring-woodyBrown ' : 'ring-starkWhite'
                }`}
            >
                <LoadingCircle loading={!loaded} isGlobal={false} />
                <button
                    type="button"
                    onClick={() => onSelected(picture, isSelected)}
                    className="outline-none h-full focus:outline-none"
                >
                    <img
                        className="absolute inset-0 object-cover w-full h-full rounded-lg xl:rounded-2xl "
                        src={src}
                        alt=""
                        onLoad={onLoadImage}
                    />
                </button>
                {isSelected && (
                    <button
                        type="button"
                        className="absolute right-0 top-0 m-3 outline-none bg-lightPeach focus:outline-none rounded-md"
                    >
                        <CheckIconSVG className="w-6 h-6" />
                    </button>
                )}
                <button
                    type="button"
                    className="absolute right-0 bottom-0 m-3 w-7 h-7 flex items-center justify-center outline-none bg-lightPeach rounded-full focus:outline-none"
                    onClick={() => onPicturePreview(index)}
                >
                    <SearchIconSVG />
                </button>
            </div>
            <p className="text-sm pt-2 line-clamp-2">{picture.description}</p>
        </div>
    );
};
