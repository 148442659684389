import useSWRInfinite from 'swr/infinite';
import container from 'src/container';

const {
    cradle: { musicService },
} = container;

export const useUserPlaylists = (userId?: string, pageSize = 25) => {
    const { data, size, setSize, mutate, isValidating, error } = useSWRInfinite(
        page => (userId ? ['playlists', userId, page + 1, pageSize] : null),
        (_, userId, page, pageSize) =>
            musicService.listPlaylists({ userId, page, pageSize }),
        { revalidateOnFocus: false },
    );
    const playlists = data ? data.map(page => page.playlists).flat() : [];
    const totalItems = data?.[0].totalItems;
    const isLoading = !data && !error;
    const isLoadingNext =
        isValidating ||
        (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.playlists.length === 0;
    const hasMore = !(
        isEmpty ||
        (data && data[data.length - 1]?.playlists.length < pageSize)
    );
    return {
        playlists,
        nextPage: () => setSize(size + 1),
        setPageSize: setSize,
        refresh: mutate,
        isLoading,
        isLoadingNext,
        error,
        hasMore,
        totalItems,
    };
};
