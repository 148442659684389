import { useState } from 'react';
import { Consumer } from 'src/domain/consumer';
import { useConsumersImages } from 'src/view/hooks/consumer';
import { CardPicture } from '../../MyClients/detail/components/CardPicture';
import { SpinnerIconSVG } from 'src/assets/svg';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { PlusIcon } from 'src/components/Icons/icons';
import cx from 'classnames';
import Card from 'src/components/Card';
import { useNotification } from 'src/components/Notification/useNotfication';
import container from 'src/container';
import { ModalPreviewPicture } from 'src/components/Modals/ModalPreviewPicture';
import { mutate } from 'swr';

const { munduClient } = container.cradle;

//TODO: add delete feature for images stored on azure-storage
type Props = {
    consumerId: Consumer['id'];
    uploaderId?: string;
    onAddImages: () => void;
};

export const ImageList = ({ consumerId, uploaderId, onAddImages }: Props) => {
    const notify = useNotification();
    const { isLoading, images, refresh } = useConsumersImages(
        consumerId,
        uploaderId,
    );
    const [previewIndex, setPreviewIndex] = useState(-1);
    const currentImage = images[previewIndex]?.media;

    const onPrev = () => {
        if (previewIndex === 0) {
            setPreviewIndex(images.length - 1);
        } else {
            setPreviewIndex(prev => prev - 1);
        }
    };

    const onNext = () => {
        if (previewIndex === images.length - 1) {
            setPreviewIndex(0);
        } else {
            setPreviewIndex(prev => prev + 1);
        }
    };

    const onDeleteImage = async (id: string) => {
        try {
            await munduClient.deletePersonalMedia(consumerId, [id]);
            notify('Bildet ble slettet!');
            mutate([consumerId, 'sessions']);
            refresh();
        } catch (e) {
            if (e instanceof Error) notify(e.message);
        }
    };

    return (
        <div
            className={cx(
                'h-full w-full relative text-woodyBrown',
                previewIndex !== -1 && 'overflow-hidden',
            )}
        >
            {currentImage && (
                <ModalPreviewPicture
                    index={previewIndex + 1}
                    numItems={images.length}
                    onClose={() => setPreviewIndex(-1)}
                    onPrevious={onPrev}
                    onNext={onNext}
                    consumerId={consumerId}
                    picture={currentImage}
                />
            )}

            <div className="flex items-start flex-wrap gap-6">
                <Card
                    onClick={onAddImages}
                    label="Legg til bilder"
                    shape="square"
                >
                    <TextIcon SVG={PlusIcon} />
                </Card>
                {images &&
                    images.map(({ media: { id, description }, url }, index) => {
                        return (
                            <CardPicture
                                key={id}
                                id={id}
                                index={index}
                                onDelete={() => onDeleteImage(id)}
                                src={url}
                                onPreview={() => setPreviewIndex(index)}
                                description={description}
                            />
                        );
                    })}
            </div>

            {isLoading && (
                <div className="grid justify-center">
                    <SpinnerIconSVG className="animate-spin h-8 w-8 text-woodyBrown" />
                </div>
            )}
        </div>
    );
};
