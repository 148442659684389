import { Key, useState } from 'react';
import { ArrowDownIconSVG } from 'src/assets/svg';
import Collapse, { Panel } from 'rc-collapse';
import './Faq.stylesheet.css';
import { FaqData } from './FaqData';
import { LinkButton } from 'src/components/Buttons/LinkButton';

type entryItem = {
    key: string;
    title: string;
    content: string;
    contact?: string;
    readMore?: string;
};

type FaqItem = {
    header: string;
    entries: entryItem[];
};

export const Faq = () => {
    const [activeKey, setActiveKey] = useState<Key | Key[]>();

    const onChange = (newActiveKey: Key | Key[]): void => {
        setActiveKey(newActiveKey);
    };

    return (
        <div className="w-full h-full text-woodyBrown">
            <div className="text-2xl mb-10">FAQ</div>

            {FaqData.map(({ header, entries }: FaqItem) => (
                <Collapse
                    key={header}
                    accordion
                    activeKey={activeKey}
                    onChange={onChange}
                >
                    <div className="my-5 font-semibold text-xl md:text-2xl xl:text-3xl">
                        {header}
                    </div>

                    {entries.map(
                        ({ key, title, content, contact, readMore }) => (
                            <Panel
                                showArrow={false}
                                header={
                                    <div className=" font-normal text-base md:text-lg xl:text-xl">
                                        {title}
                                    </div>
                                }
                                key={key}
                                extra={
                                    <ArrowDownIconSVG
                                        className={`h-3 w-3 md:h-4 md:w-4 transform duration-500 ${
                                            activeKey === key && 'rotate-180'
                                        }`}
                                    />
                                }
                            >
                                <p className="text-base">{content}</p>

                                {contact && (
                                    <div className="w-full flex my-6 md:my-8">
                                        <LinkButton
                                            title="Kontakt oss"
                                            borderColor="woodyBrown"
                                            to={`mailto:${contact}`}
                                        />
                                    </div>
                                )}
                                {readMore && (
                                    <div className="w-full flex my-6 md:my-8">
                                        <LinkButton
                                            title="Les mer"
                                            borderColor="woodyBrown"
                                            to={readMore}
                                        />
                                    </div>
                                )}
                            </Panel>
                        ),
                    )}
                </Collapse>
            ))}
        </div>
    );
};
