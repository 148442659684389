import * as R from 'ramda';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { User } from 'src/domain/user';
import { UserRedux } from 'src/state/reducers';
import { getReducerState } from 'src/state/User/UserReducer';

export const useSignedInUser = () => {
    const signedInUser = useSelector(
        R.pipe(getReducerState, UserRedux.selectors.getUser),
    );
    const user = useMemo((): User | undefined => signedInUser, [signedInUser]);
    return user;
};
