import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useMemo,
} from 'react';
import produce from 'immer';
import { useImmerReducer } from 'use-immer';
import { Consumer } from 'src/domain/consumer';
import { Playlist } from 'src/domain/music';

type ConsumerAction =
    | { type: 'SELECT_CONSUMER'; payload: Consumer }
    | { type: 'SET_PLAYLIST'; payload: Playlist }
    | { type: 'RESET' }
    | { type: 'UPDATE_CONSUMER'; payload: Consumer };

type ContextProps = {
    state: CurrentConsumerState;
    dispatch: Dispatch;
};

type CurrentConsumerState = {
    playlist?: Playlist;
    currentConsumer?: Consumer;
};
const initialState: CurrentConsumerState = {
    currentConsumer: undefined,
};

const CurrentConsumerReducer = produce(
    (draft: CurrentConsumerState = initialState, action: ConsumerAction) => {
        switch (action.type) {
            case 'SELECT_CONSUMER':
                if (draft.currentConsumer?.id === action.payload.id) return;
                draft.currentConsumer = action.payload;
                return;
            case 'SET_PLAYLIST':
                draft.playlist = action.payload;
                return;
            case 'RESET':
                draft.currentConsumer = undefined;
                draft.playlist = undefined;
                sessionStorage.removeItem('currentConsumer');
                return;
            case 'UPDATE_CONSUMER':
                draft.currentConsumer = action.payload;
                return;
            default:
                return;
        }
    },
);
type Dispatch = (action: ConsumerAction) => void;
const CurrentConsumerContext = createContext<ContextProps>({
    state: initialState,
    dispatch: () => null,
});
export const useCurrentConsumerContext = () =>
    useContext(CurrentConsumerContext);

export const CurrentConsumerStore = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => {
    const [state, dispatch] = useImmerReducer(
        CurrentConsumerReducer,
        initialState,
        () => {
            const localData = sessionStorage.getItem('currentConsumer');
            return localData ? JSON.parse(localData) : initialState;
        },
    );

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    useEffect(() => {
        sessionStorage.setItem('currentConsumer', JSON.stringify(state));
    }, [state]);

    return (
        <CurrentConsumerContext.Provider value={contextValue}>
            {children}
        </CurrentConsumerContext.Provider>
    );
};
