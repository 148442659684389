import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Organization } from 'src/domain/organization';
import { useOrganizations } from 'src/view/hooks/useOrganizations';
import { UserSelectorOption } from '../components/buttons/SelectConsumer/SelectorOption';

export const OrganizationSelector = () => {
    const { organizations } = useOrganizations();
    const history = useHistory();
    const [currentOrgId, setCurrentOrgid] = useState('');

    useEffect(() => {
        setCurrentOrgid(history.location.pathname.split('/').pop() || '');
    }, [history.location.pathname]);
    const onSelect = (organization: Organization) => {
        setCurrentOrgid(organization.id);

        history.push(`/app/admin/organization/${organization.id}`);
    };

    return (
        <div className="overflow-y-auto shadow-scrollTop w-full">
            {organizations.map(organization => {
                return (
                    <UserSelectorOption
                        key={organization.id}
                        user={organization}
                        onClick={() => onSelect(organization)}
                        isSelected={organization.id === currentOrgId}
                        name={organization.organizationDisplayName}
                        entity={'ORGANISASJON'}
                    />
                );
            })}
        </div>
    );
};
