type Props = {
    onClick: () => void;
};
export const HamburgerButton = ({ onClick }: Props) => {
    return (
        <button
            id="hamburger"
            className="visible collapse:hidden"
            onClick={onClick}
            aria-label="menu_button"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={36}
                height={36}
                viewBox="0 0 36 36"
            >
                <g fill="none" className="stroke-current" strokeWidth="4">
                    <path d="M6 11h24" data-name="Line 1" />
                    <path d="M6 18h24" data-name="Line 2" />
                    <path d="M6 25h24" data-name="Line 3" />
                </g>
            </svg>
        </button>
    );
};
