import container from 'src/container';
import useSWR from 'swr';

const {
    cradle: { munduClient },
} = container;

export const useNextOfKins = (consumerId: string) => {
    return useSWR([consumerId, 'nextOfKin/consumerId'], () =>
        munduClient.listNextOfKins({ consumerId }),
    );
};
