import {
    AuthenticationResult,
    EventError,
    EventMessage,
    EventPayload,
    EventType,
    InteractionRequiredAuthError,
    InteractionType,
    PublicClientApplication,
} from '@azure/msal-browser';

import {
    AD_B2C_PASSWORDLESS_SIGNUP_URL,
    MSAL_LOGIN_REQUEST,
    MSAL_TOKEN_REQUEST,
} from 'src/config';

interface Dependencies {
    msal: PublicClientApplication;
}
function isAuthRes(res: EventPayload): res is AuthenticationResult {
    return res !== null && 'account' in res;
}
export class AuthService {
    private msal: PublicClientApplication;
    public error: EventError;

    constructor({ msal }: Dependencies) {
        this.msal = msal;
        this.error = null;
        this.msal.addEventCallback((event: EventMessage) => {
            this.error = event.error;
            const { eventType, interactionType, payload: res } = event;
            switch (eventType) {
                case EventType.LOGIN_SUCCESS:
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                    this.error = null;
                    if (
                        interactionType === InteractionType.Redirect ||
                        interactionType === InteractionType.Popup
                    ) {
                        if (isAuthRes(res)) {
                            this.msal.setActiveAccount(res.account);
                        }
                    }
            }
        });
    }
    getActiveAccount = () => this.msal.getActiveAccount();

    login = () => this.msal.loginRedirect(MSAL_LOGIN_REQUEST);

    logout = () => {
        this.msal.logoutRedirect({
            onRedirectNavigate: () => true,
        });
    };

    getUserToken = () =>
        this.msal
            .acquireTokenSilent(MSAL_TOKEN_REQUEST)
            .then(res => res.accessToken)
            .catch(error => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return this.msal.acquireTokenRedirect(MSAL_TOKEN_REQUEST);
                }
            });

    async registerPhoneIdentity(email?: string) {
        return this.msal.loginPopup({
            ...MSAL_LOGIN_REQUEST,
            loginHint: email,
            authority: AD_B2C_PASSWORDLESS_SIGNUP_URL,
        });
    }
}
