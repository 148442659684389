import { useHistory } from 'react-router-dom';
import container from 'src/container';
import { useCurrentConsumerContext } from 'src/view/screens/Home/NewHome/reducer';
import { useSurveyContext } from 'src/view/screens/MusicalSurvey/reducer';
import { SendButton } from 'src/view/screens/NewVideo/step4/components/SendButton';
import { mutate } from 'swr';
import { useNotification } from '../Notification/useNotfication';
import { debounce } from 'lodash';

const {
    cradle: { munduClient },
} = container;

export const SaveFavoritesButton = () => {
    const {
        state: { artists },
    } = useSurveyContext();

    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();
    const history = useHistory();
    const notify = useNotification();

    const updateArtists = async () => {
        if (!currentConsumer) return;
        try {
            await munduClient.updateMusicalProfile(currentConsumer.id, {
                artists: artists.map(a => a.id),
            });
            mutate([currentConsumer.id, 'musicalProfile']);
            notify('Musikalsk profil er oppdatert!');
            history.push('/app/home');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return <SendButton title="Lagre" onClick={debounce(updateArtists, 250)} />;
};
