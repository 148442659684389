import { useEffect } from 'react';

import { MusicSubViewRouter } from './Router';
import { useAudioPlayerContext } from './audioPlayerReducer';
import { SessionFormTrack } from 'src/domain/sessionForm';

type Props = {
    initialSelection?: SessionFormTrack[];
};

export const FALLBACK_COVER_IMAGE =
    'https://artwork-cdn.7static.com/static/img/sleeveart/00/004/963/0000496338_$size$.jpg';

export const BrowseMusic = ({ initialSelection }: Props) => {
    const { dispatch } = useAudioPlayerContext();

    useEffect(() => {
        if (!initialSelection) return;
        dispatch({ type: 'SET_INITIAL_SELECTION', payload: initialSelection });
    }, [initialSelection, dispatch]);

    return <MusicSubViewRouter />;
};
