import { useHistory } from 'react-router-dom';

import { Release } from 'src/domain/music';
import { useAudioPlayerContext } from '../audioPlayerReducer';

export const ReleaseLink = ({
    release,
    className,
}: {
    release: Release;
    className?: string;
}) => {
    const { dispatch } = useAudioPlayerContext();
    const history = useHistory();
    const onClick = async () => {
        dispatch({
            type: 'SET_RELEASE',
            payload: release,
        });
        history.push('./release');
    };
    return (
        <button className={className} onClick={onClick}>
            {release.title}
        </button>
    );
};
