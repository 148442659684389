import cx from 'classnames';
import { forwardRef } from 'react';

type ButtonType = 'button' | 'submit' | 'reset';

const buttonColors = {
    light: 'border-lightPeach text-lightPeach hover:bg-lightPeach hover:text-woodyBrown',
    dark: 'border-woodyBrown text-woodyBrown hover:text-lightPeach hover:bg-woodyBrown',
    orange: 'border-peachOrange text-woodyBrown bg-peachOrange',
};

interface IProps {
    onClick?: () => void;
    disabled?: boolean;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    label: string;
    type?: ButtonType;
    color?: 'light' | 'dark' | 'orange';
}
const IconButton = forwardRef<HTMLButtonElement, IProps>(
    (
        {
            onClick,
            disabled,
            Icon,
            label,
            type = 'button',
            color = 'dark',
        }: IProps,
        ref,
    ) => {
        return (
            <button
                ref={ref}
                disabled={disabled}
                type={type}
                className={cx(
                    buttonColors[color],
                    'group items-center flex gap-2 rounded-full py-1 px-3 border-1.6px cursor-pointer',
                    'transition whitespace-nowrap',
                    'disabled:bg-transparent disabled:text-mongooseGray disabled:border-vanillaWhite disabled:pointer-events-none',
                )}
                onClick={onClick}
            >
                <Icon className="w-4 h-4" />
                <span className="text-sm lg:text-base">{label}</span>
            </button>
        );
    },
);

IconButton.displayName = 'IconButton';
export { IconButton };
