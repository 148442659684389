import { Creator } from 'src/domain/creator';
import { useConsumers } from 'src/view/hooks';
import { mutate } from 'swr';
import { UserSelectorOption } from '../../components/buttons/SelectConsumer/SelectorOption';
import { useNotification } from 'src/components/Notification/useNotfication';
import container from 'src/container';

const {
    cradle: { munduClient },
} = container;

export const AssignConsumer = ({ assignTo }: { assignTo: Creator }) => {
    const { consumers } = useConsumers();
    const notify = useNotification();
    const assigned = assignTo.followers?.consumerIds || [];

    const toggleAssignedConsumer = async (consumerId: string) => {
        if (assigned.includes(consumerId)) {
            try {
                await munduClient.removeConsumers(assignTo.id, [consumerId]);
                notify('Bruker er fjernet');
            } catch (e) {
                if (e instanceof Error) notify(e.message, true);
            }
        } else {
            try {
                await munduClient.assignConsumers(assignTo.id, [consumerId]);
                notify('Bruker er lagt til');
            } catch (e) {
                if (e instanceof Error) notify(e.message, true);
            }
        }
        mutate([assignTo.id, 'creators/creatorId']);
        mutate([{ creatorId: assignTo.id }, 'consumers']);
    };

    return (
        <div className="bg-lightPeach flex flex-col items-center">
            <div className="text-lg font-semibold pb-4 underline">
                Legg til / fjern
            </div>
            {consumers.map(consumer => (
                <UserSelectorOption
                    isLight
                    user={consumer}
                    onClick={() => toggleAssignedConsumer(consumer.id)}
                    isSelected={assigned.includes(consumer.id)}
                    key={consumer.id}
                />
            ))}
        </div>
    );
};
