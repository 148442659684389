import { useSessionForm } from '../SessionFormState/hooks';
import cx from 'classnames';
import { TrackSelectionDropdown } from 'src/components/Selector/TrackSelectionDropdown';
import { ImageSelectionDropdown } from 'src/components/Selector/ImageSelectionDropdown';

export const SessionProgress = () => {
    const { state } = useSessionForm();
    const { step } = state;

    return (
        <div className={cx(step === 4 && 'invisible', 'flex visible ')}>
            {/* <SelectRecipient /> */}
            {<TrackSelectionDropdown />}
            {<ImageSelectionDropdown />}
            {/* {playbackMode === PlaybackMode.LOOP && (
                <div className="flex items-center text-[10px] px-2 pt-2  text-mongooseGray">
                    ( <Icon SVG={LoopModeIcon} />
                    På)
                </div>
            )} */}

            {/* {<Title />} */}
        </div>
    );
};
