import { Component } from 'react';

type TabProps = {
    activeTab: string;
    label: string;
    onClick: (label: string) => void;
};

class Tab extends Component<TabProps> {
    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    };

    render() {
        const {
            onClick,
            props: { activeTab, label },
        } = this;

        return (
            <button
                className="py-2 px-2 rounded-lg font-[550] text-md tracking-[0.01em]  transition-all
				hover:bg-peachOrange hover:bg-opacity-25
				disabled:bg-woodyBrown disabled:text-lightPeach"
                onClick={onClick}
                disabled={activeTab === label}
            >
                {label}
            </button>
        );
    }
}

export default Tab;
