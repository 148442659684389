import { useCallback, useEffect, useState } from 'react';
import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom';
import {
    LeavingGuardModal,
    RouteLeavingGuard,
} from 'src/view/routes/RouteLeavingGuard';
import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { resetSessionForm, updateRecipient } from './SessionFormState/actions';
import { useSessionForm } from './SessionFormState/hooks';
import { CreateNewVideoStep1 } from './step1';
import { CreateNewVideoStep2 } from './step2';
import { CreateNewVideoStep3 } from './step4';
import { CreateNewVideoStep4 } from './step5';
import { Location } from 'history';
import { NotFound } from '../NotFound';

export const CreateNewVideo = (): JSX.Element => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const {
        state,
        dispatch,
        sessionStatus: { isEmpty, hasUnsavedChanges },
    } = useSessionForm();
    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();

    useEffect(() => {
        if (!currentConsumer) return;
        dispatch(
            updateRecipient({
                id: currentConsumer.id,
                profile: {
                    firstName: currentConsumer.profile.firstName,
                    lastName: currentConsumer.profile.lastName,
                    avatar: currentConsumer.profile.avatar,
                },
            }),
        );
    }, [currentConsumer, dispatch]);

    const [leavingGuardModal, setLeavingGuardModal] =
        useState<LeavingGuardModal>(LeavingGuardModal.confirmExit);

    const shouldBlockNavigation = (nextLocation: Location): boolean => {
        const isLeavingSessionForm = !nextLocation.pathname.startsWith(path);
        if (isLeavingSessionForm) handleSetLeavingGuardModal();
        return isLeavingSessionForm;
    };

    const handleSetLeavingGuardModal = () => {
        if ((state.editMode && !hasUnsavedChanges) || isEmpty) {
            setLeavingGuardModal(LeavingGuardModal.confirmExit);
        } else if (state.editMode && hasUnsavedChanges) {
            setLeavingGuardModal(LeavingGuardModal.editMode);
        } else {
            setLeavingGuardModal(LeavingGuardModal.saveDraft);
        }
    };

    const urlNoConfirm = `${path}/step4`;

    const onLeavePage = useCallback(
        async (path: string) => {
            history.push(path);
            dispatch(resetSessionForm());
        },
        [history, dispatch],
    );

    //Redirect to step 1 if user writes path in address field (path and state step does not match)
    //TODO: Fix redirect to current step if user navigates step 3 and 4 by address field
    useEffect(() => {
        const stepInPathname = parseInt(
            location.pathname.split('/')[3].slice(-1),
        );
        if (stepInPathname !== state.step) {
            history.push(`${path}/step${state.step}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <RouteLeavingGuard
                navigate={(path: string): void => {
                    onLeavePage(path);
                }}
                when={location.pathname !== urlNoConfirm && !isEmpty}
                shouldBlockNavigation={shouldBlockNavigation}
                modalName={leavingGuardModal}
            />
            <Switch>
                <Route path={`${path}/step1`}>
                    <CreateNewVideoStep1 />
                </Route>
                <Route path={`${path}/step2`}>
                    <CreateNewVideoStep2 />
                </Route>

                <Route exact path={`${path}/step3`}>
                    <CreateNewVideoStep3 />
                </Route>

                <Route exact path={`${path}/step4`}>
                    <CreateNewVideoStep4 />
                </Route>
                <Route path="*" component={NotFound} />
            </Switch>
        </>
    );
};
