import { Session } from '../session';
import { UserInfo } from '../user';

export const consumerUsernameRegex = /^[a-zA-Z0-9_]+$/;
export const passwordRegex = /^[^æøåÆØÅ]+$/;

export enum ConsumerSessionStatus {
    active = 'active',
    inactive = 'inactive',
}

export interface ConsumerSession {
    id: string;
    playbackCount: number;
    longestPlayTime: number;
    sessionId: string;
    consumerId: string;
    session: Session[];
    status: ConsumerSessionStatus;
}

export interface Consumer extends UserInfo {
    username: string;
    profile: ConsumerProfile;
    organizationId: string;
}

interface ConsumerProfile {
    firstName: string;
    lastName: string;
    avatar?: string;
}
