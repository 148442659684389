import { EditIcon, TickBrownIconSVG } from 'src/assets/svg';
import { Alert } from 'src/components/Alert';
import { IconButton } from 'src/components/Buttons/IconButton';
import { useSessionForm } from '../SessionFormState/hooks';

type Props = {
    onAgree: () => void;
    onCancel: () => void;
};

export const ModalEditMode = ({ onAgree, onCancel }: Props) => {
    const {
        submit,
        submitting,
        submitted,
        sessionStatus: { formValid, getMissingContent },
    } = useSessionForm();

    const handleSaveChanges = async () => {
        await submit();
        setTimeout(onAgree, 1000);
    };

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75 z-50"></div>
                </div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div
                    className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-lightPeach rounded-30px px-16 py-12 text-woodyBrown">
                        <p className="text-lg font-semibold">
                            {formValid
                                ? 'Du har ulagrede endringer!'
                                : 'Kan ikke lagre video!'}
                        </p>
                        {formValid && (
                            <p className="text-sm mt-2">
                                Ønsker du å lagre endringene?
                            </p>
                        )}
                        {!formValid && (
                            <Alert
                                message={`Mangler ${getMissingContent().join(
                                    ', ',
                                )}`}
                                isSuccess={false}
                                canClose={false}
                                className="mt-4 whitespace-nowrap text-sm pointer-events-none"
                            />
                        )}
                        <div className="mt-6">
                            <div className="mt-6 text-sm flex items-center gap-3">
                                <IconButton
                                    Icon={
                                        submitted ? TickBrownIconSVG : EditIcon
                                    }
                                    onClick={handleSaveChanges}
                                    disabled={
                                        submitting || submitted || !formValid
                                    }
                                    label={
                                        submitting
                                            ? 'Lagrer...'
                                            : submitted
                                            ? 'Lagret'
                                            : 'Lagre endringer'
                                    }
                                />

                                <button
                                    type="button"
                                    onClick={onAgree}
                                    className="px-4 py-1 rounded-full outline-none focus:outline-none border-1.6px border-woodyBrown hover:bg-woodyBrown text-woodyBrown hover:text-white transition duration-200"
                                >
                                    Forkast endringer
                                </button>
                                <button
                                    type="button"
                                    className="outline-none focus:outline-none hover:underline"
                                    onClick={onCancel}
                                >
                                    Angre
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
