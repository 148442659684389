import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Creator } from 'src/domain/creator';
import { mutate } from 'swr';
import container from 'src/container';
import Input from 'src/components/FormComponents/Input';
import { MessageIcon, PhoneIcon } from 'src/components/Icons/icons';
import { useNotification } from 'src/components/Notification/useNotfication';
import { Section } from 'src/components/Section';
import { Button } from 'src/components/Buttons/Button';
import { PeopleSVG } from 'src/assets/svg';
import Form from 'src/components/FormComponents/Form';
import {
    IsOptional,
    IsString,
    Length,
    IsNotEmpty,
    IsEmail,
    ValidateIf,
} from 'class-validator';
import { useSignInIdentities } from 'src/view/hooks/useSignInIdentities';
import _ from 'lodash';

const {
    cradle: { munduClient },
} = container;

class EditCreatorSchema {
    @IsOptional()
    @IsString()
    @Length(2, 30, { message: 'Må være mellom 2 og 30 tegn' })
    @IsNotEmpty({ message: 'Må fylles ut.' })
    firstName?: string;

    @IsOptional()
    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    lastName?: string;

    @IsOptional()
    @ValidateIf(o => o.email?.length !== 0)
    @IsString()
    @IsEmail({}, { message: 'Epost er ikke gyldig' })
    email?: string;
}

export const CreatorProfileEdit = ({ creator }: { creator: Creator }) => {
    const notify = useNotification();
    const { phoneNumber } = useSignInIdentities(creator.id);

    const handleUpdate = async (data: Partial<Creator>) => {
        try {
            await munduClient.updateCreator(creator.id, data);
            mutate([creator.id, 'creators/creatorId']);
            notify('Profil er oppdatert');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return (
        <div className="bg-lightPeach flex flex-col gap-8">
            <Section title="Rediger profil">
                <Form
                    className="bg-lightPeach text-woodyBrown"
                    schema={EditCreatorSchema}
                    onSubmit={handleUpdate}
                    mode="onChange"
                    defaultValues={{
                        email: creator.email,
                        firstName: creator.profile.firstName,
                        lastName: creator.profile.lastName,
                    }}
                >
                    {({
                        register,
                        formState: { isValid, errors, isSubmitting },
                    }) => (
                        <div className="grid grid-cols-2 gap-4 h-full">
                            <div className="col-span-full flex justify-center">
                                {/* TODO: Replace with Role icon when ready */}
                                <div className="rounded-full w-36 h-36 bg-starkWhite flex justify-center items-center mb-4">
                                    <PeopleSVG className="w-[18px] h-[18px]" />
                                </div>
                            </div>

                            <Input
                                className="text-input"
                                type="text"
                                label="Fornavn*"
                                error={errors.firstName}
                                {...register('firstName')}
                            />
                            <Input
                                className="text-input"
                                type="text"
                                label="Etternavn*"
                                error={errors.lastName}
                                {...register('lastName')}
                            />
                            <Input
                                className="text-input"
                                type="tel"
                                label="Telefonnr"
                                leadingIcon={PhoneIcon}
                                value={phoneNumber || ''}
                                readOnly
                            />
                            <Input
                                className="text-input"
                                type="email"
                                label="Epost"
                                error={errors.email}
                                leadingIcon={MessageIcon}
                                {...register('email')}
                                readOnly
                            />
                            <div className="flex items-center gap-6">
                                <Input
                                    value="Lagre"
                                    className="primary-btn-small"
                                    disabled={!isValid || isSubmitting}
                                    type="submit"
                                />
                            </div>
                        </div>
                    )}
                </Form>
            </Section>
            <Section title="Slette brukerkontakt?">
                <DeleteCreator creator={creator} />
            </Section>
        </div>
    );
};

const DeleteCreator = ({ creator }: { creator: Creator }) => {
    const [confirmLastName, setConfirmLastName] = useState('');
    const [canDelete, setCanDelete] = useState(false);
    const notify = useNotification();
    const history = useHistory();

    const onDelete = _.debounce(async () => {
        try {
            await munduClient.deleteCreator(creator.id);
            notify('Brukerkontakt er slettet');
            history.push('/app/creators');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    }, 500);

    useEffect(() => {
        if (confirmLastName === creator.profile.lastName) {
            setCanDelete(true);
        } else {
            setCanDelete(false);
        }
    }, [confirmLastName, creator.profile.lastName]);

    return (
        <>
            <div>
                Ved å slette brukerkontakt, mister bruker tilgang til Mundu og
                bruker kan ikke gjenopprettes
            </div>
            <div className="self-start flex items-end gap-3 flex-wrap">
                <Input
                    className="text-input"
                    type="email"
                    onChange={e => setConfirmLastName(e.currentTarget.value)}
                    label={`Skriv inn '${creator.profile.lastName}' for å slette`}
                    leadingIcon={MessageIcon}
                />
                <Button
                    title="Slett bruker"
                    onClick={onDelete}
                    disabled={!canDelete}
                />
            </div>
        </>
    );
};
