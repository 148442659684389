import { createContext, ReactNode, useContext, useMemo } from 'react';
import produce from 'immer';
import { useImmerReducer } from 'use-immer';
import {
    Item,
    AddItem,
    ActionKeys,
    RemoveItem,
    ClearItems,
    ActionTypes,
    ImageUploadState,
    ImageUploadContext,
    UpdateDescription,
    ToggleConsent,
    SetConsent,
    SetRecipient,
    ClearState,
} from './types';
import { Consumer } from 'src/domain/consumer';

export const addItem = (item: Item): AddItem => ({
    type: ActionKeys.ADD_ITEM,
    payload: item,
});

export const removeItem = (i: number): RemoveItem => ({
    type: ActionKeys.REMOVE_ITEM,
    payload: i,
});
export const clearItems = (): ClearItems => ({ type: ActionKeys.CLEAR_ITEMS });

export const updateDescription = (
    index: number,
    description: string,
): UpdateDescription => ({
    type: ActionKeys.UPDATE_DESCRIPTION,
    payload: { index, description },
});

export const toggleConsent = (): ToggleConsent => ({
    type: ActionKeys.TOGGLE_CONSENT,
});

export const setConsent = (consent: boolean): SetConsent => ({
    type: ActionKeys.SET_CONSENT,
    payload: consent,
});

export const setRecipient = (recipient: Consumer): SetRecipient => ({
    type: ActionKeys.SET_RECIPIENT,
    payload: recipient,
});

export const clearState = (): ClearState => ({
    type: ActionKeys.CLEAR_STATE,
});

const initialState: ImageUploadState = {
    items: [],
    consent: false,
    recipient: undefined,
};

const imageUploadContext = createContext<ImageUploadContext>({
    state: initialState,
    dispatch: () => null,
});

export const useImageUploadContext = () => useContext(imageUploadContext);

export const ImageUploadStore = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => {
    const [state, dispatch] = useImmerReducer<ImageUploadState, ActionTypes>(
        ImageUploadReducer,
        initialState,
    );

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return (
        <imageUploadContext.Provider value={contextValue}>
            {children}
        </imageUploadContext.Provider>
    );
};

const ImageUploadReducer = produce(
    (draft: ImageUploadState = initialState, action: ActionTypes) => {
        switch (action.type) {
            case ActionKeys.ADD_ITEM:
                draft.items.push(action.payload);
                return;
            case ActionKeys.REMOVE_ITEM:
                draft.items.splice(action.payload, 1);
                return;
            case ActionKeys.CLEAR_ITEMS:
                return {
                    ...draft,
                    items: [],
                };
            case ActionKeys.UPDATE_DESCRIPTION:
                draft.items[action.payload.index].description =
                    action.payload.description;
                return;
            case ActionKeys.TOGGLE_CONSENT:
                draft.consent = !draft.consent;
                return;
            case ActionKeys.SET_CONSENT:
                draft.consent = action.payload;
                return;
            case ActionKeys.SET_RECIPIENT:
                draft.recipient = action.payload;
                return;
            case ActionKeys.CLEAR_STATE:
                return initialState;
            default:
                return;
        }
    },
);
