import { useState, useEffect } from 'react';
import { useDebounce } from 'src/view/hooks/useDebounce';
import { SearchIcon } from './Icons/icons';
import Input from './FormComponents/Input';

export const SearchInput = ({
    placeholder = 'Søk',
    onSearchChange,
}: {
    placeholder?: string;
    onSearchChange: (value: string) => void;
}) => {
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);

    useEffect(() => {
        onSearchChange(debouncedSearchValue);
    }, [debouncedSearchValue, onSearchChange]);
    return (
        <Input
            type="text"
            className="rounded-full placeholder-woodyBrown border-woodyBrown border-1.6px px-6 py-2 bg-lightPeach text-woodyBrown"
            placeholder={placeholder}
            onChange={e => setSearchValue(e.target.value)}
            leadingIcon={SearchIcon}
            textColor="woodyBrown"
        />
    );
};
