import {
    useRef,
    useEffect,
    useState,
    useMemo,
    useLayoutEffect,
    useCallback,
} from 'react';
import { reportSubscriberPayload, Track } from 'src/domain/music';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { AudioPlayerControls } from './components/audioPlayerControls';
import { TrackInfo } from './components/trackInfo';
import { VolumeControl } from './components/volumeControl';
import { useAudioPlayerContext } from '../../audioPlayerReducer';
import container from 'src/container';
import { useIsMountedRef } from 'src/view/hooks';
import { useNotification } from 'src/components/Notification/useNotfication';

const {
    cradle: { musicService },
} = container;

export const AudioPlayer = () => {
    const userId = useSignedInUser()?.id;
    const { state, dispatch } = useAudioPlayerContext();
    const { currentTrack, isPlaying } = state;
    const isUnavailable = currentTrack
        ? state.unavailableTracks.includes(currentTrack.id)
        : false;
    const [volume, setVolume] = useState(0.75);
    const audioRef = useRef(new Audio());
    const [trackProgress, setTrackProgress] = useState<number>(0);
    const trackProgressValue = useMemo(() => trackProgress, [trackProgress]);
    const [isReady, setIsReady] = useState(false);
    const fallbackStreamRequested = useRef(false);
    const isMountedRef = useIsMountedRef();
    const notify = useNotification();

    const reportPlay = useCallback(
        async (currentTrack: Track): Promise<void> => {
            if (!userId) return;
            const payload = reportSubscriberPayload(
                currentTrack.id,
                currentTrack.release.id,
                audioRef.current.currentTime,
                fallbackStreamRequested.current ? 33 : undefined,
            );

            await musicService.reportSubscriberStream(userId, payload);
        },
        [userId],
    );

    // Perform setup when currentTrack changes
    useLayoutEffect(() => {
        (async () => {
            if (!currentTrack) return;
            if (!userId) return;
            setIsReady(false);

            audioRef.current.preload = 'none';
            fallbackStreamRequested.current = false;
            const src = await musicService.getSubscriberStream(
                userId,
                currentTrack.id,
            );
            audioRef.current.src = src;
            audioRef.current.load();
        })();
    }, [currentTrack, userId]);

    useLayoutEffect(() => {
        if (!isReady) return;
        if (!userId) return;
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying, isReady, userId]);

    useEffect(() => {
        if (!currentTrack) return;
        const audio = audioRef.current;
        const onUpdateProgress = () => {
            if (isMountedRef.current) {
                setTrackProgress(Math.round(audio.currentTime));
            }
        };
        const onEnd = async () => dispatch({ type: 'NEXT' });

        const onSrcNotSupported = async (e: MediaError) => {
            if (fallbackStreamRequested.current || !userId) {
                notify(
                    `Kunne ikke spille av ${currentTrack.title}. Feilmelding: ${e.message}`,
                    true,
                );

                dispatch({
                    type: 'ADD_UNAVAILABLE',
                    payload: currentTrack.id,
                });
                dispatch({
                    type: 'SET_IS_PLAYING',
                    payload: false,
                });
            } else {
                const src = await musicService.getSubscriberStreamIn320(
                    userId,
                    currentTrack.id,
                );
                fallbackStreamRequested.current = true;
                audioRef.current.src = src;
                audioRef.current.load();
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onError = async (e: any) => {
            //TODO: Check if this still works
            const { error } = e.target;
            switch (error.code) {
                //TODO: properly handle all errors
                case MediaError.MEDIA_ERR_ABORTED:
                    alert('You aborted the media playback.');
                    break;
                case MediaError.MEDIA_ERR_NETWORK:
                    alert('A network error caused the media download to fail.');
                    break;
                case MediaError.MEDIA_ERR_DECODE:
                    alert(
                        'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.',
                    );
                    break;
                case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
                    onSrcNotSupported(error);
                    break;
                default:
                    alert('An unknown media error occurred.');
            }
        };
        const onLoadedTrack = () => setIsReady(true);
        audio.addEventListener('loadeddata', onLoadedTrack);
        audio.addEventListener('ended', onEnd);
        audio.addEventListener('timeupdate', onUpdateProgress);
        audio.addEventListener('error', onError);
        return () => {
            audio.removeEventListener('timeUpdate', onUpdateProgress);
            audio.removeEventListener('ended', onEnd);
            audio.removeEventListener('error', onError);
            audio.removeEventListener('loadeddata', onLoadedTrack);
        };
    }, [currentTrack, isMountedRef, dispatch, userId, notify]);

    const onScrub = (value: number) => {
        audioRef.current.pause();
        audioRef.current.currentTime = value;
        setTrackProgress(audioRef.current.currentTime);
    };
    const onScrubEnd = () => {
        audioRef.current.play();
    };

    const onVolumeChange = (value: number) => {
        setVolume(value);
    };

    const onVolumeChangeEnd = () => {
        audioRef.current.volume = volume;
    };

    // Report on next/prev/unmount/end
    useEffect(() => {
        setTrackProgress(0);
        if (!currentTrack) return;
        const audio = audioRef.current;
        return () => {
            audio.pause();
            reportPlay(currentTrack);
        };
    }, [reportPlay, currentTrack]);

    //Removing current track on unmount
    useEffect(() => {
        return () => {
            dispatch({ type: 'REMOVE_CURRENT_TRACK' });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            id="audio_player"
            className="grid grid-cols-10 w-full h-[108px] px-[36px] text-lightPeach "
        >
            <TrackInfo track={currentTrack} unavailable={isUnavailable} />
            <AudioPlayerControls
                onScrub={onScrub}
                onScrubEnd={onScrubEnd}
                trackProgress={trackProgressValue}
            />
            <VolumeControl
                volume={volume}
                onVolumeChange={onVolumeChange}
                onVolumeChangeEnd={onVolumeChangeEnd}
            />
        </div>
    );
};
