import { Consumer } from 'src/domain/consumer';
import { SASInfo, User } from 'src/domain/user';
import { createAction } from 'typesafe-actions';
import { createActionTypePrefixFormat } from '../common';

const typePrefixFormat = createActionTypePrefixFormat('UserInfo');

/* ------------- action creators ------------- */
export const setUser = createAction(
    typePrefixFormat('SET_USER'),
    (user?: User) => ({
        user,
    }), // payload creator
)();

export const login = createAction(typePrefixFormat('LOGIN'))();
export const logout = createAction(typePrefixFormat('LOGOUT'))();
export const refresh = createAction(typePrefixFormat('REFRESH'))();

export const clearUserState = createAction(
    typePrefixFormat('CLEAR_USER_STATE'),
)();

export const setAvatarSAS = createAction(
    typePrefixFormat('SET_SAS_AVATAR'),
    (sas: SASInfo) => ({
        sas,
    }),
)();

export const setPersonalMediaSAS = createAction(
    typePrefixFormat('SET_SAS_PERSONAL'),
    (sas: SASInfo, consumerId: Consumer['id']) => ({
        sas,
        consumerId,
    }),
)();

export const setCommonMediaSAS = createAction(
    typePrefixFormat('SET_SAS_COMMON'),
    (sas: SASInfo) => ({
        sas,
    }),
)();

export type UserActions =
    | ReturnType<typeof setUser>
    | ReturnType<typeof setAvatarSAS>
    | ReturnType<typeof setPersonalMediaSAS>
    | ReturnType<typeof setCommonMediaSAS>
    | ReturnType<typeof clearUserState>
    | ReturnType<typeof login>
    | ReturnType<typeof logout>
    | ReturnType<typeof refresh>;
