import { ChangeEvent, FormEvent, useState } from 'react';
import { Release } from 'src/domain/music';
import { useReleaseSearch } from 'src/view/hooks/swr/useReleaseSearch';
import { PaginateButton } from '../BrowseMusic/components/paginateButton';
import { ReleaseList } from '../BrowseMusic/components/Release/list';
import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { RecordCollectionBanner } from './recordCollectionBanner';
import { useSurveyContext } from './reducer';

type Props = {
    editable?: boolean;
};
export const RecordCollection = ({ editable = true }: Props) => {
    const {
        dispatch,
        state: { releases },
    } = useSurveyContext();
    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();

    const onSelect = (release: Release) => {
        return dispatch({ type: 'TOGGLE_RELEASE', payload: release });
    };

    return (
        <div className="flex flex-col h-full space-y-6">
            {currentConsumer && (
                <RecordCollectionBanner author={currentConsumer} />
            )}
            <ReleaseList releases={releases} />
            <div className="flex flex-col w-full">
                {releases.length === 0 && (
                    <div className="text-lg w-1/3 text-center self-center py-16">
                        Platesamlingen er tom, bruk søkefeltet nedenfor for å
                        finne utgivelser å legge til.
                    </div>
                )}
                {editable && (
                    <AddToRecordCollection
                        placeholder="Søk etter flere utgivelser"
                        toggleSelected={onSelect}
                    />
                )}
            </div>
        </div>
    );
};

type AddToPlaylistProps = {
    placeholder: string;
    toggleSelected?: (release: Release) => void;
};

const AddToRecordCollection = ({
    placeholder,
    toggleSelected,
}: AddToPlaylistProps) => {
    const [value, setValue] = useState('');
    const [query, setQuery] = useState<string>();
    const { releases, nextPage, hasMore } = useReleaseSearch(query);

    const handleOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setQuery(value);
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };
    return (
        <div className="w-full space-y-6 ">
            <form
                className="flex flex-nowrap space-x-2 pb-4"
                onSubmit={handleOnSubmit}
            >
                <input
                    type="text"
                    className="rounded-full placeholder-woodyBrown border-woodyBrown border-1.6px px-6 py-2 bg-lightPeach text-woodyBrown"
                    placeholder={placeholder}
                    onChange={handleOnChange}
                />
                <button
                    type="submit"
                    className="rounded-full bg-lightPeach border-1.6px border-woodyBrown px-6 py-2 text-woodyBrown"
                >
                    Søk
                </button>
            </form>
            {releases && (
                <ReleaseList releases={releases} onSelect={toggleSelected} />
            )}
            {releases && releases.length > 0 && (
                <PaginateButton hasMore={hasMore} nextPage={nextPage} />
            )}

            {releases && releases.length === 0 && (
                <div className="flex flex-col w-full items-center space-y-2">
                    <div className="block-inline text-lg text-center ">
                        Fant ingen søkresultater for
                        <span className="font-bold">&quot;{query}&quot;</span>
                    </div>
                    <div className="block-inline text-md text-center">
                        Sjekk at søket er korrekt stavet eller benytt færre
                        eller andre søkeord...
                    </div>

                    <div className="w-3/5 text-sm italic text-center">
                        Savner du en spesifikk utgivelse? Mundu-katalogen
                        utvides stadig... Etterhvert som ny musikk legges til
                        vil den være tilgjenglig gjennom søk.
                    </div>
                </div>
            )}
        </div>
    );
};
