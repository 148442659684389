import { Consumer } from 'src/domain/consumer';
import { useAvatar } from 'src/view/hooks/useAvatar';
/* import { PlaylistDetails } from 'src/domain/music';
import { User } from 'src/domain/user';
import { Modal } from '../BrowseMusic/components/modal';
import { DeleteButton } from '../BrowseMusic/PlaylistView/components/deletePlaylistButton';
import { UpdatePlaylistForm } from '../BrowseMusic/PlaylistView/components/updatePlaylistForm'; */

type FavoritesBannerProps = {
    author: Consumer;
    // topColor: string;
    // bottomColor: string;
};

export const RecordCollectionBanner = ({
    author,
}: // topColor,
// bottomColor,

FavoritesBannerProps) => {
    const { src } = useAvatar(author.profile.avatar);
    const authorName = [author.profile.firstName, author.profile.lastName].join(
        ' ',
    );

    // const bannerGradient = `bg-gradient-to-b from-${topColor} to-${bottomColor}`;
    // const onEdit = () => {};

    return (
        // <div className={`${bannerGradient} px-10 pt-16 bg-opacity-75`}>
        <div className="bg-opacity pb-2">
            <div className="flex w-full justify-between gap-3">
                <div className="flex space-x-8 items-start ">
                    <div className="flex-shrink-0 relative w-24 lg:w-32 xl:w-36 aspect-square ">
                        <img
                            className="absolute inset-0 rounded-16px shadow-lg"
                            src={src}
                        />
                    </div>
                    <div className="flex flex-col justify-between h-24 lg:h-32 xl:h-36">
                        <div
                            id="entity"
                            className=" text-[8px] lg:text-sm font-extralight"
                        >
                            UTGIVELSER
                        </div>
                        <button className="font-bold font-logo text-left font-white text-xl xl:text-3xl lg:leading-normal whitespace-nowrap truncate">
                            {author.profile.firstName}&apos;s platesamling
                        </button>

                        {/* <button
                            id="description"
                            onClick={() => setShowModal(true)}
                            disabled={!editable}
                        >
                            <p className="flex-none block text-[10px] lg:text-sm text-mongooseGray text-left line-clamp-2 lg:line-clamp-3">
                                <span className="font-bold">{authorName}</span>
                                &apos;s favorittliste
                            </p>
                        </button> */}

                        <div
                            id="author"
                            className="text-sm xl:text-md font-extralight"
                        >
                            For <span className="font-bold">{authorName}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
