import { getUA } from 'react-device-detect';
import { SEVEN_DIGITAL_PARAMS } from 'src/config';

export enum EndReason {
    userSkipped = 'userSkipped',
    naturalEnd = 'naturalEnd',
    other = 'other',
}

export interface ReportSubscriberPayload {
    trackId: number;
    releaseId: number;
    formatId: number;
    playMode: string;
    dateTimePlayed: string;
    totalTimePlayed: number;
    userAgent: string;
}
export interface ReportPreviewPayload {
    country: string;
    trackId: string;
    dateTimePlayed: string;
    totalTimePlayed: number;
    userAgent: string;
    userId: string;
    playMode: string;
    playType: string;
    endReason: string;
}

export const reportSubscriberPayload = (
    trackId: number,
    releaseId: number,
    totalTimePlayed: number,
    formatId: number = SEVEN_DIGITAL_PARAMS.formatId,
): ReportSubscriberPayload => {
    return {
        trackId,
        releaseId: releaseId,
        playMode: SEVEN_DIGITAL_PARAMS.playMode,
        formatId,
        dateTimePlayed: new Date().toISOString(),
        totalTimePlayed: Math.round(totalTimePlayed),
        userAgent: getUA,
    };
};

export const reportPreviewPayload = (
    trackId: number,
    userId: string,
    totalTimePlayed: number,
    endReason: EndReason = EndReason.other,
): ReportPreviewPayload => {
    return {
        country: SEVEN_DIGITAL_PARAMS.country,
        trackId: trackId.toString(),
        dateTimePlayed: new Date().toISOString(),
        totalTimePlayed: Math.round(totalTimePlayed),
        userAgent: getUA,
        userId,
        playMode: SEVEN_DIGITAL_PARAMS.playMode,
        endReason,
        playType: SEVEN_DIGITAL_PARAMS.playType,
    };
};
