import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import container from 'src/container';
import { UserThunks } from 'src/state/thunks';
const { authService } = container.cradle;

export const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        authService.logout();
        dispatch(UserThunks.logout());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
