import { useLocation } from 'react-router-dom';
import { useTrackSearch } from 'src/view/hooks/swr/useTrackSearch';
import { useArtistSearch } from 'src/view/hooks/swr/useArtistSearch';
import { useReleaseSearch } from 'src/view/hooks/swr/useReleaseSearch';
import { TrackList } from '../components/Track/list';
import { ArtistList } from '../components/Artist/list';
import { ReleaseList } from '../components/Release/list';
import { ReactNode } from 'react';
import {
    ArtistSearchResponse,
    ReleaseSearchResponse,
    TrackSearchResponse,
} from 'src/domain/music';
import { PaginateButton } from '../components/paginateButton';
import { SearchBar } from '../../../../components/Notification/SearchBar';
import { SpinnerIconSVG } from 'src/assets/svg';

const SearchResult = ({
    header,
    resultComponent,
    hasMore,

    nextPage,
}: {
    header: string;
    resultComponent: ReactNode;
    hasMore: boolean;
    nextPage: () => Promise<
        | TrackSearchResponse[]
        | ArtistSearchResponse[]
        | ReleaseSearchResponse[]
        | undefined
    >;
}) => {
    return (
        <section className="space-y-4">
            <h1 className="font-logo font-bold text-3xl leading-none">
                {header}
            </h1>
            {resultComponent}
            <PaginateButton hasMore={hasMore} nextPage={nextPage} />
        </section>
    );
};

export const SearchView = () => {
    const { state } = useLocation<{ search?: string }>();
    const search = state?.search;
    const query = new URLSearchParams(search).get('q');
    const {
        tracks,
        nextPage: nextPageTracks,
        hasMore: hasMoreTracks,
        isLoading: isLoadingTracks,
        isEmpty: isEmptyTracks,
    } = useTrackSearch(query);
    const {
        releases,
        nextPage: nextPageReleases,
        hasMore: hasMoreReleases,
        isLoading: isLoadingReleases,
        isEmpty: isEmptyReleases,
    } = useReleaseSearch(query, 'Album');
    const {
        artists,
        nextPage: nextPageArtists,
        hasMore: hasMoreArtists,
        isLoading: isLoadingArtists,
        isEmpty: isEmptyArtists,
    } = useArtistSearch(query);

    const isLoading =
        (isLoadingTracks || isLoadingReleases || isLoadingArtists) && query;
    const isEmpty = isEmptyTracks && isEmptyReleases && isEmptyArtists;

    return (
        <div className="w-full">
            <div className="inline-flex md:hidden items-center pb-6 w-full">
                <SearchBar
                    searchPath="/app/new-video/step1/browse/search"
                    placeholder={'Søk etter musikk'}
                />
            </div>
            {isLoading ? (
                <div className="grid justify-center">
                    <SpinnerIconSVG className="animate-spin h-8 w-8 text-woodyBrown" />
                </div>
            ) : (
                <div id="search_results" className=" space-y-10">
                    {query && (
                        <>
                            {tracks && tracks.length > 0 && (
                                <SearchResult
                                    header="Sanger"
                                    resultComponent={
                                        <TrackList
                                            tracks={tracks}
                                            displayOption="CARD"
                                            showHeader={false}
                                            showIndexCol={false}
                                            showReleaseCols={false}
                                        />
                                    }
                                    hasMore={hasMoreTracks}
                                    nextPage={nextPageTracks}
                                />
                            )}
                            {artists && artists.length > 0 && (
                                <SearchResult
                                    header="Artists"
                                    resultComponent={
                                        <ArtistList artists={artists} />
                                    }
                                    hasMore={hasMoreArtists}
                                    nextPage={nextPageArtists}
                                />
                            )}
                            {releases && releases.length > 0 && (
                                <SearchResult
                                    header="Album"
                                    resultComponent={
                                        <ReleaseList releases={releases} />
                                    }
                                    hasMore={hasMoreReleases}
                                    nextPage={nextPageReleases}
                                />
                            )}

                            {isEmpty && (
                                <div className="flex flex-col w-full items-center space-y-2">
                                    <div className="block-inline text-lg text-center ">
                                        Fant ingen søkresultater for
                                        <span className="font-bold">
                                            &quot;{query}&quot;
                                        </span>
                                    </div>
                                    <div className="block-inline text-md text-center">
                                        Sjekk at søket er korrekt stavet eller
                                        benytt færre eller andre søkeord.
                                    </div>

                                    <div className="w-3/5 text-sm italic text-center">
                                        Savner du en spesifikk artist, utgivelse
                                        eller låt? Mundu-katalogen utvides
                                        stadig... Etterhvert som ny musikk
                                        legges til vil den være tilgjenglig
                                        gjennom søk.
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
};
