import { useEffect } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { UserRole } from 'src/domain/user';
import { useSignedInUser } from '../hooks/useSignedInUser';
import { useIsAuthenticated as useIsAuthenticatedByAzure } from '@azure/msal-react';
import { useNotification } from 'src/components/Notification/useNotfication';
import { WrapIf } from 'src/components/WrapIf';
import { useHomePath } from '../hooks/useHomePath';

const useIsAuthenticated = () => {
    const user = useSignedInUser();

    const isAuthenticated = useIsAuthenticatedByAzure({
        localAccountId: user?.id,
    });
    return { isAuthenticated: user ? isAuthenticated : false };
};

const useIsAuthorized = (roles: UserRole[]) => {
    const user = useSignedInUser();
    const role = user?.role || '';
    const isAuthorized = user ? roles.includes(user.role) : false;
    return { role, isAuthorized };
};

type Props = {
    roles?: UserRole[];
    requireAuth?: boolean;
} & RouteProps<string>;

const RequireAuth = ({ children }: { children: React.ReactNode }) => {
    const { isAuthenticated } = useIsAuthenticated();
    const notify = useNotification();
    useEffect(() => {
        if (!isAuthenticated) {
            notify(
                'Du må være logget inn for å få tilgang til denne siden',
                true,
            );
        }
    }, [notify, isAuthenticated]);
    return !isAuthenticated ? <Redirect to="/login" /> : <>{children}</>;
};
const RequireRole = ({
    roles,
    children,
}: {
    roles: UserRole[];
    children: React.ReactNode;
}) => {
    const home = useHomePath();
    const { isAuthorized } = useIsAuthorized(roles);
    const notify = useNotification();
    useEffect(() => {
        if (!isAuthorized) {
            notify('Du har ikke tilgang til denne siden', true);
        }
    }, [notify, isAuthorized]);

    return !isAuthorized ? <Redirect to={home} /> : <>{children}</>;
};

const AppRoute = ({
    component: Component,
    roles = [],
    requireAuth = false,
    ...rest
}: Props) => {
    return (
        <Route
            {...rest}
            render={props => (
                <WrapIf
                    condition={requireAuth}
                    wrapper={children => <RequireAuth>{children}</RequireAuth>}
                >
                    <WrapIf
                        condition={roles.length > 0}
                        wrapper={children => (
                            <RequireRole roles={roles}>{children}</RequireRole>
                        )}
                    >
                        {Component ? <Component {...props} /> : null}
                    </WrapIf>
                </WrapIf>
            )}
        />
    );
};

export default AppRoute;
