import { Key, useState } from 'react';
import { ArrowDownIconSVG } from 'src/assets/svg';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Buttons/Button';
import container from 'src/container';
import { Admin } from 'src/domain/admin';
import { getFullName } from 'src/domain/user';
import { useMessageData } from 'src/view/hooks/message';
import Collapse, { Panel } from 'rc-collapse';
import { useAdmins } from 'src/view/hooks/useAdmins';
import { format, parseISO } from 'date-fns';
import nb from 'date-fns/locale/nb';

const {
    cradle: { munduClient },
} = container;

export const AdminList = () => {
    const { admins, mutate } = useAdmins();

    const [activeKey, setActiveKey] = useState<Key | Key[]>();
    const { isSuccess, message, setMessage, clearMessage } = useMessageData();

    const onChange = (newActiveKey: Key | Key[]): void => {
        setActiveKey(newActiveKey);
    };

    const handleDelete = async (id: string) => {
        try {
            await munduClient.deleteAdmin(id);
            mutate();
            setMessage({ message: 'Admin slettet', isSuccess: true });
        } catch (e) {
            setMessage({ message: 'Noe gikk galt', isSuccess: false });
        }
    };

    return (
        <div className="overflow-y-auto rounded-lg  w-full text-woodyBrown">
            <Collapse accordion activeKey={activeKey} onChange={onChange}>
                {admins &&
                    admins.map((data, index) => (
                        <Panel
                            showArrow={false}
                            header={data.profile.firstName}
                            extra={
                                <ArrowDownIconSVG
                                    className={`h-4 w-4 transform duration-500 ${
                                        activeKey === index && 'rotate-180'
                                    }`}
                                />
                            }
                            key={index.toString()}
                        >
                            <div className="flex flex-col gap-3">
                                {renderAdminInfo(data)}
                                <Button
                                    title="Slett"
                                    className="self-end"
                                    onClick={() => handleDelete(data.id)}
                                />
                            </div>
                        </Panel>
                    ))}
            </Collapse>
            <Alert
                isSuccess={isSuccess}
                message={message}
                clearMessage={clearMessage}
            />
        </div>
    );
};

const renderAdminInfo = (data: Admin) => {
    return (
        <div className="flex flex-col">
            <p className="flex justify-between">
                <span className="font-semibold">Navn:</span>
                {getFullName(data)}
            </p>
            <p className="flex justify-between">
                <span className="font-semibold">TelefonNr:</span>
                {data.profile.phoneNumber}
            </p>
            <p className="flex justify-between">
                <span className="font-semibold">Epost:</span>
                {data.email}
            </p>
            <p className="flex justify-between">
                <span className="font-semibold">Opprettet:</span>
                {format(parseISO(data.createdDate), '(dd MMM YYY)', {
                    locale: nb,
                })}
            </p>
            <p className="flex justify-between">
                <span className="font-semibold">Oppdatert:</span>
                {data.updatedDate &&
                    format(parseISO(data.updatedDate), '(dd MMM YYY)', {
                        locale: nb,
                    })}
            </p>
        </div>
    );
};
