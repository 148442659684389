import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { WarningIconSVG } from 'src/assets/svg';
import { getFullName } from 'src/domain/user';
import { useSessionForm } from '../SessionFormState/hooks';
import { Button } from 'src/components/Buttons/Button';

import {
    useCommonMediaFetch,
    usePersonalMediaFetch,
} from 'src/view/hooks/useImageFetch';
import { resetSessionForm } from '../SessionFormState/actions';

export const CreateNewVideoStep4 = () => {
    const history = useHistory();
    const {
        state,
        dispatch: dispatchSessionForm,
        submit,
        submitting,
        submitted,
        error,
    } = useSessionForm();
    const { recipient, title, images } = state;

    const fetchPersonalImage = usePersonalMediaFetch(recipient?.id);
    const fetchCommonImage = useCommonMediaFetch();

    useEffect(() => {
        submit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                history.push('/app/home');
            }
        };
    }, [history]);

    useEffect(() => {
        return () => {
            dispatchSessionForm(resetSessionForm());
        };
    }, [dispatchSessionForm, history]);

    const backHome = () => {
        history.push('/app/home');
    };

    return (
        <div className="space-y-8 w-full h-full">
            <div className="aspect-video bg-woodyBrown rounded-lg max-w-2xl overflow-hidden mx-auto">
                <img
                    src={
                        images[0].containerType === 'pm'
                            ? fetchPersonalImage(images[0].id).src
                            : fetchCommonImage(images[0].id).src
                    }
                    className="w-full h-full object-contain"
                    alt="video_demo"
                />
            </div>

            {submitting ? (
                <div className="text-lg md:text-xl animate-pulse text-center">
                    Sender video...
                </div>
            ) : submitted && recipient ? (
                <div className="w-75 text-xl animate-fade-in-fast mx-auto text-center">
                    {`${title} er nå tilgjengelig for ${getFullName(
                        recipient,
                    )}`}
                </div>
            ) : (
                <div className="flex file:gap-3 w-full justify-center items-center animate-fade-in-fast">
                    <WarningIconSVG className="w-8 h-8 fill-woodyBrown" />
                    <div className="text-base sm:text-lg lg:text-xl max-w-xl text-center">
                        {error},{' '}
                        <span
                            onClick={submit}
                            className=" text-peachOrange hover:underline cursor-pointer font-semibold"
                        >
                            klikk her
                        </span>{' '}
                        <span>for å prøve igjen</span>
                    </div>
                </div>
            )}

            <div className="w-full flex items-center justify-center">
                <Button
                    onClick={backHome}
                    title="Til forsiden"
                    disabled={submitting}
                />
            </div>
        </div>
    );
};
