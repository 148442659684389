import { PlaybackMode } from 'src/domain/session';
import {
    IncompleteSession,
    Recipient,
    SessionFormMedia,
    SessionFormTrack,
} from 'src/domain/sessionForm';

export interface SessionFormStoreType {
    name: string;
    images: SessionFormMedia[];
    trackSelection: SessionFormTrack[];
    title: string;
    recipient: Recipient | undefined;
    duration: number;
    step: number;
    incompleteSessionId: string | undefined;
    playbackMode: PlaybackMode;
    imageDuration: number;
    editMode: boolean;
    id?: string;
    snapShot?: {
        name: string;
        images: SessionFormMedia[];
        trackSelection: SessionFormTrack[];
        title: string;
        recipient: Recipient | undefined;
        duration: number;
        step: number;
        incompleteSessionId: string | undefined;
        playbackMode: PlaybackMode;
        imageDuration: number;
        editMode: boolean;
        id?: string;
    };
}

/* ----- Type keys and Interfaces for actions ----- */

export enum SessionFormTypeKeys {
    updateName = 'UPDATE_NAME',
    updateStep = 'UPDATE_STEP',
    updateImages = 'UPDATE_IMAGES',
    updateMusic = 'UPDATE_MUSIC',
    updateRecipient = 'UPDATE_RECIPIENT',
    updateTitle = 'UPDATE_TITLE',
    removeImage = 'REMOVE_IMAGE',
    removeMusicTrack = 'REMOVE_MUSIC_TRACK',
    resetSessionForm = 'RESET_SESSION_FORM',
    setSessionForm = 'SET_SESSION_FORM',
    updateDuration = 'UPDATE_DURATION',
    togglePlaybackMode = 'TOOGLE_PLAYBACK_MODE',
    updateImageDuration = 'UPDATE_IMAGE_DURATION',
    setSnapshot = 'SET_EDIT_SESSION_FORM',
}

export interface UpdateName {
    type: SessionFormTypeKeys.updateName;
    name: string;
}
export interface UpdateDurationAction {
    type: SessionFormTypeKeys.updateDuration;
    duration: number;
}

export interface UpdateStep {
    type: SessionFormTypeKeys.updateStep;
    step: number;
}
export interface UpdateImagesAction {
    type: SessionFormTypeKeys.updateImages;
    images: SessionFormMedia[];
}

export interface UpdateMusicAction {
    type: SessionFormTypeKeys.updateMusic;
    trackSelection: SessionFormTrack[];
}

export interface UpdateRecipient {
    type: SessionFormTypeKeys.updateRecipient;
    recipient: Recipient | undefined;
}

export interface UpdateTitle {
    type: SessionFormTypeKeys.updateTitle;
    title: string;
}

export interface RemoveImage {
    type: SessionFormTypeKeys.removeImage;
    id: string;
}

export interface RemoveMusicTrack {
    type: SessionFormTypeKeys.removeMusicTrack;
    id: string;
}

export interface ResetSessionFormAction {
    type: SessionFormTypeKeys.resetSessionForm;
}

export interface SetSessionFormAction {
    type: SessionFormTypeKeys.setSessionForm;
    session: IncompleteSession;
}

export interface TogglePlaybackModeAction {
    type: SessionFormTypeKeys.togglePlaybackMode;
}
export interface UpdateImageDurationAction {
    type: SessionFormTypeKeys.updateImageDuration;
    imageDuration: number;
}
export interface SetSnapshotAction {
    type: SessionFormTypeKeys.setSnapshot;
    session: SessionFormStoreType;
}
