import { FunctionComponent, useEffect, useState } from 'react';
import { AppModal } from 'src/components/Modals/AppModal';
import { CaretIcon } from '../Icons/icons';
import { TextIcon } from '../Icons/TextIcon';
import cx from 'classnames';

type Props<T> = {
    children?: React.ReactNode;
    onChange?: (selection: T[]) => void;
    onRemove: (id: string) => void;
    selection: T[];
    name: string;
    namePlural: string;
    disabled?: boolean;
    ItemComponent: FunctionComponent<{
        item: T;
        index: number;
        onRemove: (id: string) => void;
    }>;
    icon: () => JSX.Element;
};

export const SelectionDropdown = <T extends { id: string | number }>({
    children,
    onChange,
    onRemove,
    selection,
    name,
    namePlural,
    icon,
    ItemComponent,
    disabled,
}: Props<T>) => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);
    const selectionSize = selection.length;
    useEffect(() => {
        if (!onChange) return;
        if (selectionSize === 0) setModalOpen(false);
        onChange(selection);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectionSize]);

    return (
        <div className="relative bg-transparent">
            <button
                className={cx(
                    'flex group pt-0.5 text-sm h-12 rounded-md  hover:bg-peachOrange hover:bg-opacity-25 items-center stroke-[1.8px] justify-between pl-2 disabled:pointer-events-none ',
                    modalOpen ? ' opacity-90' : 'disabled:text-mongooseGray',
                )}
                onClick={toggleModal}
                disabled={selectionSize === 0 || modalOpen || disabled}
            >
                <div className="flex items-center">
                    <TextIcon SVG={icon} />
                    <div>
                        <span className="font-bold">{`${selection.length}`}</span>
                        <span className="ml-1">
                            {selection.length === 1 ? name : namePlural}
                        </span>
                    </div>
                    <div
                        className={cx(
                            'rounded-md transition-all duration-200 ',
                            modalOpen ? 'rotate-180' : 'rotate-0',
                        )}
                    >
                        <TextIcon SVG={CaretIcon} />
                    </div>
                </div>
            </button>

            <AppModal clickOutside={() => setModalOpen(false)} show={modalOpen}>
                <div className="absolute z-40 animate-fade-in-fast origin-top-left bg-lightPeach rounded-b-xl shadow-md">
                    <div className="flex flex-col flex-1 overflow-y-scroll py-4 px-3 w-full max-h-[40vh] shadow-scrollTopBottom">
                        <div className="flex-col space-y-2 items-center h-full ">
                            {selection.map((item, index) => {
                                return (
                                    <ItemComponent
                                        key={item.id}
                                        index={index}
                                        item={item}
                                        onRemove={() =>
                                            onRemove(String(item.id))
                                        }
                                    />
                                );
                            })}
                        </div>
                    </div>

                    {children}
                </div>
            </AppModal>
        </div>
    );
};
