import {
    useCommonMediaSas,
    usePersonalMediaSas,
} from 'src/view/hooks/consumer';
import { useIncompleteSessions } from 'src/view/hooks/session';
import ConfimationModalProvider from 'src/components/Modals/ConfirmationModal/ConfirmationModalProvider';
import { IncompleteSession } from 'src/domain/sessionForm';
import container from 'src/container';
import { toTitleCase } from 'src/utils/stringUtils';
import { ConfirmationButton } from 'src/components/Modals/ConfirmationModal/ConfirmationButton';
import { EditIcon, TrashIconSVG } from 'src/assets/svg';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import { Section } from 'src/components/Section';
import { useNotification } from 'src/components/Notification/useNotfication';
import { setSessionForm } from '../NewVideo/SessionFormState/actions';
import { useHistory } from 'react-router-dom';
import { useSessionForm } from '../NewVideo/SessionFormState/hooks';
import { useCurrentConsumerContext } from './NewHome/reducer';
import { VideoIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';

const {
    cradle: { storageService, munduClient },
} = container;

export const IncompleteSessions = () => {
    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();
    const { incompleteSessions, refresh } = useIncompleteSessions(
        currentConsumer?.id,
    );
    const notify = useNotification();
    const history = useHistory();
    const { dispatch } = useSessionForm();

    const onDelete = async (id: string): Promise<void> => {
        try {
            await munduClient.deleteIncompleteSession(id);
            refresh();
            notify('Videoen er slettet');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    const onEdit = (incompleteSession: IncompleteSession) => {
        dispatch(setSessionForm(incompleteSession));
        history.push(`/app/new-video/step1`);
    };

    return (
        <>
            {incompleteSessions.length !== 0 && (
                <Section title="Uferdige videoer">
                    <div className="flex gap-6 flex-wrap">
                        {incompleteSessions.map(incompleteSession => (
                            <IncompleteSessionItem
                                key={incompleteSession.id}
                                incompleteSession={incompleteSession}
                                onDelete={() => onDelete(incompleteSession.id)}
                                onEdit={() => onEdit(incompleteSession)}
                            />
                        ))}
                    </div>
                </Section>
            )}
        </>
    );
};

const IncompleteSessionItem = ({
    incompleteSession,
    onDelete,
    onEdit,
}: {
    incompleteSession: IncompleteSession;
    onDelete: () => Promise<void>;
    onEdit: () => void;
}) => {
    const {
        id,
        sessionForm: { images, name },
        createdDate,
        updatedDate,
    } = incompleteSession;
    const { sas: personalMediaSAS } = usePersonalMediaSas(
        incompleteSession.sessionForm.recipient.id,
    );
    const { sas: commonMediaSAS } = useCommonMediaSas();

    const thumb = images[0];
    const sas =
        thumb?.containerType === 'pm' ? personalMediaSAS : commonMediaSAS;
    const thumbnail = storageService.getImageUrl(thumb?.id, sas);

    return (
        <div
            key={id}
            className="w-60 justify-self-center lg:justify-self-start"
        >
            <div className="w-60 h-36 rounded-xl bg-starkWhite overflow-hidden">
                <div className="relative h-full w-full ">
                    <div className="relative h-full">
                        {thumbnail ? (
                            <img
                                src={thumbnail}
                                className="h-full w-full object-cover"
                                alt=""
                            />
                        ) : (
                            <div className="grid justify-center h-full w-full text-2xl  bg-woodyBrown text-starkWhite ">
                                <TextIcon SVG={VideoIcon} />
                            </div>
                        )}
                    </div>

                    <div className="absolute bottom-0 right-0 left-0 h-12 bg-gradient-to-t from-blackGradient" />
                    <div className="absolute top-0 right-0 left-0 h-12 bg-gradient-to-b from-blackGradient" />
                    <button
                        className="absolute top-0 right-0 p-3 opacity-90 hover:opacity-100 active:opacity-100 transition-all"
                        onClick={onEdit}
                    >
                        <EditIcon className="w-6 stroke-lightPeach transition-colors" />
                    </button>
                </div>
            </div>
            <div className="flex justify-between items-center px-2 pb-2 pt-1 ">
                <div className="flex flex-col">
                    <div className="inline-block space-x-0.5 align-bottom line-clamp-1 truncate">
                        <span className="text-base">
                            {toTitleCase(name)}&nbsp;
                        </span>
                        <span className="text-[10px] font-MatterSQ ">
                            {format(
                                parseISO(updatedDate || createdDate),
                                '(dd MMM YYY)',
                                {
                                    locale: nb,
                                },
                            )}
                        </span>
                    </div>
                </div>
                <ConfimationModalProvider message="Er du sikker på at du vil slette denne videoen?">
                    <ConfirmationButton
                        type="button"
                        onClick={onDelete}
                        className="p-3 items-center rounded-md text-sm outline-none focus:outline-none  hover:bg-woodyBrown hover:text-lightPeach transition-all"
                    >
                        <TrashIconSVG />
                    </ConfirmationButton>
                </ConfimationModalProvider>
            </div>
        </div>
    );
};
