import { createContext, ReactNode, useEffect, useReducer } from 'react';
import { SessionFormReducer, initialState } from './reducer';
import { SessionFormStoreType } from './interfaces';
import { SessionFormActionTypes } from './actions';

export type Dispatch = (action: SessionFormActionTypes) => void;

const SessionFormStateContext = createContext<SessionFormStoreType | undefined>(
    undefined,
);

const SessionFormDispatchContext = createContext<Dispatch | undefined>(
    undefined,
);

const SessionFormStore = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => {
    const [state, dispatch] = useReducer(
        SessionFormReducer,
        initialState,
        () => {
            const localData = sessionStorage.getItem('SessionFormState');
            return localData ? JSON.parse(localData) : initialState;
        },
    );

    useEffect(() => {
        sessionStorage.setItem('SessionFormState', JSON.stringify(state));
    }, [state]);

    return (
        <SessionFormStateContext.Provider value={state}>
            <SessionFormDispatchContext.Provider value={dispatch}>
                {children}
            </SessionFormDispatchContext.Provider>
        </SessionFormStateContext.Provider>
    );
};

export {
    SessionFormStore,
    SessionFormStateContext,
    SessionFormDispatchContext,
};
