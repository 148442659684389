import container from 'src/container';
import useSWR from 'swr';

const {
    cradle: { munduClient },
} = container;

export const useAdmins = () => {
    const { data, error, mutate } = useSWR('listAdmins', () =>
        munduClient.listAdmins(),
    );

    return {
        admins: data || [],
        mutate: mutate,
        isLoading: !error && !data,
        isError: error,
    };
};
