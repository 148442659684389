import { useNextOfKins } from 'src/view/hooks/nextOfKin';
import { NextOfKin } from 'src/domain/next-of-kin';
import { CardNextOfKinLoading } from '../../MyClients/detail/components/CardNextOfKinLoading';
import { CardNextOfKin } from '../../MyClients/detail/components/CardNextOfKin';
import { Consumer } from 'src/domain/consumer';
import { AddButton } from 'src/components/Buttons/AddButton';

export const NextOfKinList = ({
    consumerId,
    onDelete,
}: {
    consumerId: Consumer['id'];
    onDelete: (id: NextOfKin['id']) => void;
}) => {
    const { data: nextOfKins } = useNextOfKins(consumerId);

    return (
        <div className="h-full relative text-woodyBrown">
            <div className="flex gap-6 flex-wrap">
                <AddButton
                    to={'/app/nextofkins/new'}
                    label="Ny pårørende"
                    aspect="video"
                />
                {nextOfKins ? (
                    nextOfKins.map(item => {
                        return (
                            <CardNextOfKin
                                onDelete={() => onDelete(item.id)}
                                key={`nextOfKin-${item.id}`}
                                item={item}
                            />
                        );
                    })
                ) : (
                    <>
                        <CardNextOfKinLoading />
                        <CardNextOfKinLoading opacity={0.5} />
                    </>
                )}
            </div>
        </div>
    );
};
