import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useIsSelectingMusic } from '../hooks/useIsSelectingMusic';
import { SmallBackButton } from '../../components/Buttons/SmallBackButton';
import { SearchBar } from '../../components/Notification/SearchBar';
import { FavoritesNavItem } from '../screens/components/buttons/UserFavoritesButton';
import cx from 'classnames';
import { SmallNextButton } from 'src/components/Buttons/SmallNextButton';
import { LibraryIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { BackButton } from 'src/components/Buttons/BackButton';

const MusicSelectionTabs = () => {
    const isBrowse = useRouteMatch('/app/new-video/step1/browse')?.isExact;
    // const isSearch = useRouteMatch(
    //     '/app/new-video/step1/browse/search',
    // )?.isExact;
    return (
        <div className="inline-flex ">
            <Link to="/app/new-video/step1/browse">
                <button
                    className={cx(
                        'flex group text-sm h-12 ml-[2px] rounded-md  hover:bg-woodyBrown hover:text-lightPeach  items-center stroke-[1.8px] justify-between pl-1 disabled:pointer-events-none ',
                        isBrowse && ' bg-woodyBrown disabled:text-lightPeach',
                    )}
                    disabled={isBrowse}
                >
                    <div className="flex items-center pr-3">
                        {/* <MusicIconSVG className="w-4" /> */}
                        <TextIcon SVG={LibraryIcon} />
                        <div>Bibliotek</div>
                    </div>
                </button>
            </Link>
            <FavoritesNavItem
                to={'/app/new-video/step1/browse/favorites'}
                type="TAB"
            />
            {/* <Link to="/app/new-video/step1/browse/search">
                <button
                    className={cx(
                        'flex group text-sm h-12 ml-2 rounded-md bg-lightPeach text-woodyBrown hover:bg-lightPeach hover:text-woodyBrown  items-center stroke-[1.8px] justify-between pl-1 disabled:pointer-events-none transition-all ',
                        isSearch &&
                            ' bg-lightPeach disabled:text-woodyBrown  bg-opacity-50 shadow-inner ',
                    )}
                    disabled={isSearch}
                >
                    <div className="flex items-center pr-3">
                        <Icon SVG={SearchIcon} />
                        <div>Søk</div>
                    </div>
                </button>
            </Link> */}
        </div>
    );
};

export const MusicSelectionHeader = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const isSelectingMusic = useIsSelectingMusic();
    // const isActive = useRouteMatch('/app/new-video/step1/browse')?.isExact;
    if (!isSelectingMusic) return null;

    const onAbort = () => history.push('/app/new-video/step1');

    return (
        <div className="flex w-full items-center justify-between h-[52px]">
            <div className="inline-flex items-center">
                <div className="hidden sm:inline-flex space-x-1 items-center mr-2 ">
                    <SmallBackButton />
                    <SmallNextButton />
                </div>
                <MusicSelectionTabs />
            </div>
            <div className="inline-flex items-center">
                <button
                    onClick={onAbort}
                    type="button"
                    className="outline-none align-bottom hidden text-sm  md:flex items-end hover:underline px-4  focus:outline-none"
                >
                    Avbryt
                </button>
                {!pathname.endsWith('userplaylist') && (
                    <div className="hidden md:inline-flex items-center">
                        <SearchBar
                            searchPath="/app/new-video/step1/browse/search"
                            placeholder={'Søk etter musikk'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export const ImageSelectionHeader = () => {
    const history = useHistory();
    const onAbort = () => history.push('/app/new-video/step2');
    return (
        <div className="flex w-full items-center justify-between h-[52px]">
            <BackButton onClick={onAbort} />
        </div>
    );
};
