import { forwardRef, useState, memo } from 'react';
import { ArrowDownIconSVG } from 'src/assets/svg';
import { Link } from 'react-scroll';
import { ContentSection } from '..';
import { Colors } from 'src/assets';
import { Circle } from '../../components';

type ValuePropositionProps = {
    onAnimationEnd?: () => void;
    bgColor: string;
    id: string;
    animate: boolean;
};

const ValueProposition = forwardRef<HTMLElement, ValuePropositionProps>(
    ({ onAnimationEnd, id, animate }: ValuePropositionProps, ref) => {
        const [suffixClassName, setSuffixClassName] = useState('');
        const [blurredClassName, setBlurredClassName] = useState(
            'animate-blurred-text-in',
        );
        const [suffix, setSuffix] = useState<'sikk' | 'ndu'>('sikk');
        const [scrollIndVisible, setScrollInvisible] = useState(
            onAnimationEnd ? false : true,
        );

        const handleSuffixAnimationEnd = () => {
            setSuffixClassName('animate-fade-in-suffix pr-0.88');
            setBlurredClassName('animate-unblur-text');
            setSuffix('ndu');
            setScrollInvisible(true);
            if (onAnimationEnd) {
                onAnimationEnd();
            }
        };
        const handleOnCircleAnimationEnd = () => {
            setSuffixClassName('animate-fade-out-suffix');
        };

        return (
            <section
                ref={ref}
                id={id}
                className="w-full justify-items-center parallax_group"
            >
                <div
                    id="tagLine"
                    className="parallax_layer parallax_layer-0 text-center leading-none text-54px sm:text-86px md:text-106px lg:text-140px font-logo text-woodyBrown pt-7/16 "
                >
                    <div>
                        Mu
                        <span
                            onAnimationEnd={handleSuffixAnimationEnd}
                            className={animate ? suffixClassName : ''}
                        >
                            {suffix}{' '}
                        </span>
                        <span className={animate ? blurredClassName : ''}>
                            vekker
                            <br />
                            livsgleden
                        </span>
                    </div>
                </div>
                <div
                    id="valuePropositionText"
                    className={`parallax_layer prose prose-peach sm:prose-lg px-6 parallax_layer-2 ${
                        scrollIndVisible ? '' : 'opacity-0'
                    } grid justify-center`}
                >
                    <p
                        className={
                            scrollIndVisible
                                ? 'text-center animate-fade-in '
                                : ''
                        }
                    >
                        Vi gir personer med demens – og alle som står dem rundt
                        – en mulighet til å skape gode øyeblikk, forbedret helse
                        og fornyet livsglede gjennom musikk og bilder fra eget
                        liv.
                    </p>
                </div>
                {scrollIndVisible ? (
                    <Link
                        containerId="LandingPage"
                        className="absolute left-1/2 top-14/16 w-6 h-6 animate-bounce "
                        to={ContentSection.PromoVideo}
                        aria-label="Scroll_Down"
                        href={'#' + ContentSection.PromoVideo}
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={250}
                    >
                        <ArrowDownIconSVG
                            className={`${
                                animate ? 'animate-fade-in' : 'transparent'
                            } relative -left-1/2 w-6 h-6`}
                        />
                    </Link>
                ) : null}
                <Circle
                    className={`h-screen flex-column parallax_layer parallax_layer-3 pt-5/16 ${
                        animate ? 'animate-blur-in' : ''
                    }`}
                    onAnimationEnd={handleOnCircleAnimationEnd}
                    color={Colors.peachOrange}
                />
            </section>
        );
    },
);
ValueProposition.displayName = 'ValueProposition';

export default ValueProposition;
