import { useReleaseTracks } from 'src/view/hooks/swr/useReleaseTracks';
import { TrackList } from '../components/Track/list';
import { useReleaseDetails } from 'src/view/hooks/swr/useReleaseDetails';
import { useAudioPlayerContext } from '../audioPlayerReducer';

export const ReleaseView = () => {
    const {
        state: { release },
    } = useAudioPlayerContext();
    const { tracks } = useReleaseTracks(release?.id);
    const { release: details } = useReleaseDetails(release?.id);

    const pline = `(P) ${details?.pline?.replace('(P)', '')?.replace('℗', '')}`;

    return (
        <div className="space-y-4">
            <div className="flex w-full justify-between gap-3 pb-2">
                <div className="flex space-x-8 items-start ">
                    <div className="flex-shrink-0 relative h-35 w-35 ">
                        <img
                            className="absolute inset-0 rounded-16px shadow-lg"
                            src={release?.image}
                            alt={`for release ${release?.title}`}
                        />
                    </div>
                    <div className="flex flex-col space-y-4">
                        <div id="entity" className=" text-sm font-extralight">
                            {'UTGIVELSE'}
                        </div>
                        <h1 className=" text-3xl font-logo line-clamp-2 ">
                            {release?.title}
                        </h1>
                        <h1 className="text-sm font-logo">{release?.year}</h1>
                    </div>
                </div>
            </div>
            {tracks && tracks.length > 0 && (
                <>
                    <TrackList
                        tracks={tracks}
                        displayOption="LIST"
                        showReleaseCols={false}
                    />
                    <p className="text-sm w-full ">{pline}</p>
                </>
            )}
        </div>
    );
};
