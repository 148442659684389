import {
    ValidatorConstraint,
    ValidatorConstraintInterface,
    ValidationOptions,
    registerDecorator,
    isPhoneNumber,
    isEmail,
} from 'class-validator';

import container from 'src/container';
import { Creator } from 'src/domain/creator';
import { Organization } from 'src/domain/organization';

import { NextOfKin } from '../next-of-kin';

const { munduClient } = container.cradle;
@ValidatorConstraint({ name: 'usernameExistsConstraint', async: true })
export class UsernameExistsConstraint implements ValidatorConstraintInterface {
    async validate(name: unknown): Promise<boolean> {
        if (typeof name !== 'string') return false;
        if (name.length > 100 || name.length < 3) return false;
        try {
            const exists = await munduClient.getUsernameExists(name);
            if (exists) return false;
            return true;
        } catch (e) {
            return false;
        }
    }
}

export function UsernameExists(validationOptions?: ValidationOptions) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return (object: Object, propertyName: string): void => {
        registerDecorator({
            name: 'UsernameExists',
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: UsernameExistsConstraint,
        });
    };
}

@ValidatorConstraint({ name: 'phonenumberExistsConstraint', async: true })
export class PhoneNumberExistConstraint
    implements ValidatorConstraintInterface
{
    async validate(phonenumber: unknown): Promise<boolean> {
        if (typeof phonenumber !== 'string') return false;
        if (!isPhoneNumber(phonenumber, 'NO')) return false;
        try {
            const exists = await munduClient.getPhoneNumberExists(phonenumber);
            if (exists) return false;
            return true;
        } catch (e) {
            return false;
        }
    }
}
export function PhoneNumberExists(validationOptions?: ValidationOptions) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return (object: Object, propertyName: string): void => {
        registerDecorator({
            name: 'PhoneNumberExists',
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: PhoneNumberExistConstraint,
        });
    };
}

@ValidatorConstraint({ name: 'emailExistConstraint', async: true })
export class EmailExistConstraint implements ValidatorConstraintInterface {
    async validate(email: unknown): Promise<boolean> {
        if (typeof email !== 'string') return false;
        if (!isEmail(email)) return false;
        try {
            const exists = await munduClient.getEmailExists(email);
            if (exists) return false;
            return true;
        } catch (e) {
            return false;
        }
    }
}
export function EmailExist(validationOptions?: ValidationOptions) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    return (object: Object, propertyName: string): void => {
        registerDecorator({
            name: 'EmailExist',
            target: object.constructor,
            propertyName: propertyName,
            options: validationOptions,
            validator: EmailExistConstraint,
        });
    };
}
export const checkIfEmailExist = async (email: string) => {
    const exists = await munduClient.getEmailExists(email);
    if (exists) throw new Error('Epost er allerede i bruk');
};

export const checkIfPhoneNumberExist = async (phoneNumber: string) => {
    const exists = await munduClient.getPhoneNumberExists(phoneNumber);
    if (exists) throw new Error('Telefonnummeret er allerede i bruk.');
};

export const checkIfUsernameExist = async (username: string) => {
    const exists = await munduClient.getUsernameExists(username);
    if (exists) throw new Error('Brukernavn eller allerede i bruk.');
};

export enum SASName {
    avatarSAS = 'avatarSAS',
    commonMediaSAS = 'commonMediaSAS',
    personalMediaSAS = 'personalMediaSAS',
}

export type User = Creator | NextOfKin | Organization;

export enum UserRole {
    consumer = 'consumer',
    creator = 'creator',
    admin = 'admin',
    organization = 'organization',
    nextOfKin = 'next-of-kin',
}

export interface SASInfo {
    value: string;
    expiresOn: string;
    containerName: string;
}

export interface UserInfo {
    id: string;
    role: UserRole;
    createdDate: string;
    updatedDate?: string;
    profile: {
        firstName: string;
        lastName: string;
        avatar?: string;
    };
    followers?: {
        creatorIds?: string[];
        consumerIds?: string[];
        nextOfKinIds?: string[];
    };
}

export const getUserRole = (role: UserRole | string) => {
    switch (role) {
        case UserRole.admin:
            return 'Admin';
        case UserRole.organization:
            return 'Superbruker';
        case UserRole.creator:
            return 'Brukerkontakt';
        case UserRole.nextOfKin:
            return 'Pårørende';
        case UserRole.consumer:
            return 'Bruker';
        default:
            return 'Rolle';
    }
};
export const getFullName = (user: Pick<User, 'profile'>): string => {
    return `${user.profile.firstName} ${user.profile.lastName}`;
};

export const getNameInitials = (user: Pick<User, 'profile'>): string => {
    return `${user.profile.firstName
        .slice(0, 1)
        .toUpperCase()}${user.profile.lastName.slice(0, 1).toUpperCase()}`;
};

const isOrganization = (user: User): user is Organization => {
    return user.role === UserRole.organization;
};

export const getOrganizationId = (user: User): string => {
    return isOrganization(user) ? user.id : user.organizationId;
};

/* export const isAdmin = (user: User): user is Admin => {
    return user.role === UserRole.admin;
}; */
