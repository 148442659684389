type Props = {
    opacity?: number;
};

export const CardNextOfKinLoading = ({ opacity = 1 }: Props) => {
    return (
        <div
            style={{ opacity: opacity }}
            className="bg-starkWhite lg:w-87.5 lg:h-44 w-75 h-40 p-5 rounded-2xl"
        >
            <div className="animate-pulse h-5 mb-1 bg-lightPeach bg-opacity-75 rounded w-2/3" />
            <div className="animate-pulse h-5 mb-1 bg-lightPeach bg-opacity-75 rounded w-1/3" />
            <div className="animate-pulse h-5 mb-1 bg-lightPeach bg-opacity-75 rounded w-4/5" />
            <div className="mt-3 flex gap-2">
                <div className="rounded-full px-3 py-1 border-1.6px border-woodyBrown border-opacity-10">
                    <p className="filter blur-sm">Send ny invitasjon</p>
                </div>
                <div className="rounded-full px-3 py-1 border-1.6px border-woodyBrown border-opacity-10">
                    <p className="filter blur-sm">Slett</p>
                </div>
            </div>
        </div>
    );
};
