import { useEffect, useRef, useState } from 'react';
import { useInterval } from 'src/view/hooks/useInterval';

export const useTimer = (audioElement: HTMLAudioElement) => {
    const accumulatedTime = useRef(0);
    const [progress, setProgress] = useState(0);

    const onUpdateProgress = () => {
        setProgress(audioElement.currentTime);
    };

    useInterval(onUpdateProgress, 1000);

    useEffect(() => {
        const onEnded = () => {
            accumulatedTime.current =
                accumulatedTime.current + audioElement.currentTime;
            setProgress(0);
        };
        audioElement.addEventListener('ended', onEnded);
        return () => {
            audioElement.removeEventListener('ended', onEnded);
        };
    }, [audioElement]);

    return Math.round(accumulatedTime.current + progress);
};
