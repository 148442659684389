import container from 'src/container';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { useState, useEffect } from 'react';
import { ScrollableModal } from './Modals/ScrollableModal';
import { Section } from './Section';
import { Button } from './Buttons/Button';
import { useNotification } from './Notification/useNotfication';
import { useSignInIdentities } from 'src/view/hooks/useSignInIdentities';

const {
    cradle: { authService },
} = container;

const userHasDeclinedPasswordlessRegistration = (id: string) => {
    const phoneNumberIdentityChecked = sessionStorage.getItem(
        `UserHasDeclinedPasswordlessRegistration-${id}`,
    );
    return phoneNumberIdentityChecked
        ? JSON.parse(phoneNumberIdentityChecked)
        : false;
};

export const PhoneNumberIdentityCheck = () => {
    const user = useSignedInUser();
    const [shouldAddPhoneNumber, setShouldAddPhoneNumber] = useState(false);
    const notify = useNotification();
    const { phoneNumber, isLoading } = useSignInIdentities(user?.id);

    const registerPasswordlessSignIn = async () => {
        try {
            await authService.registerPhoneIdentity(user?.email);
            setShouldAddPhoneNumber(false);
            notify('Du kan nå logge inn med telefonnummer');
        } catch (e) {
            notify('Noe gikk galt, prøv igjen', true);
        }
    };

    useEffect(() => {
        if (isLoading) return;
        if (!phoneNumber) {
            setShouldAddPhoneNumber(true);
        }
    }, [isLoading, phoneNumber]);

    if (!user) return null;

    if (
        userHasDeclinedPasswordlessRegistration(user.id) ||
        (!isLoading && phoneNumber)
    ) {
        return null;
    }

    return (
        <ScrollableModal
            onClose={() => {
                setShouldAddPhoneNumber(false);
                sessionStorage.setItem(
                    `UserHasDeclinedPasswordlessRegistration-${user.id}`,
                    JSON.stringify(true),
                );
            }}
            closeOnOutsideClick={false}
            visible={shouldAddPhoneNumber}
        >
            <Section title="Mundu går over til passordløs innlogging!">
                <div className="text-base">
                    Vi ønsker å gjøre Mundu enklere å bruke. Med passordløs
                    innlogging trenger du kun å skrive inn ditt telefonnummer,
                    og du vil motta en kode på sms hver gang du logger inn.
                </div>
                <div className="self-center">
                    <Button
                        title="Opprett passordløs innlogging"
                        onClick={registerPasswordlessSignIn}
                    />
                </div>
            </Section>
        </ScrollableModal>
    );
};
