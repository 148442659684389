import {
    BlobServiceClient,
    BlobUploadCommonResponse,
    BlockBlobClient,
    BlobItem,
    ContainerClient,
} from '@azure/storage-blob';
import { AZURE_STORAGE_URL } from 'src/config';
import { SASInfo } from 'src/domain/user';

export class StorageService {
    private containerClient: ContainerClient;
    setContainer(containerName: string, sas: SASInfo): void {
        const blobServiceClient = new BlobServiceClient(
            `${AZURE_STORAGE_URL}?${sas?.value}`,
        );
        this.containerClient =
            blobServiceClient.getContainerClient(containerName);
    }

    getImageUrl(
        fileName: string | undefined,
        sas?: SASInfo | undefined,
    ): string | undefined {
        if (!sas || !fileName) return;
        return `${AZURE_STORAGE_URL}/${sas.containerName}/${fileName}?${sas.value}`;
    }

    async getBlockBlobClient(
        sas: SASInfo,
        name: string,
    ): Promise<BlockBlobClient> {
        const blobServiceClient = new BlobServiceClient(
            `${AZURE_STORAGE_URL}?${sas.value}`,
        );

        const containerClient = blobServiceClient.getContainerClient(
            sas.containerName,
        );

        return containerClient.getBlockBlobClient(name);
    }

    async deleteImage(fileName: string, sas: SASInfo): Promise<void> {
        const blockBlobClient = await this.getBlockBlobClient(sas, fileName);
        await blockBlobClient.deleteIfExists();
    }

    async uploadImage(
        file: File | Blob,
        name: string,
        sas: SASInfo,
    ): Promise<BlobUploadCommonResponse> {
        const blockBlobClient = await this.getBlockBlobClient(sas, name);
        return await blockBlobClient.uploadData(file, {
            blobHTTPHeaders: { blobContentType: file.type },
        });
    }

    async getContainerClient(sas: SASInfo): Promise<ContainerClient> {
        const blobServiceClient = new BlobServiceClient(
            `${AZURE_STORAGE_URL}?${sas?.value}`,
        );

        const containerClient = blobServiceClient.getContainerClient(
            sas.containerName,
        );

        return containerClient;
    }

    async downloadBlob(
        blobItem: BlobItem,
    ): Promise<{ id: string; file: string }> {
        const blobClient = this.containerClient.getBlobClient(blobItem.name);
        const blobResponse = await blobClient.download(0);
        const blob = await blobResponse.blobBody;
        return {
            id: blobItem.name,
            file: blob ? URL.createObjectURL(blob) : '',
        };
    }

    async list(
        pageSize = 20,
        continuationToken?: string,
    ): Promise<{
        continuationToken: string | undefined;
        images: { id: string; file: string }[];
    }> {
        const iterator = this.containerClient.listBlobsFlat().byPage({
            continuationToken,
            maxPageSize: pageSize,
        });
        const {
            continuationToken: nextContinuationToken,
            segment: { blobItems },
        } = (await iterator.next()).value;

        const [containerUrl, containerClientSas] =
            this.containerClient.url.split('?');
        const images = blobItems.map(
            (blobItem: BlobItem) =>
                `${containerUrl}/${blobItem.name}/${containerClientSas}`,
        );
        return {
            continuationToken: nextContinuationToken,
            images: images,
        };
    }
}
