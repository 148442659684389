import { mutate } from 'swr';
import { UserSelectorOption } from '../../components/buttons/SelectConsumer/SelectorOption';
import { useNotification } from 'src/components/Notification/useNotfication';
import container from 'src/container';
import { useCreators } from 'src/view/hooks/creator';
import { Consumer } from 'src/domain/consumer';

const {
    cradle: { munduClient },
} = container;

export const AssignCreator = ({ assignTo }: { assignTo: Consumer }) => {
    const { creators } = useCreators();
    const notify = useNotification();
    const assigned = assignTo.followers?.creatorIds || [];

    const toggleAssignedCreator = async (creatorId: string) => {
        if (assigned.includes(creatorId)) {
            try {
                await munduClient.removeCreators(assignTo.id, [creatorId]);
                notify('Brukerkontakt er fjernet');
            } catch (e) {
                if (e instanceof Error) notify(e.message, true);
            }
        } else {
            try {
                await munduClient.assignCreators(assignTo.id, [creatorId]);
                notify('Brukerkontakt er lagt til');
            } catch (e) {
                if (e instanceof Error) notify(e.message, true);
            }
        }
        mutate([assignTo.id, 'creators/consumerId']);
        mutate([assignTo.id, 'consumer/consumerId']);
    };

    return (
        <div className="bg-lightPeach flex flex-col items-center">
            <div className="text-lg font-semibold pb-4 underline">
                Legg til / fjern
            </div>
            {creators.map(creator => (
                <UserSelectorOption
                    isLight
                    user={creator}
                    onClick={() => toggleAssignedCreator(creator.id)}
                    isSelected={assigned.includes(creator.id)}
                    key={creator.id}
                />
            ))}
        </div>
    );
};
