import { useState } from 'react';
import {
    MuteIconSVG,
    PauseIconSVG,
    PlayIconSVG,
    VolumnIconSVG,
} from 'src/assets/svg';

type Props = {
    audioElement: HTMLAudioElement;
    toggleSlideshow: () => void;
    isPlay: boolean;
};

export const Controls = ({ audioElement, toggleSlideshow, isPlay }: Props) => {
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const toggleMuted = (): void => {
        setIsMuted(prev => !prev);
        if (!isMuted) {
            audioElement.muted = true;
        } else {
            audioElement.muted = false;
        }
    };
    return (
        <>
            <button
                type="button"
                onClick={toggleSlideshow}
                className="absolute left-0 outline-none focus:outline-none"
            >
                {isPlay ? (
                    <PauseIconSVG className="fill-current text-woodyBrown" />
                ) : (
                    <PlayIconSVG className="fill-current text-woodyBrown" />
                )}
            </button>
            <button
                style={{ marginLeft: '36px' }}
                type="button"
                onClick={toggleMuted}
                className="outline-none absolute left-0 focus:outline-none"
            >
                {isMuted ? <MuteIconSVG /> : <VolumnIconSVG />}
            </button>
        </>
    );
};
