import container from 'src/container';
import { CreateAdminPayload } from 'src/domain/admin';
import { mutate } from 'swr';
import Input from 'src/components/FormComponents/Input';
import { Button } from 'src/components/Buttons/Button';
import { DesktopIcon, MessageIcon } from 'src/components/Icons/icons';
import { IsString, IsNotEmpty, IsEmail } from 'class-validator';
import Form from 'src/components/FormComponents/Form';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { useNotification } from 'src/components/Notification/useNotfication';
import { checkIfEmailExist } from 'src/domain/user';

const {
    cradle: { munduClient },
} = container;
class CreateAdminSchema {
    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut' })
    firstName: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut' })
    lastName: string;

    @IsNotEmpty({ message: 'Må fylles ut' })
    @IsEmail({}, { message: 'Ugyldig epostadresse' })
    email: string;
}

export const CreateAdminForm = () => {
    const notify = useNotification();
    const onSubmit = async (data: CreateAdminSchema): Promise<void> => {
        const payload: CreateAdminPayload = {
            email: data.email,
            profile: {
                firstName: data.firstName,
                lastName: data.lastName,
            },
            initialPassword: 'Samla2021',
        };

        try {
            await checkIfEmailExist(data.email);
            await munduClient.createAdmin(payload);
            notify('Dette gikk meget bra');
            mutate('listAdmins');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return (
        <Form
            schema={CreateAdminSchema}
            className="w-full h-full"
            mode="onChange"
            onSubmit={onSubmit}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <div className="col-span-full flex justify-center">
                        {/* TODO: Replace with Role icon when ready */}
                        <div className="rounded-full w-36 h-36 bg-starkWhite flex justify-center items-center mb-4 text-[18px] stroke-2 ">
                            <TextIcon SVG={DesktopIcon} />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            type="text"
                            className="text-input"
                            label="Fornavn*"
                            error={errors.firstName}
                            {...register('firstName')}
                        />
                        <Input
                            type="text"
                            className="text-input"
                            label="Etternavn*"
                            error={errors.lastName}
                            {...register('lastName')}
                        />

                        <Input
                            type="email"
                            className="text-input"
                            leadingIcon={MessageIcon}
                            label="Epost*"
                            error={errors.email}
                            {...register('email')}
                        />
                    </div>
                    <div className="inline-flex justify-end py-6 items-end w-full">
                        <Button submit title="Opprett" />
                    </div>
                </>
            )}
        </Form>
    );
};
