import {
    IsString,
    Length,
    IsNotEmpty,
    IsOptional,
    IsEmail,
    ValidateIf,
} from 'class-validator';

import { useSignInIdentities } from 'src/view/hooks/useSignInIdentities';
import Form from 'src/components/FormComponents/Form';
import Input from 'src/components/FormComponents/Input';
import {
    DesktopIcon,
    MessageIcon,
    PhoneIcon,
} from 'src/components/Icons/icons';
import { Admin } from 'src/domain/admin';
import { TextIcon } from 'src/components/Icons/TextIcon';

class AdminSchema {
    @IsOptional()
    @IsString()
    @Length(2, 30, { message: 'Må være mellom 2 og 30 tegn' })
    @IsNotEmpty({ message: 'Må fylles ut.' })
    firstName?: string;

    @IsOptional()
    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    lastName?: string;

    @IsOptional()
    @ValidateIf(o => o.email?.length !== 0)
    @IsString()
    @IsEmail({}, { message: 'Epost er ikke gyldig' })
    email?: string;
}

export const AdminProfile = ({ user }: { user: Admin }) => {
    const { phoneNumber } = useSignInIdentities(user.id);

    return (
        <div className="flex flex-col gap-8 max-w-3xl mx-auto">
            <Form
                className="bg-lightPeach text-woodyBrown"
                schema={AdminSchema}
                mode="onChange"
                defaultValues={{
                    email: user.email,
                    firstName: user.profile.firstName,
                    lastName: user.profile.lastName,
                }}
            >
                {({ register, formState: { errors } }) => (
                    <div className="grid grid-cols-2 gap-4 h-full">
                        <div className="col-span-full flex justify-center">
                            {/* TODO: Replace with Role icon when ready */}
                            <div className="rounded-full w-36 h-36 bg-starkWhite flex justify-center items-center mb-4 text-[18px] stroke-2 ">
                                <TextIcon SVG={DesktopIcon} />
                            </div>
                        </div>
                        <Input
                            className="text-input"
                            type="text"
                            label="Fornavn*"
                            error={errors.firstName}
                            readOnly
                            {...register('firstName')}
                        />
                        <Input
                            className="text-input"
                            type="text"
                            label="Etternavn*"
                            error={errors.lastName}
                            readOnly
                            {...register('lastName')}
                        />
                        <Input
                            className="text-input"
                            type="tel"
                            label="Telefonnr"
                            leadingIcon={PhoneIcon}
                            value={phoneNumber || ''}
                            readOnly
                        />
                        <Input
                            className="text-input"
                            type="email"
                            label="Epost"
                            error={errors.email}
                            leadingIcon={MessageIcon}
                            readOnly
                            {...register('email')}
                        />
                    </div>
                )}
            </Form>
        </div>
    );
};
