import { forwardRef } from 'react';
import { Post } from 'src/view/hooks/sanity';
import { NewsCard } from '../../news/newsCard';
import cx from 'classnames';
import { Link } from 'react-router-dom';

type NewsProps = {
    newsPosts: Post[];
    bgColor?: string;
    id: string;
};

const News = forwardRef<HTMLElement, NewsProps>(
    ({ bgColor = 'lightPeach', id, newsPosts }: NewsProps, ref) => {
        return (
            <section
                ref={ref}
                id={id}
                className={cx(`relative z-10 py-16 w-full bg-${bgColor}`)}
            >
                <div className="flex w-full flex-col  px-6 sm:px-8 md:px-18">
                    <div className="grow max-w-max mx-auto">
                        <div className="grid gap-6 grid-cols-2 md:grid-cols-3 md:gap-8  grow">
                            {newsPosts.map((post: Post, index: number) => (
                                <NewsCard key={index} post={post} />
                            ))}
                        </div>
                    </div>

                    <Link to={'/news'}>
                        <button className="btn-lightPeach mt-4 md:mt-6 float-right">
                            Se alle
                        </button>
                    </Link>
                </div>
            </section>
        );
    },
);

News.displayName = 'News';

export default News;
