import { ReactNode } from 'react';

import { Provider } from 'react-redux';
import { Store } from 'redux';

interface Props {
    store: Store;
    children: ReactNode;
}

const ReduxProviderFactory = ({ store, children }: Props): JSX.Element => (
    <Provider store={store}>{children}</Provider>
);

ReduxProviderFactory.displayName = 'ReduxProviderFactory';

export default ReduxProviderFactory;
