import { useEffect, useState } from 'react';
import { SUPPORT_EMAIL } from 'src/config';
import container from 'src/container';
import { UserRole } from 'src/domain/user';
import { useRoleUserAuthenticated } from 'src/view/hooks/role';

const {
    cradle: { munduClient },
} = container;

type Props = {
    classNameContainer?: string;
    onCancel: () => void;
};

export const ModalLimitReached = ({ classNameContainer, onCancel }: Props) => {
    const [orgName, setOrgName] = useState<string>('');

    useEffect(() => {
        (async () => {
            const organizationName = await munduClient.getOrgDisplayName();
            setOrgName(organizationName);
        })();
    }, []);

    const isOrganization = useRoleUserAuthenticated([UserRole.organization]);
    return (
        <div
            className={`fixed z-50 inset-0 overflow-y-auto ${classNameContainer}`}
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75 z-50"></div>
                </div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div
                    className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-lightPeach rounded-30px px-20 py-16 text-woodyBrown">
                        <div>
                            <span>Maksimalt antall brukere for&nbsp;</span>
                            <span className="font-bold">{orgName}</span>
                            <span>&nbsp;er nådd.</span>
                        </div>

                        <div className="mt-2">
                            {isOrganization && (
                                <p>
                                    Ta kontakt for å utvide antall brukere:
                                    <a
                                        className="hover:underline text-blue-700"
                                        href={`mailto:${SUPPORT_EMAIL}`}
                                    >
                                        &nbsp;{SUPPORT_EMAIL}
                                    </a>
                                </p>
                            )}
                        </div>

                        <div className="mt-6 text-sm">
                            <button
                                type="button"
                                onClick={onCancel}
                                className="px-4 py-1 rounded-full outline-none focus:outline-none border-1.6px border-woodyBrown mr-3 hover:bg-woodyBrown hover:text-white transition duration-200"
                            >
                                Lukk
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
