import { useCallback, useEffect, useState } from 'react';

import { usePlaylistTracks } from 'src/view/hooks/swr/usePlaylistTracks';
import { PlaylistBanner } from './components/playListBanner';
import { AddToPlaylist } from './components/addToPlaylist';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { TrackList } from '../components/Track/list';
import { SpinnerIconSVG } from 'src/assets/svg';
import { useAudioPlayerContext } from '../audioPlayerReducer';

type Props = {
    editable: boolean;
};
export const PlaylistView = ({ editable = true }: Props) => {
    const {
        state: { playlist },
    } = useAudioPlayerContext();
    const user = useSignedInUser();
    const userId = user?.id;

    const {
        playlistTracks,
        hasMore,
        isLoadingNext,
        isLoading,
        isEmpty,
        nextPage,
        addTrack,
        deleteTrack,
    } = usePlaylistTracks(playlist?.id, userId);

    const [element, setElement] = useState<HTMLDivElement | null>(null);

    const paginateCallback = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            if (entries[0].isIntersecting) {
                nextPage();
            }
        },
        [nextPage],
    );

    useEffect(() => {
        const observer = new IntersectionObserver(paginateCallback, {
            threshold: 1,
        });

        if (element) {
            observer.observe(element);
        }
        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, [element, paginateCallback]);

    return (
        <div className="space-y-6 ">
            {playlist && (
                <>
                    <PlaylistBanner playlist={playlist} editable={editable} />
                    {playlistTracks && playlistTracks.length > 0 && (
                        <>
                            <TrackList
                                displayOption="LIST"
                                onAddTrack={addTrack}
                                onDeleteTrack={deleteTrack}
                                tracks={playlistTracks}
                                editable={editable}
                            />
                            {isLoadingNext && (
                                <div className="grid justify-center">
                                    <SpinnerIconSVG className="animate-spin h-8 w-8 text-woodyBrown" />
                                </div>
                            )}
                            {!isLoadingNext && hasMore && (
                                <div
                                    className="h-6 w-6"
                                    ref={setElement}
                                    style={{ background: 'transparent' }}
                                ></div>
                            )}
                        </>
                    )}

                    <div className="flex flex-col w-full">
                        {!isLoading && isEmpty && (
                            <div className="text-lg w-1/3 text-center self-center py-16">
                                Spillelisten er tom, bruk søkefeltet nedenfor
                                for å finne sanger å legge til.
                            </div>
                        )}
                        {editable && <AddToPlaylist onAddTrack={addTrack} />}
                    </div>
                </>
            )}
        </div>
    );
};
