import { useState, useLayoutEffect } from 'react';

export default function useWindowDimensions() {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useLayoutEffect(() => {
        function onResize(this: Window) {
            setDimensions({
                width: this.innerWidth,
                height: this.innerHeight,
            });
        }

        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return dimensions;
}
