import { useSelector } from 'react-redux';
import container from 'src/container';
import { UserRedux } from 'src/state/reducers';
import { getReducerState } from 'src/state/User/UserReducer';
import * as R from 'ramda';
import avatarDefault from 'src/assets/images/client_default.png';
import { Consumer } from 'src/domain/consumer';

const {
    cradle: { storageService },
} = container;

export const useAvatarFetch = () => {
    const { avatarSAS } = useSelector(
        R.pipe(getReducerState, UserRedux.selectors.getSASTokens),
    );
    return (id: string | undefined) => {
        return {
            src: storageService.getImageUrl(id, avatarSAS) || avatarDefault,
        };
    };
};
export const useCommonMediaFetch = () => {
    const { commonMediaSAS } = useSelector(
        R.pipe(getReducerState, UserRedux.selectors.getSASTokens),
    );

    return (id: string | undefined) => {
        return {
            src:
                storageService.getImageUrl(id, commonMediaSAS) || avatarDefault,
        };
    };
};

export const usePersonalMediaFetch = (consumerId?: Consumer['id']) => {
    const sas = useSelector(
        R.pipe(getReducerState, state =>
            UserRedux.selectors.getPersonalMediaSas(state, consumerId),
        ),
    );

    return (id: string | undefined) => {
        return {
            src: storageService.getImageUrl(id, sas) || '',
        };
    };
};
