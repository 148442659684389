import * as awilix from 'awilix';

import { AuthService } from './api/auth.service';
import { MunduClient } from './api/mundu.service';
import { HTTPService } from './api/http.service';
// import { ProxyService } from './api/proxy';
import { PublicClientApplication } from '@azure/msal-browser';
import makeMsalInstance from './api/msal.service';
import { MusicService } from './api/music.service';
import { StorageService } from './api/storage.service';
import { ProxyService } from './api/proxy.service';

export interface Cradle {
    msal: PublicClientApplication;
    authService: AuthService;
    httpService: HTTPService;
    proxyService: ProxyService;
    munduClient: MunduClient;
    musicService: MusicService;
    storageService: StorageService;
}

const container = awilix.createContainer<Cradle>();

container.register({
    msal: awilix.asFunction(makeMsalInstance).singleton(),
    authService: awilix.asClass(AuthService).singleton(),
    httpService: awilix.asClass(HTTPService).singleton(),
    proxyService: awilix.asClass(ProxyService).singleton(),
    munduClient: awilix.asClass(MunduClient).singleton(),
    musicService: awilix.asClass(MusicService).singleton(),
    storageService: awilix.asClass(StorageService).singleton(),
});

container.register({});

export default container;
