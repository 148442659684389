import { useEffect } from 'react';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { ClassConstructor } from 'class-transformer';
import {
    SubmitHandler,
    UseFormReturn,
    useForm,
    UseFormProps,
    Path,
    FormState,
} from 'react-hook-form';

type FormHTMLProps = Omit<
    React.DetailedHTMLProps<
        React.FormHTMLAttributes<HTMLFormElement>,
        HTMLFormElement
    >,
    'onSubmit'
>;

interface FormProps<T>
    extends Omit<FormHTMLProps, 'children'>,
        Omit<UseFormProps<T>, 'children' | 'resolver'> {
    schema: ClassConstructor<T>;
    onSubmit?: SubmitHandler<T>;
    onFormStateChange?: (formState: FormState<T>) => void;
    children: (methods: UseFormReturn<T>) => React.ReactNode;
    watch?: Path<T>;
    watchAll?: boolean;
}

//The following comma is because of JSX
const Form = <T,>({
    schema,
    children,
    onSubmit,
    onFormStateChange,
    mode = 'onSubmit',
    reValidateMode = 'onChange',
    defaultValues,
    criteriaMode = 'firstError',
    shouldFocusError = true,
    shouldUnregister = false,
    delayError,
    context,
    ...props
}: FormProps<T>) => {
    const resolver = classValidatorResolver<T>(schema, context);

    const methods = useForm<T>({
        resolver,
        mode,
        reValidateMode,
        defaultValues,
        criteriaMode,
        shouldFocusError,
        shouldUnregister,
        delayError,
    });

    useEffect(() => {
        onFormStateChange && onFormStateChange(methods.formState);
    }, [methods.formState, onFormStateChange]);

    return (
        <form
            onSubmit={
                onSubmit &&
                methods.handleSubmit(onSubmit, () => console.log('InvalidForm'))
            }
            {...props}
        >
            {children(methods)}
        </form>
    );
};

export default Form;
