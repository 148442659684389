import { useEffect, useState } from 'react';
import { PlaybackMode } from 'src/domain/session';
import { useSessionForm } from '../../SessionFormState/hooks';
import { ClockIcon, LoopIcon } from 'src/components/Icons/icons';
import { Label } from 'src/components/FormComponents/Input';
import { IconComponent, TextIcon } from 'src/components/Icons/TextIcon';
import _ from 'lodash';
import cx from 'classnames';
import {
    togglePlaybackMode,
    updateImageDuration,
} from '../../SessionFormState/actions';

export const LoopController = () => {
    const { state, dispatch: dispatchSessionsForm } = useSessionForm();
    const { playbackMode, duration, images, imageDuration } = state;

    const normalImageDuration =
        images.length >= 1 ? duration / images.length : 0;

    const maxImageDurationLoopMode = Math.min(
        Math.floor(normalImageDuration),
        60,
    );

    const initialDur =
        playbackMode === PlaybackMode.LOOP
            ? imageDuration
            : Math.max(Math.round((maxImageDurationLoopMode + 5) / 2), 5);

    const [loopDur, setLoopDur] = useState<number>(initialDur);

    useEffect(() => {
        if (playbackMode === PlaybackMode.NORMAL) {
            dispatchSessionsForm(updateImageDuration(normalImageDuration));
        } else if (playbackMode === PlaybackMode.LOOP) {
            dispatchSessionsForm(updateImageDuration(loopDur));
        }
    }, [playbackMode, dispatchSessionsForm, normalImageDuration, loopDur]);

    useEffect(() => {
        if (images.length === 1 && playbackMode === PlaybackMode.LOOP) {
            dispatchSessionsForm(togglePlaybackMode());
            dispatchSessionsForm(updateImageDuration(normalImageDuration));
        }
    }, [
        images.length,
        normalImageDuration,
        playbackMode,
        dispatchSessionsForm,
    ]);

    return (
        <div className="flex w-full justify-between items-center">
            <LoopControllerInput
                disabled={playbackMode === PlaybackMode.NORMAL}
                min={5}
                max={Math.round(maxImageDurationLoopMode)}
                value={imageDuration || 0}
                onChange={e => setLoopDur(Number(e.target.value))}
                leadingIcon={LoopIcon}
                trailingIcon={ClockIcon}
            />
        </div>
    );
};

const LoopControllerInput = ({
    value,
    min,
    max,
    onChange,
    disabled,
    leadingIcon,
    trailingIcon,
}: {
    value: number;
    min: number;
    max: number;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    disabled?: boolean;
    trailingIcon?: IconComponent;
    leadingIcon?: IconComponent;
}) => {
    const validValue = _.clamp(value, min, max);
    const isWithinRange = Math.round(value) === validValue;

    const progress = ((value - min) / (max - min)) * 100;

    const style = {
        background: `
	-webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${progress}%, #ebd7c9), color-stop(${progress}%, #4b2f3e))
  `,
    };

    const { state, dispatch: dispatchSessionsForm } = useSessionForm();

    return (
        <div>
            <Label label={'Varighet per bilde'} />
            <div className="flex flex-col  justify-between items-center ">
                <div className={cx('-translate-x-[0.45em] inline-flex ')}>
                    <button
                        onClick={() =>
                            dispatchSessionsForm(togglePlaybackMode())
                        }
                        disabled={state.images.length < 2}
                        className={cx(
                            'disabled:mongooseGray hover:bg-peachOrange hover:bg-opacity-25 rounded-md  transition-all',
                            state.playbackMode === PlaybackMode.LOOP
                                ? 'text-woodyBrown hover:bg-peachOrange  '
                                : 'text-mongooseGray bg-transparent rounded-md  hover:bg-opacity-25 hover:text-woodyBrown ',
                        )}
                    >
                        {leadingIcon && <TextIcon SVG={leadingIcon} />}
                    </button>
                    <div className=" w-full px-[0.4em] ">
                        <input
                            className="disabled:opacity-30 opacity-100"
                            disabled={disabled}
                            style={style}
                            type="range"
                            max={max}
                            min={min}
                            value={value}
                            onChange={onChange}
                        />
                    </div>
                    <div
                        className={cx(
                            'inline-flex items-center ',
                            state.playbackMode === PlaybackMode.LOOP
                                ? 'text-woodyBrown'
                                : 'text-mongooseGray',
                        )}
                    >
                        {trailingIcon && <TextIcon SVG={trailingIcon} />}
                        <div
                            className={cx(
                                'w-[3ch] transition-all',
                                isWithinRange
                                    ? 'text-woodyBrown'
                                    : 'text-venetianRed',
                            )}
                        >
                            {Math.round(value)}s
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
