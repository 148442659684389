import { Footer } from '../components/footer';
import { ScrollToTopOnMount, LandingPageHeader } from '../components';
import { Screen } from 'src/view/routes/Router';

export const Licences = () => {
    return (
        <div>
            <ScrollToTopOnMount />
            <LandingPageHeader
                colors={{
                    textColor: 'starkWhite',
                    activeTextColor: 'mongooseGray',
                    backgroundColor: 'woodyBrown',
                }}
                scrollItems={false}
                navItems={[{ id: Screen.Mundu, name: 'Hjem' }]}
            />
            <div className="grid min-h-screen justify-center w-full bg-woodyBrown pt-32 pb-16 px-6 sm:px-32 lg:px-56">
                <div className="prose md:prose-lg xl:prose-xl">
                    <h1>Lisenser</h1>
                </div>
            </div>
            <Footer textColor="starkWhite" backgroundColor="woodyBrown" />
        </div>
    );
};
