import { CheckIconSVG, PauseIconSVG, MediaIconSVG } from 'src/assets/svg';
import { convertURLToHttps } from 'src/utils/stringUtils';
import { FALLBACK_COVER_IMAGE } from '../../..';

type TrackCardAlbumCoverProps = {
    src: string;
    alt: string;
    isPlaying: boolean;
    isSelected: boolean;
    disabled?: boolean;
    onClick: () => void;
    togglePlay: () => void;
};
export const TrackCardAlbumCover = ({
    src,
    alt,
    isPlaying,
    isSelected,
    disabled = false,
    onClick,
    togglePlay,
}: TrackCardAlbumCoverProps) => {
    return (
        <div
            className={`relative w-full aspect-square ${isSelected && 'ring'}`}
        >
            <img
                className="card-image hover:opacity-80"
                src={convertURLToHttps(src)}
                onError={e => (e.currentTarget.src = FALLBACK_COVER_IMAGE)}
                alt={alt}
                onClick={onClick}
            />
            <button
                onClick={onClick}
                className={`card-check-mark ${isSelected && 'opacity-100'}`}
            >
                <CheckIconSVG className="card-btn-icon w-6 h-6 " />
            </button>

            {isPlaying && !disabled ? (
                <button
                    id="pause"
                    className="card-bottom-btn rounded-full bg-lightPeach"
                    onClick={togglePlay}
                    aria-label="Pause"
                >
                    <PauseIconSVG className="card-btn-icon fill-current text-woodyBrown" />
                </button>
            ) : (
                <button
                    className="card-bottom-btn"
                    onClick={togglePlay}
                    disabled={disabled}
                    aria-label="Play"
                >
                    <MediaIconSVG className="card-btn-icon h-8 w-8 " />
                </button>
            )}
        </div>
    );
};
