import { Consumer } from 'src/domain/consumer';
import { LocalImage } from 'src/domain/personalMedia';

export interface Item {
    id: string;
    file: File;
    thumbnail: LocalImage;
    description?: string;
}

export enum ActionKeys {
    ADD_ITEM = 'ADD_ITEM',
    REMOVE_ITEM = 'REMOVE_ITEM',
    CLEAR_ITEMS = 'CLEAR_ITEMS',
    UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION',
    TOGGLE_CONSENT = 'TOGGLE_CONSENT',
    SET_CONSENT = 'SET_CONSENT',
    SET_RECIPIENT = 'SET_RECIPIENT',
    CLEAR_STATE = 'CLEAR_STATE',
}

export interface AddItem {
    type: ActionKeys.ADD_ITEM;
    payload: Item;
}
export interface RemoveItem {
    type: ActionKeys.REMOVE_ITEM;
    payload: number;
}

export interface ClearItems {
    type: ActionKeys.CLEAR_ITEMS;
}

export interface UpdateDescription {
    type: ActionKeys.UPDATE_DESCRIPTION;
    payload: {
        index: number;
        description: string;
    };
}
export interface ToggleConsent {
    type: ActionKeys.TOGGLE_CONSENT;
}
export interface SetConsent {
    type: ActionKeys.SET_CONSENT;
    payload: boolean;
}

export interface SetRecipient {
    type: ActionKeys.SET_RECIPIENT;
    payload: Consumer;
}

export interface ClearState {
    type: ActionKeys.CLEAR_STATE;
}
export type ActionTypes =
    | AddItem
    | RemoveItem
    | ClearItems
    | UpdateDescription
    | ToggleConsent
    | SetConsent
    | SetRecipient
    | ClearState;

type Dispatch = (action: ActionTypes) => void;

export type ImageUploadContext = {
    state: ImageUploadState;
    dispatch: Dispatch;
};

export interface ImageUploadState {
    items: Item[];
    consent: boolean;
    recipient: Consumer | undefined;
}
