import { IsEmail, IsNotEmpty, IsPhoneNumber, IsString } from 'class-validator';

export class ContactFormInfo {
    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut' })
    name: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut' })
    work: string;

    @IsString()
    @IsEmail({}, { message: 'Ugyldig epostadresse' })
    email: string;

    @IsNotEmpty({ message: 'Må fylles ut' })
    @IsPhoneNumber('NO', { message: 'Telefonnummer er ugyldig' })
    phoneNumber: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut' })
    subject: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut' })
    message: string;
}
