import { useHistory } from 'react-router-dom';
import { TrashIconSVG } from 'src/assets/svg';
import { Artist } from 'src/domain/music';
import { useAudioPlayerContext } from '../../audioPlayerReducer';

export const ArtistItem = ({
    artist,
    onSelect,
    onRemove,
}: {
    artist: Artist;
    onSelect?: () => void;
    onRemove?: () => void;
}) => {
    const { dispatch } = useAudioPlayerContext();
    const history = useHistory();

    const onClick = () => {
        dispatch({ type: 'SET_ARTIST', payload: artist });
        history.push('./artist');
    };

    return (
        <div className="bg-peachOrange flex items-center rounded-full">
            <button
                onClick={onSelect || onClick}
                className="font-bold font-logo max-w-30ch truncate secondary-btn bg-peachOrange"
            >
                {artist.name}
            </button>
            {onRemove && (
                <span>
                    <button
                        onClick={onRemove}
                        className="py-2 px-2 mr-2 rounded-full hover:text-lightPeach active:text-lightPeach transition-all"
                    >
                        <TrashIconSVG className="w-4 h-4 lg:w-6 lg:h-6" />
                    </button>
                </span>
            )}
        </div>
    );
};
