import { useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid as Grid, GridChildComponentProps } from 'react-window';

type Item = {
    id: string;
};
interface AutoSizeGridProps<T = Item> {
    items: T[];
    Item: React.FunctionComponent<{
        index: number;
        item: T;
        onClick?: (index: number) => void;
        onChange?: (
            index: number,
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => void;
    }>;
    itemProps: {
        onClick?: (index: number) => void;
        onChange?: (
            index: number,
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ) => void;
    };
    aspectRatio: number;
}

interface ItemProps<T> {
    items: T[];
    columnCount: number;
    itemGap: number;
    aspectRatio: number;
}

export const AutoSizeGrid = <T extends Item>({
    items,
    Item,
    itemProps,
    aspectRatio,
}: AutoSizeGridProps<T>) => {
    const renderCell = useCallback(
        ({
            data,
            rowIndex,
            columnIndex,
            style,
        }: GridChildComponentProps<ItemProps<T>>) => {
            const { items, columnCount, itemGap, aspectRatio } = data;
            const index = rowIndex * columnCount + columnIndex;
            const item = items[index];

            if (!item) return null;
            const styles = {
                ...style,
                left: Number(style.left) + itemGap,
                top: Number(style.top) + itemGap,
                height: Number(style.height) - itemGap * aspectRatio,
                width: Number(style.width) - itemGap,
            };

            return (
                <div style={styles}>
                    <Item index={index} item={item} {...itemProps} />
                </div>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [Item],
    );
    return (
        <div className="h-full w-full">
            <AutoSizer>
                {({ height, width }) => {
                    const maxWidth = 220;
                    const minColumnCount = 3;

                    const columnCount = Math.max(
                        Math.ceil(width / maxWidth),
                        minColumnCount,
                    );
                    const itemWidth = width / columnCount;
                    const itemGap = itemWidth * 0.1;
                    const columnWidth = itemWidth - itemGap / columnCount;
                    const rowCount = Math.ceil(items.length / columnCount);

                    return (
                        <Grid<ItemProps<T>>
                            itemKey={({ rowIndex, columnIndex }) => {
                                const index =
                                    rowIndex * columnCount + columnIndex;
                                const id = items[index]?.id;
                                return id ? id : index;
                            }}
                            columnCount={columnCount}
                            columnWidth={columnWidth}
                            rowHeight={columnWidth * aspectRatio}
                            rowCount={rowCount}
                            width={width}
                            height={height}
                            itemData={{
                                items,
                                columnCount,
                                itemGap,
                                aspectRatio,
                            }}
                        >
                            {renderCell}
                        </Grid>
                    );
                }}
            </AutoSizer>
        </div>
    );
};
