import { CustomPlaylistTrack, Track } from 'src/domain/music';
import { TrackCard } from './components/card';
import { TrackRow } from './components/row';

type TrackItemProps = {
    track: Track | CustomPlaylistTrack;
    index: number;
    displayAs: 'LIST' | 'CARD';
    isSelected: boolean;
    isPlaying: boolean;
    isUnavailable: boolean;
    editable?: boolean;
    showReleaseCols?: boolean;
    showIndexCol?: boolean;
    isPlaylistSearch?: boolean;
    togglePlay: () => void;
    onSelect?: (track: Track | CustomPlaylistTrack) => void;
    onDelete?: (track: CustomPlaylistTrack) => void;
    onAdd?: (track: Track) => void;
};
export const TrackItem = ({
    track,
    index,
    displayAs,
    isSelected,
    isPlaying,
    editable,
    isUnavailable,
    showReleaseCols,
    showIndexCol,
    isPlaylistSearch,
    togglePlay,
    onSelect,
    onDelete,
    onAdd,
}: TrackItemProps) => {
    return displayAs === 'LIST' ? (
        <TrackRow
            track={track}
            index={index}
            isPlaying={isPlaying}
            togglePlay={togglePlay}
            isSelected={isSelected}
            onSelect={onSelect}
            onDeleteTrack={onDelete}
            onAddTrack={onAdd}
            editable={editable}
            isUnavailable={isUnavailable}
            showReleaseCols={showReleaseCols}
            showIndexCol={showIndexCol}
            isPlaylistSearch={isPlaylistSearch}
        />
    ) : (
        <TrackCard
            track={track}
            index={index}
            isPlaying={isPlaying}
            isUnavailable={isUnavailable}
            togglePlay={togglePlay}
            isSelected={isSelected}
            onDeleteTrack={onDelete}
            onAddTrack={onAdd}
            onSelect={onSelect}
        />
    );
};
