import { forwardRef, memo } from 'react';
import { ContentSection } from '..';
import { ContactForm, Footer } from '../../components';

type ContactProps = {
    bgColor?: string;
    id?: string;
};
const Contact = forwardRef<HTMLElement, ContactProps>(
    (
        { bgColor = 'peachOrange', id = ContentSection.Contact }: ContactProps,
        ref,
    ) => (
        <section
            ref={ref}
            id={id}
            className={`grid min-h-screen justify-center relative z-10 bg-${bgColor}`}
        >
            <div className="px-6 sm:px-32 xl:px-72 py-16">
                <div className="prose prose-orange sm:prose-lg md:prose-xl xl:prose-xl xl:max-w-none">
                    <h1>Kontakt</h1>
                    <div className="grid xl:grid-cols-5 gap-8">
                        <div className="xl:col-span-2">
                            <p>
                                Kontakt oss om hvordan vi kan hjelpe personer
                                med demens i din kommune å vekke livsgleden!
                                Attraktiv abonnementsløsning - ingen skjulte
                                kostnader.
                            </p>
                            <p>
                                Mundu er tilgjengelig for sykehus, sykehjem,
                                dagsenter og hjemmetjeneste. Vi tilbyr Mundu på
                                kommunalt nivå og institusjoner enkeltvis.
                            </p>
                            <p>
                                Representerer du en ideell organisasjon, bedrift
                                eller privat institusjon som jobber med
                                demenssykdom, ta kontakt om hvordan vi kan bli
                                din støttespiller.
                            </p>
                            <p className="font-bold">
                                Ta kontakt direkte eller bruk skjemaet under:
                            </p>
                            <p>
                                Christoffer Haanshuus Isaksen
                                <br />
                                CEO Mundu
                                <br />
                                <a href="tel:+4798629910">+47 986 29 910</a>
                                <br />
                                <a href="mailto:chi@mundu.no">chi@mundu.no</a>
                            </p>
                            <p className="font-bold">La oss komme i prat!</p>
                        </div>
                        <div className="justify-self-center xl:col-span-3 prose prose-orange sm:prose-lg md:prose-lg xl:prose-lg">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <Footer textColor="woodyBrown" backgroundColor="transparent" />
        </section>
    ),
);
Contact.displayName = 'Contact';

export default Contact;
