import { RefObject, useEffect } from 'react';

export const useClickOutside = (
    ref: RefObject<HTMLDivElement>,
    clickOutside: () => void,
): void => {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent): void {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                clickOutside();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return (): void => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [clickOutside, ref]);
};
