type Props = {
    onClick: () => void;
};
export const CloseCardButton = ({ onClick }: Props) => {
    return (
        <button
            type="button"
            onClick={onClick}
            className="w-7 h-7 z-10 flex pb-0.5 pl-0.5 items-center justify-center text-md text-center bg-starkWhite transform rotate-45 outline-none focus:outline-none rounded-full absolute top-0 right-0 -m-2 hover:bg-woodyBrown hover:text-white transition duration-150"
        >
            +
        </button>
    );
};
