import { CONTACT_EMAIL, SUPPORT_EMAIL } from 'src/config';

export const FaqData = [
    {
        header: 'Om Mundu',
        entries: [
            {
                key: '1',
                title: 'Hva er Mundu?',
                content:
                    'Forskning viser at riktig bruk av personlig musikk kan ha en god helseeffekt på personer med demens. Personlig musikk kan gi dempet angst, uro, depresjon og stimulere til bedre hukommelse. Bedre hukommelse gir også grobunn for bedre toveis samtaler og skaper gode øyeblikk sammen med pårørende. Personlig musikk satt sammen med bilder som betyr noe for personen med demens kan også gi en enda mer kraftfull, positiv virkning og stimulere til gode samtaler. Dette er essensen av Mundu. ',
            },
            {
                key: '2',
                title: 'Hva betyr Mundu?',
                content:
                    'Mundu er et gammelt norrønt ord som fortsatt brukes på Island. Navnet settes i konteksten om hukommelse, som f.eks. “Husker du den gangen..?”.',
            },
            {
                key: '3',
                title: 'Hvordan virker Mundu?',
                content:
                    'Mundu står på skuldrene til dokumentert musikkterapeutisk forskning som viser at preferansebasert (personlig) musikk kan gi dempet angst, uro, depresjon og stimulere til bedre hukommelse. Det handler om å skape de gode øyeblikkene, alene eller sammen med andre. På institusjon, dagsenter eller hjemmeboende. Mundu tilbyr musikkterapeuter og helsepersonell et digitalt verktøy for å opprette brukere (person med demens), invitere pårørende til samarbeid, lage og sende video til personer med demens. Videoen sees på nettbrett og inneholder personlig musikk, satt sammen med bilder som betyr noe for den enkelte bruker. Vi vet at Mundu er ingen kur for demens, men målet er å skape bedre øyeblikk, verdighet og relasjoner gjennom sykdomsforløpet.',
            },
            {
                key: '4',
                title: 'Hvem passer Mundu best for?',
                content:
                    'Mundu er fokusert på personer med mild- til moderat demens, men kan også omfavne andre grupper innen psykisk helse fordi prinsippet bak musikk for helse kan ha positiv effekt på også andre. Har du en kollega innen et annet fagområde innen psykisk helse som vil teste Mundu, ta kontakt.',
                contact: CONTACT_EMAIL,
            },
            {
                key: '5',
                title: 'Hvem har laget Mundu?',
                content:
                    'Mundu er et 100% norsk produkt. Opphavet stammer fra NKS Olaviken alderspsykiatriske sykehus, som har spesialisert seg innen eldres psykiske helse. Idéen ble overført til norske gründere innen musikkteknologi og Olaviken er en svært nær samarbeidspartner av Mundu. Vår tjeneste er utviklet, testet og validert i samarbeid med musikkterapeuter og helsepersonell.',
            },
        ],
    },
    {
        header: 'Begreper',
        entries: [
            {
                key: '6',
                title: 'Hva er en bruker?',
                content:
                    'En bruker er en beboer, pasient, hjemmeboende eller besøkende person med demens på dagsenter.',
            },
            {
                key: '7',
                title: 'Hva er en pårørende?',
                content:
                    'En pårørende er en eller flere personer som forstås har familiær - eller i fravær av familie en nær tilknytning til brukeren.',
            },
            {
                key: '8',
                title: 'Hva er en superbruker?',
                content:
                    'Superbrukeren av Mundu er personen i institusjonen som har det overordnede administrative ansvaret. Superbrukeren gir brukerkontakter tilgang til Mundu, mulighet for å slette brukere, pårørende og brukerkontakter samt administrere kundeforholdet.',
            },
            {
                key: '9',
                title: 'Hva er en brukerkontakt?',
                content:
                    'En brukerkontakt betegnes som den personen som har direkte kontakt med brukeren.',
            },
            {
                key: '10',
                title: 'Hva er en forbindelse?',
                content:
                    'Du oppretter en forbindelse til brukeren i denne webklienten. Når du har opprettet en forbindelse, er du klar til å lage en video.',
            },
            {
                key: '11',
                title: 'Hva er en video?',
                content:
                    'En video består av musikk brukeren har et nært forhold til sammen med brukerens bilder av levd liv. Når du har laget og sendt en video, ligger den klar i appen, til glede for brukeren.',
            },
            {
                key: '12',
                title: 'Hva er en webklient?',
                content:
                    'Webklienten er verktøyet superbruker og brukerkontakter bruker for å opprette brukere, kartlegge brukerens musikalske livshistorie, invitere pårørende til samarbeid og lage video til brukeren.',
            },
        ],
    },
    {
        header: 'Veiledning',
        entries: [
            {
                key: '14',
                title: 'Hvordan går jeg fram i Mundu?',
                content:
                    'Vi anbefaler følgende trinn-for-trinn for at du skal få en god opplevelse: Opprett aller først en forbindelse til brukeren som skal motta videoen. Du blir trinnvis geleidet gjennom prosessen, hvor du legger inn den musikalske livshistorien til brukeren og inviterer pårørende til samarbeid. Pårørende deler bilder av brukerens levde liv slik at bildene ligger klar i brukerens bildebibliotek hos deg. Mundu orienterer deg på mail når dette er gjort. Tips: Minn gjerne pårørende på at det gjør din jobb lettere om de beskriver en kort tekst til hvert bilde. Da kan du sette relevante bilder sammen. Du får ikke laget en video før pårørende har delt bilder. Kartlegg brukerens musikalske livshistorie gjennom barndom til i dag. Brukerens personlige smak er helt essensielt for å skape de gode øyeblikkene! Når grunnarbeidet i overnevnte er gjort, kan du lage en video. Klikk på ikon for “Lag ny video”. Når du klikker på dette ikonet tar vi deg gjennom prosessen på en tydelig, trinnvis og enkel måte',
            },
            {
                key: '15',
                title: 'Hvordan lager jeg en video?',
                content:
                    'På førstesiden etter innlogging står det et tydelig ikon for “Lag ny video”. Når du klikker på dette ikonet tar vi deg gjennom prosessen på en tydelig, trinnvis og enkel måte. Hvis veiledningen likevel ikke er lett å forstå, har du ikke gjort noen feil. Vi må da gjøre tjenesten bedre for deg. Gi oss gjerne tilbakemelding på hva du syntes var vanskelig.',
                contact: CONTACT_EMAIL,
            },
            {
                key: '16',
                title: 'Tar det lang tid å lage en video?',
                content:
                    'Mundu er bygget for at du skal bruke kortest mulig tid å lage en video. Dette gjør vi med tydelige trinn-for-trinn steg i prosessen. Investeringen du bruker i tid skal lønne seg ved å se positiv endring hos brukeren. Føler du at du bruker for lang tid enn ønsket vil vi gjerne vite hva som forårsaker dette, ta kontakt.',
                contact: CONTACT_EMAIL,
            },
            {
                key: '17',
                title: 'Hva er et kartleggingsskjema og hvordan bruker jeg den?',
                content:
                    'Kartleggingsskjemaet er et viktig verktøy for at du skal ha best mulig kjennskap til brukerens musikalske livshistorie. Kartlegg sammen med brukeren eller pårørende på hvilke artister eller sanger brukeren har likt gjennom et levd liv. Kartleggingsskjemaet gir deg et meget godt grunnlag til å velge riktig musikk for brukeren når du lager videoer. ',
            },
            {
                key: '18',
                title: 'Hvor mange brukere kan jeg lage video for?',
                content:
                    'Antall lisenser arbeidsgiver abonnerer styrer hvor mange brukere du kan lage video for. Trenger dere flere lisenser, ta kontakt.',
                contact: CONTACT_EMAIL,
            },
            {
                key: '19',
                title: 'Hvor mange brukerkontakter kan jeg invitere til samarbeid?',
                content:
                    'Du kan invitere et ubegrenset antall brukerkontakter til samarbeid.',
            },
            {
                key: '20',
                title: 'Kan brukerens video vises uten tilgang til wifi?',
                content:
                    'Ja, brukeren kan være offline i inntil 30 dager og se videoen, men nettbrettet må være tilkoblet wifi eller mobilt nettverk for å laste ned videoen aller første gang.',
            },
            {
                key: '21',
                title: 'Hva skjer hvis nettbrettet har vært uten wifi-tilgang utover 30 dager?',
                content:
                    'Mundu har et rapporteringsansvar til rettighetshavere iht lovlig bruk av musikken vi tilbyr. Derfor anbefaler vi sterkt at nettbrett som er offline blir pålogget til wifi eller mobilt nettverk innen 30 dager. Etter 30 dager må nettbrettet kobles på wifi eller mobilt nettverk for å få tilgang igjen. ',
            },
            {
                key: '22',
                title: 'Kan flere brukere bruke appen på samme nettbrett?',
                content:
                    'Ja, det er ingen begrensing på hvor mange brukere som kan bruke samme nettbrett. Hver bruker har sitt eget brukernavn og passord. Vær påpasselig å logge ut en brukere når brukeren er ferdig å bruke Mundu for å ivareta personvernet.',
            },
            {
                key: '23',
                title: 'Må jeg logge ut en bruker for at ny bruker kan bruke Mundu på nettbrettet?',
                content: 'Ja, det må du gjøre.',
            },
            {
                key: '24',
                title: 'Hvordan fungerer appen til pårørende?',
                content:
                    'Har du ansvaret for å lage video til en bruker, er en del av prosessen å invitere pårørende til samarbeid. De mottar automatisk invitasjon på e-post når du sender ut invitasjon, og blir geleidet av oss hvordan de kommer i gang med Mundu. Hensikten er at pårørende sender relevante digitale bilder direkte til brukerens bildebibliotek i webklienten. Pårørende kan i appen: Ta bilder av fysiske bilder med mobil/nettbrett, f.eks bilder i et fotoalbum. Dele bilder pårørende har tilgang til på mobil eller nettbrett. Tilgang til webportal (tjenesten er i utvikling) for å dele bilder som enten ligger på harddisk eller skylagringstjeneste.',
            },
            {
                key: '25',
                title: 'Får jeg melding når pårørende har delt bilder med meg?',
                content:
                    'Ja, du får automatisk tilsendt en e-post av oss når bildene er delt og ligger klar i brukerens bildebibliotek.',
            },
            {
                key: '26',
                title: 'Får pårørende melding når jeg har laget en video?',
                content:
                    'Ja, pårørende får automatisk tilsendt en e-post når du har delt en ferdig video til brukeren. På den måten er pårørende oppdatert på fremdriften. ',
            },
            {
                key: '27',
                title: 'Kan jeg legge til flere forbindelser/brukere når jeg deler en video?',
                content:
                    'Dette er ikke mulig i nåværende versjon. Er det ønskelig å legge til flere brukere for videoer som ikke bryter personvernet, gi oss tilbakemelding. Et konkret behovs eksempel kan være bilder tatt i felles stund eller tilstelninger på avdeling og som ikke er av personlig art.',
                contact: CONTACT_EMAIL,
            },
            {
                key: '28',
                title: 'Har Mundu et bildebibliotek av generelle bilder som f.eks dyr, natur, geografi, hobbyer etc?',
                content:
                    'Vi har valgt å kun gi muligheten å bruke private bilder i videoer. Årsaken er at våre tester og i samråd med fagpersonell, viser at private bilder av levd liv gir best reminisens (hukommelses) effekt.  Er generelle bilder noe brukeren og pårørende ønsker skal være en del av tilbudet, gi oss tilbakemelding.',
                contact: CONTACT_EMAIL,
            },
            {
                key: '29',
                title: 'Jeg får feilmelding, hva gjør jeg?',
                content:
                    'Får du feilmelding, prøv igjen. Får du stadig feilmelding, meld i fra til superbruker som har gitt deg tilgang. Vi loggfører feilmeldinger og fikser problemet så fort det lar seg gjøre.',
            },
            {
                key: '30',
                title: 'Hvor kommer musikken i Mundu fra?',
                content: 'Mundu lisensierer musikkbiblioteket fra 7Digital',
                readMore: 'https://www.7digital.com',
            },
            {
                key: '31',
                title: 'Får vi en ekstra regning fra TONO når vi bruker musikken i Mundu?',
                content:
                    'Nei, bruk av musikk i Mundu er innbakt i lisensprisen. Mundu har avtaler med TONO og rettighetshaverne gjennom GRAMO.',
            },
            {
                key: '32',
                title: 'Kan jeg koble Mundu til f.eks Spotify?',
                content:
                    'Du kan ikke koble Mundu med Spotify eller andre musikkstrømming leverandører, men Mundu har tilnærmet samme utvalg av musikk. Gjennom 7Digital har vi også integrert spillelister fra Spotify.',
            },
            {
                key: '33',
                title: 'Jeg finner ikke musikken jeg søker, hvorfor det?',
                content:
                    'Med Mundu har du tilgang til det aller meste av tilgjengelig digital musikk for å gi brukeren en god opplevelse. I noen enkelttilfeller støtter vår leverandør ikke tilgang til ønsket musikk. Pass også på at du har skrevet navn på artist eller navn korrekt. ',
            },
            {
                key: '34',
                title: 'Jeg vil legge inn en filmsnutt i videoen jeg lager, kan jeg det?',
                content:
                    'Dette støttes ikke på nåværende tidspunkt. Er dette noe du ønsker skal være en del av tilbudet, gi oss tilbakemelding.',
                contact: SUPPORT_EMAIL,
            },
            {
                key: '35',
                title: 'Kan jeg bestemme rekkefølgen på sangene jeg har valgt til videoen?',
                content:
                    'Ja, du kan enkelt klikk-og-dra med musen sangene du har valgt i den rekkefølgen du ønsker. Bruker du nettbrett kan du holde fingeren på sangen og flytte den dit du vil.',
            },
            {
                key: '36',
                title: 'Kan jeg bestemme rekkefølgen på bildene jeg har valgt til videoen?',
                content:
                    'Ja, du kan enkelt klikk-og-dra med musen bildene du har valgt i den rekkefølgen du ønsker. Bruker du nettbrett kan du holde fingeren på bildet og flytte det dit du vil. ',
            },
            {
                key: '37',
                title: 'Hvor mange bilder er det lurt å ha i en video?',
                content:
                    'Ut i fra hvor mange minutter alle sangene du har valgt varer gir Mundu deg en anbefaling i en nedre og øvre grense på hvor mange bilder som gir brukeren en god opplevelse. Nedre grense gir hvert bilde ca 30 sekunder, mens øvre grense gir hvert bilde ca 12 sekunder.',
            },
            {
                key: '38',
                title: 'Kan jeg lagre en påbegynt arbeid med video?',
                content:
                    'Ja, har du behov for å avbryte arbeidet lagres progresjonen til neste gang du kommer tilbake.',
            },
            {
                key: '39',
                title: 'Kan pårørende dele en film i tillegg til bilder?',
                content:
                    'Dette støttes ikke på nåværende tidspunkt. Er filmer fra pårørende noe brukeren og pårørende ønsker skal være en del av tilbudet, gi oss gjerne tilbakemelding.',
                contact: CONTACT_EMAIL,
            },
            {
                key: '40',
                title: 'Kan jeg slette en forbindelse til en bruker?',
                content:
                    'Ja, superbruker kan slette en forbindelse til en bruker. Et behov for å slette bruker kan oppstå ved f.eks en brukers død eller har kommet så langt i sykdomsforløpet at Mundu ikke gir samme gode virkning lenger. All data og tilhørende videomateriell slettes fra våre servere innen 90 dager. ',
            },
            {
                key: '41',
                title: 'Hvis jeg bytter en bruker, hva skjer med filmene som er laget for den personen?',
                content:
                    'Et behov for å bytte bruker kan oppstå ved f.eks en brukers død eller har kommet så langt i sykdomsforløpet at Mundu ikke gir samme gode virkning lenger. Det er dessverre ikke mulig per i dag å beholde videomateriell som er laget som følge av rettighetsspørsmål og Mundus lisensavtale med rettighetshavere. Videomateriell slettes derfor fra våre servere innen 90 dager.',
            },
            {
                key: '42',
                title: 'Må vi kjøpe flere lisenser hvis vi bytter bruker?',
                content:
                    'Nei, lisensgrunnlaget er basert på antall lisenser dere betaler for og ikke hvem som opptar hver enkelt lisens. Dere kan således bytte bruker innenfor lisens antallet med ubegrenset omgang.',
            },
            {
                key: '43',
                title: 'Kan jeg se videoen jeg har laget før jeg deler den med brukeren?',
                content:
                    'Ja, du kan forhåndsvise videoen i siste steg når du lager en video. Er det ting du vil endre kan du også gjøre det.',
            },
            {
                key: '44',
                title: 'Kan jeg spole i videoen på brukerens nettbrett?',
                content: 'Ikke enda, men snart. Dette er i utvikling.',
            },
            {
                key: '45',
                title: 'Kan jeg pause video, gå ut av videoen og se videre fra der jeg stoppet?',
                content:
                    'Dette støttes ikke på nåværende tidspunkt. Dette er i utvikling. ',
            },
            {
                key: '46',
                title: 'Er det trygt å sende video?',
                content:
                    'Videoen du sender vil kun være tilgjengelig for mottakeren du har valgt å sende til. Mottakeren må logge inn på nettbrettet for å kunne se denne videoen.',
            },
            {
                key: '47',
                title: 'Blir personvernet ivaretatt?',
                content: 'Ja, personvernet er ivaretatt iht lovverket',
                readMore: 'https://www.mundu.no/privacy',
            },
            {
                key: '48',
                title: 'Hvordan ivaretas sikkerheten?',
                content:
                    'Mundu har nødvendige sikkerhetstiltak for å ivareta kundens interesser og sikkerhet. Vi understreker at institusjon har to-faktor sikkerhet.',
                readMore: 'https://www.mundu.no/privacy',
            },
            {
                key: '49',
                title: 'Har Mundu en veiviser i webklienten?',
                content:
                    'Mye av det du kanskje lurer på ligger på denne siden. Vi har bevisst utelatt veiviser i dagens versjon fordi vi tror tjenesten er intuitiv og selvforklarende. Mundu er ikke et ferdig produkt og vi kan ta feil. Vi tar gjerne imot tilbakemeldinger om du opplever steder i Mundu der du skulle hatt en veiviser. På denne måten kan du bidra til at Mundu blir en bedre opplevelse for deg.',
                contact: CONTACT_EMAIL,
            },
            {
                key: '50',
                title: 'Hva skjer hvis brukeren ikke har pårørende?',
                content:
                    'I dette tilfelle kan for eksempel en helsearbeider ta rollen som pårørende. Det er viktig at pårørende som inviteres ikke bruker samme e-postadresse som en allerede eksisterende bruker.',
            },
        ],
    },

    {
        header: 'Slette brukere eller avslutte abonnement',
        entries: [
            {
                key: '51',
                title: 'Hvem kan slette brukere av Mundu?',
                content:
                    'Superbruker har alene tilgang til å slette brukerkontakter, brukere og pårørende.',
            },
            {
                key: '52',
                title: 'Kan jeg slette en bruker?',
                content: 'Kun superbruker har adgang til å slette en bruker.',
            },
            {
                key: '53',
                title: 'Jeg er pårørende og ønsker å slette profilen min, hva gjør jeg?',
                content:
                    'Pårørende kan slette sin profil under innstillinger i appen',
            },
            {
                key: '54',
                title: 'Hvordan avslutter jeg abonnementet?',
                content: 'Ta kontakt for å avlutte abonnement',
                contact: SUPPORT_EMAIL,
            },
        ],
    },
];
