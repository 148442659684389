import { useHistory } from 'react-router-dom';
import { ForwardIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';

export const SmallNextButton = () => {
    const history = useHistory();
    const onGoForward = () => {
        history.goForward();
    };
    return (
        <button
            onClick={onGoForward}
            className="text-woodyBrown text-[6px] p-[6px] rounded-full bg-lightPeach border-[1.2px] border-woodyBrown disabled:border-mongooseGray disabled:text-mongooseGray"
        >
            <TextIcon SVG={ForwardIcon} />
        </button>
    );
};
