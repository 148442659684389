import { useArtistReleases } from 'src/view/hooks/swr/useArtistReleases';
import { ReleaseList } from '../components/Release/list';
import { useTrackSearch } from 'src/view/hooks/swr/useTrackSearch';
import { TrackList } from '../components/Track/list';
import { PaginateButton } from '../components/paginateButton';
import { useAudioPlayerContext } from '../audioPlayerReducer';

export const ArtistView = () => {
    const {
        state: { artist },
    } = useAudioPlayerContext();
    const {
        tracks,
        hasMore: hasMoreTracks,
        nextPage: nextPageTracks,
    } = useTrackSearch(artist?.name);

    const {
        releases,
        hasMore: hasMoreReleases,
        nextPage: nextPageReleases,
    } = useArtistReleases(artist?.id);

    return (
        <div className="space-y-6">
            <div className="flex w-full justify-between gap-3 ">
                <div className="flex space-x-8 items-start ">
                    <div className="flex flex-col space-y-4">
                        <div id="entity" className=" text-sm font-extralight">
                            ARTIST
                        </div>
                        <h1 className="text-5xl font-logo line-clamp-3 pb-2">
                            {artist?.name}
                        </h1>
                    </div>
                </div>
            </div>

            {tracks && tracks.length > 0 && (
                <>
                    <TrackList tracks={tracks} displayOption="LIST" />
                    <div className="grid justify-start">
                        <PaginateButton
                            hasMore={hasMoreTracks}
                            nextPage={nextPageTracks}
                        />
                    </div>
                </>
            )}
            <h1 className="font-logo font-bold text-3xl pt-6 leading-none">
                Utgivelser
            </h1>
            {releases && releases.length > 0 && (
                <>
                    <ReleaseList releases={releases} />
                    <div className="grid justify-start">
                        <PaginateButton
                            hasMore={hasMoreReleases}
                            nextPage={nextPageReleases}
                        />
                    </div>
                </>
            )}
        </div>
    );
};
