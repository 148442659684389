import {
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router';
import { ImageSelectorStore } from '../../state/reducers/ImageSelectorReducer';
import { ArtistView } from '../screens/BrowseMusic/ArtistView';
import { AudioPlayerStore } from '../screens/BrowseMusic/audioPlayerReducer';
import { DiscoverView } from '../screens/BrowseMusic/DiscoverView';
import { PlaylistView } from '../screens/BrowseMusic/PlaylistView';
import { ReleaseView } from '../screens/BrowseMusic/ReleaseView';
import { SearchView } from '../screens/BrowseMusic/SearchView';
import { Faq } from '../screens/Faq';
import { CurrentConsumerStore } from '../screens/Home/NewHome/reducer';
import { ModuleSelector } from '../../components/ModuleSelector/ModuleSelector';
import { EULA } from '../screens/Mundu/eula';
import { LandingPage } from '../screens/Mundu/landingPage';
import { Contact } from '../screens/Mundu/landingPage/sections';
import { Licences } from '../screens/Mundu/licences';
import { Privacy } from '../screens/Mundu/privacy';
import { Tippetue } from '../screens/Mundu/tippetue';
import { MusicalSurvey } from '../screens/MusicalSurvey';
import { SurveyStore } from '../screens/MusicalSurvey/reducer';
import { RecordCollection } from '../screens/MusicalSurvey/recordCollection';
import { MyProfile } from '../screens/MyProfile';
import { NewConsumer } from '../screens/NewConsumer';
import { CreateNewVideo } from '../screens/NewVideo';
import { SessionFormStore } from '../screens/NewVideo/SessionFormState/store';
import { Users } from '../screens/Users';
import { UserProfile } from '../screens/Users/Profile';
import { Home } from '../screens/Home';
import { NewCreator } from '../screens/NewCreator';
import { CreatorProfile } from '../screens/Creators/Profile';
import { NewNextOfKin } from '../screens/NewNextOfKin';
import { ImageUpload } from '../screens/ImageUpload';
import { ImageUploadStore } from '../screens/ImageUpload/state';
import AppRoute from './AppRoute';
import { UserRole } from 'src/domain/user';
import { NotificationProvider } from 'src/components/Notification/NotificationProvider';
import { UploadHistory } from '../screens/NextOfKin';
import { Logout } from '../screens/logout';
import { Login } from '../screens/login';
import { PhoneNumberIdentityCheck } from 'src/components/AddPhoneNumberIdentity';
import { NotFound } from '../screens/NotFound';
import { Creators } from '../screens/Creators';
import { Admin } from '../screens/Admin';
import {
    CreateAdminForm,
    CreateOrganizationForm,
} from '../screens/Admin/components';

import { useSignedInUser } from '../hooks/useSignedInUser';
import { OrgInfo } from '../screens/Admin/OrgInfo';
import { NewsPost, NewsPosts } from '../screens/Mundu';
import { LandingPageComingSoon } from '../screens/Mundu/landingPageComingSoon';

/**
 * Mundu web app routes.
 */
const appRoutes = () => (
    <Switch>
        <AppRoute
            exact
            roles={[UserRole.creator, UserRole.organization]}
            path="/app/home"
            component={Home}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            exact
            path="/app/faq"
            component={Faq}
        />
        <AppRoute
            roles={[
                UserRole.nextOfKin,
                UserRole.organization,
                UserRole.creator,
                UserRole.admin,
            ]}
            exact
            path="/app/my-profile"
            component={MyProfile}
        />

        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            exact
            path="/app/music"
            component={DiscoverView}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            path="/app/music/playlist"
            component={PlaylistView}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            path="/app/music/artist"
            component={ArtistView}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            path="/app/music/release"
            component={ReleaseView}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            path="/app/music/search"
            component={SearchView}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            path="/app/music/releases"
            component={RecordCollection}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            exact
            path="/app/musical-survey"
            component={MusicalSurvey}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            exact
            path="/app/users"
            component={Users}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            exact
            path="/app/users/new"
            component={NewConsumer}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            path="/app/users/profile/:id"
            component={UserProfile}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            path="/app/nextofkins/new"
            component={NewNextOfKin}
        />

        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            path="/app/new-video"
            component={CreateNewVideo}
        />
        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            path="/videos/edit"
        />

        <AppRoute
            exact
            roles={[UserRole.organization]}
            path="/app/creators"
            component={Creators}
        />
        <AppRoute
            roles={[UserRole.organization]}
            path="/app/creators/profile/:id"
            component={CreatorProfile}
        />
        <AppRoute
            roles={[UserRole.organization]}
            path="/app/creators/new"
            component={NewCreator}
        />

        <AppRoute
            roles={[UserRole.creator, UserRole.organization]}
            path="/app/image-upload"
            component={ImageUpload}
        />

        <AppRoute
            roles={[UserRole.nextOfKin]}
            path="/app/nextofkin-image-list"
            component={UploadHistory}
        />
        <AppRoute
            roles={[UserRole.nextOfKin]}
            path="/app/nextofkin-image-upload"
            component={ImageUpload}
        />
        <AppRoute
            roles={[UserRole.nextOfKin]}
            path="/app/nextofkin-profile"
            component={MyProfile}
        />
        <AppRoute
            roles={[UserRole.admin]}
            exact
            path="/app/admin"
            component={Admin}
        />
        <AppRoute
            roles={[UserRole.admin]}
            path="/app/admin/organization/:id"
            component={OrgInfo}
        />
        <AppRoute
            roles={[UserRole.admin]}
            path="/app/admin/organizations/new"
            component={CreateOrganizationForm}
        />
        <AppRoute
            roles={[UserRole.admin]}
            path="/app/admin/admins/new"
            component={CreateAdminForm}
        />

        <Route path="*" component={NotFound} />
    </Switch>
);
const App = () => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const isAdmin = useSignedInUser()?.role === 'admin';
    return (
        <>
            {!isAdmin && <PhoneNumberIdentityCheck />}
            <ModuleSelector history={history} location={location} match={match}>
                {appRoutes}
            </ModuleSelector>
        </>
    );
};

const Routes = () => {
    return (
        <Switch>
            <Route exact path={'/'} component={LandingPage} />
            <Route
                exact
                path={'/kommersnart'}
                component={LandingPageComingSoon}
            />
            <Route path={'/contact'} component={Contact} />
            <Route path={'/tippetue'} component={Tippetue} />
            <Route path={'/privacy'} component={Privacy} />
            <Route path={'/eula'} component={EULA} />
            <Route path={'/licences'} component={Licences} />
            <Route path={'/login'} component={Login} />
            <Route path={'/logout'} component={Logout} />
            <Route exact path={'/news'} component={NewsPosts} />
            <Route path={'/news/:slug'} component={NewsPost} />

            <ImageUploadStore>
                <NotificationProvider>
                    <CurrentConsumerStore>
                        <SurveyStore>
                            <SessionFormStore>
                                <AudioPlayerStore>
                                    <ImageSelectorStore>
                                        <AppRoute
                                            path={'/app'}
                                            requireAuth
                                            component={App}
                                        />
                                    </ImageSelectorStore>
                                </AudioPlayerStore>
                            </SessionFormStore>
                        </SurveyStore>
                    </CurrentConsumerStore>
                </NotificationProvider>
            </ImageUploadStore>
            <Route path="*" component={NotFound} />
        </Switch>
    );
};

export default Routes;
