import { useState } from 'react';
import { ModalConfirm } from 'src/components/Modals/ModalConfirm';
import { Consumer } from 'src/domain/consumer';
import { Recipient } from 'src/domain/sessionForm';
import { getFullName } from 'src/domain/user';
import {
    updateImages,
    updateRecipient,
} from 'src/view/screens/NewVideo/SessionFormState/actions';
import { useSessionForm } from 'src/view/screens/NewVideo/SessionFormState/hooks';
import { SelectConsumer } from '..';

export const SelectRecipient = () => {
    const {
        state: { recipient, images },
        dispatch,
    } = useSessionForm();

    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);

    const [newRecipient, setNewRecipient] = useState<Recipient | undefined>();
    const changeAllowed = images.length === 0;

    const onConfirm = () => {
        dispatch(updateImages([]));
        dispatch(updateRecipient(newRecipient));
        setConfirmModalIsVisible(false);
        setNewRecipient(undefined);
    };
    const onSelectRecipient = ({
        id,
        profile: { firstName, lastName, avatar },
    }: Consumer) => {
        if (!changeAllowed) {
            setNewRecipient({
                id,
                profile: { firstName, lastName, avatar },
            });
            setConfirmModalIsVisible(true);
        } else if (recipient && id === recipient.id)
            dispatch(updateRecipient(undefined));
        else
            dispatch(
                updateRecipient({
                    id,
                    profile: { firstName, lastName, avatar },
                }),
            );
    };

    return (
        <>
            {confirmModalIsVisible && recipient && (
                <ModalConfirm
                    onAgree={onConfirm}
                    onCancel={() => setNewRecipient(undefined)}
                    title={`Endring av mottaker vil fjerne alle bilder som tilhører ${getFullName(
                        recipient,
                    )} fra denne videoen.`}
                />
            )}

            <SelectConsumer
                disabled={recipient !== undefined}
                current={recipient?.id}
                label="Velg mottaker"
                onSelect={onSelectRecipient}
            />
        </>
    );
};
