import {
    IncompleteSession,
    Recipient,
    SessionFormMedia,
    SessionFormTrack,
} from 'src/domain/sessionForm';
import {
    UpdateName,
    RemoveImage,
    RemoveMusicTrack,
    ResetSessionFormAction,
    SetSessionFormAction,
    SessionFormTypeKeys,
    UpdateImagesAction,
    UpdateMusicAction,
    UpdateRecipient,
    UpdateStep,
    UpdateTitle,
    UpdateDurationAction,
    TogglePlaybackModeAction,
    UpdateImageDurationAction,
    SetSnapshotAction,
    SessionFormStoreType,
} from './interfaces';

export type SessionFormActionTypes =
    | UpdateName
    | UpdateDurationAction
    | UpdateStep
    | UpdateImagesAction
    | UpdateMusicAction
    | UpdateRecipient
    | UpdateTitle
    | RemoveImage
    | RemoveMusicTrack
    | ResetSessionFormAction
    | SetSessionFormAction
    | TogglePlaybackModeAction
    | UpdateImageDurationAction
    | SetSnapshotAction;

export const updateName = (name: string): UpdateName => ({
    type: SessionFormTypeKeys.updateName,
    name,
});

export const updateDuration = (duration: number): UpdateDurationAction => ({
    type: SessionFormTypeKeys.updateDuration,
    duration,
});

export const updateStep = (step: number): UpdateStep => ({
    type: SessionFormTypeKeys.updateStep,
    step,
});

export const updateMusic = (
    trackSelection: SessionFormTrack[],
): UpdateMusicAction => ({
    type: SessionFormTypeKeys.updateMusic,
    trackSelection,
});

export const updateImages = (
    images: SessionFormMedia[],
): UpdateImagesAction => ({
    type: SessionFormTypeKeys.updateImages,
    images,
});

export const updateRecipient = (
    recipient: Recipient | undefined,
): UpdateRecipient => ({
    type: SessionFormTypeKeys.updateRecipient,
    recipient,
});

export const updateTitle = (title: string): UpdateTitle => ({
    type: SessionFormTypeKeys.updateTitle,
    title,
});

export const removeImage = (id: string): RemoveImage => ({
    type: SessionFormTypeKeys.removeImage,
    id,
});

export const removeMusicTrack = (id: string): RemoveMusicTrack => ({
    type: SessionFormTypeKeys.removeMusicTrack,
    id,
});

export const resetSessionForm = (): ResetSessionFormAction => ({
    type: SessionFormTypeKeys.resetSessionForm,
});

export const setSessionForm = (
    session: IncompleteSession,
): SetSessionFormAction => ({
    type: SessionFormTypeKeys.setSessionForm,
    session,
});

export const togglePlaybackMode = (): TogglePlaybackModeAction => ({
    type: SessionFormTypeKeys.togglePlaybackMode,
});

export const updateImageDuration = (
    imageDuration: number,
): UpdateImageDurationAction => ({
    type: SessionFormTypeKeys.updateImageDuration,
    imageDuration,
});

export const setSnapshot = (
    session: SessionFormStoreType,
): SetSnapshotAction => ({
    type: SessionFormTypeKeys.setSnapshot,
    session,
});
