import useSWRInfinite from 'swr/infinite';
import container from 'src/container';

const {
    cradle: { musicService },
} = container;

export const usePartnerPlaylists = (pageSize = 25) => {
    const { data, size, setSize, mutate, error, isValidating } = useSWRInfinite(
        page => ['playlists/mundu', page + 1, pageSize],
        (_, page, pageSize) => musicService.listPlaylists({ page, pageSize }),
        { revalidateOnFocus: false },
    );
    const playlists = data
        ? data.map(page => page.playlists).flat()
        : undefined;

    const isLoading = !data && !error;
    const isLoadingNext =
        isValidating ||
        (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.playlists.length === 0;
    const hasMore = !(
        isEmpty ||
        (data && data[data.length - 1].playlists.length < pageSize)
    );
    return {
        playlists: playlists || [],
        nextPage: () => setSize(size + 1),
        setPageSize: setSize,
        refresh: mutate,
        isEmpty,
        isLoading,
        isLoadingNext,
        error,
        hasMore,
    };
};
