import { useContext } from 'react';
import cx from 'classnames';
import { WarningIconSVG, TickBrownIconSVG } from 'src/assets/svg';
import { NotificationContext } from './NotificationProvider';

export const Notification = () => {
    const {
        state: { notify, message, isError },
    } = useContext(NotificationContext);
    return (
        <div
            className={cx(
                'fixed top-4 right-4 p-3 rounded-lg flex max-w-xs md:max-w-md max-h-28 items-center gap-3 overflow-hidden bg-peachOrange text-woodyBrown z-100 transition-all duration-300 shadow-lg',
                notify
                    ? 'visible opacity-95 translate-x-0'
                    : 'invisible opacity-0 translate-x-full',
            )}
        >
            {isError ? (
                <WarningIconSVG className="h-6 w-6 shrink-0 text-woodyBrown" />
            ) : (
                <TickBrownIconSVG className="h-6 w-6 shrink-0" />
            )}
            <span className="max-h-20">{message}</span>
        </div>
    );
};
