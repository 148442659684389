import { FunctionComponentElement, SVGProps, cloneElement } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };
type XOR<T, U> = T | U extends any
    ? (Without<T, U> & U) | (Without<U, T> & T)
    : T | U;
type IconProps = Omit<SVGProps<SVGSVGElement>, 'onClick'>;
type ImageProps = Omit<
    React.DetailedHTMLProps<
        React.ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    >,
    'onClick' | 'className'
>;

type ChildrenProps = (ImageProps | IconProps) & {
    id: string;
    onClick?: () => void;
};

const ChildIsImage = (
    childProps: IconProps | ImageProps,
): childProps is ImageProps => 'alt' in childProps;

type CardProps = {
    fetchImage?: (id: string) => { src: string };
    onDelete?: () => void;
    onPreview?: () => void;
    onSelect?: () => void;
    tooglePlay?: () => void;
    onPlay?: () => void;
    isSelected?: boolean;

    children: FunctionComponentElement<ChildrenProps>;
    disabled?: boolean;
    shape: 'square' | 'circle' | 'video';
    label?: string;
} & typeof defaultProps &
    XOR<{ to: string; onClick?: () => void }, { onClick?: () => void }>;

const defaultProps = {
    disabled: false,
    shape: 'square',
};

const Card = ({
    shape,
    children,
    label,
    onClick,
    fetchImage,
    to,
    ...props
}: CardProps) => {
    const childProps = children.props;
    const childIsImage = ChildIsImage(childProps);

    const src = childIsImage
        ? fetchImage
            ? fetchImage(childProps.id).src
            : childProps.src
        : undefined;
    const childIsIcon = !childIsImage;
    const shapes = [
        shape === 'square' && 'w-32 xl:w-36 rounded-lg',
        shape === 'circle' && 'w-32 xl:w-36 rounded-full',
        shape === 'video' && 'w-56 xl:w-60  rounded-lg',
    ];
    const extraChildProps = childIsImage
        ? {
              className: cx('h-32 xl:h-36 object-cover', ...shapes),
              src,
              onClick,
          }
        : { className: 'text-current ', onClick };

    const extraParentProps = childIsIcon
        ? {
              className: cx(
                  'flex group w-32 h-32 xl:h-36 xl:w-36 items-center justify-center bg-woodyBrown text-lightPeach hover:bg-opacity-75 transition-all disabled:border-starkWhite  disabled:text-starkWhite disabled:border-[1.4px] disabled:bg-lightPeach',
                  ...shapes,
              ),
              id: childProps.id,
          }
        : { id: childProps.id };

    return (
        <div className="flex flex-col w-32 xl:w-36 justify-between items-center group space-y-2 ">
            {to ? (
                <>
                    <Link to={to}>
                        <button {...extraParentProps} {...props}>
                            {cloneElement(children, extraChildProps)}
                        </button>
                    </Link>
                    <Link to={to}>
                        {label && (
                            <label
                                onClick={!props.disabled ? onClick : undefined}
                                className={cx(
                                    'whitespace-nowrap text-center group-disabled:text-starkWhite text-sm xl:text-base translate-all ',
                                    props.disabled
                                        ? 'text-starkWhite'
                                        : 'text-woodyBrown',
                                )}
                                htmlFor={childProps.id}
                            >
                                {label}
                            </label>
                        )}
                    </Link>
                </>
            ) : (
                <>
                    <button {...extraParentProps} {...props} onClick={onClick}>
                        {cloneElement(children, extraChildProps)}
                    </button>
                    {label && (
                        <label
                            onClick={!props.disabled ? onClick : undefined}
                            className={cx(
                                'whitespace-nowrap text-center group-disabled:text-starkWhite text-sm xl:text-base translate-all ',
                                props.disabled
                                    ? 'text-starkWhite'
                                    : 'text-woodyBrown',
                            )}
                            htmlFor={childProps.id}
                        >
                            {label}
                        </label>
                    )}
                </>
            )}
        </div>
    );
};

Card.defaultProps = defaultProps;
export default Card;
