import { TrashIconSVG } from 'src/assets/svg';
import { SessionFormMedia } from 'src/domain/sessionForm';
import { usePersonalMediaSas } from 'src/view/hooks/consumer';
import container from 'src/container';
import { useSessionForm } from 'src/view/screens/NewVideo/SessionFormState/hooks';

const { getImageUrl } = container.cradle.storageService;

export const ImageSelectionItem = ({
    item,
    onRemove,
}: {
    item: SessionFormMedia;
    onRemove: (id: string) => void;
}) => {
    const {
        state: { recipient },
    } = useSessionForm();
    const { sas } = usePersonalMediaSas(recipient?.id);

    return (
        <div className="flex w-40 justify-between items-center">
            <img
                className="h-10 w-10 rounded-md"
                src={getImageUrl(item.id, sas)}
            />
            <span> {item.description} </span>
            <button className="w-10 h-10" onClick={() => onRemove(item.id)}>
                <TrashIconSVG />
            </button>
        </div>
    );
};
