import { Link } from 'react-router-dom';
import { useHomePath } from 'src/view/hooks/useHomePath';

import { LogoIcon } from '../Icons/LogoIcon';

export const LogoLink = ({
    className,
}: React.HTMLAttributes<HTMLAnchorElement>) => {
    const home = useHomePath();
    return (
        <Link to={home} className={className} aria-label="Homepage">
            <LogoIcon />
        </Link>
    );
};
