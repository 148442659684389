import { Component, ReactElement } from 'react';
import Tab from './Tab';
import cx from 'classnames';

type TabsProps = Readonly<{
    children: ReactElement[];
}>;
export default class Tabs extends Component<TabsProps, { activeTab: string }> {
    constructor(props: TabsProps) {
        super(props);
        this.state = {
            activeTab: this.props.children[0].props.id,
        };
    }
    onClickTab = (tabName: string) => {
        this.setState({ activeTab: tabName });
    };

    render() {
        const {
            onClickTab,
            props: { children },
            state: { activeTab },
        } = this;

        return (
            <div className="tabs w-full">
                <div className="flex w-full justify-start sticky -top-6 bg-lightPeach z-30 py-2 ">
                    <ol className="inline-flex flex-wrap gap-x-1 gap-y-2">
                        {children.map(({ props }) => {
                            return (
                                <Tab
                                    activeTab={activeTab}
                                    key={props.id || 'uniqeKey'}
                                    label={props.id}
                                    onClick={onClickTab}
                                />
                            );
                        })}
                    </ol>
                </div>
                <div>
                    {children.map(({ props }) => {
                        return (
                            <span
                                className={cx(
                                    props.id !== activeTab && 'hidden',
                                )}
                                key={props.id || 'uniqeKey'}
                            >
                                <label htmlFor={props.id} />
                                {props.children}
                            </span>
                        );
                    })}
                </div>
            </div>
        );
    }
}
