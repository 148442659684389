import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { NameNewVideoFormInfo } from 'src/domain/sessionForm/schema';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { updateName } from '../SessionFormState/actions';
import { useSessionForm } from '../SessionFormState/hooks';
import { incompleteSessionPayload } from 'src/domain/sessionForm';
import container from 'src/container';
import Input from 'src/components/FormComponents/Input';
import { Button } from 'src/components/Buttons/Button';

type Props = {
    onAgree: () => void;
    onCancel: () => void;
};

const resolver = classValidatorResolver(NameNewVideoFormInfo);

const {
    cradle: { munduClient },
} = container;

export const ModalSaveDraft = ({ onAgree, onCancel }: Props) => {
    const { state, dispatch } = useSessionForm();
    const { incompleteSessionId } = state;

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        watch,
    } = useForm({
        resolver: resolver,
        mode: 'onChange',
    });

    const name = watch('name');

    useEffect(() => {
        dispatch(updateName(name));
    }, [dispatch, name]);

    const onClickSave = async () => {
        const payload = incompleteSessionPayload(state);
        if (incompleteSessionId) {
            await munduClient.updateIncompleteSession(
                incompleteSessionId,
                payload,
            );
        } else {
            await munduClient.createIncompleteSession(payload);
        }
        onAgree();
    };

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-black bg-opacity-75 z-50"></div>
                </div>

                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <div
                    className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-lightPeach rounded-30px px-16 py-12 text-woodyBrown">
                        <p className="text-lg">Ønsker du å lagre utkast?</p>
                        <p className="text-sm">
                            Gi dette utkastet et navn, så vil du enkelt finne
                            denne igjen senere på startsiden.
                        </p>
                        <form
                            onSubmit={handleSubmit(onClickSave)}
                            className="mt-6"
                        >
                            <Input
                                className="text-input"
                                type="text"
                                placeholder="Navn på utkast"
                                error={errors.name}
                                {...register('name')}
                            />
                            <div className="mt-6 text-sm flex items-center gap-3">
                                <Button
                                    title="Lagre"
                                    submit
                                    disabled={!isValid}
                                />

                                <Button
                                    title="Avslutt uten å lagre"
                                    onClick={onAgree}
                                />
                                <button
                                    type="button"
                                    className="outline-none focus:outline-none hover:underline"
                                    onClick={onCancel}
                                >
                                    Angre
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
