import _ from 'lodash';
import {
    removeMusicTrack,
    updateDuration,
} from 'src/view/screens/NewVideo/SessionFormState/actions';
import { useSessionForm } from 'src/view/screens/NewVideo/SessionFormState/hooks';
import { Duration } from '../Duration';
import { MusicIcon } from '../Icons/icons';
import { SelectionDropdown } from './SelectionDropdown';
import { TrackSelectionItem } from './SelectionItems/TrackSelectionItem';

export const TrackSelectionDropdown = () => {
    const {
        state: { trackSelection, duration, step },
        dispatch,
    } = useSessionForm();

    return (
        <SelectionDropdown
            selection={trackSelection}
            name="Sang"
            namePlural="Sanger"
            icon={MusicIcon}
            disabled={step === 3}
            ItemComponent={TrackSelectionItem}
            onRemove={id => {
                dispatch(removeMusicTrack(id));
            }}
            onChange={selection =>
                dispatch(
                    updateDuration(_.sum(selection.map(x => x.duration || 0))),
                )
            }
        >
            <div className="flex-col gap-2 grid grid-cols-12 py-4 px-4 ">
                <div className="col-span-8 text-sm font-bold text-right whitespace-nowrap ">
                    Total spilletid:
                </div>
                <div className="col-span-4 tabular-nums text-sm font-matterSQ ">
                    <Duration value={duration} />
                </div>
            </div>
        </SelectionDropdown>
    );
};
