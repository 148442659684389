export default {
    woodyBrown: '#4B2F3E',
    lightPeach: '#FFF3EB',
    peachOrange: '#FFC29C',
    starkWhite: '#EBD7C9',
    vanillaWhite: '#D0BBAA',
    mongooseGray: '#B79C86',
    transparent: 'transparent',
    woodyBrownLight: '#8B6479',
    woodyBrownOpaque: 'rgba(75,47,62,0)',
    milanoRed: '#CB2506',
    palmLeaf: '#779645',
    white: '#FFFFFF',
};
