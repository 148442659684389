import * as R from 'ramda';
interface ObjectWithId {
    id: string | number;
}

export const checkDuplicatesWithId = (
    id: string | number,
    array: ObjectWithId[],
): boolean => {
    return R.find(R.propEq('id', id))(array) ? true : false;
};

export const removeElementWithId = <T extends ObjectWithId>(
    id: string | number,
    array: T[],
): T[] => {
    return R.reject<T, T[]>(o => o.id === id, array);
};
