import { useEffect, useCallback } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { MusicIconSVG } from 'src/assets/svg';
import { useMessageData } from 'src/view/hooks/message';
import { useAudioPlayerContext } from '../../audioPlayerReducer';
import { updateMusic } from 'src/view/screens/NewVideo/SessionFormState/actions';
import { useSessionForm } from 'src/view/screens/NewVideo/SessionFormState/hooks';
import _ from 'lodash';
import { TrackSelectionItem } from 'src/components/Selector/SelectionItems/TrackSelectionItem';
import { Duration } from 'src/components/Duration';

export const TracksSelector = () => {
    const { state, dispatch } = useAudioPlayerContext();
    const { dispatch: sessionFormDispatch } = useSessionForm();
    const history = useHistory();

    const updateSelection = useCallback(() => {
        sessionFormDispatch(updateMusic(state.selection));
        history.replace('/app/new-video/step1');
    }, [sessionFormDispatch, history, state.selection]);

    const { pathname } = useLocation();
    const { clearMessage, setMessage } = useMessageData();

    const selection = state.selection;
    const selectionSize = selection.length;

    const totalDuration = _.sum(selection.map(track => track.duration));

    useEffect(() => {
        switch (true) {
            case pathname.includes('Playlist'):
            case pathname.includes('playlist'):
                setMessage({
                    message:
                        'Klikk på "Velg"-knappen for å legge til en sang fra spillelisten',
                });
                break;
            case pathname.includes('search'):
                setMessage({
                    message:
                        'Klikk på et albumcover for å legge til sang fra søkeresultatet',
                });
                break;
            case pathname.includes('release'):
                setMessage({
                    message:
                        'Klikk på "Velg"-knappen for å legge til en sang fra utgivelsen',
                });
                break;
            case pathname.includes('artist'):
                setMessage({
                    message:
                        'Klikk på "Velg"-knappen for å legge til en sang av artisten',
                });
                break;
            default:
                clearMessage();
        }
    }, [clearMessage, pathname, setMessage]);

    return (
        <div className="flex flex-col flex-1 h-full w-full text-left items-center bg-lightPeach text-woodyBrown">
            <div className="flex items-center gap-2 py-3">
                <MusicIconSVG className="w-4" />
                <h1 className="text-lg font-bold font-logo text-center w-full  ">
                    Valgte sanger
                </h1>
            </div>
            {/* {message && <p className="  text-sm text-center ">{message}</p>} */}
            <div className="flex flex-col flex-1 overflow-y-scroll pt-4 px-2 shadow-scrollTop  w-full h-full overflow-x-hidden ">
                <div className="relative text-left ">
                    {selectionSize === 0 && (
                        <p className="text-sm whitespace-nowrap text-center justify-self-center h-full w-full absolute inset-0">
                            Seleksjonen er tom...
                        </p>
                    )}

                    <div className="flex flex-col space-y-2  ">
                        {selectionSize > 0 &&
                            state.selection.map((track, index) => (
                                <TrackSelectionItem
                                    key={track.id}
                                    index={index}
                                    item={track}
                                    onRemove={() =>
                                        dispatch({
                                            type: 'DESELECT',
                                            payload: track.id,
                                        })
                                    }
                                />
                            ))}
                    </div>
                </div>
            </div>
            {selectionSize > 0 && (
                <div className="inline-grid grid-cols-12 items-center gap-2 pl-2 w-full  pt-4 ">
                    <div className="col-span-8 text-sm font-bold text-right ">
                        Total spilletid:
                    </div>
                    <div className="col-span-4 tabular-nums text-sm font-matterSQ ">
                        <Duration value={totalDuration} />
                    </div>
                </div>
            )}
            {selectionSize > 0 ? (
                <button
                    type="button"
                    onClick={updateSelection}
                    className="secondary-btn-small my-4 bg-peachOrange "
                >
                    {'Legg til'}
                    <span className="font-bold">
                        &nbsp;{selection.length}
                        &nbsp;
                    </span>
                    sanger
                </button>
            ) : (
                <Link
                    to="/app/new-video/step1"
                    type="button"
                    className="secondary-btn-small my-4  "
                >
                    {'Avbryt og gå tilbake'}
                </Link>
            )}
        </div>
    );
};
