import { PROXY_URL } from 'src/config';
import { AuthService } from './auth.service';
import { BaseHTTPService, RequestOptions } from './http.service';

export class ProxyService extends BaseHTTPService {
    constructor({ authService }: { authService: AuthService }) {
        super({
            options: {
                hooks: {
                    beforeRequest: [
                        async request => {
                            request.headers.set(
                                'authorization',
                                `Bearer ${await authService.getUserToken()}`,
                            );
                        },
                    ],
                },
                timeout: 10000,
                headers: {
                    'content-type': 'application/json',
                },
                prefixUrl: PROXY_URL,
                retry: 0,
            },
        });
    }
    get = <T>(endpoint: string, options?: RequestOptions<T>) =>
        this.request<T>('GET', endpoint, { ...options });

    post = <T>(endpoint: string, options?: RequestOptions<T>) =>
        this.request<T>('POST', endpoint, { ...options });

    delete = <T>(endpoint: string, options?: RequestOptions<T>) =>
        this.request<T>('DELETE', endpoint, { ...options });

    patch = <T>(endpoint: string, options?: RequestOptions<T>) =>
        this.request<T>('PATCH', endpoint, { ...options });
}
