export const DurationIcon = () => {
    return (
        <svg viewBox="0 0 100 100">
            <g className="stroke-current">
                <circle cx="50" cy="50" r="44" strokeWidth="6" fill="none" />
                <circle className="fill-current" cx="50" cy="50" r="5" />
                <line
                    x1="50"
                    y1="50"
                    x2="50"
                    y2="16"
                    strokeWidth="6"
                    strokeLinecap="round"
                />
                <line
                    x1="50"
                    y1="50"
                    x2="66"
                    y2="66"
                    pathLength="100"
                    strokeWidth="6"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
};
