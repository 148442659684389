import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EditIcon, SpinnerIconSVG, TrashIconSVG } from 'src/assets/svg';
import container from 'src/container';
import { ConsumerSession, ConsumerSessionStatus } from 'src/domain/consumer';
import { Session } from 'src/domain/session';

import { LoadingCircle } from 'src/view/components/loading/Circle';
import { useConsumerSession } from 'src/view/hooks/consumer';
import { setSnapshot } from 'src/view/screens/NewVideo/SessionFormState/actions';
import { useSessionForm } from 'src/view/screens/NewVideo/SessionFormState/hooks';
import { SessionFormTrack } from 'src/domain/sessionForm';
import { convertURLToHttps, toTitleCase } from 'src/utils/stringUtils';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import nb from 'date-fns/esm/locale/nb/index.js';
import { UserRole } from 'src/domain/user';
import { ConfirmationButton } from 'src/components/Modals/ConfirmationModal/ConfirmationButton';
import { format, parseISO } from 'date-fns';
import { ToggleSwitch } from 'src/components/Input/ToggleSwitch';

type Props = {
    thumbnail: string;
    session: Session;
    onDelete: (id: string) => void;
};

const {
    cradle: { munduClient, musicService },
} = container;

export const CardVideo = ({ thumbnail, session, onDelete }: Props) => {
    const { data: consumerSession, mutate } = useConsumerSession(session.id);

    const [loaded, setLoaded] = useState<boolean>(false);
    const { state, dispatch } = useSessionForm();
    const history = useHistory();
    const user = useSignedInUser();
    const [author, setAuthor] = useState({ name: '', id: '' });
    const [canEdit, setCanEdit] = useState(false);
    const [preparingEdit, setPreparingEdit] = useState(false);
    const [errorFetchingImage, setErrorFetchingImage] = useState(false);

    const onLoadImage = (): void => {
        if (!loaded) setLoaded(true);
    };

    const onToggleActive = useCallback(
        async (consumerSession: ConsumerSession) => {
            if (consumerSession.status === ConsumerSessionStatus.active) {
                mutate(
                    {
                        ...consumerSession,
                        status: ConsumerSessionStatus.inactive,
                    },
                    false,
                );
                await munduClient.updateConsumerSession(
                    consumerSession.id,
                    ConsumerSessionStatus.inactive,
                );
                mutate();
            } else {
                mutate(
                    {
                        ...consumerSession,
                        status: ConsumerSessionStatus.active,
                    },
                    false,
                );
                await munduClient.updateConsumerSession(
                    consumerSession.id,
                    ConsumerSessionStatus.active,
                );
                mutate();
            }
        },
        [mutate],
    );

    const editVideo = async () => {
        if (!consumerSession) return;
        setPreparingEdit(true);
        const trackSelection: SessionFormTrack[] = await Promise.all(
            session.media.audios.map(async ({ trackId, releaseId }) => {
                const {
                    items: { tracks },
                } = await musicService.getTrackDetails([Number(trackId)]);
                const {
                    duration,
                    artist,
                    title,
                    release: { image },
                } = tracks[0];

                return {
                    id: Number(trackId),
                    releaseId,
                    artist: artist.appearsAs,
                    duration,
                    cover: convertURLToHttps(image),
                    title,
                };
            }),
        );

        const consumer = await munduClient.getConsumer(
            consumerSession.consumerId,
        );
        if (!consumer) return;

        dispatch(
            setSnapshot({
                ...state,
                images: session.media.images,
                trackSelection,
                imageDuration: session.media.imageDuration,
                recipient: consumer,
                playbackMode: session.playbackMode,
                duration: session.duration,
                title: session.title,
                editMode: true,
                id: session.id,
            }),
        );
        history.push('/app/new-video/step1');
    };

    useEffect(() => {
        const getAuthor = () =>
            //TODO maybe we should handle memoryleak?
            munduClient
                .getUsername(session.author)
                .then(author => setAuthor({ name: author, id: session.author }))
                .catch();

        getAuthor();
    }, [session.author]);

    useEffect(() => {
        if (user?.id === author.id || user?.role === UserRole.organization) {
            setCanEdit(true);
        }
    }, [user, author.id]);

    const handleError = () => {
        setLoaded(true);
        setErrorFetchingImage(true);
    };

    return (
        <div
            key={session.id}
            className=" w-60 justify-self-center lg:justify-self-start"
        >
            <div className="w-60 h-36 rounded-xl bg-starkWhite overflow-hidden">
                {consumerSession && (
                    <div className="relative h-full w-full ">
                        <div className="relative h-full">
                            {errorFetchingImage ? (
                                <div className="absolute inset-0 flex items-center justify-center text-center">
                                    Video mangler ett eller flere bilder
                                </div>
                            ) : (
                                <img
                                    src={thumbnail}
                                    className="h-full w-full object-cover"
                                    onLoad={onLoadImage}
                                    onError={handleError}
                                />
                            )}

                            <div className="absolute top-0 right-0 left-0 h-12 bg-gradient-to-b from-blackGradient" />
                            <div className="absolute bottom-0 right-0 left-0  h-12 bg-gradient-to-t from-blackGradient" />

                            {canEdit ? (
                                preparingEdit ? (
                                    <SpinnerIconSVG className="absolute w-6 top-4 right-4 text-lightPeach animate-spin" />
                                ) : (
                                    <button
                                        className="group absolute w-6 h-6 top-4 right-4 opacity-90 hover:opacity-100 transition-all"
                                        onClick={editVideo}
                                    >
                                        <EditIcon className="w-6 stroke-lightPeach transition-colors" />
                                    </button>
                                )
                            ) : null}

                            <LoadingCircle
                                loading={!loaded}
                                isGlobal={!false}
                            />
                        </div>
                        {/* TODO: Add when soft-delete is implemented
                                <div className="absolute flex items-center justify-center top-0 w-full h-full bg-black bg-opacity-75 text-white">
                                    Slettet
                                </div>
                            */}
                        {canEdit && (
                            <div className="absolute bottom-0 right-0 pb-2 pr-2 opacity-90 hover:opacity-100 transition-all">
                                <ToggleSwitch
                                    classNameContainer="m-2"
                                    checked={
                                        consumerSession.status ===
                                        ConsumerSessionStatus.active
                                    }
                                    onClick={(): Promise<void> =>
                                        onToggleActive(consumerSession)
                                    }
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className="flex justify-between items-center px-2 pb-2 pt-1 ">
                <div className="flex flex-col">
                    <div className="inline-block space-x-0.5 align-bottom line-clamp-1 truncate">
                        <span className="text-md">
                            {toTitleCase(session.title)}&nbsp;
                        </span>
                        <span className="text-[10px] font-MatterSQ ">
                            {format(
                                parseISO(
                                    session.updatedDate
                                        ? session.updatedDate
                                        : session.createdDate,
                                ),
                                '(dd MMM YYY)',
                                {
                                    locale: nb,
                                },
                            )}
                        </span>
                    </div>

                    <div className="inline-block space-x-1 text-xs align-bottom line-clamp-1 truncate">
                        Av:{' '}
                        <span className="font-semibold ">{author.name}</span>
                    </div>
                </div>

                {canEdit && (
                    <ConfirmationButton
                        type="button"
                        onClick={() => onDelete(session.id)}
                        className="p-3 items-center rounded-md text-sm outline-none focus:outline-none  hover:bg-woodyBrown hover:text-lightPeach transition-all"
                    >
                        <TrashIconSVG />
                    </ConfirmationButton>
                )}
            </div>
        </div>
    );
};
