import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import container from 'src/container';
import { UserRedux } from 'src/state/reducers';
import { getReducerState } from 'src/state/User/UserReducer';
import * as R from 'ramda';
import avatarDefault from 'src/assets/images/client_default.png';
import { useAvatar } from './useAvatar';
import { Consumer } from 'src/domain/consumer';
import { useCurrentConsumerContext } from '../screens/Home/NewHome/reducer';
import { v4 as generateId } from 'uuid';
import { mutate } from 'swr';

const {
    cradle: { storageService, munduClient },
} = container;

export const useUploadAvatar = (consumer?: Consumer) => {
    const { src } = useAvatar(consumer?.profile.avatar);
    const [preview, setPreview] = useState<string>(src);
    const [file, setFile] = useState<File>();
    const { avatarSAS } = useSelector(
        R.pipe(getReducerState, UserRedux.selectors.getSASTokens),
    );
    const { dispatch } = useCurrentConsumerContext();

    const uploadAvatar = async (consumer: Consumer, file?: File) => {
        if (!avatarSAS || !file) return;
        const fileName = generateId();
        await storageService.uploadImage(file, fileName, avatarSAS);
        const res = await munduClient.updateConsumer(consumer.id, {
            avatar: fileName,
        });

        dispatch({ type: 'UPDATE_CONSUMER', payload: res });
        mutate([consumer.id, 'consumer/consumerId']);
        mutate('consumers');
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (!e.target.files) return;
        setFile(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
        if (consumer) {
            uploadAvatar(consumer, e.target.files[0]);
        }
    };

    const removeAvatar = async () => {
        setFile(undefined);
        preview && URL.revokeObjectURL(preview);
        setPreview(avatarDefault);
        if (consumer?.profile.avatar && avatarSAS) {
            await storageService.deleteImage(
                consumer.profile.avatar,
                avatarSAS,
            );
            const res = await munduClient.updateConsumer(consumer.id, {
                avatar: null,
            });
            dispatch({ type: 'UPDATE_CONSUMER', payload: res });
            mutate([consumer.id, 'consumer/consumerId']);
            mutate('consumers');
        }
    };

    useEffect(() => {
        setPreview(src);
    }, [src, setPreview]);

    return {
        uploadAvatar,
        removeAvatar,
        onChange,
        preview,
        file,
        setPreview,
    };
};
