import { useState } from 'react';
import { NavButton } from 'src/components/Buttons/NavItem';
import { Modal } from '../Modals/Modal';
import { SlideList } from '../onboarding/Onboarding';
import { DocumentsIcon } from '../Icons/icons';

export const RepeatOnboardingButton = ({
    isLight = false,
}: {
    isLight?: boolean;
}) => {
    const [showOnboarding, setShowOnboarding] = useState(false);

    return (
        <div className="w-64">
            <NavButton
                onClick={() => setShowOnboarding(true)}
                leadingIcon={DocumentsIcon}
                isLight={isLight}
                label="Åpne brukerveiledning"
            />
            <Modal
                show={showOnboarding}
                fadeDuration={350}
                appear={false}
                className="fixed inset-0 z-70 h-screen w-screen bg-woodyBrown"
            >
                <SlideList
                    onComplete={() => setShowOnboarding(false)}
                    cancelable
                />
            </Modal>
        </div>
    );
};
