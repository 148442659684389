import { CardGrid } from 'src/components/AppScreen/AppLayout';
import { ListPlaylistItem } from 'src/domain/music';

import { PlaylistItem } from './item';

export const PlaylistList = ({
    playlists,
}: {
    playlists: ListPlaylistItem[];
}) => {
    const hasNum = (str: string) => {
        return /\d/.test(str);
    };
    const sortedPlaylists = [
        ...playlists
            .filter(p => hasNum(p.name))
            .sort((a, b) => a.name.localeCompare(b.name)),
        ...playlists
            .filter(p => !hasNum(p.name))
            .sort((a, b) => a.name.localeCompare(b.name)),
    ];

    return (
        <CardGrid>
            {sortedPlaylists.map(playlist => (
                <PlaylistItem key={playlist.id} item={playlist} />
            ))}
        </CardGrid>
    );
};
