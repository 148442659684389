import container from 'src/container';
import { AppThunk } from 'src/state/common';
import { UserActions } from 'src/state/_actions';

const {
    cradle: { storageService, munduClient },
} = container;

const updatePersonalMediaSas =
    (consumerId: string): AppThunk =>
    async (dispatch): Promise<void> => {
        const res = await munduClient.getPersonalMediaSas(consumerId);
        storageService.setContainer(res.containerName, res);
        dispatch(UserActions.setPersonalMediaSAS(res, consumerId));
    };

const updateCommonMediaSAS =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        const res = await munduClient.getCommonMediaSas();
        dispatch(UserActions.setCommonMediaSAS(res));
    };

const updateAvatarSAS =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        const res = await munduClient.getAvatarSas();
        dispatch(UserActions.setAvatarSAS(res));
    };

const refreshUser =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        const user = await munduClient.getUser();
        dispatch(UserActions.setUser(user));
    };

const logout =
    (): AppThunk =>
    async (dispatch): Promise<void> => {
        dispatch(UserActions.logout());
        dispatch(UserActions.clearUserState());
        sessionStorage.clear();
    };

export default {
    updateCommonMediaSAS,
    updatePersonalMediaSas,
    updateAvatarSAS,
    logout,
    refreshUser,
};
