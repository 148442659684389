import cx from 'classnames';
import { Colors } from 'src/assets';

type Props = {
    title: string;
    className?: string;
    fill?: string;
    borderColor?: string;
    textColor?: string;
    to?: string;
};
export const LinkButton = ({
    title,
    className,
    fill,
    textColor = Colors.lightPeach,
    borderColor = Colors.lightPeach,
    to,
}: Props) => {
    return (
        <a
            href={to}
            rel="noopener noreferrer"
            target="_blank"
            className={cx(
                fill
                    ? `bg-${fill} text-${textColor} border-${fill} hover:bg-woodyBrown hover:text-lightPeach hover:border-lightPeach`
                    : `border-1.6px border-${borderColor} hover:bg-woodyBrown hover:text-lightPeach`,
                `text-${textColor} border-1.6px rounded-full py-1 px-4 text-sm lg:text-base align-middle transition-all duration-150 cursor-pointer`,

                className,
            )}
        >
            {title}
        </a>
    );
};
