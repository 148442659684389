import { useState } from 'react';
import cx from 'classnames';

export const Popover = ({
    message,
    children,
}: {
    message: string;
    children: React.ReactNode;
}) => {
    const [visible, setVisible] = useState(false);
    return (
        <div className="relative grow max-w-xl">
            <button
                onClick={() => setVisible(prev => !prev)}
                onMouseEnter={() => setVisible(true)}
                onMouseLeave={() => setVisible(false)}
            >
                {children}
            </button>
            <div
                className={cx(
                    'flex absolute max-w-lg bg-lightPeach transition-all shadow-lg p-4 rounded-lg text-base font-normal z-50',
                    visible ? 'visible opacity-100' : 'invisible opacity-0',
                )}
            >
                {message}
            </div>
        </div>
    );
};
