import { forwardRef, memo } from 'react';
import { Link } from 'react-router-dom';
import { Screen } from 'src/view/routes/Router';

type aboutPromoVideoProps = {
    bgColor: string;
    id: string;
};
const AboutPromoVideo = forwardRef<HTMLElement, aboutPromoVideoProps>(
    ({ bgColor = 'lightPeach', id }: aboutPromoVideoProps, ref) => {
        return (
            <section
                ref={ref}
                id={id}
                className={`relative z-10 grid justify-center px-6 sm:px-32 xl:px-72 py-16 bg-${bgColor}`}
            >
                <div className="prose prose-peach sm:prose-lg md:prose-xl xl:prose-xl xl:max-w-none">
                    <h1>Tippetue</h1>
                    <p className="lead">
                        Vigdis og Roy har vært et par siden 70-tallet. De har
                        delt livets små og store stunder og fikk to barn sammen.
                    </p>
                    <div className="grid xl:grid-cols-2 xl:gap-8">
                        <p>
                            Etter at Vigdis fikk påvist demens i 2015, har
                            tilværelsen hennes forandret seg. Hun og Roy er
                            blant de aller første brukerene av Mundu.
                        </p>
                        <div>
                            <p>
                                Filmen du ser ovenfor er et utdrag av
                                &quot;Tippetue&quot;, en Mundu-film Vigdis og
                                Roy så flere ganger i uken på nettbrettet da
                                Vigdis fortsatt bodde hjemme.
                            </p>
                            <Link to={Screen.Tippetue}>
                                <button className="btn-lightPeach mt-4 float-right">
                                    Les mer
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    },
);
AboutPromoVideo.displayName = 'AboutPromoVideo';

export default AboutPromoVideo;
