import { pickBy } from 'lodash';
import { Admin } from 'src/domain/admin';
import {
    Consumer,
    ConsumerSession,
    ConsumerSessionStatus,
} from 'src/domain/consumer';
import { ContactFormPayload } from 'src/domain/contact';
import { Creator } from 'src/domain/creator';
import { NextOfKin } from 'src/domain/next-of-kin';
import { LicenceEntity, Organization } from 'src/domain/organization';
import { Media, PersonalMedia } from 'src/domain/personalMedia';
import { Session } from 'src/domain/session';
import {
    IncompleteSession,
    IncompleteSessionPayload,
} from 'src/domain/sessionForm';
import { SASInfo, User } from 'src/domain/user';
import { HTTPService } from './http.service';
import {
    SessionDto,
    NextOfKinDto,
    AdminDto,
    CreatorDto,
    UpdateCreatorDto,
    ConsumerDto,
    UpdateConsumerDto,
    OrganizationDto,
    UpdateOrganizationDto,
    PersonalMediaDto,
    B2CIdentity,
    MusicalProfile,
} from './mundu.params';

const getFilterParams = (
    params: Record<string, string | number | undefined>,
) => {
    const _params = pickBy<Record<string, string | number | undefined>>(
        params,
        v => v !== undefined,
    ) as Record<string, string | number>;
    return { params: _params };
};

type UpdateSessionDto = Partial<SessionDto>;
type UpdateNextOfKinDto = Partial<NextOfKinDto>;

export class MunduClient {
    // Auth
    private api: HTTPService;
    constructor({ httpService }: { httpService: HTTPService }) {
        this.api = httpService;
    }

    getUser = (): Promise<User> => this.api.get('auth/getUser');
    getUsername = (id: string): Promise<string> =>
        this.api.get(`user/name/${id}`);

    getPhoneNumberExists = (phonenumber: string) =>
        this.api.get<boolean>(`auth/checkPhoneNumberExists/${phonenumber}`);

    getEmailExists = (email: string) =>
        this.api.get<boolean>(`auth/checkEmailExists/${email}`);

    getUsernameExists = (username: string) =>
        this.api.get<boolean>(`auth/checkUsernameExists/${username}`);

    getPersonalMediaSas = (consumerId: string) =>
        this.api.get<SASInfo>(`auth/personalMediaSAS`, {
            params: { consumerId },
        });

    getCommonMediaSas = () => this.api.get<SASInfo>(`auth/commonMediaSAS`);

    getAvatarSas = () => this.api.get<SASInfo>(`auth/avatarSAS`);

    // Admin
    createAdmin = (payload: AdminDto) =>
        this.api.post<Admin>(`admin`, { payload });

    deleteAdmin = (id: string) =>
        this.api.delete(`admin/${id}`, {
            deserialize: res => res.text(),
        });
    listAdmins = () => this.api.get<Admin[]>(`admin`);

    // Creators
    listCreators = ({
        email,
        organizationId,
        phoneNumber,
        consumerId,
        limit,
        pageIndex,
    }: {
        email?: string;
        organizationId?: string;
        phoneNumber?: string;
        consumerId?: string;
        limit?: number;
        pageIndex?: number;
    }) =>
        this.api.get<Creator[]>(
            'creators',
            getFilterParams({
                email,
                organizationId,
                phoneNumber,
                'consumerIds[0]': consumerId,
                limit,
                pageIndex,
            }),
        );

    createCreator = (payload: CreatorDto) =>
        this.api.post<Creator>(`creators`, { payload });

    getCreator = (id: string) => this.api.get<Creator>(`creators/${id}`);

    updateCreator = (id: string, { profile, ...rest }: UpdateCreatorDto) =>
        this.api.patch<Creator>(`creators/${id}`, {
            payload: { ...rest, ...profile },
        });

    deleteCreator = (id: string) =>
        this.api.delete(`creators/${id}`, {
            deserialize: res => res.text(),
        });

    // Consumers
    listConsumers = ({
        organizationId,
        creatorId,
    }: {
        organizationId?: string;
        creatorId?: string;
    }) =>
        this.api.get<Consumer[]>(
            'consumers',
            getFilterParams({
                organizationId,
                creatorIds: creatorId,
            }),
        );

    createConsumer = (payload: ConsumerDto) =>
        this.api.post<Consumer>(`consumers`, { payload });
    getConsumer = (id: string) => this.api.get<Consumer>(`consumers/${id}`);

    updateConsumer = (id: string, payload: UpdateConsumerDto) =>
        this.api.patch<Consumer>(`consumers/${id}`, {
            payload: payload,
        });

    deleteConsumer = (id: string) =>
        this.api.delete(`consumers/${id}`, { deserialize: res => res.text() });

    resetConsumerPassword = (id: string) =>
        this.api.patch<{ password: string; status: string; message: 'string' }>(
            `user/resetPassword/${id}`,
        );

    // NextOfKins
    listNextOfKins = ({
        organizationId,
        consumerId,
        firstName,
        lastName,
    }: {
        firstName?: string;
        lastName?: string;
        organizationId?: string;
        consumerId?: string;
    }) =>
        this.api.get<NextOfKin[]>(
            'nextOfKins',
            getFilterParams({
                'consumerIds[0]': consumerId,
                organizationId,
                firstName,
                lastName,
            }),
        );

    createNextofKin = (payload: NextOfKinDto) =>
        this.api.post<NextOfKin>(`nextOfKins`, { payload });

    getNextOfKin = (id: string) => this.api.get<NextOfKin>(`nextOfKins/${id}`);

    updateNextOfKin = (id: string, payload: UpdateNextOfKinDto) =>
        this.api.patch<NextOfKin>(`nextOfKins/${id}`, { payload });

    deleteNextOfKin = (id: string) =>
        this.api.delete(`nextOfKins/${id}`, { deserialize: res => res.text() });

    //Organization
    getNumberOfLicences = () => this.api.get<LicenceEntity>('licence/info');
    getOrgName = () => this.api.get<string>('organizationName');
    getOrgDisplayName = () => this.api.get<string>('organizationName/display');
    listOrganizations = () => this.api.get<Organization[]>('organizations');
    createOrganization = (payload: OrganizationDto): Promise<Organization> =>
        this.api.post<Organization>(`organizations`, { payload });
    getOrganization = (id: string) =>
        this.api.get<Organization>(`organizations/${id}`);
    updateOganization = (id: string, payload: UpdateOrganizationDto) =>
        this.api.patch<Organization>(`organizations/${id}`, { payload });
    deleteOrganization = (id: string) =>
        this.api.delete(`organizations/${id}`, {
            deserialize: res => res.text(),
        });

    //Sessions
    listSessions = ({
        author,
        consumerId,
        limit,
        pageIndex,
    }: {
        author?: string;
        consumerId?: string;
        limit?: number;
        pageIndex?: number;
    }) =>
        this.api.get<Session[]>(
            'sessions',
            getFilterParams({
                author,
                consumerId,
                limit,
                pageIndex,
            }),
        );

    createSession = (payload: SessionDto) =>
        this.api.post<Session>('sessions', { payload });
    updateSession = (id: string, payload: UpdateSessionDto) =>
        this.api.patch<Session>(`sessions/${id}`, { payload });
    getSession = (id: string) => this.api.get<Session>(`sessions/${id}`);

    deleteSession = (id: string) =>
        this.api.delete(`sessions/${id}`, {
            deserialize: res => res.text(),
        });

    // IncompleteSessions
    listIncompleteSessions = (
        author?: string,
        recipient?: string,
        limit?: number,
        pageIndex?: number,
    ) =>
        this.api.get<IncompleteSession[]>(
            'inCompleteSessions',
            getFilterParams({
                author,
                recipient,
                limit,
                pageIndex,
            }),
        );

    createIncompleteSession = (payload: IncompleteSessionPayload) =>
        this.api.post<IncompleteSession>('inCompleteSessions', {
            payload,
        });
    getIncompleteSession = (id: string) =>
        this.api.get<IncompleteSession>(`inCompleteSessions/${id}`);
    updateIncompleteSession = (
        id: string,
        payload: Partial<IncompleteSessionPayload>,
    ) =>
        this.api.patch(`inCompleteSessions/${id}`, {
            payload,
        });
    deleteIncompleteSession = (id: string) =>
        this.api.delete(`inCompleteSessions/${id}`, {
            deserialize: res => res.text(),
        });

    // ConsumerSessions
    getConsumerSession = (id: string) =>
        this.api
            .get<ConsumerSession[]>(
                'sessions/consumerSessions',
                getFilterParams({
                    sessionIds: id,
                }),
            )
            .then(res => res[0]);

    listConsumerSessions = () =>
        this.api.get<ConsumerSession[]>('sessions/consumerSessions');

    updateConsumerSession = (id: string, status: ConsumerSessionStatus) =>
        this.api.patch<ConsumerSession>(`sessions/consumerSessions/${id}`, {
            payload: { status },
        });

    // PersonalMedia
    updatePersonalMedia = (payload: PersonalMediaDto) =>
        this.api.post<PersonalMedia>('personalMedias/upload', { payload });

    listPersonalMedia = ({
        consumerId,
        uploaderId,
        limit,
        pageIndex,
    }: {
        consumerId?: string;
        uploaderId?: string;
        limit?: number;
        pageIndex?: number;
    }) =>
        this.api.get<PersonalMedia[]>(
            `personalMedias`,
            getFilterParams({
                consumerId,
                uploaderId,
                limit,
                pageIndex,
            }),
        );

    deletePersonalMedia = (consumerId: string, imageIds: string[]) =>
        this.api.delete(`personalMedias/media/${consumerId}`, {
            payload: { mediaIds: imageIds },
            deserialize: res => res.text(),
        });

    // CommonMedias
    listCommonMedias = (): Promise<Media[]> => this.api.get(`commonMedias`);

    // SessionLogs
    listSessionLogs = () => this.api.get(`sessionLogs`);

    // Contact
    contact = (payload: ContactFormPayload) =>
        this.api.post('contact', {
            payload,
            options: {
                hooks: {
                    beforeRequest: [],
                },
            },
        });

    // Consent
    updateConsent = (id: string, consent: boolean) =>
        this.api.patch<User>(`user/consent/${id}`, { payload: { consent } });
    getUserName = (id: string) => this.api.get(`user/name/${id}`);

    getSignInIdentities = (id: string) =>
        this.api.get<B2CIdentity[]>(`user/signInIdentities/${id}`);

    assignConsumers = (creatorId: string, consumerIds: string[]) =>
        this.api.patch<{ message: string }>(
            `organizations/assignConsumers/${creatorId}`,
            {
                payload: { consumerIds },
            },
        );

    removeConsumers = (creatorId: string, consumerIds: string[]) =>
        this.api.delete<{ consumerIds: string[] }>(
            `organizations/removeConsumers/${creatorId}`,
            {
                payload: { consumerIds },
            },
        );

    assignCreators = (consumerId: string, creatorIds: string[]) =>
        this.api.patch<{ message: string }>(
            `organizations/assignCreators/${consumerId}`,
            {
                payload: { creatorIds },
            },
        );

    removeCreators = (consumerId: string, creatorIds: string[]) =>
        this.api.delete<{ creatorIds: string[] }>(
            `organizations/removeCreators/${consumerId}`,
            {
                payload: { creatorIds },
            },
        );

    updateMusicalProfile = (id: string, payload: Partial<MusicalProfile>) =>
        this.api.patch(`musicalProfile/${id}`, { payload });

    getMusicalProfile = (id: string): Promise<MusicalProfile> =>
        this.api.get(`musicalProfile/${id}`);
}
