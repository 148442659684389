import { FunctionComponent, SVGProps } from 'react';

export type IconComponent = FunctionComponent<SVGProps<SVGSVGElement>>;

type Props = {
    SVG: IconComponent;
    onClick?: () => void;
};
export const TextIcon = ({ SVG, onClick }: Props) => {
    return (
        <div className="p-[0.5em] flex items-center justify-center ">
            <div onClick={onClick} className={'relative pb-[1em] w-[1em]'}>
                {<SVG />}
            </div>
        </div>
    );
};
