import { createContext, ReactNode, useContext, useMemo } from 'react';
import produce from 'immer';
import { useImmerReducer } from 'use-immer';
import { Artist, Playlist, Release } from 'src/domain/music';

type SurveyAction =
    | { type: 'SET_PLAYLIST'; payload: Playlist }
    | { type: 'TOGGLE_RELEASE'; payload: Release }
    | { type: 'TOGGLE_ARTIST'; payload: Artist }
    | { type: 'SET_ARTISTS'; payload: Artist[] }
    | { type: 'SET_INITIAL_STATE'; payload: SurveyState }
    | { type: 'RESET' };

type ContextProps = {
    state: SurveyState;
    dispatch: Dispatch;
};

type SurveyState = {
    playlist?: Playlist;
    releases: Release[];
    artists: Artist[];
};
const initialState: SurveyState = {
    playlist: undefined,
    releases: [],
    artists: [],
};

const SurveyReducer = produce(
    (draft: SurveyState = initialState, action: SurveyAction) => {
        switch (action.type) {
            case 'SET_INITIAL_STATE':
                draft = action.payload;
                return;
            case 'TOGGLE_ARTIST':
                if (
                    draft.artists.some(
                        artist => artist.id === action.payload.id,
                    )
                )
                    draft.artists = draft.artists.filter(
                        artist => artist.id !== action.payload.id,
                    );
                else {
                    draft.artists = [...draft.artists, action.payload];
                }
                return;
            case 'SET_ARTISTS':
                draft.artists = action.payload;
                return;
            case 'TOGGLE_RELEASE':
                if (
                    draft.releases.some(
                        release => release.id === action.payload.id,
                    )
                )
                    draft.releases = draft.releases.filter(
                        release => release.id !== action.payload.id,
                    );
                else {
                    draft.releases = [...draft.releases, action.payload];
                }
                return;

            case 'SET_PLAYLIST':
                draft.playlist = action.payload;
                return;
            case 'RESET':
                draft = initialState;
                sessionStorage.removeItem('surveyStore');
                return;
            default:
                return;
        }
    },
);
type Dispatch = (action: SurveyAction) => void;
const SurveyContext = createContext<ContextProps>({
    state: initialState,
    dispatch: () => null,
});

export const useSurveyContext = () => useContext(SurveyContext);

export const SurveyStore = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => {
    const [state, dispatch] = useImmerReducer(SurveyReducer, initialState);
    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);
    return (
        <SurveyContext.Provider value={contextValue}>
            {children}
        </SurveyContext.Provider>
    );
};
