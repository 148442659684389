import cx from 'classnames';
type Props = {
    isLight: boolean;
    isCurrent: boolean;
};

export const Dot = ({ isLight, isCurrent }: Props) => {
    return (
        <div
            className={cx(
                'w-2 mx-1 aspect-square rounded-full transition-all  duration-700',
                isLight ? 'bg-woodyBrown' : 'bg-lightPeach',
                isCurrent ? 'opacity-80' : 'opacity-30',
            )}
        />
    );
};
