export enum MediaType {
    image = 'IMAGE',
    video = 'VIDEO',
}

export interface Media {
    id: string;
    description?: string;
    thumbnail?: LocalImage;
    type?: MediaType;
    name?: string;
    metaData?: Record<string, unknown>;
    containerType: string;
}

export interface LocalImage {
    id: string;
    file: string;
    width: number;
    height: number;
    mime: string;
}

export type MediaItem = {
    id: string;
    thumbnail: LocalImage;
    file: string;
    mime: string;
    size?: number;
    description?: string;
    type: MediaType;
    metaData?: Record<string, unknown>;
};

export interface PersonalMedia {
    id: string;
    consumerId: string;
    uploaderId: string;
    medias: Media[];
}
