import { useRouteMatch } from 'react-router-dom';

export const useIsSelectingMusic = () => {
    const isSelectingMusic = useRouteMatch('/app/new-video/step1/browse');
    return isSelectingMusic ? true : false;
};

export const useIsSelectingImages = () => {
    const isSelectingMusic = useRouteMatch(
        '/app/new-video/step2/personalmedia',
    );
    return isSelectingMusic ? true : false;
};

export const useIsImageSelection = () => {
    const isSelectingMusic = useRouteMatch('/app/new-video/step2');
    return isSelectingMusic ? true : false;
};

export const useIsImageUpload = () => {
    const isUploadingImages = useRouteMatch('/app/image-upload/');
    return isUploadingImages ? true : false;
};

export const useIsNewVideo = () => {
    const isUploadingImages = useRouteMatch('/app/new-video');
    return isUploadingImages ? true : false;
};
