import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MessageIcon, PhoneIcon } from 'src/components/Icons/icons';
import { useNotification } from 'src/components/Notification/useNotfication';
import { Section } from 'src/components/Section';
import container from 'src/container';
import { NextOfKin } from 'src/domain/next-of-kin';
import { UserThunks } from 'src/state/thunks';
import Input from 'src/components/FormComponents/Input';
import { useSignInIdentities } from 'src/view/hooks/useSignInIdentities';

import { Button } from 'src/components/Buttons/Button';
import { useHistory } from 'react-router-dom';
import {
    IsOptional,
    IsString,
    IsNotEmpty,
    IsEmail,
    ValidateIf,
} from 'class-validator';
import { PeopleSVG } from 'src/assets/svg';
import Form from 'src/components/FormComponents/Form';

const {
    cradle: { munduClient },
} = container;

class EditNextOfKinSchema {
    @IsOptional()
    @IsString()
    @IsNotEmpty({ message: 'Fornavn må fylles ut.' })
    firstName?: string;

    @IsOptional()
    @IsString()
    @IsNotEmpty({ message: 'Etternavn må fylles ut.' })
    lastName?: string;

    @IsOptional()
    @ValidateIf(o => o.email?.length !== 0)
    @IsString()
    @IsEmail({}, { message: 'Epost er ikke gyldig' })
    email?: string;
}

export const NextofkinProfile = ({ user }: { user: NextOfKin }) => {
    const notify = useNotification();
    const dispatch = useDispatch();
    const { phoneNumber } = useSignInIdentities(user.id);

    const handleUpdate = async (data: EditNextOfKinSchema) => {
        try {
            await munduClient.updateNextOfKin(user.id, data);
            dispatch(UserThunks.refreshUser());
            notify('Profil er oppdatert');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return (
        <div className="flex flex-col gap-8 max-w-3xl mx-auto">
            <Form
                className="bg-lightPeach text-woodyBrown"
                schema={EditNextOfKinSchema}
                onSubmit={handleUpdate}
                mode="onChange"
                defaultValues={{
                    firstName: user.profile.firstName,
                    lastName: user.profile.lastName,
                    email: user.email,
                }}
            >
                {({
                    register,
                    formState: { isValid, errors, isDirty, isSubmitting },
                }) => (
                    <div className="grid grid-cols-2 gap-4 h-full">
                        <div className="col-span-full flex justify-center">
                            {/* TODO: Replace with Role icon when ready */}
                            <div className="rounded-full w-36 h-36 bg-starkWhite flex justify-center items-center mb-4">
                                <PeopleSVG className="w-[18px] h-[18px]" />
                            </div>
                        </div>
                        <Input
                            className="text-input"
                            type="text"
                            label="Fornavn*"
                            error={errors.firstName}
                            {...register('firstName')}
                        />
                        <Input
                            className="text-input"
                            type="text"
                            label="Etternavn*"
                            error={errors.lastName}
                            {...register('lastName')}
                        />
                        <Input
                            className="text-input"
                            type="tel"
                            label="Telefonnr*"
                            leadingIcon={PhoneIcon}
                            value={phoneNumber || ''}
                            readOnly
                        />
                        <Input
                            className="text-input"
                            type="email"
                            label="Epost"
                            error={errors.email}
                            leadingIcon={MessageIcon}
                            {...register('email')}
                            readOnly
                        />

                        <div className="flex items-center gap-6">
                            <Input
                                value="Lagre"
                                className="primary-btn-small"
                                disabled={!isValid || isSubmitting || !isDirty}
                                type="submit"
                            />
                        </div>
                    </div>
                )}
            </Form>

            <Section title="Slette konto?">
                <div>Ved å slette kontoen mister du tilgang til Mundu</div>
                <DeleteNextofkin user={user} />
            </Section>
        </div>
    );
};

const DeleteNextofkin = ({ user }: { user: NextOfKin }) => {
    const [confirmLastName, setConfirmLastName] = useState('');
    const [canDelete, setCanDelete] = useState(false);
    const notify = useNotification();
    const history = useHistory();

    const onDelete = async () => {
        munduClient
            .deleteNextOfKin(user.id)
            .then(() => history.push('/logout'))
            .catch((error: Error) => notify(error.message, true));
    };

    useEffect(() => {
        if (confirmLastName === user.profile.lastName) {
            setCanDelete(true);
        } else {
            setCanDelete(false);
        }
    }, [confirmLastName, user.profile.lastName]);

    return (
        <>
            <div className="self-start flex items-end gap-3 flex-wrap">
                <Input
                    className="text-input"
                    type="email"
                    onChange={e => setConfirmLastName(e.currentTarget.value)}
                    label={`Skriv inn '${user.profile.lastName}' for å slette`}
                    leadingIcon={MessageIcon}
                />
                <Button
                    title="Slett bruker"
                    onClick={onDelete}
                    disabled={!canDelete}
                />
            </div>
        </>
    );
};
