type Props = {
    size: number;
};
export const OutboundLinkIcon = ({ size }: Props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox={`0 0 ${size} ${size}`}
        >
            <mask id="cornerMask" x={0} y={0} height={size} width={size}>
                <rect
                    className="fill-current"
                    x={0}
                    y={0}
                    width={(2 * size) / 5}
                    height={size}
                />
                <rect
                    className="fill-current"
                    x={0}
                    y={(2 * size) / 4}
                    width={size}
                    height={(2 * size) / 3}
                />
            </mask>
            <rect
                className="stroke-current"
                strokeWidth="2"
                fill="none"
                height={size}
                width={size}
                rx={size / 3}
                mask="url(#cornerMask)"
            />
            <g className="stroke-current" id="arrow" strokeWidth="2">
                <line
                    strokeWidth="1.67"
                    x1={size / 2}
                    y1={size / 2}
                    x2={size}
                    y2={0}
                />
                <line x1={size - size / 3} y1={0} x2={size} y2={0} />
                <line x1={size} y1={size / 3} x2={size} y2={0} />
            </g>
        </svg>
    );
};
