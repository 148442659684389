import { useHistory, useLocation } from 'react-router-dom';
import { NavItem } from 'src/components/Buttons/NavItem';
import { UsersIcon } from 'src/components/Icons/icons';
import { UserRole } from 'src/domain/user';
import { useCreators } from 'src/view/hooks/creator';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { UserSelectorOption } from '../buttons/SelectConsumer/SelectorOption';

export const LinkToCreatorsIfOrganization = () => {
    const user = useSignedInUser();

    if (user?.role !== UserRole.organization) return null;

    return (
        <NavItem
            icon={UsersIcon}
            label="Brukerkontakter"
            screen="/app/creators"
            indentChildren={false}
        />
    );
};

export const CreatorSelector = () => {
    const { creators } = useCreators();
    const history = useHistory();
    const { pathname } = useLocation();

    return (
        <div className="overflow-y-auto shadow-scrollTop">
            {creators.length !== 0 &&
                creators.map(creator => (
                    <UserSelectorOption
                        key={creator.id}
                        user={creator}
                        onClick={() =>
                            history.push(`/app/creators/profile/${creator.id}`)
                        }
                        isSelected={pathname.endsWith(creator.id)}
                    />
                ))}
        </div>
    );
};
