import { updateTitle } from '../SessionFormState/actions';
import { useSessionForm } from '../SessionFormState/hooks';

export const VideoTitle = () => {
    const { state, dispatch } = useSessionForm();
    const { title } = state;
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        dispatch(updateTitle(event.target.value));
    };

    return (
        <div className="group relative flex justify-start items-center text-woodyBrown  text-3xl md:text-5xl font-logo">
            <input
                className="editable-text  w-full h-16 bg-transparent"
                value={title}
                onChange={handleChange}
                placeholder="Tittel"
                type="text"
                autoCapitalize="on"
                autoFocus
            />
        </div>
    );
};
