import { useCallback, useEffect, useState } from 'react';

export const usePaginateIfVisible = (nextPage: () => void) => {
    const [element, setElement] = useState<HTMLDivElement | null>(null);

    const paginateCallback = useCallback(
        (entries: IntersectionObserverEntry[]) => {
            if (entries[0].isIntersecting) {
                nextPage();
            }
        },
        [nextPage],
    );

    useEffect(() => {
        const observer = new IntersectionObserver(paginateCallback, {
            threshold: 1,
        });

        if (element) {
            observer.observe(element);
        }
        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, [element, paginateCallback]);

    const PaginateIfVisible = useCallback(
        () => <div ref={setElement} style={{ background: 'transparent' }} />,
        [],
    );

    return PaginateIfVisible;
};
