import { useState, useEffect } from 'react';
import { LockIcon, CaretIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';
import container from 'src/container';
import { Consumer } from 'src/domain/consumer';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { useCurrentConsumerContext } from 'src/view/screens/Home/NewHome/reducer';
import { ConsumerSelectOption } from './Option';

const { munduClient } = container.cradle;

type Props = {
    label?: string;
    onSelect: (consumer: Consumer) => void;
    disabled?: boolean;
    isLight?: boolean;
};

export const SelectConsumerAsNextofkin = ({
    label = 'Velg bruker',
    onSelect,
    disabled = false,
    isLight = true,
}: Props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);
    const user = useSignedInUser();
    const consumerIds = user?.followers?.consumerIds;

    const {
        dispatch,
        state: { currentConsumer },
    } = useCurrentConsumerContext();

    useEffect(() => {
        if (!consumerIds) return;
        (async () => {
            const consumer = await munduClient.getConsumer(consumerIds[0]);
            if (consumer) {
                dispatch({
                    type: 'UPDATE_CONSUMER',
                    payload: consumer,
                });
            }
        })();
    }, [onSelect, consumerIds, dispatch]);

    return (
        <>
            <div className="relative bg-transparent">
                {currentConsumer ? (
                    <ConsumerSelectOption
                        key={currentConsumer.id}
                        isLight={isLight}
                        disabled={disabled}
                        onClick={toggleModal}
                        consumer={currentConsumer}
                    />
                ) : (
                    <button
                        className="flex pt-0.5 text-sm hover:bg-woodyBrown hover:text-lightPeach h-10 rounded-md items-center stroke-[1.8px] justify-between px-4 pr-2 transition-all duration-200 ease-in-out"
                        onClick={toggleModal}
                        disabled={disabled}
                    >
                        <span className="pb-1 whitespace-nowrap ">{label}</span>
                        <TextIcon SVG={disabled ? LockIcon : CaretIcon} />
                    </button>
                )}
            </div>
        </>
    );
};
