import { SessionFormTrack } from 'src/domain/sessionForm';
import { convertURLToHttps } from 'src/utils/stringUtils';
import { CloseCardButton } from '../Buttons/CloseCardButton';

type Props = {
    item: SessionFormTrack;
    onRemove: () => void;
};

export const SessionTrackCard = ({ item, onRemove }: Props) => {
    return (
        <div
            className="relative bg-starkWhite w-32 pb-32 rounded-xl xl:w-36 xl:pb-36 xl:rounded-2xl"
            key={item.id}
        >
            <img
                src={convertURLToHttps(item.cover)}
                className="absolute top-0 w-full h-2/3 cursor-pointer rounded-t-xl xl:rounded-t-2xl object-cover object-top"
                alt=""
            />
            <CloseCardButton onClick={onRemove} />
            <div className="absolute bottom-0 h-1/3 w-full text-xs xl:text-sm py-1  px-4 text-center">
                <p className="truncate">{item.artist}</p>
                <p className="font-bold truncate">{item.title}</p>
            </div>
        </div>
    );
};
