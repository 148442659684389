/* eslint-disable react/no-children-prop */
import { useCallback } from 'react';
import { Media } from 'src/domain/personalMedia';
import { removeElementWithId } from 'src/utils/generalUtils';
import { ButtonAddMedia } from '../components/ButtonAddMedia';
import { updateImages, updateStep } from '../SessionFormState/actions';
import { useSessionForm } from '../SessionFormState/hooks';
import { CardPicture } from './components/CardPicture';
import { BrowseImages } from '../../BrowseImages';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styles from '../sortable.module.css';
import { NotFound } from '../../NotFound';

type IPropsSortableList = {
    list: Media[];
    onRemove: (id: string) => void;
    openPopup: () => void;
};

type IPropsSortableItem = {
    media: Media;
    onRemove: (id: string) => void;
};

const SortableItem = SortableElement<IPropsSortableItem>(
    ({ media, onRemove }: IPropsSortableItem) => (
        <div className={styles.sortableItem} key={media.id}>
            <CardPicture item={media} onRemove={onRemove} />
        </div>
    ),
);

const SortableList = SortableContainer<IPropsSortableList>(
    ({ list, onRemove, openPopup }: IPropsSortableList) => {
        return (
            <div className="flex mx-auto gap-x-5 gap-y-8 items-center flex-wrap">
                <ButtonAddMedia
                    active={!!list}
                    onClick={openPopup}
                    label="Legg til bilder"
                />
                {list.map((media: Media, index: number) => (
                    <SortableItem
                        key={media.id}
                        index={index}
                        media={media}
                        onRemove={onRemove}
                    />
                ))}
            </div>
        );
    },
);

export const CreateNewVideoStep2 = () => {
    const { path } = useRouteMatch();
    const { state, dispatch: dispatchSessionsForm } = useSessionForm();
    const { images } = state;
    const amount = images.length;
    const history = useHistory();
    const onRemovePicture = useCallback(
        (id: string) => {
            const newList = removeElementWithId(id, images);
            dispatchSessionsForm(updateImages(newList));
            if (newList.length === 0) dispatchSessionsForm(updateStep(2));
        },
        [dispatchSessionsForm, images],
    );

    const openPopup = useCallback(() => {
        history.push(`${path}/personalmedia`);
    }, [history, path]);

    const onSortEnd = useCallback(
        ({
            oldIndex,
            newIndex,
        }: {
            oldIndex: number;
            newIndex: number;
        }): void => {
            const element = images[oldIndex];
            images.splice(oldIndex, 1);
            images.splice(newIndex, 0, element);
            dispatchSessionsForm(updateImages(images));
        },
        [dispatchSessionsForm, images],
    );

    return (
        <Switch>
            <Route exact path={path}>
                <div className=" text-woodyBrown">
                    <>
                        {amount === 0 ? (
                            <div className="flex gap-y-10 gap-x-3 items-start content-start flex-wrap h-full w-full ">
                                <ButtonAddMedia
                                    disabled={!state.recipient}
                                    onClick={openPopup}
                                    label="Legg til bilder"
                                />
                            </div>
                        ) : (
                            <SortableList
                                list={images}
                                onRemove={onRemovePicture}
                                axis="xy"
                                onSortEnd={onSortEnd}
                                openPopup={openPopup}
                            />
                        )}
                    </>
                </div>
            </Route>
            <Route
                exact
                path={`${path}/personalmedia`}
                children={({ match }) => {
                    return match && <BrowseImages initialSelection={images} />;
                }}
            />
            <Route path="*" component={NotFound} />
        </Switch>
    );
};
