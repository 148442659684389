import { useEffect } from 'react';
import container from 'src/container';
import { Playlist } from 'src/domain/music';
import { useUserPlaylists } from './swr/useUserPlaylists';

export const useFavoritesPlaylist = (
    consumerId?: string,
    onChange?: (playlist: Playlist) => void,
) => {
    const { playlists, isLoading, refresh } = useUserPlaylists(consumerId);
    const favoritesPlaylist = playlists.find(x => x.name === 'Favoritter');

    useEffect(() => {
        if (!consumerId || !onChange) return;
        if (isLoading) return;
        if (favoritesPlaylist) {
            onChange(favoritesPlaylist);
            return;
        } else {
            (async () => {
                await container.cradle.musicService.createPlaylist(consumerId, {
                    name: 'Favoritter',
                });
                refresh();
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, favoritesPlaylist]);

    return { playlist: favoritesPlaylist, isLoading };
};
