import { TrashIconSVG } from 'src/assets/svg';
import { Item } from './state/types';

type Props = {
    index: number;
    item: Item;
    onClick?: (index: number) => void;
    onChange?: (
        index: number,
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
};

export const PreviewItem = ({ index, item, onClick, onChange }: Props) => {
    return (
        <div className="w-full h-full flex flex-col justify-between text-lightPeach">
            <div className="relative shadow-md">
                <button
                    type="button"
                    className="absolute z-10 bottom-0 right-0 p-4 hover:text-woodyBrown"
                    onClick={() => onClick && onClick(index)}
                >
                    <TrashIconSVG className="w-4 h-4 md:w-5 md:h-5" />
                </button>

                <img
                    className="w-full object-cover aspect-square rounded-[10px]"
                    src={item.thumbnail.file}
                />
            </div>
            <textarea
                name="description"
                placeholder="Legg til en beskrivelse"
                className=" text-input text-xs px-2 py-1 sm:text-sm rounded-[10px] resize-none shadow-md w-full h-1/4"
                onChange={e => onChange && onChange(index, e)}
                maxLength={100}
                value={item.description}
            />
        </div>
    );
};
