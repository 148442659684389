import { useConfirmationModalContext } from './ConfirmationModalProvider';

export const ConfirmationButton = ({
    onClick,
    ...props
}: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>) => {
    const modalContext = useConfirmationModalContext();

    const handleOnClick = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        const result = await modalContext.showConfirmation();
        result && onClick && onClick(event);
    };

    return (
        <button onClick={handleOnClick} {...props}>
            {props.children}
        </button>
    );
};
