type Props = {
    className: string;
    color: string;
    onAnimationEnd: () => void;
};
export const Circle = ({ color, className, onAnimationEnd }: Props) => {
    return (
        <div className={className} onAnimationEnd={onAnimationEnd}>
            <svg viewBox="0 0 70 30.625">
                <g data-name="Mask Group 2">
                    <circle
                        cx={45.5}
                        cy={45.5}
                        r={45.5}
                        fill={color}
                        data-name="Ellipse 6"
                        transform="translate(-10.3)"
                    />
                </g>
            </svg>
            <div className={'w-full h-4/6 circleOverflow:h-0 bg-peachOrange'} />
        </div>
    );
};
