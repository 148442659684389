import { useForm } from 'react-hook-form';
import container from 'src/container';
import { PlaylistDetails } from 'src/domain/music';
import { UpdatePlaylistSchema } from 'src/domain/music/schemas';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { UpdatePlaylistDto } from 'src/api/music.params';
import { useMessageData } from 'src/view/hooks/message';
import { useUserPlaylists } from 'src/view/hooks/swr/useUserPlaylists';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { mutate } from 'swr';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Buttons/Button';
import Input from 'src/components/FormComponents/Input';

const {
    cradle: { musicService },
} = container;

const resolver = classValidatorResolver(UpdatePlaylistSchema);

export const UpdatePlaylistForm = ({
    playlist,
    setShowModal,
}: {
    playlist: PlaylistDetails;
    setShowModal: (value: boolean) => void;
}) => {
    const userId = useSignedInUser()?.id;
    const { refresh } = useUserPlaylists(userId);
    const { isSuccess, message, clearMessage } = useMessageData();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<UpdatePlaylistSchema>({
        resolver,
        mode: 'onChange',
    });
    const onSubmit = async (data: UpdatePlaylistDto): Promise<void> => {
        if (!userId) return;
        await musicService.updatePlaylist(userId, playlist.id, data);
        refresh();
        mutate([playlist.id, userId, 'playlists/id']);
        setShowModal(false);
    };

    return (
        <div className="bg-lightPeach p-4 rounded-16px w-333px lg:w-500px">
            <h1 className="text-lg font-bold">Rediger spillelisten </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Alert
                    isSuccess={isSuccess}
                    message={message}
                    clearMessage={clearMessage}
                />

                <Input
                    label="Navn"
                    name="name"
                    error={errors.name}
                    {...register}
                    placeholder={playlist.name}
                />
                <Input
                    label="Beskrivelse"
                    name="description"
                    error={errors.description}
                    placeholder={playlist.description}
                    {...register}
                />
                <div className="flex items-center mt-8">
                    <Button submit title="Lagre" className="mr-3" />

                    <button
                        className="hover:underline"
                        onClick={() => setShowModal(false)}
                        type="button"
                    >
                        Avbryt
                    </button>
                </div>
            </form>
        </div>
    );
};
