import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from 'src/sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import { Footer, LandingPageHeader, ScrollToTopOnMount } from '../components';
import { Screen } from 'src/view/routes/Router';
import { PortableText } from '@portabletext/react';
import { Post } from 'src/view/hooks/sanity';
import { captureException as reportToSentry } from '@sentry/react';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source?: SanityImageSource) {
    return source ? builder.image(source).quality(85).url() : undefined;
}
interface NewsPostData extends Post {
    authorName: string;
    authorImage: string;
}

export const NewsPost = () => {
    const [newsPost, setNewsPost] = useState<NewsPostData>();
    const { slug } = useParams<Record<string, string>>();

    useEffect(() => {
        sanityClient
            .fetch(
                `*[slug.current == $slug]{
            title,
            slug,
            "mainImage": mainImage.asset -> url,
            body,
            "authorName": author->name,
            "authorImage": author->image
       }`,
                { slug },
            )
            .then(data => setNewsPost(data[0]))
            .catch(reportToSentry);
    }, [slug]);

    const headerColors = {
        textColor: 'woodyBrown',
        activeTextColor: 'mongooseGray',
        backgroundColor: 'lightPeach',
    };
    return (
        <div id="newsPost" className="h-screen">
            <ScrollToTopOnMount />
            <LandingPageHeader
                colors={headerColors}
                scrollItems={false}
                navItems={[{ id: Screen.Mundu, name: 'Hjem' }]}
            />
            {newsPost && (
                <div className="bg-lightPeach pt-32 h-full flex flex-col gap-8">
                    <div className="grow flex justify-center">
                        <div className="prose prose-light sm:prose-sm md:prose-md xl:prose-xl lg:prose-2xl">
                            <h1>{newsPost.title}</h1>
                            <div className="flex items-center not-prose">
                                <img
                                    src={urlFor(newsPost.authorImage)}
                                    className="w-8 h-8 rounded-full"
                                    alt="Author"
                                />
                                <h4 className="cursive flex items-center pl-2 text-md text-mongooseGray">
                                    {newsPost.authorName}
                                </h4>
                            </div>
                            <img
                                className="w-full object-cover aspect-video rounded-2xl"
                                src={urlFor(newsPost.mainImage)}
                                alt=""
                            />

                            <PortableText
                                value={newsPost.body}
                                components={{
                                    types: {
                                        image: ({ value }) => (
                                            <img
                                                className="w-full object-cover aspect-video rounded-2xl"
                                                src={urlFor(value)}
                                                alt=""
                                            />
                                        ),
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <Footer
                        textColor="woodyBrown"
                        backgroundColor="transparent"
                    />
                </div>
            )}
        </div>
    );
};
