import { forwardRef, memo } from 'react';
import { ContentSection } from '..';
import { Footer } from '../../components';
import React, { useEffect } from 'react';

const ContactForm = (props: {
    region: string;
    portalId: string;
    formId: string;
}) => {
    const { region, portalId, formId } = props;
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            if (window.hbspt) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                window.hbspt.forms.create({
                    region: region,
                    portalId: portalId,
                    formId: formId,
                    target: '#hubspotForm',
                });
            }
        });
    }, []);

    return (
        <div>
            <div id="hubspotForm"></div>
        </div>
    );
};

type ContactProps = {
    bgColor?: string;
    id?: string;
};
const Contact = forwardRef<HTMLElement, ContactProps>(
    (
        { bgColor = 'peachOrange', id = ContentSection.Contact }: ContactProps,
        ref,
    ) => (
        <section
            ref={ref}
            id={id}
            className={`grid min-h-screen justify-center relative z-10 bg-${bgColor}`}
        >
            <div className="px-6 sm:px-32 xl:px-72 py-16">
                <div className="prose prose-orange sm:prose-lg md:prose-xl xl:prose-xl xl:max-w-none">
                    <p className="font-bold">
                        Fyll inn din kontaktinformasjon og få beskjed når
                        lanseringsdatoen er klar. I tillegg får du prøve helt
                        gratis i 30 dager!
                    </p>
                    <ContactForm
                        region="eu1"
                        portalId="144729337"
                        formId="cc2346d3-1216-41cb-b604-1e32490d8c55"
                    />
                    <div className="grid xl:grid-cols-5 gap-8">
                        <div className="xl:col-span-5">
                            <br />
                            <h1>Mundu vekker livsgleden!</h1>
                            <p>
                                Et hjelpemiddel for personer med demens, og
                                deres pårørende
                            </p>
                            <p>
                                • Gjenskaper og vekker minner, ved bruk av
                                bilder og musikk
                            </p>
                            <p>
                                • Bedrer kommunikasjon og relasjon til pårørende
                            </p>
                            <p>
                                • Utviklet i samarbeid med Olaviken
                                alderspsykiatriske sykehus
                            </p>
                            <p>
                                Svært gode resultater fra forskningsstudie, og
                                brukertilfredshet på 8/10 
                            </p>
                        </div>
                        <div className="xl:col-span-3 prose prose-orange sm:prose-lg md:prose-lg xl:prose-lg">
                            <p>
                                {'Kontakt: '}
                                <a href="mailto:chi@mundu.no">chi@mundu.no</a>
                            </p>
                            <p className="font-bold">La oss komme i prat!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ),
);
Contact.displayName = 'Contact';

export default Contact;
