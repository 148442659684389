import useSWRInfinite from 'swr/infinite';
import container from 'src/container';
import { ImageSize, Sort } from 'src/api/music.params';

const {
    cradle: { musicService },
} = container;

export const useArtistSearch = (
    query?: string | null,
    sort: Sort = 'popularity desc',
    imageSize: ImageSize = 180,
    pageSize = 5,
) => {
    const { data, size, setSize, mutate, isValidating, error } = useSWRInfinite(
        page =>
            query
                ? ['artist/search', query, sort, imageSize, page + 1, pageSize]
                : null,
        (_, query, sort, imageSize, page, pageSize) =>
            musicService.artistSearch({
                query,
                sort,
                imageSize,
                page,
                pageSize,
            }),
        { revalidateOnFocus: false },
    );
    const artists = data
        ? data
              .map(page => page.searchResults.searchResult)
              .flat()
              .map(result => result.artist)
        : undefined;
    const totalItems = data?.[0].searchResults.totalItems;
    const isLoading = !data && !error;
    const isLoadingNext =
        isValidating ||
        (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.searchResults.searchResult.length === 0;
    const hasMore = !(
        isEmpty ||
        (data &&
            data[data.length - 1]?.searchResults.searchResult.length < pageSize)
    );
    return {
        artists,
        nextPage: () => setSize(size + 1),
        setPageSize: setSize,
        refresh: mutate,
        isLoading,
        isLoadingNext,
        hasMore,
        totalItems,
        isEmpty,
    };
};
