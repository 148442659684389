import { Link } from 'react-router-dom';
import sanityClient from 'src/sanityClient';
import { Post } from 'src/view/hooks/sanity';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
import { Screen } from 'src/view/routes/Router';
import { SanityImageSource } from '@sanity/image-url/lib/types/types';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source?: SanityImageSource) {
    return source ? builder.image(source).quality(85).url() : undefined;
}
export const NewsCard = ({ post }: { post: Post }) => {
    return (
        <Link to={Screen.NewsPosts + post.slug.current} key={post.slug.current}>
            <div className="flex flex-col w-full h-full rounded-2xl overflow-hidden shadow group max-w-md">
                <img
                    className="w-full aspect-video object-cover rounded-t-2xl"
                    src={urlFor(post.mainImage)}
                    alt=""
                />
                <div className="py-4 px-4 md:px-6 text-center ">
                    <h2 className="font-bold  text-left text-sm sm:text-lg md:text-xl group-hover:text-peachOrange transition duration-300 whitespace-nowrap overflow-ellipsis">
                        {post.title}
                    </h2>
                    <div className="text-xs text-left sm:text-sm md:text-base text-ellipsis line-clamp-3">
                        <PortableText value={post.body} />
                    </div>
                </div>
            </div>
        </Link>
    );
};
