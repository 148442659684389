import { forwardRef, memo, useState } from 'react';
import ReactPlayer from 'react-player';
import { MediaIconSVG } from 'src/assets/svg';
import { AZURE_STORAGE_URL } from 'src/config';

type PromoVideoProps = {
    bgColor: string;
    id: string;
};
const PromoVideo = forwardRef<HTMLElement, PromoVideoProps>(
    ({ bgColor = 'woodyBrown', id }: PromoVideoProps, ref) => {
        const shareUrl = `${AZURE_STORAGE_URL}/landingpage-content/share_memory.png`;

        return (
            <section
                ref={ref}
                id={id}
                className={`relative z-10 bg-${bgColor}`}
            >
                <div className="relative w-full h-full pb-thumbHeight">
                    <div className="absolute inset-0 cursor-pointer">
                        <img src={shareUrl} alt="mundu_on_macbook" />
                    </div>
                </div>
            </section>
        );
    },
);
PromoVideo.displayName = 'PromoVideo';

export default PromoVideo;
