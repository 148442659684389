import { SessionFormStoreType } from 'src/view/screens/NewVideo/SessionFormState/interfaces';
import { PlaybackMode } from '../session';

export interface SessionFormTrack {
    id: number;
    releaseId: number;
    artist: string; // name of artist
    title: string;
    duration: number;
    cover: string; // url
}

export interface SessionFormMedia {
    id: string;
    containerType: string;
    description?: string;
}

export interface Recipient {
    id: string;
    profile: {
        firstName: string;
        lastName: string;
        avatar?: string;
    };
}

export interface IncompleteSessionPayload {
    name: string;
    step: number;
    recipient?: Recipient;
    images?: SessionFormMedia[];
    trackSelection?: SessionFormTrack[];
    title?: string;
    playbackMode: PlaybackMode;
    duration?: number;
}

interface IncompleteSessionForm {
    name: string;
    images: SessionFormMedia[];
    trackSelection: SessionFormTrack[];
    title: string;
    recipient: Recipient;
    duration: number;
    step: number;
    playbackMode: PlaybackMode;
}

export interface IncompleteSession {
    createdDate: string;
    updatedDate?: string;
    id: string;
    author: string;
    sessionForm: IncompleteSessionForm;
}

export const incompleteSessionPayload = (
    state: SessionFormStoreType,
): IncompleteSessionPayload => {
    return {
        recipient: state.recipient,
        title: state.title,
        images: state.images,
        trackSelection: state.trackSelection,
        duration: state.duration,
        name: state.name,
        step: state.step,
        playbackMode: state.playbackMode,
    };
};
