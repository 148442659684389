import { useState, useEffect } from 'react';

export function useCursorIsIdle(time = 3000) {
    const [isIdle, setIsIdle] = useState(false);
    useEffect(() => {
        if (isIdle) return;
        const timeout = setTimeout(() => {
            setIsIdle(true);
        }, time);
        return () => clearTimeout(timeout);
    }, [isIdle, time]);

    useEffect(() => {
        const handleMouseMove = () => setIsIdle(false);
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);
    return { isIdle };
}
