import { Post, useNewsPosts } from 'src/view/hooks/sanity';
import { Screen } from 'src/view/routes/Router';
import { Footer, LandingPageHeader, ScrollToTopOnMount } from '../components';
import { NewsCard } from './newsCard';

export const NewsPosts = () => {
    const newsPosts = useNewsPosts();
    const headerColors = {
        textColor: 'woodyBrown',
        activeTextColor: 'mongooseGray',
        backgroundColor: 'lightPeach',
    };
    return (
        <div id="allNews" className="bg-fixed h-screen">
            <ScrollToTopOnMount />
            <LandingPageHeader
                colors={headerColors}
                scrollItems={false}
                navItems={[{ id: Screen.Mundu, name: 'Hjem' }]}
            />
            <div className="bg-lightPeach pt-32 h-full flex flex-col gap-10">
                <div className="grow max-w-max mx-auto">
                    <div className="px-6 md:px-18 grid gap-6 md:gap-8 grid-cols-2 grow">
                        {newsPosts.length !== 0 &&
                            newsPosts.map((post: Post, index: number) => (
                                <NewsCard key={index} post={post} />
                            ))}
                    </div>
                </div>
                <Footer textColor="woodyBrown" backgroundColor="transparent" />
            </div>
        </div>
    );
};
