import { useState, RefObject } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import { HamburgerButton } from '.';
import { ContentSection } from '../landingPage';
import { LogoMunduSVG } from 'src/assets/svg';

type HeaderColors = {
    backgroundColor: string;
    textColor: string;
    activeTextColor: string;
};

type NavItem = {
    id: string;
    name: string;
};
type Props = {
    containerId?: string;
    headerRef?: RefObject<HTMLElement>;
    visible?: boolean;
    colors?: HeaderColors;
    onAnimationEnd?: () => void;
    scrollItems: boolean;
    disableLogin?: boolean;
    navItems?: NavItem[];
};

export const LandingPageHeader = ({
    containerId,
    headerRef,
    onAnimationEnd,
    colors = {
        textColor: 'woodyBrown',
        activeTextColor: 'mongooseGray',
        backgroundColor: 'lightPeach',
    },
    navItems,
    scrollItems,
    disableLogin,
}: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);

    const renderItem = (item: NavItem) => {
        return (
            <li key={item.name}>
                <Link
                    containerId={containerId}
                    to={item.id}
                    href={`#${item.id}`}
                    className={
                        item.id === ContentSection.Contact
                            ? 'bg-peachOrange rounded-lg text-woodyBrown py-2 px-4'
                            : 'hover:underline transition-colors'
                    }
                    activeClass={`text-${colors.activeTextColor} hover:no-underline`}
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                >
                    {item.name}
                </Link>
            </li>
        );
    };

    const renderNavLinks = (item: NavItem) => {
        return (
            <li key={item.name}>
                <NavLink
                    exact
                    to={item.id}
                    className={
                        item.id === ContentSection.Contact
                            ? 'bg-peachOrange rounded-lg text-woodyBrown py-2 px-4'
                            : 'hover:underline transition-colors'
                    }
                >
                    {item.name}
                </NavLink>
            </li>
        );
    };

    const ulClassName = `absolute ${isOpen ? '' : 'hidden'} collapse:flex ${
        colors.backgroundColor === 'transparent' ? 'shadow-none' : 'shadow-sm'
    } collapse:static collapse:shadow-none py-2 px-4 collapse:px-0 top-76.5px right-0 justify-start inline collapse:space-x-4 collapse:bg-transparent bg-${
        colors.backgroundColor
    }`;
    return (
        <header
            ref={headerRef}
            id="header"
            onAnimationEnd={onAnimationEnd}
            className={`animate-header-in collapse:shadow-none appearance-none w-full focus:outline-none focus:shadow-bottom transition-colors h-76.5px collapse:bg-transparent z-50 sticky top-0 flex items-center justify-between py-6 px-4 -mb-76.5px text-${
                colors.textColor
            } bg-${colors.backgroundColor} ${
                colors.backgroundColor === 'transparent'
                    ? 'shadow-none'
                    : 'shadow-sm'
            }`}
        >
            <button onClick={() => scroll.scrollToTop()}>
                <LogoMunduSVG
                    className="fill-current h-6 w-auto"
                    onClick={() => scroll.scrollToTop()}
                />
            </button>
            <nav
                id="navitems"
                className="text-12px sm:text-18px xl:text-20px flex gap-4"
            >
                {scrollItems && (
                    <Link
                        containerId={containerId}
                        to={ContentSection.Contact}
                        href={`#${ContentSection.Contact}`}
                        className={
                            ' bg-peachOrange rounded-lg text-woodyBrown py-2 px-4 hover:bg-opacity-80 transition-all whitespace-nowrap'
                        }
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >
                        Bli kunde
                    </Link>
                )}

                <ul className={ulClassName}>
                    {scrollItems
                        ? navItems?.map(item => renderItem(item))
                        : navItems?.map(item => renderNavLinks(item))}
                    {disableLogin != true && (
                        <li key="jazz" id="login">
                            <NavLink
                                to={'/login'}
                                className="hover:underline whitespace-nowrap"
                            >
                                Logg inn
                            </NavLink>
                        </li>
                    )}
                </ul>
                <HamburgerButton onClick={toggleDropdown} />
            </nav>
        </header>
    );
};
