type FadeImageProps = {
    source: string | undefined;
    fadeDuration?: number;
    startAnimation: boolean;
    fade: 'fadein' | 'fadeout';
};

export const FadeImage = ({
    source,
    fadeDuration = 3,
    startAnimation,
    fade,
}: FadeImageProps) => {
    return (
        <div
            className="absolute w-full h-full"
            style={{
                WebkitAnimationFillMode: 'forwards',
                animationName: `${fade}`,
                animationDuration: `${fadeDuration}s`,
                animationPlayState: startAnimation ? 'running' : 'paused',
            }}
        >
            <img
                src={source}
                className={`absolute w-full h-full object-contain`}
                alt="video_demo"
            />
        </div>
    );
};
