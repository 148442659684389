import { NextOfKin } from 'src/domain/next-of-kin';
import { getFullName } from 'src/domain/user';
import { formatPhoneNumber } from 'src/utils/stringUtils';
import { ConfirmationButton } from 'src/components/Modals/ConfirmationModal/ConfirmationButton';
import ConfimationModalProvider from 'src/components/Modals/ConfirmationModal/ConfirmationModalProvider';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { PhoneIcon, MessageIcon } from 'src/components/Icons/icons';
import { useSignInIdentities } from 'src/view/hooks/useSignInIdentities';

type Props = {
    item: NextOfKin;
    onDelete: () => void;
};

export const CardNextOfKin = ({ item, onDelete }: Props) => {
    const { email } = item;
    const { phoneNumber } = useSignInIdentities(item.id);
    const deleteMessage = 'Er du sikker på at du vil slette denne kontakten?';

    return (
        <div className="flex flex-col justify-between bg-starkWhite w-60 h-36 px-4 pt-2 pb-3 rounded-xl">
            <div className="flex flex-col">
                <p className="line-clamp-1 text-lg">{getFullName(item)}</p>
                <div className="text-[12px] -ml-1">
                    <span className="flex items-center">
                        <TextIcon SVG={PhoneIcon} />
                        {phoneNumber && formatPhoneNumber(phoneNumber)}
                    </span>

                    <span className="flex items-center">
                        <TextIcon SVG={MessageIcon} />
                        <p className="break-all">{email || ''}</p>
                    </span>
                </div>
            </div>
            <div>
                <ConfimationModalProvider message={deleteMessage}>
                    <ConfirmationButton
                        className="mr-2 primary-btn-small px-3 py-1 text-sm bg-starkWhite hover:bg-woodyBrown"
                        onClick={onDelete}
                    >
                        Slett
                    </ConfirmationButton>
                </ConfimationModalProvider>
            </div>
        </div>
    );
};
