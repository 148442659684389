import { useEffect, useState } from 'react';
import { CardGrid } from 'src/components/AppScreen/AppLayout';
import Card from 'src/components/Card';
import { PlusIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { useNotification } from 'src/components/Notification/useNotfication';
import container from 'src/container';
import { Consumer } from 'src/domain/consumer';
import { LicenceEntity } from 'src/domain/organization';
import { useConsumers } from 'src/view/hooks';
import { useAvatarFetch } from 'src/view/hooks/useImageFetch';

import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { ModalLimitReached } from '../MyClients/list/components/ModalLimitReached';

const {
    cradle: { munduClient },
} = container;

export const Users = () => {
    const { dispatch } = useCurrentConsumerContext();
    const [licence, setLicence] = useState<LicenceEntity | undefined>();
    const [isReached, setIsReached] = useState<boolean>(true);
    const [isShowModalReached, setIsShowModalReached] =
        useState<boolean>(false);
    const notify = useNotification();

    const { consumers, isError } = useConsumers();

    useEffect(() => {
        dispatch({ type: 'RESET' });
        (async (): Promise<void> => {
            const licence = await munduClient.getNumberOfLicences();
            setLicence(licence);
        })();
    }, [dispatch]);

    useEffect(() => {
        if (!licence) return;
        const isReached = licence.total === licence.active;
        setIsReached(isReached);
    }, [licence]);

    useEffect(() => {
        if (isError) {
            notify(isError.message, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    const getAvatar = useAvatarFetch();

    const onClick = (consumer: Consumer) => {
        return dispatch({
            type: 'SELECT_CONSUMER',
            payload: consumer,
        });
    };

    return (
        <>
            {isShowModalReached && (
                <ModalLimitReached
                    onCancel={(): void => {
                        setIsShowModalReached(false);
                    }}
                />
            )}
            <div className="h-full w-full relative  text-woodyBrown">
                <CardGrid>
                    <Card
                        to={'/app/users/new'}
                        label="Ny bruker"
                        shape="circle"
                        disabled={isReached}
                    >
                        <TextIcon SVG={PlusIcon} />
                    </Card>

                    {consumers.map(
                        (
                            { id, profile: { avatar, firstName, lastName } },
                            index,
                        ) => (
                            <Card
                                fetchImage={() => getAvatar(avatar)}
                                key={id}
                                to={`/app/users/profile/${id}`}
                                onClick={() => onClick(consumers[index])}
                                label={`${firstName} ${lastName}`}
                                shape="circle"
                            >
                                <img id={avatar} alt="consumer-avatar" />
                            </Card>
                        ),
                    )}
                </CardGrid>
            </div>
        </>
    );
};
