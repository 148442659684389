import { useState, ChangeEvent } from 'react';
import {
    useForm,
    FieldError,
    UseFormRegister,
    UseFormSetValue,
} from 'react-hook-form';
import container from 'src/container';
import { ContactFormPayload } from 'src/domain/contact';
import { ContactFormInfo } from 'src/domain/contact/schema';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { Alert } from 'src/components/Alert';
import { useNotification } from 'src/components/Notification/useNotfication';

type TextInputProps = {
    label: string;
    name: string;
    placeholder?: string;
    error?: FieldError;
    errorMessage?: string;
    register: UseFormRegister<any>;
    setValue?: UseFormSetValue<any>;
    formatText?: (text: string) => string;
    value?: string;
};

const PhoneNumberInput = ({
    label,
    name,
    error,
    register,
    placeholder,
    setValue,
}: TextInputProps) => {
    const [text, setText] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        setText(formatPhoneNumber(e.target.value));
        setPhoneNumber(deformat(e.target.value));

        setValue &&
            setValue('phoneNumber', deformat(e.target.value), {
                shouldValidate: true,
            });
    };

    const deformat = (text: string) => {
        return text.replace(/[^\d]/g, '');
    };

    const formatPhoneNumber = (value: string) => {
        if (!value) return '';
        const phoneNumber = deformat(value);
        const phoneNumberLength = phoneNumber.length;

        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 6) {
            return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
        }

        return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(
            3,
            5,
        )} ${phoneNumber.slice(5, 8)}`;
    };

    return (
        <div className="mb-2">
            <div className="flex justify-between items-center mb-1">
                <label className="block">{label}</label>
                {error && (
                    <Alert
                        className="text-10px bg-peachOrange fill-peachOrange inline-flex"
                        isSuccess={false}
                        message={error.message}
                        canClose={false}
                    />
                )}
            </div>
            <input
                {...register(name)}
                className="hidden"
                value={phoneNumber}
                placeholder={placeholder}
            />
            <input
                name="display"
                className={`${
                    error ? 'border-venetianRed' : ' '
                } shadow-sm placeholder-starkWhite border rounded-12px focus:text-peachOrange appearance-none w-full py-2 px-3 leading-tight`}
                onChange={handleOnChange}
                value={text}
                placeholder={placeholder}
            />
        </div>
    );
};

const TextInput = ({
    label,
    name,
    error,
    register,
    value,
    placeholder,
}: TextInputProps) => {
    return (
        <div className="mb-2">
            <div className="flex justify-between items-center mb-1">
                <label className="block">{label}</label>
                {error && (
                    <Alert
                        className="text-10px bg-peachOrange fill-peachOrange inline-flex"
                        isSuccess={false}
                        message={error.message}
                        canClose={false}
                    />
                )}
            </div>
            <input
                className="shadow-sm placeholder-starkWhite border rounded-12px focus:text-peachOrange appearance-none w-full py-2 px-3 leading-tight"
                {...register(name)}
                value={value}
                placeholder={placeholder}
            />
        </div>
    );
};

const TextArea = ({
    label,
    name,
    error,
    register,
    placeholder,
}: TextInputProps) => {
    return (
        <div className="mb-2">
            <div className="flex justify-between items-center mb-1">
                <label className="block">{label}</label>
                {error && (
                    <Alert
                        className="text-10px bg-peachOrange fill-peachOrange inline-flex"
                        isSuccess={false}
                        message={error.message}
                        canClose={false}
                    />
                )}
            </div>
            <textarea
                className={`${
                    error ? 'border-venetianRed' : ' '
                } shadow-sm placeholder-starkWhite border text-white focus:text-peachOrange rounded-12px appearance-none  w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline`}
                rows={3}
                cols={53}
                {...register(name)}
                placeholder={placeholder}
            />
        </div>
    );
};

const {
    cradle: { munduClient },
} = container;
const resolver = classValidatorResolver(ContactFormInfo);

//TODO: Refactor to use new textinput components with forwardref
export const ContactForm = () => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm<ContactFormPayload>({
        resolver,
        mode: 'onChange',
    });

    const notify = useNotification();

    const onSubmit = async (data: ContactFormPayload): Promise<void> => {
        try {
            await munduClient.contact(data);
            notify('Epost sendt, takk for din interesse for Mundu!');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return (
        <form
            className="bg-lightPeach  shadow-xl rounded-30px my-4 px-8 pt-6 pb-4"
            onSubmit={handleSubmit(onSubmit)}
        >
            <TextInput
                name="name"
                label="Navn*"
                error={errors.name}
                register={register}
                placeholder="Kari Nordmann"
            />
            <TextInput
                name="work"
                label="Arbeidssted*"
                error={errors.work}
                register={register}
                placeholder="Hvor jobber du?"
            />
            <TextInput
                name="email"
                label="Epost*"
                register={register}
                error={errors.email}
                placeholder="min@epost.no"
            />
            <PhoneNumberInput
                name="phoneNumber"
                label="Telefonnummer*"
                error={errors.phoneNumber}
                register={register}
                placeholder="xxx xx xxx"
                setValue={setValue}
            />
            <TextInput
                name="subject"
                label="Emne*"
                register={register}
                error={errors.subject}
                placeholder="Emne"
            />
            <TextArea
                name="message"
                label="Melding*"
                register={register}
                error={errors.message}
                placeholder="Jeg ønsker å få vite mer om Mundu..."
            />
            <button className="btn-lightPeach my-4" type="submit">
                Send
            </button>
        </form>
    );
};
