import { useState } from 'react';
import { PauseIconSVG, MediaIconSVG } from 'src/assets/svg';
import { formatDuration } from 'src/utils/stringUtils';
import { useAudioPlayerContext } from '../../../audioPlayerReducer';
import { NextIcon } from './nextIcon';
import { PrevIcon } from './prevIcon';

type Props = {
    onScrub: (value: number) => void;
    onScrubEnd: () => void;
    trackProgress: number;
};

export const AudioPlayerControls = ({
    onScrub,
    onScrubEnd,
    trackProgress,
}: Props) => {
    const [toggleDisabled, setToggleDisabled] = useState(false);
    const {
        state: {
            currentTrack: track,
            unavailableTracks,
            isPlaying,
            index,
            queue,
        },
        dispatch,
    } = useAudioPlayerContext();

    const isUnavailable = track ? unavailableTracks.includes(track.id) : false;
    const elapsedTime = Math.round(trackProgress);
    const currentPercentage = track
        ? `${(trackProgress / track.duration) * 100}%`
        : '0%';
    const trackStyling = `
	  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #FFF3EB), color-stop(${currentPercentage}, #34202b))
	`;

    const disabled = isUnavailable || queue.isEmpty();

    const onClickWrapper = (onClick: () => void) => {
        setToggleDisabled(true);
        onClick();
        setTimeout(() => setToggleDisabled(false), 500);
    };
    return (
        <div
            className={`col-span-6 flex justify-center items-center ${
                disabled && 'opacity-80'
            }`}
        >
            <div className="flex-col w-full space-y-4">
                <div className="flex justify-center items-center space-x-6 px-16 ">
                    <button
                        type="button"
                        className={'w-3 h-3 flex-shrink-0'}
                        aria-label="Previous"
                        onClick={() =>
                            onClickWrapper(() => dispatch({ type: 'PREV' }))
                        }
                        disabled={disabled || index < 0}
                    >
                        <PrevIcon />
                    </button>
                    {isPlaying && !isUnavailable ? (
                        <button
                            id="pause"
                            type="button"
                            className="rounded-full bg-lightPeach w-10 h-10 justify-center"
                            onClick={() =>
                                onClickWrapper(() =>
                                    dispatch({ type: 'TOGGLE_PLAY' }),
                                )
                            }
                            disabled={toggleDisabled}
                            aria-label="Pause"
                        >
                            <PauseIconSVG className="mx-auto fill-current text-woodyBrown w-4 h-4" />
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="play fill-lightPeach "
                            onClick={() =>
                                onClickWrapper(() =>
                                    dispatch({ type: 'TOGGLE_PLAY' }),
                                )
                            }
                            disabled={
                                isUnavailable ||
                                track === undefined ||
                                toggleDisabled
                            }
                            aria-label="Play"
                        >
                            <MediaIconSVG className="w-10 h-10" />
                        </button>
                    )}
                    <button
                        type="button"
                        className="w-3 h-3 flex-shrink-0"
                        aria-label="Next"
                        disabled={index >= queue.size() || disabled}
                        onClick={() =>
                            onClickWrapper(() => dispatch({ type: 'NEXT' }))
                        }
                    >
                        <NextIcon />
                    </button>
                </div>
                <div className="flex justify-center items-center space-x-2 px-4">
                    {track ? (
                        <p className="text-xs w-8 ">
                            {formatDuration(elapsedTime)}
                        </p>
                    ) : (
                        <p className="text-xs w-8">00:00</p>
                    )}

                    <input
                        id="progress_bar"
                        type="range"
                        value={trackProgress}
                        step="1"
                        min="0"
                        disabled={isUnavailable || track === undefined}
                        max={track ? track.duration : undefined}
                        className="flex w-full max-w-lg min-w-[184px]"
                        onChange={e => onScrub(Number(e.target.value))}
                        onMouseUp={onScrubEnd}
                        onKeyUp={onScrubEnd}
                        onTouchEnd={onScrubEnd}
                        style={{ background: trackStyling }}
                    />
                    {track ? (
                        <p className="text-xs w-8">
                            {'-' + formatDuration(track.duration - elapsedTime)}
                        </p>
                    ) : (
                        <p className="text-xs w-8">00:00</p>
                    )}
                </div>
            </div>
        </div>
    );
};
