export const ReleaseDateIcon = () => {
    return (
        <svg viewBox="0 0 100 100">
            <g className="stroke-current">
                <rect
                    rx="4"
                    y="4"
                    width="94"
                    height="94"
                    strokeWidth="6"
                    fill="none"
                />
                <line
                    x1="25"
                    y1="0"
                    x2="25"
                    y2="8"
                    strokeWidth="6"
                    strokeLinecap="round"
                />
                <line
                    x1="0"
                    y1="25"
                    x2="94"
                    y2="25"
                    strokeWidth="6"
                    strokeLinecap="round"
                />
                <line
                    x1="75"
                    y1="0"
                    x2="75"
                    y2="8"
                    strokeWidth="6"
                    strokeLinecap="round"
                />
            </g>
        </svg>
    );
};
