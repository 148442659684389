import { useState } from 'react';
import { CardGrid } from 'src/components/AppScreen/AppLayout';
import Card from 'src/components/Card';
import { PlusIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { ScrollableModal } from 'src/components/Modals/ScrollableModal';
import { Consumer } from 'src/domain/consumer';
import { CardUserLoading } from 'src/view/components/loading/skeleton/CardUserLoading';
import { useConsumersFollowers } from 'src/view/hooks/creator';
import { useAvatarFetch } from 'src/view/hooks/useImageFetch';
import { AssignCreator } from './AssignCreator';

export const CreatorsList = ({ consumer }: { consumer: Consumer }) => {
    const { data: creators } = useConsumersFollowers(consumer.id);
    const [assigningCreator, setAssigningCreator] = useState(false);
    const getAvatar = useAvatarFetch();

    return (
        <div className="h-full w-full relative text-woodyBrown">
            <ScrollableModal
                visible={assigningCreator}
                onClose={() => setAssigningCreator(false)}
            >
                <AssignCreator assignTo={consumer} />
            </ScrollableModal>
            <CardGrid>
                <Card
                    onClick={() => setAssigningCreator(true)}
                    label="Legg til / fjern"
                    shape="circle"
                >
                    <TextIcon SVG={PlusIcon} />
                </Card>
                {creators ? (
                    creators.map(
                        ({ id, profile: { avatar, firstName, lastName } }) => (
                            <Card
                                fetchImage={() => getAvatar(avatar)}
                                key={id}
                                to={`/app/creators/profile/${id}`}
                                label={`${firstName} ${lastName}`}
                                shape="circle"
                            >
                                <img id={avatar} alt="consumer-avatar" />
                            </Card>
                        ),
                    )
                ) : (
                    <>
                        <CardUserLoading />
                        <CardUserLoading opacity={0.75} />
                        <CardUserLoading opacity={0.5} />
                    </>
                )}
            </CardGrid>
        </div>
    );
};
