import { createContext, useContext, useRef, useState } from 'react';
import { AppModal } from 'src/components/Modals/AppModal';
import cx from 'classnames';
type Props = {
    children: React.ReactNode;
    message: string;
    title?: string;
};

const ConfimationModalProvider = ({ children, message, title }: Props) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const resolver = useRef<any | null>(null);

    const handleShow = (): Promise<unknown> => {
        setShowConfirmationModal(true);

        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    };

    const handleOk = () => {
        resolver.current && resolver.current(true);
        setShowConfirmationModal(false);
    };

    const handleCancel = () => {
        resolver.current && resolver.current(false);
        setShowConfirmationModal(false);
    };

    return (
        <ConfirmationModalContext.Provider
            value={{ showConfirmation: handleShow }}
        >
            {children}
            <div
                className={cx(
                    ' fixed z-50 inset-0 overflow-y-auto bg-black bg-opacity-75 transition-opacity',
                    !showConfirmationModal && 'hidden',
                )}
            >
                <AppModal
                    show={showConfirmationModal}
                    clickOutside={() => setShowConfirmationModal(false)}
                >
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-lightPeach rounded-30px px-20 py-16 text-woodyBrown flex flex-col gap-2">
                        <p className="text-lg font-semibold">{title}</p>
                        <p>{message}</p>
                        <div className="mt-4 text-sm">
                            <button
                                className="px-4 py-1 rounded-full outline-none focus:outline-none border-1.6px border-woodyBrown mr-3 hover:bg-woodyBrown hover:text-white transition duration-200"
                                onClick={handleOk}
                            >
                                Bekreft
                            </button>
                            <button
                                className="outline-none focus:outline-none"
                                onClick={handleCancel}
                            >
                                Angre
                            </button>
                        </div>
                    </div>
                </AppModal>
            </div>
        </ConfirmationModalContext.Provider>
    );
};

const ConfirmationModalContext = createContext<{
    showConfirmation: () => Promise<unknown>;
}>({
    showConfirmation: () => Promise.resolve(() => false),
});

export const useConfirmationModalContext = () =>
    useContext(ConfirmationModalContext);
export default ConfimationModalProvider;
