import useSWRInfinite from 'swr/infinite';
import container from 'src/container';
import { ImageSize } from 'src/api/music.params';

const {
    cradle: { musicService },
} = container;

export const useTrackSearch = (
    query?: string | null,
    pageSize = 5,
    imageSize: ImageSize = 180,
) => {
    const { data, size, setSize, mutate, error, isValidating } = useSWRInfinite(
        page =>
            query
                ? ['track/search', query, imageSize, page + 1, pageSize]
                : null,
        (_, query, imageSize, page: number, pageSize) =>
            musicService.trackSearch({
                query,
                imageSize,
                page,
                pageSize,
            }),
        { revalidateOnFocus: false },
    );

    const tracks = data
        ? data
              .map(page => page.searchResults.searchResult)
              .flat()
              .map(result => result.track)
        : undefined;

    const isLoading = !data && !error;
    const isLoadingNext =
        isValidating ||
        (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.searchResults.searchResult.length === 0;
    const hasMore = !(
        isEmpty ||
        (data &&
            data[data.length - 1]?.searchResults.searchResult.length < pageSize)
    );

    return {
        tracks,
        nextPage: () => setSize(size + 1),
        setPageSize: setSize,
        refresh: mutate,
        isLoading,
        isLoadingNext,
        error,
        hasMore,
        isEmpty,
    };
};
