import * as React from 'react';
import { OutboundLinkIcon } from './outboundLinkIcon';

type Props = {
    href: string;
    children: React.ReactNode;
    iconSize?: number;
};
export const OutBoundLink = ({ href, children, iconSize = 16 }: Props) => {
    return (
        <a
            className="inline-flex"
            href={href}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
            <span className="pl-0.5 self-center">
                <OutboundLinkIcon size={iconSize} />
            </span>
        </a>
    );
};
