import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch } from 'react-redux';
import container from 'src/container';
import { useIsMountedRef } from 'src/view/hooks/useIsMountedRef';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { useSessionForm } from '../../SessionFormState/hooks';
import { SessionFormStoreType } from '../../SessionFormState/interfaces';
import { Controls } from './Controls';
import { ImagePair } from './imagePair';
import { useTimer } from './Timer';
import { reportSubscriberPayload } from 'src/domain/music';
import { PlaybackMode } from 'src/domain/session';
import { SASInfo } from 'src/domain/user';
import { MediaIconSVG } from 'src/assets/svg';
import { useNotification } from 'src/components/Notification/useNotfication';
import { DEFAULT_VOLUME } from 'src/config';
import { formatDuration } from 'src/utils/stringUtils';
import { SessionFormTrack } from 'src/domain/sessionForm';
import {
    useCommonMediaSas,
    usePersonalMediaSas,
} from 'src/view/hooks/consumer';

const {
    cradle: { musicService, storageService },
} = container;

type Slideshow = {
    imageUrls: (string | undefined)[];
    imageDuration: number;
    maxImageIndex: number;
    playbackMode: PlaybackMode;
    tracks: SessionFormTrack[];
    duration: number;
};
const prepareSlideshow = ({
    commonMediaSAS,
    personalMediaSAS,
    state,
}: {
    commonMediaSAS?: SASInfo;
    personalMediaSAS?: SASInfo;
    state: SessionFormStoreType;
}): Slideshow => {
    const {
        imageDuration: _imageDuration,
        playbackMode,
        duration,
        trackSelection: tracks,
        images,
    } = state;

    const imageUrls = images.map(({ id, containerType }) => {
        return storageService.getImageUrl(
            id,
            containerType === 'pm' ? personalMediaSAS : commonMediaSAS,
        );
    });

    const numberOfImages =
        playbackMode === PlaybackMode.LOOP
            ? // If LOOP-mode,:
              //
              // Round to whatever nearest integer that yields:
              // 1. The imageduration that best approximates the one selected by the user,
              // 2. A total Playtime exactly equal to the total duration of the music.
              Math.round(duration / _imageDuration)
            : // IF NORMAL-mode, we play all the images once!
              images.length;

    const imageDuration = duration / numberOfImages;
    const maxImageIndex = numberOfImages - 1;

    return {
        imageUrls,
        imageDuration,
        maxImageIndex,
        playbackMode,
        tracks,
        duration,
    };
};

type SlideshowPlayerProps = Slideshow & { onEnd: () => void };
const SlideShowPlayer = ({
    imageUrls,
    imageDuration,
    maxImageIndex,
    playbackMode,
    tracks,
    duration,
    onEnd,
}: SlideshowPlayerProps) => {
    const dispatch = useDispatch();
    const notify = useNotification();
    const audioRef = useRef<HTMLAudioElement>(new Audio());
    const user = useSignedInUser();
    const userId = user?.id;
    const fallbackStreamRequested = useRef(false);
    const mountedRef = useIsMountedRef();
    audioRef.current.volume = DEFAULT_VOLUME;
    const [isPlaying, setIsPlaying] = useState<boolean>(true);
    const [trackIndex, setTrackIndex] = useState(0);
    const [isReady, setIsReady] = useState(false);
    const currentTime = useTimer(audioRef.current);
    const imageIndex = useRef(0);

    if (currentTime - imageDuration * (imageIndex.current + 1) >= 0) {
        if (imageIndex.current !== maxImageIndex) {
            imageIndex.current = imageIndex.current + 1;
        }
    }
    const shouldStartAnimation =
        currentTime - imageDuration * (imageIndex.current + 1) >= -3 &&
        isPlaying; // start fade animation 3 seconds before the next image

    const getNextImage = () => {
        if (imageIndex.current === maxImageIndex) return undefined;
        if (playbackMode === PlaybackMode.LOOP)
            return imageUrls[(imageIndex.current + 1) % imageUrls.length];
        return imageUrls[imageIndex.current + 1];
    };

    const getCurrentImage = () => {
        if (playbackMode === PlaybackMode.LOOP)
            return imageUrls[imageIndex.current % imageUrls.length];
        return imageUrls[imageIndex.current];
    };

    const togglePlayback = (): void => {
        setIsPlaying(prev => !prev);
    };

    useEffect(() => {
        if (!tracks[trackIndex]) {
            onEnd();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackIndex, onEnd]);

    // Perform setup when currentTrack changes
    useLayoutEffect(() => {
        (async () => {
            if (!tracks[trackIndex] || !userId) return;
            setIsReady(false);
            fallbackStreamRequested.current = false;
            audioRef.current.preload = 'none';
            audioRef.current.src = await musicService.getSubscriberStream(
                userId,
                tracks[trackIndex].id,
            );
            audioRef.current.load();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackIndex, userId]);

    useEffect(() => {
        if (!isReady) return;
        if (isPlaying) {
            audioRef.current.play();
        } else {
            audioRef.current.pause();
        }
    }, [isPlaying, isReady]);

    const reportStream = useCallback(
        async (track: SessionFormTrack): Promise<void> => {
            try {
                if (!userId) return;
                const payload = reportSubscriberPayload(
                    track.id,
                    track.releaseId,
                    Math.min(audioRef.current.currentTime, track.duration),
                    fallbackStreamRequested.current ? 33 : undefined,
                );

                await musicService.reportSubscriberStream(userId, payload);
            } catch (e) {
                if (mountedRef.current && e instanceof Error)
                    notify(e.message, true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userId],
    );

    const onLoadedTrack = () => setIsReady(true);

    // send report preview video to 7digital
    useEffect(() => {
        if (!tracks[trackIndex]) return;
        const currentAudio = audioRef.current;
        return (): void => {
            currentAudio.pause();
            reportStream(tracks[trackIndex]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportStream, trackIndex]);

    useEffect(() => {
        const audio = audioRef.current;
        if (!userId) return;
        const nextTrack = () => {
            setTrackIndex(prev => prev + 1);
        };

        const onSrcNotSupported = async (e: MediaError) => {
            if (!userId) return;
            if (fallbackStreamRequested.current) {
                notify(
                    `Kunne ikke spille av ${tracks[trackIndex].title}. Feilmelding: ${e.message}`,
                    true,
                );
                dispatch({
                    type: 'ADD_UNAVAILABLE',
                    payload: tracks[trackIndex].id,
                });
                dispatch({
                    type: 'SET_IS_PLAYING',
                    payload: false,
                });
            } else {
                const src = await musicService.getSubscriberStreamIn320(
                    userId,
                    tracks[trackIndex].id,
                );
                fallbackStreamRequested.current = true;
                audioRef.current.src = src;
                audioRef.current.load();
            }
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onError = async (e: any) => {
            //TODO: Test that this works
            const { error } = e.target;
            switch (error.code) {
                //TODO: properly handle all errors
                case MediaError.MEDIA_ERR_ABORTED:
                    alert('You aborted the media playback.');
                    break;
                case MediaError.MEDIA_ERR_NETWORK:
                    alert('A network error caused the media download to fail.');
                    break;
                case MediaError.MEDIA_ERR_DECODE:
                    alert(
                        'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.',
                    );
                    break;
                case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
                    onSrcNotSupported(error);
                    break;
                default:
                    alert('An unknown media error occurred.');
            }
        };
        audio.addEventListener('loadeddata', onLoadedTrack);
        audio.addEventListener('error', onError);
        audio.addEventListener('ended', nextTrack);
        return () => {
            audio.removeEventListener('loadeddata', onLoadedTrack);
            audio.removeEventListener('error', onError);
            audio.removeEventListener('ended', nextTrack);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackIndex, userId]);

    return (
        <>
            <ImagePair
                startAnimation={shouldStartAnimation}
                key={imageIndex.current}
                images={{
                    next: getNextImage(),
                    current: getCurrentImage(),
                }}
            />
            <div
                style={{ height: '56px', width: '220px' }}
                className="absolute bottom-0 bg-starkWhite rounded-full mb-4 md:mb-8 left-1/2 transform -translate-x-1/2 px-6"
            >
                <div className="relative w-full h-full  flex items-center justify-between">
                    <Controls
                        audioElement={audioRef.current}
                        toggleSlideshow={togglePlayback}
                        isPlay={isPlaying}
                    />
                    <span className="absolute right-0">
                        {formatDuration(currentTime)}
                        &nbsp;/&nbsp;
                        {formatDuration(Math.round(duration))}
                    </span>
                </div>
            </div>
        </>
    );
};

export const PreviewVideo = () => {
    const [playPressed, setPlayPressed] = useState<boolean>(false);
    const [slideshow, setSlideshow] = useState<Slideshow>();

    const { state } = useSessionForm();
    const { images, recipient } = state;
    const { sas: commonMediaSAS } = useCommonMediaSas();
    const { sas: personalMediaSAS } = usePersonalMediaSas(recipient?.id);

    useEffect(() => {
        if (playPressed) {
            (async () => {
                setSlideshow(
                    prepareSlideshow({
                        state,
                        commonMediaSAS,
                        personalMediaSAS,
                    }),
                );
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playPressed]);

    return (
        <div className="aspect-video bg-woodyBrown rounded-xl max-w-2xl overflow-hidden mx-auto relative">
            {!playPressed && (
                <>
                    <img
                        src={storageService.getImageUrl(
                            images[0].id,
                            images[0].containerType === 'pm'
                                ? personalMediaSAS
                                : commonMediaSAS,
                        )}
                        className="absolute w-full h-full object-contain"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <button
                            onClick={() => setPlayPressed(true)}
                            className="outline-none focus:outline-none"
                            type="button"
                        >
                            <MediaIconSVG className="lg:w-32 lg:h-32 md:w-24 md:h-24 w-16 h-16" />
                        </button>
                    </div>
                </>
            )}
            {slideshow && playPressed && (
                <SlideShowPlayer
                    {...slideshow}
                    onEnd={() => setPlayPressed(false)}
                />
            )}
        </div>
    );
};
