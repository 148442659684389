import { ReactNode, useEffect, useState } from 'react';

type ToggleButtonProps = {
    children: ReactNode;
    active?: boolean;
    disabled?: boolean;
    onClick?: () => void;
};

export const ToggleButton: React.FC<ToggleButtonProps> = ({
    children,
    active = false,
    disabled,
    onClick,
}: ToggleButtonProps) => {
    const [isActive, setIsActive] = useState(active);

    useEffect(() => {
        setIsActive(active);
    }, [active]);

    const handleOnClick = () => {
        if (!onClick) return;
        onClick();
        setIsActive(!isActive);
    };
    return (
        <button
            defaultChecked={isActive}
            className={`secondary-btn-small relative z-20 ${
                isActive && 'bg-woodyBrown text-lightPeach'
            }`}
            disabled={disabled}
            onClick={handleOnClick}
        >
            {children}
        </button>
    );
};
