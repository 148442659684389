import { usePartnerPlaylists } from 'src/view/hooks/swr/usePartnerPlaylists';
import { PlaylistList } from '../components/Playlist/list';
import { usePaginateIfVisible } from 'src/view/hooks/usePaginateIfVisible';
import { SpinnerIconSVG } from 'src/assets/svg';

export const DiscoverView = () => {
    const { playlists, nextPage, hasMore, isLoadingNext, isLoading, isEmpty } =
        usePartnerPlaylists();

    const PaginateIfVisible = usePaginateIfVisible(nextPage);
    return (
        <div className="space-y-4">
            {!isLoading && !isEmpty && <PlaylistList playlists={playlists} />}
            {isLoadingNext && hasMore && (
                <div className="grid justify-center">
                    <SpinnerIconSVG className="animate-spin h-8 w-8 text-woodyBrown" />
                </div>
            )}
            {!isLoadingNext && hasMore && <PaginateIfVisible />}
        </div>
    );
};
