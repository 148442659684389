import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Buttons/Button';
import { updateStep } from 'src/view/screens/NewVideo/SessionFormState/actions';
import { useSessionForm } from 'src/view/screens/NewVideo/SessionFormState/hooks';

const STEPS = [
    { name: 'Musikk', step: 1 },
    { name: 'Bilder', step: 2 },
    { name: 'Video', step: 3 },
];
export const HeaderCenterNewVideo = () => {
    const history = useHistory();
    const { state, dispatch } = useSessionForm();

    const onSelectStep = (step: number): void => {
        dispatch(updateStep(step));
        history.push(`/app/new-video/step${step}`);
    };
    const { trackSelection: tracks, images, imageDuration, title } = state;
    const stepOneComplete = tracks.length > 0;
    const stepTwoComplete = images.length > 0 && imageDuration > 5;
    const stepThreeComplete = title.length > 0;

    const firstIncomplete = !stepOneComplete
        ? 1
        : !stepTwoComplete
        ? 2
        : !stepThreeComplete
        ? 3
        : 4;

    return (
        <div className="flex gap-1 md:gap-3 sm:gap-2">
            {STEPS.map(item => {
                return (
                    <Button
                        key={item.step}
                        title={`${item.step}. ${item.name}`}
                        active={item.step === state.step}
                        disabled={item.step > firstIncomplete}
                        onClick={() => onSelectStep(item.step)}
                    />
                );
            })}
        </div>
    );
};
