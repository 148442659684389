import container from 'src/container';
import useSWR from 'swr';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { UserRole } from 'src/domain/user';

const {
    cradle: { munduClient },
} = container;

export const useConsumers = () => {
    // TOOD FIX
    const user = useSignedInUser();
    const userId = user?.id;
    const isCreator = user?.role === UserRole.creator;

    const { data, error, mutate } = useSWR(userId ? 'consumers' : null, () =>
        munduClient.listConsumers(
            isCreator ? { creatorId: userId } : { organizationId: userId },
        ),
    );

    return {
        consumers: data || [],
        mutate: mutate,
        isLoading: !error && !data,
        isError: error,
    };
};
