import { useState } from 'react';
import container from 'src/container';
import { Media } from 'src/domain/personalMedia';
import { usePersonalMediaSas } from 'src/view/hooks/consumer';
import { ButtonRemoveMedia } from '../../components/ButtonRemoveMedia';
import { useSessionFormState } from '../../SessionFormState/hooks';

type Props = {
    item: Media;
    onRemove: (id: string) => void;
};

const {
    cradle: { storageService },
} = container;

export const CardPicture = ({ item, onRemove }: Props) => {
    const { recipient } = useSessionFormState();
    const { sas } = usePersonalMediaSas(recipient?.id);
    const [errorFetchingImage, setErrorFetchingImage] = useState(false);
    const handleError = () => {
        setErrorFetchingImage(true);
    };
    return (
        <div className="relative w-32 h-32 xl:h-36 xl:w-36" key={item.id}>
            {errorFetchingImage ? (
                <div className="absolute inset-0 flex items-center justify-center text-center bg-woodyBrown text-lightPeach rounded-xl xl:rounded-2xl">
                    Bildet ser ut til å ha blitt slettet!
                </div>
            ) : (
                <img
                    src={storageService.getImageUrl(
                        item.thumbnail ? item.thumbnail.id : item.id,
                        sas,
                    )}
                    onError={handleError}
                    className="cursor-pointer h-full w-full rounded-xl xl:rounded-2xl object-cover"
                />
            )}
            <ButtonRemoveMedia onClick={(): void => onRemove(item.id)} />
        </div>
    );
};
