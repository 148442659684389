import { useIsAuthenticated } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import { LogoMunduSVG } from 'src/assets/svg';
import { Button } from 'src/components/Buttons/Button';
import { useHomePath } from 'src/view/hooks/useHomePath';

export const NotFound = () => {
    const isAuthenticated = useIsAuthenticated();
    const { push } = useHistory();
    const home = useHomePath();
    return (
        <div className="fixed top-0 left-0 h-screen w-screen bg-lightPeach z-100 flex justify-center items-center text-woodyBrown">
            <LogoMunduSVG className=" fill-current absolute top-0 left-0 w-44 mx-4 my-7 " />
            <div className="sm:p-24 sm:shadow-lg rounded-xl flex flex-col justify-center items-center gap-5">
                <h1 className="text-lg md:text-2xl whitespace-nowrap">
                    Denne siden finnes ikke!
                </h1>
                {isAuthenticated ? (
                    <Button title={'Gå hjem'} onClick={() => push(home)} />
                ) : (
                    <Button title={'Gå til Mundu'} onClick={() => push('/')} />
                )}
            </div>
        </div>
    );
};
