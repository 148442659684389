/* eslint-disable @typescript-eslint/ban-types */
import { useEffect, useRef } from 'react';
import { IsNotEmpty, IsString, Length, Matches } from 'class-validator';
import { consumerUsernameRegex, passwordRegex } from 'src/domain/consumer';
import { Match } from 'src/domain/consumer/schema';
import {
    checkIfUsernameExist,
    getOrganizationId,
    UserRole,
} from 'src/domain/user';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { useState } from 'react';
import container from 'src/container';
import Form from 'src/components/FormComponents/Form';
import Input from 'src/components/FormComponents/Input';
import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { LockIcon, EyeIcon, ClosedEyeIcon } from 'src/components/Icons/icons';
import { useNotification } from 'src/components/Notification/useNotfication';
import AvatarInput, {
    HandleUploadAvatar,
} from 'src/components/FormComponents/AvatarInput';
import { mutate } from 'swr';
import { useLicenceInfo } from 'src/view/hooks/organization';
import { LinkButton } from 'src/components/Buttons/LinkButton';
import { SUPPORT_EMAIL } from 'src/config';

class ConsumerSchema {
    @IsString()
    @Length(2, 30, { message: 'Må være mellom 2 og 30 tegn' })
    @IsNotEmpty({ message: 'Må fylles ut.' })
    firstName: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    lastName: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    @Length(8, 64, {
        message: 'Må være mellom 8 og 64 tegn',
    })
    @Matches(passwordRegex, {
        message: 'Kan ikke inneholde æ, ø eller å.',
    })
    initialPassword: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    @Match('initialPassword', {
        message: 'Passord og bekreftelse må stemme overens',
    })
    confirmPassword: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    @Length(3, 113, {
        message: 'Må være mellom 3 og 113 tegn',
    })
    @Matches(consumerUsernameRegex, {
        message:
            "Kan bare inneholde bokstaver fra A til Z, tall og tegnene '. - _! # ^ ~",
    })
    username: string;
}
const {
    cradle: { munduClient },
} = container;

export const NewConsumer = () => {
    const user = useSignedInUser();
    const avatarInputRef = useRef<HandleUploadAvatar>(null);
    const [passVisible, setPassVisible] = useState(false);
    const [passConfirmVisible, setPassConfirmVisible] = useState(false);
    const notify = useNotification();
    const { licenceLimitReached, isLoading } = useLicenceInfo();

    const { dispatch } = useCurrentConsumerContext();

    useEffect(() => {
        dispatch({ type: 'RESET' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!user || isLoading) return null;

    if (licenceLimitReached) {
        return (
            <div className="flex flex-col max-w-3xl mx-auto">
                <div className="flex flex-col items-center gap-8">
                    <div className="text-3xl">Alle lisenser er i bruk!</div>
                    <div className="text-lg">Ønsker du flere lisenser?</div>
                    <LinkButton
                        title="Kontakt oss"
                        fill="peachOrange"
                        textColor="woodyBrown"
                        to={`mailto:${SUPPORT_EMAIL}`}
                    />
                </div>
            </div>
        );
    }
    const organizationId = getOrganizationId(user);

    const onSubmit = async (data: ConsumerSchema) => {
        let followers = undefined;
        if (user.role === UserRole.creator)
            followers = { creatorIds: [user.id] };

        try {
            await checkIfUsernameExist(data.username);
            const newConsumer = await munduClient.createConsumer({
                profile: {
                    firstName: data.firstName,
                    lastName: data.lastName,
                },
                username: data.username,
                initialPassword: data.initialPassword,
                organizationId,
                followers,
            });
            await avatarInputRef.current?.uploadAvatar(newConsumer);
            notify('Bruker ble opprettet');
            mutate('consumers');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return (
        <div className="flex flex-col max-w-3xl mx-auto">
            <Form schema={ConsumerSchema} onSubmit={onSubmit} mode="onChange">
                {({
                    register,
                    formState: { isValid, errors, isSubmitting },
                }) => (
                    <div className="flex flex-col gap-4">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="col-span-full">
                                <AvatarInput ref={avatarInputRef} />
                            </div>
                            <Input
                                className="text-input"
                                label="Fornavn*"
                                error={errors.firstName}
                                type="text"
                                {...register('firstName')}
                            />
                            <Input
                                className="text-input"
                                label="Etternavn*"
                                error={errors.lastName}
                                type="text"
                                {...register('lastName')}
                            />
                            <Input
                                className="text-input"
                                label="Passord*"
                                error={errors.initialPassword}
                                leadingIcon={LockIcon}
                                trailingIcon={
                                    passVisible ? EyeIcon : ClosedEyeIcon
                                }
                                onTrailingClick={() =>
                                    setPassVisible(prev => !prev)
                                }
                                type={passVisible ? 'text' : 'password'}
                                {...register('initialPassword')}
                            />
                            <Input
                                className="text-input"
                                label="Gjenta passordet*"
                                error={errors.confirmPassword}
                                type={passConfirmVisible ? 'text' : 'password'}
                                leadingIcon={LockIcon}
                                trailingIcon={
                                    passConfirmVisible ? EyeIcon : ClosedEyeIcon
                                }
                                onTrailingClick={() =>
                                    setPassConfirmVisible(prev => !prev)
                                }
                                {...register('confirmPassword')}
                            />
                            <Input
                                className="text-input"
                                error={errors.username}
                                label="Brukernavn*"
                                type="text"
                                {...register('username')}
                                debounce={700}
                            />
                        </div>
                        <Input
                            value="Opprett ny bruker"
                            className="primary-btn-small"
                            disabled={!isValid || isSubmitting}
                            type="submit"
                        />
                    </div>
                )}
            </Form>
        </div>
    );
};
