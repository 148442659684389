import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { SearchView } from '../SearchView';
import { PlaylistView } from '../PlaylistView';
import { ArtistView } from '../ArtistView';
import { ReleaseView } from '../ReleaseView';
import { DiscoverView } from '../DiscoverView';
import { MusicalSurvey } from '../../MusicalSurvey';
import { NotFound } from '../../NotFound';

export const MusicSubViewRouter = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <DiscoverView />
            </Route>
            <Route path={`${path}/favorites`}>
                <MusicalSurvey editable={false} />
            </Route>
            <Route path={`${path}/search`}>
                <SearchView />
            </Route>
            <Route path={`${path}/munduplaylist`}>
                <PlaylistView editable={false} />
            </Route>
            <Route path={`${path}/artist`}>
                <ArtistView />
            </Route>
            <Route path={`${path}/release`}>
                <ReleaseView />
            </Route>
            <Route path="*" component={NotFound} />
        </Switch>
    );
};
