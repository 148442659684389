import { useSessionFormState } from '../../SessionFormState/hooks';
import { LoopController } from './LoopController';

export const ControlPanel = () => {
    const { images } = useSessionFormState();

    return (
        <div className={'flex h-full bg-lightPeach text-woodyBrown px-4'}>
            <div className="flex flex-col gap-5 ">
                <div className="prose-xl font-semibold py-3 text-center ">
                    Bilderotasjon
                </div>
                {images.length >= 2 && (
                    <div className="text-sm text-center">
                        Ønsker du å justere visningstiden per bilde? Trykk på
                        loop-ikonet for å aktivere bilderotasjon.
                    </div>
                )}
                {images.length <= 1 && (
                    <div className="text-sm text-center">
                        For å kunne justere visningstid eller avspillingsmodus.
                        Må du minst ha lagt til 2 bilder i utvalget.
                    </div>
                )}
                <LoopController />
            </div>
        </div>
    );
};
