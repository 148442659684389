type Props = {
    onVolumeChange: (value: number) => void;
    onVolumeChangeEnd: () => void;
    volume: number;
};

export const VolumeControl = ({
    volume,
    onVolumeChange,
    onVolumeChangeEnd,
}: Props) => {
    const currentVolume = volume ? `${(volume / 1) * 100}%` : '0%';
    const volumeStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentVolume}, #FFF3EB), color-stop(${currentVolume}, #34202b))
`;
    return (
        <div className="col-span-2 flex justify-end items-center">
            <div className="w-24">
                <input
                    id="volume_control"
                    type="range"
                    value={volume}
                    step="0.01"
                    min="0"
                    max={1}
                    onChange={e => onVolumeChange(Number(e.target.value))}
                    onMouseUp={onVolumeChangeEnd}
                    onKeyUp={onVolumeChangeEnd}
                    style={{ background: volumeStyling }}
                />
            </div>
        </div>
    );
};
