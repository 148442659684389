// ;
// import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import { UserRole } from 'src/domain/user';
// import { FaqScreen, MyProfileScreen, AdminScreen } from 'src/view/screens';
// import { EULA } from '../screens/Mundu/eula';
// import { Licences } from '../screens/Mundu/licences';
// import { Privacy } from '../screens/Mundu/privacy';
// import { Tippetue } from '../screens/Mundu/tippetue';
// import { SessionFormStore } from '../screens/NewVideo/SessionFormState/store';
// import { PrivateRoute, PublicOnlyRoute } from './ControlledRoute';
// import { Contact } from '../screens/Mundu/landingPage/sections';
// import { AudioPlayerStore } from '../screens/BrowseMusic/audioPlayerReducer';
// import { CurrentConsumerStore } from '../screens/Home/NewHome/reducer';
// import { MusicalSurvey } from '../screens/MusicalSurvey';
// import { VideosScreen } from '../screens/Videoes';
// import { ModuleSelector } from '../screens/Module';
// import { MyClientsList } from '../screens/MyClients/list';

// import { DiscoverView } from '../screens/BrowseMusic/DiscoverView';
// import { PlaylistView } from '../screens/BrowseMusic/PlaylistView';
// import { CreateNewVideo } from '../screens/NewVideo';
// import { LandingPage } from '../screens/Mundu/landingPage';

export enum Screen {
    Mundu = '/',
    Contact = '/contact',
    Home = '/home',
    BrowseMusic = '/browse',
    Videos = '/videos',
    MusicalSurvey = '/musikalsk-kartlegging',
    MusicLibrary = '/music',
    MyClients = '/my-clients',
    NewClient = '/my-clients/new',
    NewVideo = '/new-video',
    MyCreators = '/my-creators',
    MyProfile = '/my-profile',
    Faq = '/faq',
    Tippetue = '/tippetue',
    Privacy = '/privacy',
    EULA = '/eula',
    Licences = '/licences',
    NewsPosts = '/news/',
    NewsPost = '/news/:slug',
    Admin = '/admin',
    MusicSelection = '/new-video/step1/browse',
}

// const Router = () => {
//     return (
//         <BrowserRouter>
//             <Switch>
//                 <PublicOnlyRoute exact path={'/'} component={LandingPage} />
//                 <PublicOnlyRoute exact path={'/contact'} component={Contact} />
//                 <PublicOnlyRoute path={'/tippetue'} component={Tippetue} />
//                 <PublicOnlyRoute path={'/privacy'} component={Privacy} />
//                 <PublicOnlyRoute exact path={'/eula'} component={EULA} />
//                 <PublicOnlyRoute path={'/licences'} component={Licences} />
//                 <CurrentConsumerStore>
//                     <SessionFormStore>
//                         <AudioPlayerStore>
//                             <div id="modal-root" />
//                             <Route
//                                 path={'/home/:moduleName?/:tabname?'}
//                                 render={props => {
//                                     return (
//                                         <ModuleSelector {...props}>
//                                             {(key: string) => {
//                                                 return (
//                                                     <Switch key={key}>
//                                                         <Route
//                                                             path={
//                                                                 '/home/musicalsurvey'
//                                                             }
//                                                             component={
//                                                                 MusicalSurvey
//                                                             }
//                                                         />

//                                                         <Route
//                                                             exact
//                                                             path={'/home/music'}
//                                                         >
//                                                             <DiscoverView />
//                                                         </Route>
//                                                         <Route
//                                                             path={
//                                                                 '/home/music/munduplaylist'
//                                                             }
//                                                         >
//                                                             <PlaylistView
//                                                                 editable={false}
//                                                             />
//                                                         </Route>
//                                                         <Route
//                                                             path={
//                                                                 '/home/music/userplaylist'
//                                                             }
//                                                         >
//                                                             <PlaylistView
//                                                                 editable={true}
//                                                             />
//                                                         </Route>
//                                                         <Route
//                                                             exact
//                                                             path={
//                                                                 '/home/videos'
//                                                             }
//                                                             component={
//                                                                 VideosScreen
//                                                             }
//                                                         />
//                                                         <Route
//                                                             path={
//                                                                 '/home/new-video'
//                                                             }
//                                                         >
//                                                             <CreateNewVideo />
//                                                         </Route>

//                                                         <Route
//                                                             path={Screen.Faq}
//                                                             component={
//                                                                 FaqScreen
//                                                             }
//                                                         />
//                                                         <Route
//                                                             exact
//                                                             path={'/home/users'}
//                                                         >
//                                                             <SessionFormStore>
//                                                                 <MyClientsList />
//                                                             </SessionFormStore>
//                                                         </Route>
//                                                         {/* <Route
//                                                             path={
//                                                                 '/home/users/new'
//                                                             }
//                                                         >
//                                                             <div className="flex flex-col w-full h-full justify-start items-start">
//                                                                 <NewConsumerForm />
//                                                             </div>
//                                                         </Route> */}

//                                                         <Route
//                                                             path={
//                                                                 Screen.MyProfile
//                                                             }
//                                                             component={
//                                                                 MyProfileScreen
//                                                             }
//                                                         />
//                                                         <PrivateRoute
//                                                             requiredRoles={[
//                                                                 UserRole.admin,
//                                                             ]}
//                                                             path={Screen.Admin}
//                                                             component={
//                                                                 AdminScreen
//                                                             }
//                                                         />
//                                                     </Switch>
//                                                 );
//                                             }}
//                                         </ModuleSelector>
//                                     );
//                                 }}
//                             />
//                         </AudioPlayerStore>
//                     </SessionFormStore>
//                 </CurrentConsumerStore>
//             </Switch>
//         </BrowserRouter>
//     );
// };

// export default Router;

export {};
