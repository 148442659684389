import { useEffect } from 'react';

/* 
import {
    IsNumber,
    IsString,
    Min,
    Max,
    IsBoolean,
    IsArray,
    IsOptional,
} from 'class-validator';
import { useState } from 'react';
import { Artist, Release, Track } from 'src/domain/music';
import Form from '../../../components/FormComponents/Form';
import CheckBox from '../../../components/FormComponents/CheckBox';
import Range from '../../../components/FormComponents/Range';
import { useArtistSearch } from 'src/view/hooks/swr/useArtistSearch';
import { useReleaseSearch } from 'src/view/hooks/swr/useReleaseSearch';
import { useTrackSearch } from 'src/view/hooks/swr/useTrackSearch';
import { debounce } from 'lodash';
import SelectMultiple from '../../../components/FormComponents/MultiSelect';
import Select from '../../../components/FormComponents/Select/Select';
import Input from '../../../components/FormComponents/Input';
import { useGenres } from 'src/view/hooks/swr/useGenres';
import { Genre } from 'src/domain/music/genre';
import { toTitleCase } from 'src/utils/stringUtils';
import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { useUserPlaylists } from 'src/view/hooks/swr/useUserPlaylists';
import container from 'src/container'; */
import { Favorites } from './favorites';
import { useFavoriteArtists } from 'src/view/hooks/useFavoriteArtists';
import { useSurveyContext } from './reducer';
import { useFavoritesPlaylist } from 'src/view/hooks/useFavoritesPlaylist';
import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { SpinnerIconSVG } from 'src/assets/svg';

export type MusicalForm = 'Vokal' | 'Instrumental' | 'Begge Deler';
/* class Survey {
    @IsOptional()
    @IsNumber()
    @Min(1)
    @Max(5)
    importance: number;

    @IsOptional()
    @IsBoolean()
    playsInstrument: boolean;

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    instruments: string[];

    @IsOptional()
    @IsBoolean()
    enjoysSinging: boolean;

    @IsOptional()
    @IsBoolean()
    enjoysDancing: boolean;

    @IsOptional()
    @IsString()
    musicalForm: MusicalForm;

    @IsOptional()
    @IsArray()
    @IsString({ each: true })
    genres: Genre['id'][];

    @IsOptional()
    @IsArray()
    @IsNumber({}, { each: true })
    tracks: Track['id'][];

    @IsOptional()
    @IsArray()
    @IsNumber({}, { each: true })
    artists: Artist['id'][];

    @IsOptional()
    @IsArray()
    @IsNumber({}, { each: true })
    releases: Release['id'][];
} */

/* const Instruments = [
    'Piano',
    'Akustisk Gitar',
    'Elektrisk Gitar',
    'Bass',
    'Trommer',
    'Fiolin',
    'Cello',
    'Trompet',
    'Fløyte',
    'Klarinett',
    'Saksofon',
]; */

export const MusicalSurvey = ({ editable = true }: { editable: boolean }) => {
    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();
    const { dispatch } = useSurveyContext();

    const { artists: initialArtists, isLoading: artistsIsLoading } =
        useFavoriteArtists(currentConsumer?.id);

    const { playlist, isLoading: playlistIsLoading } = useFavoritesPlaylist(
        currentConsumer?.id,
        playlist => dispatch({ type: 'SET_PLAYLIST', payload: playlist }),
    );

    const musicalSurveyIsLoading =
        artistsIsLoading ||
        playlistIsLoading ||
        !initialArtists ||
        !playlist ||
        !currentConsumer;

    useEffect(() => {
        if (musicalSurveyIsLoading) return;
        dispatch({ type: 'SET_ARTISTS', payload: initialArtists });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [musicalSurveyIsLoading, dispatch]);

    return musicalSurveyIsLoading ? (
        <div className="grid justify-center">
            <SpinnerIconSVG className="animate-spin h-8 w-8 text-woodyBrown" />
        </div>
    ) : (
        <Favorites editable={editable} />
    );
};

// export const MusicalSurvey = () => {
//     // const [trackQuery, setTrackQuery] = useState('');
//     // const [artistQuery, setArtistQuery] = useState('');
//     // const [releaseQuery, setReleaseQuery] = useState('');

//     const onSearch = (
//         query: string,
//         setValue: React.Dispatch<React.SetStateAction<string>>,
//     ) => {
//         setValue(query);
//     };

//     const debouncedResults = useMemo(() => {
//         return debounce(onSearch, 700);
//     }, []);

//     useEffect(() => {
//         return () => {
//             debouncedResults.cancel();
//         };
//     }, [debouncedResults]);

//     // const { tracks, isLoading: isLoadingTracks } = useTrackSearch(trackQuery);
//     // const { releases, isLoading: isLoadingReleases } = useReleaseSearch(
//     //     releaseQuery,
//     //     'Album',
//     // );
//     const { genres, isLoading: isLoadingGenres } = useGenres();
//     const genreOptions = genres?.flatMap(genre => [
//         {
//             id: genre.id,
//             name: toTitleCase(genre.text),
//         },
//     ]);

//     // const { artists, isLoading: isLoadingArtists } =
//     //     useArtistSearch(artistQuery);

//     const onSubmit = (data: Survey) => {
//         console.log(data);
//     };

//     // const trackOptions = tracks?.flatMap(track => [
//     //     { id: track.id, title: track.title, artist: track.artist.name },
//     // ]);

//     // const releaseOptions = releases?.flatMap(release => [
//     //     { id: release.id, title: release.title, artist: release.artist.name },
//     // ]);

//     return (
//         <Form
//             className="bg-lightPeach rounded-30px pt-4 px-6 h-full w-full"
//             schema={Survey}
//             onSubmit={onSubmit}
//             mode="onChange"
//         >
//             {({ register, watch, setValue, formState: { errors } }) => (
//                 <div className="max-w-[600px] min-w-72">
//                     <div className="grid grid-cols-1 gap-4">
//                         <Range
//                             label="Hvor viktig rolle har musikk spilt i brukerens liv? (1 - 5)"
//                             value={watch('importance', 1)}
//                             min={1}
//                             max={5}
//                             step={1}
//                             setValue={value => setValue('importance', value)}
//                             {...register('importance')}
//                         />
//                         <CheckBox
//                             value={watch('playsInstrument')}
//                             label="Behersker/behersket bruker et musikkinstrument?"
//                             setValue={value =>
//                                 setValue('playsInstrument', value)
//                             }
//                             error={errors.playsInstrument}
//                             {...register('playsInstrument')}
//                         />
//                         <SelectMultiple
//                             options={Instruments}
//                             placeholder="Velg musikkinstrument"
//                             disable={!watch('playsInstrument')}
//                             closeOnSelect
//                             isObject={false}
//                             setValue={value => setValue('instruments', value)}
//                         />

//                         <CheckBox
//                             label="Liker/likte bruker å synge?"
//                             value={watch('enjoysSinging')}
//                             setValue={value => setValue('enjoysSinging', value)}
//                             error={errors.enjoysSinging}
//                             {...register('enjoysSinging')}
//                         />
//                         <CheckBox
//                             label="Liker/likte bruker å danse?"
//                             value={watch('enjoysDancing')}
//                             setValue={value => setValue('enjoysDancing', value)}
//                             error={errors.enjoysDancing}
//                             {...register('enjoysDancing')}
//                         />

//                         <Select
//                             label="Hva slags form tar brukerens favorittmusikk? "
//                             options={['Vokal', 'Instrumental', 'Begge Deler']}
//                             placeholder="Velg musikalsk form"
//                             closeOnSelect
//                             isObject={false}
//                             setValue={value => setValue('musicalForm', value)}
//                             {...register('musicalForm')}
//                         />

//                         <SelectMultiple
//                             label="Hvilke musikalske sjangere liker brukeren"
//                             isObject
//                             displayValue="name"
//                             placeholder="Velg sjangere"
//                             setValue={(value: Genre[]) =>
//                                 setValue(
//                                     'genres',
//                                     value.map(x => x.id),
//                                 )
//                             }
//                             options={genreOptions}
//                             loading={isLoadingGenres}
//                             closeOnSelect
//                             {...register('genres')}
//                         />

//                         {/* <SelectMultiple
//                             label="Hvilke sanger blir brukeren glad av å høre på?"
//                             isObject
//                             displayValue="title"
//                             placeholder="Søk etter sanger"
//                             options={trackOptions}
//                             groupBy="artist"
//                             loading={isLoadingTracks && trackQuery.length > 0}
//                             emptyRecordMsg={
//                                 trackQuery
//                                     ? `Ingen treff på ${trackQuery}`
//                                     : 'Fyll inn et søkeord'
//                             }
//                             setValue={(value: Track[]) =>
//                                 setValue(
//                                     'tracks',
//                                     value.map(x => x.id),
//                                 )
//                             }
//                             onSearch={value =>
//                                 debouncedResults(value, setTrackQuery)
//                             }
//                             closeOnSelect
//                             onSelect={() => setTrackQuery('')}
//                             {...register('tracks')}
//                         />
//                         <SelectMultiple
//                             isObject
//                             options={artists}
//                             loading={isLoadingArtists && artistQuery.length > 0}
//                             displayValue="name"
//                             label="Hvilke artister liker brukeren aller best å høre på?"
//                             placeholder="Søk etter artister"
//                             emptyRecordMsg={
//                                 artistQuery
//                                     ? `Ingen treff på "${artistQuery}"`
//                                     : 'Fyll inn et søkeord'
//                             }
//                             setValue={(value: Artist[]) =>
//                                 setValue(
//                                     'artists',
//                                     value.map(x => x.id),
//                                 )
//                             }
//                             onSearch={value =>
//                                 debouncedResults(value, setArtistQuery)
//                             }
//                             onSelect={() => setArtistQuery('')}
//                             closeOnSelect
//                             {...register('artists')}
//                         />

//                         <SelectMultiple
//                             isObject
//                             options={releaseOptions}
//                             loading={
//                                 isLoadingReleases && releaseQuery.length > 0
//                             }
//                             groupBy="artist"
//                             displayValue="title"
//                             label="Hvilke utgivelser finnes i brukerens private samling?"
//                             placeholder="Søk etter utgivelser"
//                             emptyRecordMsg={
//                                 releaseQuery
//                                     ? `Ingen treff på "${releaseQuery}"`
//                                     : 'Fyll inn et søkeord'
//                             }
//                             setValue={(value: Release[]) =>
//                                 setValue(
//                                     'releases',
//                                     value.map(x => x.id),
//                                 )
//                             }
//                             onSearch={value =>
//                                 debouncedResults(value, setReleaseQuery)
//                             }
//                             onSelect={() => setReleaseQuery('')}
//                             closeOnSelect
//                             {...register('releases')}
//                         /> */}
//                     </div>
//                     <div className="inline-flex w-full space-x-4 justify-end pt-4">
//                         <Input
//                             value="Lagre"
//                             className="primary-btn-small"
//                             disabled={false}
//                             type="submit"
//                         />
//                     </div>
//                 </div>
//             )}
//         </Form>
//     );
// };
