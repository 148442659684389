import { useState, useCallback, useMemo } from 'react';
import container from 'src/container';
import { addItem, clearItems, setConsent, useImageUploadContext } from '.';
import {
    convertBase64ToBlob,
    generateThumbnail,
    optimizeImage,
} from 'src/utils/imageUtils';
import { MediaItem, MediaType } from 'src/domain/personalMedia';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { v4 as generateId } from 'uuid';
import { Item } from './types';

const {
    cradle: { storageService, munduClient },
} = container;

export const useImageUpload = () => {
    const { state, dispatch } = useImageUploadContext();
    const { items } = state;
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const user = useSignedInUser();
    const userId = user?.id;
    const recipientId = state.recipient?.id;
    const [uploadCount, setuploadCount] = useState(0);

    const uploadPercentage = useMemo(
        () =>
            uploadCount > 0 && items.length > 0
                ? Math.round((uploadCount / items.length) * 100)
                : 0,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [uploadCount],
    );

    const optimizeMediaSelection = async (
        items: Item[],
    ): Promise<MediaItem[]> => {
        return Promise.all(
            items.map(async item => {
                const optimizedImage = await optimizeImage(item.file);
                return {
                    ...item,
                    ...optimizedImage,
                    type: MediaType.image,
                };
            }),
        );
    };

    const uploadMediaSelection = useCallback(
        async (mediaSelection: MediaItem[]) => {
            if (!recipientId) return;
            const sas = await munduClient.getPersonalMediaSas(recipientId);
            return Promise.all(
                mediaSelection.map(async item => {
                    const optimizedImageBlob = await convertBase64ToBlob(
                        item.file,
                    );
                    await storageService.uploadImage(
                        optimizedImageBlob,
                        item.id,
                        sas,
                    );

                    const thumbnailBlob = await convertBase64ToBlob(
                        item.thumbnail.file,
                    );
                    await storageService.uploadImage(
                        thumbnailBlob,
                        item.thumbnail.id,
                        sas,
                    );

                    setuploadCount(prev => prev + 1);
                }),
            );
        },
        [recipientId],
    );

    const submit = useCallback(async () => {
        if (!recipientId || !userId) return;
        setSubmitting(true);
        await munduClient.updateConsent(userId, true);
        const optimizedMediaSelection = await optimizeMediaSelection(items);
        await uploadMediaSelection(optimizedMediaSelection);

        /* eslint-disable @typescript-eslint/no-unused-vars */
        await munduClient.updatePersonalMedia({
            consumerId: recipientId,
            uploaderId: userId,
            medias: optimizedMediaSelection.map(item => {
                const { file: optimizedFile, thumbnail, ...rest } = item;
                const { file: thumbnailFile, ...thumbnailData } = thumbnail;
                return {
                    ...rest,
                    thumbnail: thumbnailData,
                };
            }),
        });

        setSubmitting(false);
        setSubmitted(true);
        dispatch(clearItems());
    }, [recipientId, userId, items, uploadMediaSelection, dispatch]);

    const handleAcceptedFiles = async (files: File[]) => {
        dispatch(setConsent(false));
        await Promise.all(
            files.map(async file => {
                const thumbnail = await generateThumbnail(file);
                dispatch(addItem({ id: generateId(), thumbnail, file }));
            }),
        );
    };

    return {
        state,
        dispatch,
        submit,
        submitting,
        submitted,
        handleAcceptedFiles,
        uploadPercentage,
    };
};
