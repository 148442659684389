import cx from 'classnames';
import { EditIcon } from 'src/assets/svg';

type Props = {
    onClick?: () => void;
    entity: string;
    name: string;
    username: string;
    avatar: string;
    rounded?: boolean;
};

export const ProfileBanner = ({
    onClick,
    entity,
    name,
    username,
    avatar,
    rounded = false,
}: Props) => {
    return (
        <div className="flex flex-col gap-3 pb-2 w-full relative">
            <button
                onClick={onClick}
                className={cx(
                    'inline-flex space-x-4 w-full    rounded-2xl  transition-all group',
                    rounded ? 'rounded-full' : 'rounded-[16px]',
                )}
            >
                <div className="flex-shrink-0 relative w-24 lg:w-32 xl:w-36 aspect-square ">
                    <img
                        className={cx(
                            'absolute inset-0 rounded-xl xl:rounded-2xl shadow-lg w-full aspect-square object-cover',
                            rounded ? 'rounded-full' : 'rounded-lg',
                        )}
                        src={avatar}
                        alt="avatar consumer"
                    />
                </div>

                <div className="text-left relative h-24 lg:h-32 xl:h-36 runcate">
                    <div>
                        <h1 className="font-bold font-logo text-left font-white text-xl xl:text-3xl lg:leading-normal whitespace-nowrap truncate">
                            {name}
                        </h1>
                        <p className="text-[12px] md:text-[14px] leading-none mb-3">{`(${username})`}</p>

                        <div
                            id="entity"
                            className=" text-[8px] lg:text-sm font-extralight"
                        >
                            {entity}
                        </div>
                    </div>
                    {/* <div className="inline-flex items-center space-x-[4px] mb-[2px] ">
                    <div className="bg-palmleafGreen w-[8px] h-[8px] mt-[2px] rounded-full " />
                    <p className="text-[12px] align-middle leading-none ">
                        {status}
                    </p>
                </div> */}
                </div>
                <div className="absolute bottom-4 right-0">
                    <div className="h-8 w-8 grow grid place-content-center p-6 rounded-md group-hover:bg-woodyBrown group-hover:text-lightPeach transition">
                        <EditIcon className="w-8 h-8" />
                    </div>
                </div>
            </button>
            <hr className="w-full border-woodyBrown opacity-30" />
        </div>
    );
};
