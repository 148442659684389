import { FieldError } from 'react-hook-form';
import { Label, Error } from './Input';
import { InputIcon } from 'src/components/Icons/InputIcon';
import { CheckMark } from 'src/components/Icons/icons';
import cx from 'classnames';
import { forwardRef } from 'react';

type CheckBoxProps = Omit<
    React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    >,
    'value'
> & {
    label: string;
    error?: FieldError;
    value: boolean;
    setValue?: (value: boolean) => void;
    onToggle?: () => void;
};

const CheckBox = forwardRef<HTMLInputElement, CheckBoxProps>(
    ({ error, label, setValue, value, onClick, disabled, ...props }, ref) => {
        return (
            <div
                className={cx(
                    'inline-flex items-center space-x-2',
                    disabled && 'text-mongooseGray',
                )}
            >
                <div
                    className={cx(
                        'flex-shrink-0 relative transition-all rounded  w-6 aspect-square border-[1.6px] scale-1 hover:scale-110 hover:text-starkWhite justify-center',
                        value === true && !disabled
                            ? 'text-lightPeach bg-woodyBrown'
                            : 'text-transparent bg-lightPeach ',
                        disabled
                            ? 'border-mongooseGray pointer-events-none'
                            : 'border-woodyBrown',
                    )}
                    onClick={
                        setValue
                            ? () => setValue(!value)
                            : onClick
                            ? onClick
                            : undefined
                    }
                >
                    <input
                        className="absolute appearance-none"
                        ref={ref}
                        type="checkbox"
                        {...props}
                    />
                    <InputIcon SVG={CheckMark} type="center" />
                </div>
                <Label label={label} />
                <Error error={error} />
            </div>
        );
    },
);
CheckBox.displayName = 'CheckBox';
export default CheckBox;
