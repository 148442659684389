import { CustomPlaylistTrack, Track } from 'src/domain/music';
import { convertURLToHttps, formatDuration } from 'src/utils/stringUtils';
import { ArtistLink } from '../../artistLink';
import { TrackCardAlbumCover } from './cardAlbumCover';
import cx from 'classnames';

type TrackCardProps = {
    track: Track;
    index: number;
    isPlaying: boolean;
    isSelected: boolean;
    isUnavailable?: boolean;
    onSelect?: (track: Track | CustomPlaylistTrack) => void;
    togglePlay: () => void;
    onDeleteTrack?: (track: CustomPlaylistTrack) => void;
    onAddTrack?: (track: Track) => void;
};

export const TrackCard = ({
    track,
    isPlaying,
    isSelected,
    isUnavailable = false,
    onSelect,
    togglePlay,
    onAddTrack,
}: TrackCardProps) => {
    const {
        title,
        release: { image },
        artist,
    } = track;
    const duration = formatDuration(track.duration);

    const handleOnClick = () => {
        if (isUnavailable) return undefined;
        if (onAddTrack) return onAddTrack(track);
        if (onSelect) return onSelect(track);
    };

    return (
        <div
            className={cx(
                'relative w-24 md:w-32 xl:w-36 rounded-md md:rounded-lg xl:rounded-2xl',
                isUnavailable && 'opacity-70',
            )}
        >
            <TrackCardAlbumCover
                src={convertURLToHttps(image)}
                alt={`albumcover for ${title} by ${artist.name}`}
                isPlaying={isPlaying}
                isSelected={isSelected}
                disabled={isUnavailable}
                onClick={handleOnClick}
                togglePlay={togglePlay}
            />
            <p className="mt-2 truncate">
                <ArtistLink artist={artist} />
            </p>
            <p className="text-sm font-bold truncate">{title}</p>
            <p className="text-xs">{duration}</p>
        </div>
    );
};
