import { Logo } from './icons';

export const LogoIcon = ({ onClick }: { onClick?: () => void }) => {
    return (
        <div className="items-center justify-center">
            <div
                onClick={onClick}
                className={'relative pb-[1em] w-[5.581em]  z-10'}
            >
                <Logo />
            </div>
        </div>
    );
};
