import { useState } from 'react';
import { ListPlaylistItem } from 'src/domain/music';
import { FALLBACK_COVER_IMAGE } from 'src/view/screens/BrowseMusic';

import { Link } from 'react-router-dom';

import { useAudioPlayerContext } from '../../audioPlayerReducer';
type Props = {
    item: ListPlaylistItem;
};

export const PlaylistItem = ({ item }: Props) => {
    const { dispatch } = useAudioPlayerContext();
    const [loaded, setLoaded] = useState<boolean>(false);
    const onLoadImage = () => {
        if (!loaded) setLoaded(true);
    };

    return (
        <div className="w-32 xl:w-36">
            <div className="relative aspect-square w-full">
                <Link to="./browse/munduPlaylist">
                    <button
                        className="relative w-full h-full"
                        onClick={() =>
                            dispatch({
                                type: 'SET_PLAYLIST',
                                payload: item,
                            })
                        }
                    >
                        <img
                            className="absolute inset-0 rounded-xl xl:rounded-2xl shadow-xl"
                            src={item.image ? item.image : FALLBACK_COVER_IMAGE}
                            alt=""
                            onLoad={onLoadImage}
                        />
                    </button>
                </Link>
            </div>
            <p className="text-sm font-bold mt-2 truncate">{item.name}</p>
            <p className="text-sm  truncate">Av Mundu</p>{' '}
        </div>
    );
};
