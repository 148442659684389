import { useState } from 'react';
import { getUserRole } from 'src/domain/user';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import cx from 'classnames';
import { AppModal } from 'src/components/Modals/AppModal';
import { DocumentsIcon, HomeIcon, QuestionMarkIcon } from '../Icons/icons';
import { NavItem } from './NavItem';
import { UserRole } from 'src/domain/user';
import { useHistory } from 'react-router-dom';
import { ScrollableModal } from '../Modals/ScrollableModal';
import { Faq } from 'src/view/screens/Faq';
import { useHomePath } from 'src/view/hooks/useHomePath';

export const AccountButton = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);
    const [showFaq, setShowFaq] = useState(false);
    const history = useHistory();
    const user = useSignedInUser();
    const home = useHomePath();

    if (!user) return null;

    const {
        role,
        profile: { firstName, lastName },
    } = user;

    const initials = `${firstName.charAt(0)}${lastName.charAt(
        0,
    )}`.toUpperCase();

    const fullName = `${firstName} ${lastName}`;

    return (
        <div className="py-2 " id="Account-btn">
            <ScrollableModal
                visible={showFaq}
                onClose={() => setShowFaq(false)}
            >
                <Faq />
            </ScrollableModal>
            <button
                className={cx(
                    'flex items-center justify-center w-10 h-10 rounded-md text-sm hover:bg-starkWhite hover:text-woodyBrown focus:outline-none transition-all',
                    modalOpen
                        ? 'bg-peachOrange text-woodyBrown '
                        : 'bg-woodyBrown text-lightPeach ',
                )}
                onClick={toggleModal}
            >
                {initials}
            </button>
            <AppModal clickOutside={() => setModalOpen(false)} show={modalOpen}>
                <div className="animate-fade-in-fast origin-top-right z-40 absolute bg-lightPeach py-4 text-woodyBrown w-64 right-[16px]  rounded-[16px] shadow-xl">
                    <div className="inline-flex h-[60px] space-x-[16px]  px-[16px] items-center">
                        <div className="z-10 w-[60px] h-[60px] text-[18px] outline-none  focus:outline-none rounded-[12px] bg-woodyBrown text-lightPeach flex items-center justify-center">
                            {initials}
                        </div>
                        <div className="h-[60px]">
                            <div>
                                <p className="text-[18px] leading-none ">
                                    {fullName}
                                </p>
                                <p className="text-[12px] font-extralight">
                                    {getUserRole(role).toUpperCase()}
                                </p>
                            </div>
                            <div className="inline-flex items-center space-x-[4px] mb-[2px] ">
                                <div className="bg-palmleafGreen w-[8px] h-[8px] mt-[2px] rounded-full " />
                                <p className="text-[12px] align-middle leading-none ">
                                    Innlogget
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-[16px]">
                        <NavItem
                            icon={HomeIcon}
                            screen={home}
                            label="Hjem"
                            onClick={() => setModalOpen(false)}
                            isLight
                        />
                        <NavItem
                            icon={DocumentsIcon}
                            screen={
                                role === UserRole.nextOfKin
                                    ? '/app/nextofkin-profile'
                                    : '/app/my-profile'
                            }
                            onClick={() => setModalOpen(false)}
                            label="Profil"
                            isLight={true}
                        />
                        <NavItem
                            label="FAQ"
                            icon={QuestionMarkIcon}
                            isLight
                            onClick={() => setShowFaq(true)}
                        />
                    </div>
                    <div className="float-right pt-[16px] pr-[16px] ">
                        <button
                            className="primary-btn-small"
                            onClick={() => history.push('/logout')}
                        >
                            Logg ut
                        </button>
                    </div>
                </div>
            </AppModal>
        </div>
    );
};
