import { createContext, useMemo, useState } from 'react';
import { Notification } from './Notification';
type NotificationState = {
    message: string;
    isError?: boolean;
    notify: boolean;
};

type Dispatch = (action: NotificationState) => void;

type ContextProps = {
    state: NotificationState;
    dispatch: Dispatch;
};
export const NotificationContext = createContext<ContextProps>({
    state: { message: '', isError: false, notify: false },
    dispatch: () => null,
});

export const NotificationProvider = ({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element => {
    const [state, dispatch] = useState<NotificationState>({
        message: '',
        isError: false,
        notify: false,
    });
    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return (
        <NotificationContext.Provider value={contextValue}>
            <Notification />
            {children}
        </NotificationContext.Provider>
    );
};
