import { forwardRef, memo } from 'react';

type TestimonialsProps = {
    bgColor?: string;
    id: string;
    items?: Testimonial[];
};

const DATA = [
    {
        quote: 'Dere har gjort en grå dag til en solskinnsdag!',
        role: 'Pårørende',
    },
    {
        quote: 'Gu, kor glad eg e, det kommer tilbake med musikken! Eg e søtten år igjen!',
        role: 'Person med demens',
    },
    {
        quote: 'Kjempesuksess med allsang og latter. Er dere evig takknemlig for disse flotte filmene.',
        role: 'Pårørende',
    },
];

type Testimonial = {
    quote: string;
    role: string;
};
const Testimonials = forwardRef<HTMLElement, TestimonialsProps>(
    ({ bgColor, id, items = DATA }: TestimonialsProps, ref) => {
        const renderItem = (item: Testimonial) => {
            return (
                <div
                    key={item.quote}
                    className="flex flex-col prose md:prose-lg aspect-auto justify-between pt-4 bg-woodyBrown rounded-xl shadow-md min-w-[333px] md:max-w-[450px]"
                >
                    <blockquote className="mx-8">
                        <span className="font-bold">{item.quote}</span>
                    </blockquote>
                    <div className="pb-8 px-8">{item.role}</div>
                </div>
            );
        };
        return (
            <section
                ref={ref}
                id={id}
                className={`relative z-10 w-full py-16 px-8 flex gap-8 lg:justify-center  overflow-x-auto shadow-inner bg-${bgColor}`}
            >
                {items.map(item => renderItem(item))}
            </section>
        );
    },
);
Testimonials.displayName = 'Testimonials';

export default Testimonials;
