import { useMemo } from 'react';
import container from 'src/container';
import useSWR from 'swr';

const {
    cradle: { munduClient },
} = container;

export const useSignInIdentities = (userId?: string) => {
    const { data } = useSWR(
        userId ? userId : null,
        munduClient.getSignInIdentities,
        { revalidateOnFocus: false },
    );

    const phoneNumber = useMemo(
        () =>
            data
                ?.find(identity => identity.signInType === 'phoneNumber')
                ?.issuerAssignedId.slice(3),
        [data],
    );

    const emailAddress = useMemo(
        () =>
            data
                ?.find(identity => identity.signInType === 'emailAddress')
                ?.issuerAssignedId.slice(3),
        [data],
    );

    return { phoneNumber, emailAddress, allIdentities: data, isLoading: !data };
};
