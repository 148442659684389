import { CardGrid } from 'src/components/AppScreen/AppLayout';
import { Artist } from 'src/domain/music';

import { ArtistItem } from './item';

export const ArtistList = ({
    artists,
    onSelect,
    onRemove,
}: {
    artists: Artist[];
    onSelect?: (artist: Artist) => void;
    onRemove?: (artist: Artist) => void;
}) => {
    return (
        <CardGrid>
            {artists.map(artist => (
                <ArtistItem
                    key={artist.id}
                    artist={artist}
                    onSelect={onSelect ? () => onSelect(artist) : undefined}
                    onRemove={onRemove ? () => onRemove(artist) : undefined}
                />
            ))}
        </CardGrid>
    );
};
