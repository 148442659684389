import { ReactElement } from 'react';
import { Component } from 'react';
import {
    matchPath,
    RouteComponentProps,
    match,
    useRouteMatch,
} from 'react-router-dom';
import { AppScreen, AppScreenProps } from '../AppScreen/AppScreen';
import { AudioPlayer } from '../../view/screens/BrowseMusic/components/AudioPlayer';
import { ConsumerSelector } from '../../view/screens/components/ConsumerSelector';
import { HeaderCenterNewVideo } from 'src/view/layout/HeaderCenter/NewVideo';
import {
    ImageSelectionHeader,
    MusicSelectionHeader,
} from 'src/view/components/musicSelectionHeader';
import { TracksSelector } from '../../view/screens/BrowseMusic/components/Selection';
import { ImageSelector } from '../../view/screens/components/ImageSelector';
import { ControlPanel } from '../../view/screens/NewVideo/step2/components/ControlPanel';
import { StepNavigator } from '../../view/screens/NewVideo/components/StepNavigator';
import {
    CreatorSelector,
    LinkToCreatorsIfOrganization,
} from '../../view/screens/components/CreatorSelector';
import { useCurrentConsumerContext } from '../../view/screens/Home/NewHome/reducer';
import { SelectConsumer } from '../../view/screens/components/buttons/SelectConsumer';
import { Consumer } from 'src/domain/consumer';
import { SelectRecipient } from '../../view/screens/components/buttons/SelectConsumer/SelectRecipient';
import { HomeButton } from '../../view/screens/NewVideo/step4/components/HomeButton';
import { AccountButton } from '../Buttons/AccountButton';
import { HomeIcon, UsersIcon, PlusIcon } from '../Icons/icons';
import { NavItem, NavButton } from '../Buttons/NavItem';
import { SubmitPanel } from 'src/view/screens/ImageUpload/SubmitPanel';
import { SelectConsumerAsNextofkin } from 'src/view/screens/components/buttons/SelectConsumer/SelectConsumerAsNextOfKin';
import { SaveFavoritesButton } from '../Buttons/SaveFavoritesButton';
import { LogoLink } from '../Buttons/LogoLink';
import { RepeatOnboardingButton } from '../Buttons/RepeatOnboardingButton';
import { OrganizationSelector } from 'src/view/screens/Admin/OrganizationSelector';

type ModuleParams = {
    match?: match<RouteParams>;
    loading: boolean;
    prevKey: string;
    setLoading?: () => void;
    children: ReactElement<any, any>;
};

const CurrentConsumerSelect = ({
    isLight,
    disabled = false,
}: {
    isLight?: boolean;
    disabled?: boolean;
}) => {
    const {
        dispatch,
        state: { currentConsumer },
    } = useCurrentConsumerContext();

    const onSelect = (consumer: Consumer) => {
        dispatch({
            type: 'SELECT_CONSUMER',
            payload: consumer,
        });
    };
    return (
        <SelectConsumer
            onSelect={onSelect}
            current={currentConsumer?.id}
            isLight={isLight}
            disabled={disabled}
        />
    );
};

const Module = ({ children }: ModuleParams) => {
    return <span className="animate-fade-in-fast">{children}</span>;
};
const getParams = (pathname: string) => {
    const matchModule = matchPath<RouteParams>(pathname, {
        path: `/app/:moduleName/:tabName?/:subModule?`,
    });
    return { ...(matchModule && matchModule.params) } || {};
};

export const useIsSelectingMusic = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isSelectingMusic = useRouteMatch('/app/new-video/step1/browse');
    return isSelectingMusic ? true : false;
};

const MusicSelectionAudioPlayer = () => {
    const isSelectingMusic = useIsSelectingMusic();
    if (!isSelectingMusic) return null;
    return <AudioPlayer />;
};

const getScreenProps = (
    moduleName?: string,
    tabname?: string,
    subModule?: string,
): AppScreenProps => {
    switch (moduleName) {
        case 'admin':
            return {
                HeaderLeft: (
                    <div className="inline-flex justify-start w-full items-center text-xl space-x-8 ">
                        <h1 className="font-logo opacity-80  whitespace-nowrap ">
                            {tabname === 'organizations'
                                ? 'Ny organisasjon'
                                : tabname === 'admins'
                                ? 'Ny admin'
                                : tabname === 'organization'
                                ? `Organisasjon/${subModule}`
                                : 'Admin'}
                        </h1>
                    </div>
                ),
                HeaderRight: <AccountButton />,
                NavItems: (
                    <>
                        <NavItem
                            icon={HomeIcon}
                            screen={'/app/admin'}
                            label="Hjem"
                        />

                        <NavItem
                            icon={UsersIcon}
                            screen={'/app/organizations'}
                            label="Organisasjoner"
                            indentChildren={false}
                            isActive={true}
                        >
                            <OrganizationSelector />
                        </NavItem>
                        <NavButton
                            key="kebab"
                            leadingIcon={PlusIcon}
                            to={'/app/admin/organizations/new'}
                            label="Ny organisasjon"
                            isActive={'new' === tabname}
                        />
                    </>
                ),
            };
        case 'me':
            return {};
        case 'musical-survey':
            return {
                ContentHeader: (
                    <div className="w-full">
                        <div className="flex w-full justify-between items-center">
                            <HomeButton title="Hjem" />
                            <SaveFavoritesButton />
                        </div>
                        <hr className="w-full border-woodyBrown border-1 mt-2 opacity-30" />
                    </div>
                ),
                HeaderLeft: (
                    <>
                        <CurrentConsumerSelect disabled />
                    </>
                ),
                HeaderRight: <AccountButton />,
                Footer: <AudioPlayer />,
            };
        case 'new-video': {
            if (subModule === 'browse') {
                return {
                    HeaderLeft: <SelectRecipient />,
                    HeaderRight: <AccountButton />,
                    HeaderCenter: <HeaderCenterNewVideo />,
                    ContentHeader: <MusicSelectionHeader />,

                    // HeaderCenter: <SubHeader />,
                    Selector: <TracksSelector />,
                    Footer: <MusicSelectionAudioPlayer />,
                    navOnTheRight: true,
                    sidebarIsNextStep: true,
                };
            } else if (subModule === 'personalmedia') {
                return {
                    // SubHeader: <SubHeader />,
                    HeaderLeft: <SelectRecipient />,
                    ContentHeader: <ImageSelectionHeader />,
                    HeaderRight: <AccountButton />,
                    HeaderCenter: <HeaderCenterNewVideo />,
                    Selector: <ImageSelector />,
                    navOnTheRight: true,
                    sidebarIsNextStep: true,
                };
            } else if (tabname === 'step2') {
                return {
                    HeaderLeft: <SelectRecipient />,
                    SubHeader: <StepNavigator />,
                    HeaderRight: <AccountButton />,
                    HeaderCenter: <HeaderCenterNewVideo />,
                    Selector: <ControlPanel />,
                    navOnTheRight: true,
                    sidebarIsNextStep: true,
                };
            } else if (tabname === 'step4') {
                return {
                    HeaderLeft: <SelectRecipient />,
                    HeaderRight: <AccountButton />,
                    sidebarIsNextStep: false,
                };
            } else {
                return {
                    HeaderLeft: <SelectRecipient />,
                    HeaderRight: <AccountButton />,
                    SubHeader: <StepNavigator />,
                    HeaderCenter: <HeaderCenterNewVideo />,
                };
            }
        }
        case 'home': {
            return {
                HeaderLeft: (
                    <>
                        <CurrentConsumerSelect />
                    </>
                ),
                HeaderRight: (
                    <>
                        <AccountButton />
                    </>
                ),
                NavItems: (
                    <>
                        <NavItem
                            icon={HomeIcon}
                            screen={'/app/home'}
                            label="Hjem"
                        />
                        <LinkToCreatorsIfOrganization />
                        <NavItem
                            icon={UsersIcon}
                            screen={'/app/users'}
                            label="Brukere"
                            indentChildren={false}
                        >
                            <ConsumerSelector />
                        </NavItem>
                        <NavButton
                            leadingIcon={PlusIcon}
                            to={'/app/users/new'}
                            label="Ny bruker"
                        />
                        <RepeatOnboardingButton />
                    </>
                ),
            };
        }
        case 'users': {
            return {
                HeaderLeft:
                    'new' === tabname ? (
                        <div className="inline-flex justify-start w-full items-center text-xl space-x-8 ">
                            <h1 className="font-logo opacity-80  whitespace-nowrap ">
                                Ny bruker
                            </h1>
                        </div>
                    ) : (
                        <CurrentConsumerSelect disabled />
                    ),

                HeaderRight: (
                    <>
                        <AccountButton />
                    </>
                ),
                NavItems: (
                    <>
                        <NavItem
                            icon={HomeIcon}
                            screen={'/app/home'}
                            label="Hjem"
                        />

                        <NavItem
                            icon={UsersIcon}
                            screen={'/app/users'}
                            label="Brukere"
                            indentChildren={false}
                            isActive={true}
                        >
                            <ConsumerSelector />
                        </NavItem>
                        <NavButton
                            key="kebab"
                            leadingIcon={PlusIcon}
                            to={'/app/users/new'}
                            label="Ny bruker"
                            isActive={'new' === tabname}
                        />
                    </>
                ),

                // Selector: <ConsumerSelector />,
            };
        }
        case 'nextofkins': {
            return {
                HeaderLeft: <CurrentConsumerSelect disabled />,
                HeaderRight: (
                    <>
                        <AccountButton />
                    </>
                ),
                NavItems: (
                    <>
                        <NavItem
                            icon={HomeIcon}
                            screen={'/app/home'}
                            label="Hjem"
                        />

                        <NavItem
                            icon={UsersIcon}
                            screen={'/app/users'}
                            label="Brukere"
                            indentChildren={false}
                            isActive={true}
                        >
                            <ConsumerSelector />
                        </NavItem>
                    </>
                ),
            };
        }
        case 'image-upload': {
            return {
                ContentHeader: (
                    <div className="inline-flex w-full justify-between items-center">
                        <HomeButton title="Hjem" />
                    </div>
                ),
                HeaderLeft: <CurrentConsumerSelect disabled />,
                HeaderRight: (
                    <>
                        <AccountButton />
                    </>
                ),
                Selector: <SubmitPanel />,
                navOnTheRight: true,
                sidebarIsNextStep: true,
            };
        }
        case 'nextofkin-image-upload': {
            return {
                ContentHeader: (
                    <div className="inline-flex w-full justify-between items-center">
                        <HomeButton title="Hjem" />
                    </div>
                ),
                HeaderLeft: (
                    <SelectConsumerAsNextofkin onSelect={() => null} disabled />
                ),
                HeaderRight: (
                    <>
                        <AccountButton />
                    </>
                ),
                Selector: <SubmitPanel isNextofkin={true} />,
                navOnTheRight: true,
                sidebarIsNextStep: true,
            };
        }
        case 'nextofkin-image-list': {
            return {
                HeaderLeft: (
                    <SelectConsumerAsNextofkin onSelect={() => null} disabled />
                ),
                HeaderRight: (
                    <>
                        <RepeatOnboardingButton isLight />
                        <AccountButton />
                    </>
                ),
            };
        }
        case 'nextofkin-profile': {
            return {
                ContentHeader: (
                    <div className="inline-flex w-full justify-between items-center">
                        <HomeButton title="Hjem" />
                    </div>
                ),
                HeaderLeft: (
                    <SelectConsumerAsNextofkin onSelect={() => null} disabled />
                ),
                HeaderRight: (
                    <>
                        <AccountButton />
                    </>
                ),
            };
        }
        case 'my-profile': {
            return {
                HeaderLeft: (
                    <div className="inline-flex justify-start w-full items-center text-xl space-x-8 ">
                        <LogoLink />
                        <h1 className="font-logo opacity-80  whitespace-nowrap ">
                            Min profil
                        </h1>
                    </div>
                ),
                ContentHeader: (
                    <div className="inline-flex w-full justify-between items-center">
                        <HomeButton title="Hjem" />
                    </div>
                ),

                HeaderRight: (
                    <>
                        <AccountButton />
                    </>
                ),
            };
        }
        case 'creators': {
            return {
                HeaderLeft: (
                    <div className="inline-flex justify-start w-full items-center text-xl space-x-8 ">
                        <h1 className="font-logo opacity-80  whitespace-nowrap ">
                            {tabname === 'new'
                                ? 'Ny brukerkontakter'
                                : tabname === 'profile'
                                ? 'Brukerkontakter / profil'
                                : 'Brukerkontakter'}
                        </h1>
                    </div>
                ),
                HeaderRight: (
                    <>
                        <AccountButton />
                    </>
                ),
                NavItems: (
                    <>
                        <NavItem
                            icon={HomeIcon}
                            screen={'/app/home'}
                            label="Hjem"
                        />
                        <NavItem
                            icon={UsersIcon}
                            label="Brukerkontakter"
                            screen="/app/creators"
                            indentChildren={false}
                            shouldLink={tabname === 'profile'}
                        >
                            <CreatorSelector />
                        </NavItem>

                        <NavButton
                            leadingIcon={PlusIcon}
                            to={'/app/creators/new'}
                            label="Ny brukerkontakt"
                            isActive={'new' === tabname}
                        />
                    </>
                ),
            };
        }

        case 'video':
        default: {
            return {};
        }
    }
};

type RouteParams = {
    subModule?: string;
    moduleName?: string;
    tabName?: string;
    id?: string;
};

type State = {
    loading: boolean;
    tabName?: string;
    screenProps: AppScreenProps;
    prevKey: string;
};

type Props = RouteComponentProps<{
    moduleName?: string;
    tabName?: string;
    subModule?: string;
    id?: string;
}> &
    Readonly<{
        children: (key: string) => ReactElement;
    }>;

export class ModuleSelector extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const { moduleName, tabName, subModule } = getParams(
            props.location.pathname,
        );
        this.state = {
            screenProps: getScreenProps(moduleName, tabName, subModule),
            loading: false,
            prevKey: '',
            tabName,
        };
    }
    onClickTabItem(tabName: string) {
        this.setState({ tabName });
    }
    loadModule = (onLoad: () => void) => {
        return new Promise(resolve => {
            setTimeout(() => resolve(onLoad()), 200);
        });
    };

    async componentDidUpdate(prevProps: Props) {
        const { pathname } = this.props.location;
        const { pathname: prevPathname } = prevProps.location;
        const { moduleName, tabName, subModule } = getParams(pathname);
        const prevParams = getParams(prevPathname);

        if (pathname === '/app' && pathname !== prevPathname) {
            this.setState({ loading: true });
            const screenProps = getScreenProps(moduleName, tabName, subModule);
            this.setState({ screenProps: screenProps });
            this.loadModule(() => {
                this.setState({ loading: false });
            });
            this.setState({ prevKey: prevPathname });
        }
        if (
            (moduleName && moduleName !== prevParams.moduleName) ||
            (subModule && prevParams.subModule === undefined) ||
            (!subModule && prevParams.subModule !== undefined)
        ) {
            this.setState({ loading: true });
            const screenProps = getScreenProps(moduleName, tabName, subModule);
            this.setState({ screenProps: screenProps });
            this.loadModule(() => {
                this.setState({ loading: false });
            });
            this.setState({ prevKey: prevPathname });
        } else if (tabName && tabName !== prevParams.tabName) {
            const screenProps = getScreenProps(moduleName, tabName, subModule);
            this.setState({ screenProps: screenProps });
            // this.setState({
            //     loading: true,
            // });
            // this.loadModule();
            // const screenProps = await getTabProps(tabName);
            // this.setState({ screenProps: screenProps });
        }
    }

    render() {
        const { screenProps, loading } = this.state;
        return (
            <AppScreen {...screenProps}>
                <Module prevKey={this.state.prevKey} loading={loading}>
                    {this.props.children(this.props.match.url)}
                </Module>
            </AppScreen>
        );
    }
}
