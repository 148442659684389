type PaginateButtonProps = {
    hasMore: boolean;
    nextPage?: () => void;
};

export const PaginateButton = ({ hasMore, nextPage }: PaginateButtonProps) => {
    return (
        <button
            className="secondary-btn-small"
            disabled={!hasMore}
            onClick={nextPage}
        >
            Vis flere
        </button>
    );
};
