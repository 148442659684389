import { SpinnerIconSVG } from 'src/assets/svg';
import { Button } from 'src/components/Buttons/Button';
import container from 'src/container';
import { getFullName } from 'src/domain/user';
import useSWR, { mutate } from 'swr';
import { useHistory, useParams } from 'react-router-dom';
import { useNotification } from 'src/components/Notification/useNotfication';
import { Section } from 'src/components/Section';
import { format, parseISO } from 'date-fns';
import nb from 'date-fns/locale/nb';

const {
    cradle: { munduClient },
} = container;

export const OrgInfo = () => {
    const { id } = useParams<{ id: string }>();
    const notify = useNotification();
    const history = useHistory();
    const { data } = useSWR([`orginfo`, id], (_, id) =>
        munduClient.getOrganization(id),
    );

    if (!data)
        return (
            <div className="grid justify-center">
                <SpinnerIconSVG className="animate-spin h-8 w-8 text-woodyBrown" />
            </div>
        );
    const onDelete = () => {
        munduClient
            .deleteOrganization(id)
            .then(() => {
                notify('Organisasjon slettet');
                mutate('organizations');
                history.push('/app/admin');
            })
            .catch((error: Error) => notify(error.message, true));
    };
    return (
        <Section title={data.organizationDisplayName}>
            <div className="w-full">
                <p className="flex justify-between">
                    <span className="font-semibold">SuperBruker:</span>
                    {getFullName(data)}
                </p>
                <p className="flex justify-between">
                    <span className="font-semibold">Telefonnummer:</span>
                    {data.profile.phoneNumber}
                </p>
                <p className="flex justify-between">
                    <span className="font-semibold">Epost:</span>
                    {data.email}
                </p>
                <p className="flex justify-between">
                    <span className="font-semibold">OrgNavn:</span>
                    {data.organizationName}
                </p>
                <p className="flex justify-between">
                    <span className="font-semibold">Lisenser:</span>
                    {data.licence}
                </p>
                <p className="flex justify-between">
                    <span className="font-semibold">Opprettet:</span>
                    {format(parseISO(data.createdDate), '(dd MMM YYY)', {
                        locale: nb,
                    })}
                </p>
                <p className="flex justify-between">
                    <span className="font-semibold">Oppdatert:</span>
                    {data.updatedDate &&
                        format(parseISO(data.updatedDate), '(dd MMM YYY)', {
                            locale: nb,
                        })}
                </p>
                <div className="inline-flex w-full justify-end py-6">
                    <Button title="Slett" onClick={onDelete} />
                </div>
            </div>
        </Section>
    );
};
