import {
    IsNotEmpty,
    IsOptional,
    IsString,
    ValidateNested,
    IsEnum,
    MaxLength,
} from 'class-validator';
import { UpdatePlaylistDto } from 'src/api/music.params';
import { Status, Visibility } from './playlist';

export class UpdatePlaylistSchema implements UpdatePlaylistDto {
    @IsString()
    @IsNotEmpty({ message: 'Listen må ha et navn' })
    @MaxLength(500, { message: 'Maksimalt 500 tegn' })
    name: string;

    @IsOptional()
    @IsEnum(Status)
    status?: Status;

    @IsOptional()
    @IsString()
    @MaxLength(500, { message: 'Maksimalt 500 tegn' })
    description?: string;

    @IsOptional()
    @IsEnum(Visibility)
    visibility?: Visibility;

    @IsOptional()
    @ValidateNested()
    annotations?: { [key: string]: string };
}
