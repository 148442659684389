import { useState } from 'react';
import { SearchInput } from 'src/components/SearchInput';
import { Track } from 'src/domain/music';
import { Spinner } from 'src/view/components/loading/Spinner';
import { useTrackSearch } from 'src/view/hooks/swr/useTrackSearch';
import { PaginateButton } from '../../components/paginateButton';
import { TrackList } from '../../components/Track/list';

type AddToPlaylistProps = {
    onAddTrack?: (track: Track) => void;
};

export const AddToPlaylist = ({ onAddTrack }: AddToPlaylistProps) => {
    const [query, setQuery] = useState<string>();
    const { tracks, nextPage, hasMore, isLoading, isLoadingNext } =
        useTrackSearch(query);

    return (
        <div className="w-full space-y-6 ">
            <SearchInput
                onSearchChange={setQuery}
                placeholder="Søk etter flere sanger"
            />
            {tracks && (
                <TrackList
                    tracks={tracks}
                    displayOption="CARD"
                    onAddTrack={onAddTrack}
                />
            )}
            {(isLoading || isLoadingNext) && !!query && (
                <div className="w-full flex justify-center">
                    <Spinner />
                </div>
            )}
            {tracks && tracks.length > 0 && (
                <PaginateButton hasMore={hasMore} nextPage={nextPage} />
            )}

            {tracks && tracks.length === 0 && (
                <div className="flex flex-col w-full items-center space-y-2">
                    <div className="block-inline text-lg text-center ">
                        Fant ingen søkresultater for
                        <span className="font-bold">&quot;{query}&quot;</span>
                    </div>
                    <div className="block-inline text-md text-center">
                        Sjekk at søket er korrekt stavet eller benytt færre
                        eller andre søkeord...
                    </div>

                    <div className="w-3/5 text-sm italic text-center">
                        Savner du en spesifikk artist, utgivelse eller låt?
                        Mundu-katalogen utvides stadig... Etterhvert som ny
                        musikk legges til vil den være tilgjenglig gjennom søk.
                    </div>
                </div>
            )}
        </div>
    );
};
