import { forwardRef, memo, useState } from 'react';
import ReactPlayer from 'react-player';
import { MediaIconSVG } from 'src/assets/svg';
import { AZURE_STORAGE_URL } from 'src/config';

type PromoVideoProps = {
    bgColor: string;
    id: string;
};
const PromoVideo = forwardRef<HTMLElement, PromoVideoProps>(
    ({ bgColor = 'woodyBrown', id }: PromoVideoProps, ref) => {
        const [isPlaying, setIsPlaying] = useState(false);
        const togglePlay = () => {
            setIsPlaying(!isPlaying);
        };
        const thumbnailUrl = `${AZURE_STORAGE_URL}/landingpage-content/tippetue_premiere.jpg`;
        const videoUrl = `${AZURE_STORAGE_URL}/landingpage-content/Vigdis%20-%20webside.mp4`;
        return (
            <section
                ref={ref}
                id={id}
                className={`relative z-10 bg-${bgColor}`}
            >
                <div className="relative w-full h-full pb-thumbHeight">
                    <div
                        className="absolute inset-0 cursor-pointer"
                        onClick={togglePlay}
                    >
                        <ReactPlayer
                            className="shadow-md object-cover "
                            id="video"
                            url={videoUrl}
                            playing={isPlaying}
                            light={thumbnailUrl}
                            width="100%"
                            height="100%"
                            controls={false}
                            playIcon={<MediaIconSVG />}
                        />
                    </div>
                </div>
            </section>
        );
    },
);
PromoVideo.displayName = 'PromoVideo';

export default PromoVideo;
