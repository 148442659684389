import { useState, useEffect } from 'react';
import container from 'src/container';
import avatarDefault from 'src/assets/images/client_default.png';
import { useAvatarSas } from './consumer';

const {
    cradle: { storageService },
} = container;

export const useAvatar = (avatar?: string) => {
    const [src, setSrc] = useState<string>(avatarDefault);
    const { sas } = useAvatarSas();
    useEffect(() => {
        setSrc(storageService.getImageUrl(avatar, sas) || avatarDefault);
    }, [avatar, sas]);

    return { src, isDefault: src === avatarDefault };
};
