import { BrowserRouter } from 'react-router-dom';
import Colors from 'src/assets/Colors';
import Fonts from 'src/assets/Fonts';
import { ReduxProvider } from 'src/state';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Routes from './routes/Routes';
import { MsalProvider } from '@azure/msal-react';
import container from 'src/container';
import 'rc-collapse/assets/index.css';

const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'readableFont';
    font-style: normal;
    src: url(${Fonts.readableFont});
  }
  html {
    height: 100%;
  }
  body {
    height: 100%;
    background: ${Colors.lightPeach};
    background-attachment:fixed;
    font-family: 'readableFont';
    color: ${Colors.woodyBrown};
    #root {
      height: 100%;
    }
  }

`;

const Application = () => {
    return (
        <MsalProvider instance={container.cradle.msal}>
            <ThemeProvider theme={{}}>
                <GlobalStyle />
                <ReduxProvider>
                    <BrowserRouter>
                        <div id="modal-root" />
                        <Routes />
                    </BrowserRouter>
                </ReduxProvider>
            </ThemeProvider>
        </MsalProvider>
    );
};

export default Application;
