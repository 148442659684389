import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { TextIcon } from '../Icons/TextIcon';
import { PlusIcon } from '../Icons/icons';

type Props = {
    to: string;
    label: string;
    disabled?: boolean;
    routeParams?: Record<string, unknown>;
    aspect?: 'square' | 'video';
};

export const AddButton = ({
    to,
    routeParams,
    label,
    disabled = false,
    aspect = 'square',
}: Props) => {
    const history = useHistory();
    const onClick = () => history.push(to, routeParams);
    return (
        <div className="flex flex-col text-center items-center ">
            <button
                type="button"
                disabled={disabled}
                onClick={onClick}
                className={cx(
                    'bg-woodyBrown outline-none h-36 focus:outline-none flex items-center justify-center rounded-xl text-starkWhite ',
                    aspect === 'video' && 'w-60',
                    aspect === 'square' && 'w-36',
                )}
            >
                <TextIcon SVG={PlusIcon} />
            </button>
            <p
                className={cx(
                    'mt-2 font-medium w-[120px] transition-all whitespace-nowrap',
                    disabled && 'text-starkWhite',
                )}
            >
                {label}
            </p>
        </div>
    );
};
