import { Annotations, Links } from './common';

export enum Visibility {
    Private = 'private',
    Public = 'public',
    Partner = 'partner',
    User = 'user',
    All = 'all',
}
export enum Status {
    Draft = 'draft',
    Published = 'published',
}

export interface PlaylistTrack {
    playlistItemId: string;
    trackId: number;
    trackTitle: string;
    trackVersion: string;
    artistAppearsAs: string;
    releaseId: string;
    releaseTitle: string;
    releaseArtistAppearsAs: string;
    releaseVersion: string;
    source: string;
    audioUrl: string;
    user: string;
}

export interface ListPlaylistTrack
    extends Omit<PlaylistTrack, 'playlistItemId'> {
    id: string;
}

export interface Playlist {
    id: string;
    name: string;
    status: Status;
    visibility: Visibility;
    image: string;
    description: string;
    tracks: PlaylistTrack[];
    annotations: Annotations;
    lastUpdated: Date;
}
export type UserPlaylist = Omit<Playlist, 'tracks'> & {
    _links: Links;
    trackCount: number;
};

export type PlaylistDetails = Omit<Playlist, 'tracks'>;

export interface ListPlaylistItem {
    id: string;
    name: string;
    status: Status;
    visibility: Visibility;
    image: string;
    partnerId: string;
    shopId: number;
    tags: string[];
    description: string;
    tracks: PlaylistTrack[];
    trackCount: number;
    _links: Links;
    annotations: Annotations;
    lastUpdated: Date;
}

export interface PlaylistGroup {
    id: string;
    name: string;
    playlistCount: number;
    lastUpdated: Date;
    _links: Links;
}
