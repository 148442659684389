import { useEffect } from 'react';
import {
    removeItem,
    updateDescription,
    setRecipient,
    clearState,
} from './state';
import { useImageUpload } from './state/hooks';
import { Item } from './state/types';
import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { useUnloadBlocker } from 'src/view/hooks';
import { AutoSizeGrid } from 'src/components/AutoSizeGrid';
import { PreviewItem } from './PreviewItem';
import { FileDrop } from './FileDrop';

export const ImageUpload = () => {
    const { state, dispatch, handleAcceptedFiles } = useImageUpload();

    useUnloadBlocker(state.items.length !== 0);

    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();

    useEffect(() => {
        if (!currentConsumer) return;
        if (state.recipient?.id !== currentConsumer.id) {
            dispatch(clearState());
        }
        dispatch(setRecipient(currentConsumer));
    }, [currentConsumer, dispatch, state.recipient]);

    return (
        <div className="flex flex-col h-full -mt-4 -mx-6">
            <div className="flex gap-5 grow shadow-inner bg-black bg-opacity-5 rounded-lg">
                <FileDrop handleAcceptedFiles={handleAcceptedFiles}>
                    {state.items.length > 0 && (
                        <AutoSizeGrid<Item>
                            items={state.items}
                            Item={PreviewItem}
                            itemProps={{
                                onClick: index => dispatch(removeItem(index)),
                                onChange: (index, event) =>
                                    dispatch(
                                        updateDescription(
                                            index,
                                            event.currentTarget.value,
                                        ),
                                    ),
                            }}
                            aspectRatio={7 / 5}
                        />
                    )}
                </FileDrop>
            </div>
        </div>
    );
};
