import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Buttons/Button';
import container from 'src/container';
import { Playlist } from 'src/domain/music';
import { useUserPlaylists } from 'src/view/hooks/swr/useUserPlaylists';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { Modal } from '../../components/modal';

const {
    cradle: { musicService },
} = container;

export const DeleteButton = ({
    id,
    name,
}: {
    id: Playlist['id'];
    name: Playlist['name'];
}) => {
    const { refresh } = useUserPlaylists();
    const history = useHistory();
    const userId = useSignedInUser()?.id;
    const [showModal, setShowModal] = useState(false);

    const onDeletePlayList = async (id: Playlist['id']) => {
        if (!userId) return;
        await musicService.deletePlaylist(userId, id);
        refresh();
        setShowModal(false);
        history.goBack();
    };
    return (
        <div>
            <Button title="Slett" onClick={() => setShowModal(true)} />
            <Modal isVisible={showModal}>
                <div className="flex flex-col bg-lightPeach rounded-16px justify-center space-y-4 p-4">
                    <h1 className="text-lg">
                        Slett spillelisten{' '}
                        <span className="font-bold">{name}</span>?{' '}
                    </h1>
                    <div>Listen vil ikke kunne gjennopprettes.</div>
                    <div className="flex space-x-4">
                        <Button
                            title="Slett"
                            onClick={() => onDeletePlayList(id)}
                        />
                        <button
                            className="hover:underline"
                            onClick={() => setShowModal(false)}
                            type="button"
                        >
                            Avbryt
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
