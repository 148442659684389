import { useMemo } from 'react';
import { UserRole } from 'src/domain/user';
import { useSignedInUser } from './useSignedInUser';

// hook check if user logged in
export const useRoleUserAuthenticated = (
    requiredRoles: UserRole[] | undefined,
): boolean => {
    const user = useSignedInUser();

    const auth = useMemo(() => {
        if (!requiredRoles) {
            return true;
        }
        if (!user) {
            return false;
        }

        const roleAccepted = requiredRoles.includes(user.role);

        return roleAccepted;
    }, [requiredRoles, user]);

    return auth;
};
