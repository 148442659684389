import { useEffect, useMemo, useState } from 'react';
import { Consumer } from 'src/domain/consumer';
import { AppModal } from 'src/components/Modals/AppModal';
import { useConsumers } from 'src/view/hooks';
import { ConsumerSelectOption } from './Option';
import { UserSelectorOption } from './SelectorOption';
import { LockIcon, CaretIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';

type Props = {
    current?: Consumer['id'];
    label?: string;
    onSelect: (consumer: Consumer) => void;
    disabled?: boolean;
    isLight?: boolean;
};

export const SelectConsumer = ({
    current,
    label = 'Velg bruker',
    onSelect,
    disabled = false,
    isLight = true,
}: Props) => {
    const { consumers } = useConsumers();
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);
    const currentConsumer = consumers.find(x => x.id === current);
    const consumersStringified = useMemo(
        () => JSON.stringify(consumers),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [consumers.length],
    );

    useEffect(() => {
        if (!currentConsumer && consumers[0]) {
            onSelect(consumers[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentConsumer, consumersStringified]);

    return (
        <>
            <div className="relative bg-transparent">
                {currentConsumer ? (
                    <ConsumerSelectOption
                        key={currentConsumer.id}
                        isLight={isLight}
                        disabled={disabled}
                        onClick={toggleModal}
                        consumer={currentConsumer}
                    />
                ) : (
                    <button
                        className="flex pt-0.5 text-sm hover:bg-woodyBrown hover:text-lightPeach h-10 rounded-md items-center stroke-[1.8px] justify-between px-4 pr-2 transition-all duration-200 ease-in-out"
                        onClick={toggleModal}
                        disabled={disabled}
                    >
                        <span className="pb-1 whitespace-nowrap ">{label}</span>
                        <TextIcon SVG={disabled ? LockIcon : CaretIcon} />
                    </button>
                )}
                <AppModal
                    clickOutside={() => setModalOpen(false)}
                    show={modalOpen}
                >
                    <div className="animate-fade-in-fast origin-top-left z-40 absolute bg-lightPeach text-woodyBrown w-[256px] left-0  rounded-b-[16px] shadow-xl overflow-y-scroll max-h-72 ">
                        {consumers.map(consumer => {
                            return (
                                <UserSelectorOption
                                    key={consumer.id}
                                    isLight={true}
                                    onClick={() => {
                                        toggleModal();
                                        onSelect(consumer);
                                    }}
                                    isSelected={
                                        currentConsumer?.id === consumer.id
                                    }
                                    user={consumer}
                                />
                            );
                        })}
                    </div>
                </AppModal>
            </div>
        </>
    );
};
