export const PrevIcon = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 100 100">
            <polygon className="fill-current" points="0,50 100,0 100,100" />
            <line
                className="stroke-current"
                strokeLinecap="round"
                strokeWidth="40"
                x1="0"
                y1="0"
                x2="0"
                y2="100"
            />
        </svg>
    );
};
