import {
    createRef,
    KeyboardEventHandler,
    useEffect,
    KeyboardEvent,
} from 'react';
import { KeyboardEventKey } from './types';

export function useKeyboard(map: { [key in KeyboardEventKey]?: () => void }) {
    const ref = createRef<HTMLDivElement>();

    useEffect(() => {
        const element = ref.current;
        if (element) {
            element.focus();
        }
    }, [ref]);

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (
        e: KeyboardEvent<HTMLDivElement>,
    ) => map[e.key]?.();

    return {
        tabIndex: 1,
        ref,
        onKeyDown: handleKeyDown,
    };
}
