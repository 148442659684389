import { useState } from 'react';
import { SearchInput } from 'src/components/SearchInput';
import { Creator } from 'src/domain/creator';
import { Artist } from 'src/domain/music';
import { Organization } from 'src/domain/organization';
import { getFullName } from 'src/domain/user';
import { Spinner } from 'src/view/components/loading/Spinner';
import { useArtistSearch } from 'src/view/hooks/swr/useArtistSearch';
import { ArtistList } from '../BrowseMusic/components/Artist/list';
import { PaginateButton } from '../BrowseMusic/components/paginateButton';
import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { useSurveyContext } from './reducer';

type Props = {
    author: Creator | Organization | 'Mundu';
    editable?: boolean;
};

export const FavoriteArtists = ({ editable }: Props) => {
    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();
    const {
        dispatch,
        state: { artists },
    } = useSurveyContext();

    const toggleArtist = (artist: Artist) => {
        return dispatch({ type: 'TOGGLE_ARTIST', payload: artist });
    };
    const consumerName = currentConsumer ? getFullName(currentConsumer) : '';
    return (
        <div>
            {/* {currentConsumer && (
                <FavoriteArtistsBanner author={currentConsumer} />
            )} */}

            <h2 className="font-bold text-3xl font-logo pb-4 ">
                Favorittartister{' '}
                <span
                    id="entity"
                    className=" font-body text-[8px] lg:text-sm font-extralight"
                >
                    ARTISTER
                </span>
            </h2>

            <div className="flex flex-col h-full space-y-6">
                <ArtistList
                    artists={artists}
                    onRemove={editable ? toggleArtist : undefined}
                />

                <div className="flex flex-col w-full">
                    {artists.length === 0 && (
                        <div className="text-lg w-full md:w-1/2 text-center self-center py-6  ">
                            {editable
                                ? `Ingen favorittartister er registrert for ${consumerName}, bruk søkefeltet nedenfor for å finne artister å legge til.`
                                : `Ingen favorittartister er registrert for ${consumerName}. Gjennomfør musikalsk kartlegging for å legge til artister.`}
                        </div>
                    )}
                    {editable && (
                        <AddToFavoriteArtists toggleSelected={toggleArtist} />
                    )}
                </div>
            </div>
        </div>
    );
};

type AddToFavoriteArtistsProps = {
    toggleSelected?: (release: Artist) => void;
};

const AddToFavoriteArtists = ({
    toggleSelected,
}: AddToFavoriteArtistsProps) => {
    const [query, setQuery] = useState<string>();
    const { artists, nextPage, hasMore, isLoading, isLoadingNext } =
        useArtistSearch(query);

    return (
        <div className="w-full space-y-6 ">
            <SearchInput
                onSearchChange={setQuery}
                placeholder="Søk etter flere artister"
            />

            {artists && (
                <ArtistList artists={artists} onSelect={toggleSelected} />
            )}
            {(isLoading || isLoadingNext) && !!query && (
                <div className="w-full flex justify-center">
                    <Spinner />
                </div>
            )}
            {artists && artists.length > 0 && (
                <PaginateButton hasMore={hasMore} nextPage={nextPage} />
            )}

            {artists && artists.length === 0 && (
                <div className="flex flex-col w-full items-center space-y-2">
                    <div className="block-inline text-lg text-center ">
                        Fant ingen søkresultater for
                        <span className="font-bold">&quot;{query}&quot;</span>
                    </div>
                    <div className="block-inline text-md text-center">
                        Sjekk at søket er korrekt stavet eller benytt færre
                        eller andre søkeord...
                    </div>

                    <div className="w-3/5 text-sm italic text-center">
                        Savner du en spesifikk artist? Mundu-katalogen utvides
                        stadig... Etterhvert som ny musikk legges til vil den
                        være tilgjenglig gjennom søk.
                    </div>
                </div>
            )}
        </div>
    );
};
