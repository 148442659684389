import { pickBy } from 'lodash';
import { SEVEN_DIGITAL_PARAMS } from 'src/config';
import qs from 'query-string';
import {
    ListPlaylistTracksResponse,
    CustomPlaylistTrack,
    GetTrackDetailsBatchResponse,
    Release,
    Artist,
    ReportSubscriberPayload,
    ReportPreviewPayload,
    TrackSearchResponse,
    ArtistSearchResponse,
    ReleaseSearchResponse,
    ListPlaylistsResponse,
    GetPlaylistResponse,
    Playlist,
    PlaylistTrack,
    ReleaseTracksRepsonse,
    ListArtistReleasesResponse,
    ListArtistTopTracksResponse,
    CreatePlaylistResponse,
    CreatePlaylistGroupResponse,
    ListGenresResponse,
} from 'src/domain/music';
import {
    ListPlaylistTracksParams,
    ImageSize,
    TrackSearchParams,
    ArtistSearchParams,
    ReleaseSearchParams,
    ListPlaylistParams,
    PatchPlaylistTracksDto,
    ListArtistReleasesParams,
    ListArtistTopTracksParams,
    CreatePlaylistDto,
    UpdatePlaylistDto,
    CreatePlaylistGroupDto,
    ListGenresParams,
} from 'src/api/music.params';
import { ProxyService } from './proxy.service';
import { HTTPError } from 'ky';
import { add } from 'date-fns';

const getParams = (endpoint: string, params?: Record<string, any>) => {
    const target = `${endpoint}?${qs.stringify(
        pickBy(
            { ...params, country: SEVEN_DIGITAL_PARAMS.country },
            value => value !== undefined,
        ),
    )}`;
    return { params: { target } };
};
export class MusicService {
    private api: ProxyService;

    constructor({ proxyService }: { proxyService: ProxyService }) {
        this.api = proxyService;
    }

    listPlaylistTracks = (params: ListPlaylistTracksParams) =>
        this.api.get<ListPlaylistTracksResponse>(
            '7digital',
            getParams(`playlists/${params.playlistId}/tracks`, params),
        );

    listPlaylistTracksAsTracks = async (params: ListPlaylistTracksParams) => {
        const playlistTrackItems = await this.listPlaylistTracks(params);
        const trackIds = playlistTrackItems.tracks.map(
            ({ trackId }) => trackId,
        );
        const itemIds = playlistTrackItems.tracks.map(track => track.id);

        if (trackIds.length > 0) {
            const trackBatch = await this.getTrackDetails(trackIds, 180);
            return trackBatch.items.tracks.map((obj, index) => ({
                ...obj,
                playlistItemId: itemIds[index],
            })) as CustomPlaylistTrack[];
        }
        return [] as CustomPlaylistTrack[];
    };

    listArtists = (artistIds: number[]) =>
        Promise.all(
            artistIds.map(id =>
                this.getArtistDetails(id).then(({ artist }) => artist),
            ),
        );

    getTrackDetails = (trackIds: number[], imageSize?: ImageSize) =>
        this.api.get<GetTrackDetailsBatchResponse>(
            '7digital',
            getParams('track/details/batch', {
                trackIds,
                usageTypes: SEVEN_DIGITAL_PARAMS.usageTypes,
                imageSize,
            }),
        );

    getReleaseDetails = (releaseId: number, imageSize?: ImageSize) =>
        this.api.get<{ release: Release }>(
            '7digital',
            getParams('release/details', {
                releaseId,
                usageTypes: SEVEN_DIGITAL_PARAMS.usageTypes,
                country: SEVEN_DIGITAL_PARAMS.country,
                imageSize,
            }),
        );

    getArtistDetails = (artistId: number) =>
        this.api.get<{ artist: Artist }>(
            '7digital',
            getParams('artist/details', {
                artistId,
                usageTypes: SEVEN_DIGITAL_PARAMS.usageTypes,
            }),
        );

    getPreviewStream = (userId: string, trackId: number): Promise<string> =>
        this.api.get<string>('stream/preview', {
            ...getParams(`clip/${trackId}`),
            deserialize: res => res.text(),
        });
    getSubscriberStream = (userId: string, trackId: number): Promise<string> =>
        this.api.get<string>('stream', {
            ...getParams('stream/subscription', {
                userId: userId,
                trackId: trackId,
                formatId: SEVEN_DIGITAL_PARAMS.formatId,
            }),
            deserialize: res => res.text(),
        });

    getSubscriberStreamIn320 = (userId: string, trackId: number) =>
        this.api.get<string>('stream', {
            ...getParams('stream/subscription', {
                userId: userId,
                trackId: trackId,
                formatId: 33,
            }),
            deserialize: res => res.text(),
        });

    reportSubscriberStream = (
        userId: string,
        reportBody: ReportSubscriberPayload,
    ) =>
        this.api.post<string>('7digital', {
            ...getParams('user/subscription/log', { userId }),
            payload: { playLogItem: [reportBody] },
        });

    reportPreviewStream = (reportBody: ReportPreviewPayload) =>
        this.api.post('preview/log', { payload: { logs: [reportBody] } });

    trackSearch = (params: TrackSearchParams) =>
        this.api.get<TrackSearchResponse>(
            '7digital',
            getParams('track/search', {
                q: params.query,
                imageSize: params.imageSize,
                usageTypes: SEVEN_DIGITAL_PARAMS.usageTypes,
                page: params.page,
                pageSize: params.pageSize,
            }),
        );
    artistSearch = (params: ArtistSearchParams) =>
        this.api.get<ArtistSearchResponse>(
            '7digital',
            getParams('artist/search', {
                q: params.query,
                sort: params.sort,
                imageSize: params.imageSize,
                usageTypes: SEVEN_DIGITAL_PARAMS.usageTypes,
                page: params.page,
                pageSize: params.pageSize,
            }),
        );

    releaseSearch = (params: ReleaseSearchParams) =>
        this.api.get<ReleaseSearchResponse>(
            '7digital',
            getParams('release/search', {
                q: params.query,
                type: params.type,
                imageSize: params.imageSize,
                usageTypes: SEVEN_DIGITAL_PARAMS.usageTypes,
                page: params.page,
                pageSize: params.pageSize,
            }),
        );

    listPlaylists = (params: ListPlaylistParams) =>
        this.api.get<ListPlaylistsResponse>(
            '7digital',
            getParams('playlists', params),
        );

    getPlaylist = (playlistId: string, userId?: string) =>
        this.api.get<GetPlaylistResponse>(
            '7digital',
            getParams(`playlists/${playlistId}`, { userId }),
        );

    addPlaylistTracks = (
        userId: string,
        playlistId: Playlist['id'],
        payload: PatchPlaylistTracksDto[],
    ): Promise<PlaylistTrack[]> =>
        this.api.post<PlaylistTrack[]>('7digital', {
            ...getParams(`playlists/${playlistId}/tracks`, { userId }),
            payload,
        });

    listReleaseTracks = (releaseId: number, imageSize: ImageSize) =>
        this.api.get<ReleaseTracksRepsonse>(
            '7digital',
            getParams(`release/tracks`, {
                releaseId,
                imageSize,
                usageTypes: SEVEN_DIGITAL_PARAMS.usageTypes,
            }),
        );

    listArtistReleases = (params: ListArtistReleasesParams) =>
        this.api.get<ListArtistReleasesResponse>(
            '7digital',
            getParams('artist/releases', {
                ...params,
                usageTypes: SEVEN_DIGITAL_PARAMS.usageTypes,
            }),
        );

    listArtistTopTracks = (params: ListArtistTopTracksParams) =>
        this.api.get<ListArtistTopTracksResponse>(
            '7digital',
            getParams('artist/toptracks', {
                ...params,
                usageTypes: SEVEN_DIGITAL_PARAMS.usageTypes,
            }),
        );

    createPlaylist = (userId: string, payload: CreatePlaylistDto) =>
        this.api.post<CreatePlaylistResponse>('7digital', {
            ...getParams('playlists', { userId }),
            payload,
        });

    updatePlaylist = (
        userId: string,
        playlistId: string,
        payload: UpdatePlaylistDto,
    ) =>
        this.api.post<boolean>('7digital', {
            ...getParams(`playlists/${playlistId}`, { userId }),
            payload,
        });

    deletePlaylist = (userId: string, playlistId: string) =>
        this.api.delete<boolean>(
            '7digital',
            getParams(`playlists/${playlistId}`, { userId }),
        );

    removePlaylistTrack = (
        userId: string,
        playlistId: Playlist['id'],
        playlistItemId: PlaylistTrack['playlistItemId'],
    ) =>
        this.api.delete<Playlist[]>(
            '7digital',
            getParams(`playlists/${playlistId}/tracks/${playlistItemId}`, {
                userId,
            }),
        );

    createPlaylistGroup = (userId: string, payload: CreatePlaylistGroupDto) =>
        this.api.post<CreatePlaylistGroupResponse>('7digital', {
            ...getParams('user/playlists/groups', {
                userId,
            }),
            payload,
        });

    listGenres = (params: ListGenresParams) =>
        this.api.get<ListGenresResponse>('7digital', {
            ...getParams('tag', params),
        });

    activateTestUser = (userId: string) => {
        this.api
            .get<{
                streaming: {
                    level: string;
                    expiryDate: string;
                };
            }>('7digital', {
                ...getParams('user/unlimitedStreaming', { userId }),
            })
            .then(
                res => {
                    const {
                        streaming: { expiryDate, level },
                    } = res;
                    const now = new Date();
                    const expiresAt = new Date(expiryDate);
                    if (
                        expiresAt.getTime() - now.getTime() < 0 ||
                        level === 'none'
                    ) {
                        const expiryDate = add(now, { months: 1 });
                        console.log(expiryDate, expiresAt, now);
                        return this.api.post(
                            '7digital?target=user/unlimitedStreaming',
                            {
                                payload: {
                                    country: 'NO',
                                    activatedAt:
                                        now.toISOString().split('.')[0] + 'Z',
                                    currentPeriodStartDate:
                                        now.toISOString().split('.')[0] + 'Z',
                                    planCode: 'premium-unlimited-streaming',
                                    userId,
                                    currency: 'NOK',
                                    recurringFee: 0,
                                    status: 'active',
                                    expiryDate:
                                        expiryDate.toISOString().split('.')[0] +
                                        'Z',
                                },
                            },
                        );
                    }
                },
                (error: HTTPError) => {
                    if (error.response.status === 404) {
                        this.api.get('7digital', {
                            ...getParams('user/create', { userId }),
                        });
                    }
                },
            );
    };
}
