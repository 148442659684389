import useSWRInfinite from 'swr/infinite';
import container from 'src/container';
import { ImageSize } from 'src/api/music.params';

const {
    cradle: { musicService },
} = container;

export const useArtistReleases = (
    artistId?: number,
    imageSize: ImageSize = 180,
    pageSize = 10,
) => {
    const { data, size, setSize, mutate, error, isValidating } = useSWRInfinite(
        page =>
            artistId
                ? ['artist/releases', artistId, imageSize, page + 1, pageSize]
                : null,
        (_, artistId, imageSize, page, pageSize) =>
            musicService.listArtistReleases({
                artistId,
                imageSize,
                page,
                pageSize,
            }),
        { revalidateOnFocus: false },
    );

    const releases = data
        ? data.map(page => page.releases.releases).flat()
        : undefined;
    const isLoading = !data && !error;
    const isLoadingNext =
        isValidating ||
        (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.releases.releases.length === 0;
    const hasMore = !(
        isEmpty ||
        (data && data[data.length - 1]?.releases.releases.length < pageSize)
    );
    return {
        releases,
        nextPage: () => setSize(size + 1),
        setPageSize: setSize,
        refresh: mutate,
        hasMore,
        isLoading,
        isLoadingNext,
    };
};
