import { Colors } from 'src/assets';
import cx from 'classnames';

type Props = {
    onClick: () => void;
    classNameContainer?: string;
    active?: boolean;
    disabled?: boolean;
    label?: string;
};
export const ButtonAddMedia = ({
    onClick,
    classNameContainer = '',
    active = false,
    label = 'Legg til musikk',
    disabled = false,
}: Props) => {
    return (
        <div className="flex flex-col justify-center items-center flex-wrap relative">
            <button
                type="button"
                onClick={onClick}
                disabled={disabled}
                className={cx(
                    classNameContainer,
                    !active && 'bg-peachOrange',
                    active && 'bg-woodyBrown',
                    'disabled:bg-lightPeach disabled:border-1.4px disabled:border-starkWhite w-32 xl:w-36 rounded-xl xl:rounded-2xl aspect-square outline-none focus:outline-none flex items-center justify-center ',
                )}
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    className="w-6 h-6"
                >
                    <g
                        id="Icon_feather-plus"
                        data-name="Icon feather-plus"
                        transform="translate(-7.5 -7.5)"
                    >
                        <path
                            id="Path_99"
                            data-name="Path 99"
                            d="M18,7.5v12"
                            transform="translate(-4.5 0)"
                            fill="none"
                            stroke={
                                disabled
                                    ? Colors.starkWhite
                                    : active
                                    ? '#FFF3EB'
                                    : '#4B2F3E'
                            }
                            strokeLinejoin="round"
                            strokeWidth="1.4"
                        />
                        <path
                            id="Path_100"
                            data-name="Path 100"
                            d="M7.5,18h12"
                            transform="translate(0 -4.5)"
                            fill="none"
                            stroke={
                                disabled
                                    ? Colors.starkWhite
                                    : active
                                    ? '#FFF3EB'
                                    : '#4B2F3E'
                            }
                            strokeLinejoin="round"
                            strokeWidth="1.4"
                        />
                    </g>
                </svg>
            </button>
            <p
                className={cx(
                    'font-medium text-md -bottom-7 w-32 xl:w-36  text-center absolute whitespace-nowrap',
                    disabled && 'text-starkWhite',
                )}
            >
                {label}
            </p>
        </div>
    );
};
