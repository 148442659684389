import { ReactNode, useState } from 'react';
import { useRouteMatch, NavLink, Link, useHistory } from 'react-router-dom';
import cx from 'classnames';
import { IconComponent, TextIcon } from '../Icons/TextIcon';
import { CaretIcon } from '../Icons/icons';
import { WrapIf } from '../WrapIf';

const CustomNavLink = ({
    label,
    to,
    leadingIcon,
    isLight,
    onClick,
}: {
    label: string;
    to?: string;
    leadingIcon: IconComponent;
    isLight?: boolean;
    onClick?: () => void;
}) => {
    const isActive = Boolean(useRouteMatch(to || {}));

    return (
        <>
            {to ? (
                <NavLink
                    to={to}
                    className={cx(
                        'flex justify-start stroke-[1.6px] items-center px-4 rounded py-2 w-full bg-none duration-200 transition-all hover:cursor-pointer',
                        isActive &&
                            !isLight &&
                            'cursor-default hover:bg-black hover:bg-opacity-5 text-white hover:text-starkWhite',
                        !isActive &&
                            !isLight &&
                            'hover:bg-white hover:bg-opacity-5 hover:text-white',
                        isActive &&
                            isLight &&
                            'cursor-default hover:bg-woodyBrown woodyBrown hover:bg-opacity-5 text-woodyBrown  ',
                        !isActive &&
                            isLight &&
                            ' hover:bg-peachOrange hover:bg-opacity-20 text-woodyBrown',
                    )}
                    onClick={onClick}
                >
                    <TextIcon SVG={leadingIcon} />
                    <p
                        className={cx(
                            'tracking-tight align-middle text-left whitespace-nowrap',
                        )}
                    >
                        {label}
                    </p>
                </NavLink>
            ) : (
                <button
                    className={cx(
                        'flex justify-start stroke-[1.6px] items-center px-4 rounded py-2 w-full bg-none duration-200 transition-all hover:cursor-pointer',
                        isActive &&
                            !isLight &&
                            'cursor-default hover:bg-black hover:bg-opacity-5 text-white hover:text-starkWhite',
                        !isActive &&
                            !isLight &&
                            'hover:bg-white hover:bg-opacity-5 hover:text-white',
                        isActive &&
                            isLight &&
                            'cursor-default hover:bg-woodyBrown woodyBrown hover:bg-opacity-5 text-woodyBrown  ',
                        !isActive &&
                            isLight &&
                            ' hover:bg-peachOrange hover:bg-opacity-20 text-woodyBrown',
                    )}
                    onClick={onClick}
                >
                    <TextIcon SVG={leadingIcon} />
                    <p
                        className={cx(
                            'tracking-tight align-middle text-left whitespace-nowrap',
                        )}
                    >
                        {label}
                    </p>
                </button>
            )}
        </>
    );
};

const NavDropdown = ({
    to,
    children,
    label,
    leadingIcon,
    indentChildren = true,
    isLight = false,
    disabled = false,
    shouldLink,
}: {
    to?: string;
    children?: ReactNode | ReactNode[];
    label: string;
    leadingIcon: IconComponent;
    isActive?: boolean;
    disabled?: boolean;
    indentChildren?: boolean;
    isLight?: boolean;
    shouldLink?: boolean;
}) => {
    const [expanded, setExpanded] = useState(true);
    const { push } = useHistory();
    const match = useRouteMatch(to ? to : {});
    const isActive = shouldLink ? Boolean(match?.isExact) : Boolean(match);

    return (
        <>
            <button
                onClick={() =>
                    shouldLink ? push('/app/creators') : setExpanded(!expanded)
                }
                disabled={disabled || isActive}
                className={cx(
                    'flex justify-start items-center px-4 rounded py-2 w-full transition duration-200 stroke-2 group',
                    isActive &&
                        !isLight &&
                        'cursor-default hover:bg-black hover:bg-opacity-5  hover:text-mongooseGray text-starkWhite',
                    !isActive &&
                        !isLight &&
                        'hover:bg-white hover:bg-opacity-5 text-starkWhite hover:text-white',
                    isActive &&
                        isLight &&
                        'cursor-default hover:bg-woodyBrown  hover:bg-opacity-5 text-woodyBrown',
                    !isActive &&
                        isLight &&
                        ' hover:bg-peachOrange hover:bg-opacity-20 text-woodyBrown',
                )}
            >
                <TextIcon SVG={leadingIcon} />
                <p className="tracking-tight font-body align-middle text-left flex-1 whitespace-nowrap group-disabled:pointer-events-none">
                    {label}
                </p>

                <div
                    className={cx(
                        'rounded-md transition-all duration-200 ',
                        expanded || isActive ? 'rotate-180' : 'rotate-0',
                        isActive
                            ? 'text-starkWhite group-hover:text-mongooseGray group-disabled:pointer-events-none '
                            : 'text-starkWhite group-hover:text-white ',
                    )}
                >
                    <TextIcon SVG={CaretIcon} />
                </div>
            </button>

            <ul
                className={cx(
                    ' flex flex-col justify-start w-full overflow-y-hidden items-end transition-all duration-300 z-10',
                    expanded || isActive
                        ? 'visible opacity-100 max-h-max'
                        : 'invisible  opacity-0 max-h-0',

                    indentChildren && 'ml-[48px]',
                )}
            >
                {children}
            </ul>
        </>
    );
};

type Item = {
    label: string;
    nestedItems?: { screen: Screen; label: string }[];
    screen?: string;
    icon: IconComponent;
    children?: ReactNode | ReactNode[];
    isNested?: boolean;
    isActive?: boolean;
    disabled?: boolean;
    indentChildren?: boolean;
    isLight?: boolean;
    onClick?: () => void;
    shouldLink?: boolean;
};

export const NavItem = ({
    label,
    children,
    screen,
    icon,
    indentChildren = true,
    isActive = false,
    disabled = false,
    isLight = false,
    onClick,
    shouldLink,
}: Item) => {
    return (
        <>
            {children ? (
                <NavDropdown
                    to={screen}
                    label={label}
                    leadingIcon={icon}
                    indentChildren={indentChildren}
                    isActive={isActive}
                    disabled={disabled}
                    shouldLink={shouldLink}
                >
                    {children}
                </NavDropdown>
            ) : (
                <CustomNavLink
                    to={screen}
                    isLight={isLight}
                    leadingIcon={icon}
                    label={label}
                    onClick={onClick}
                />
            )}
        </>
    );
};

export const NavButton = ({
    to,
    label,
    leadingIcon,
    isActive = false,
    onClick,
    trailingIcon,
    isLight = false,
}: {
    label: string;
    isActive?: boolean;
    leadingIcon?: IconComponent;
    trailingIcon?: IconComponent;
    isLight?: boolean;
} & XOR<{ to: string; onClick?: () => void }, { onClick?: () => void }>) => {
    const match = useRouteMatch(to ? to : {});
    return (
        <WrapIf
            condition={to !== undefined}
            wrapper={children => (
                <Link
                    className={cx(
                        'w-full',
                        isActive
                            ? 'bg-lightPeach text-woodyBrown pointer-events-none'
                            : 'text-lightPeach bg-woodyBrown',
                    )}
                    to={to || '/'}
                    onClick={onClick}
                >
                    {children}
                </Link>
            )}
        >
            <button
                onClick={onClick}
                disabled={!!match}
                className={cx(
                    'flex justify-start items-center px-4  min-w-full w-full bg-none rounded-lg',
                    // match && 'stroke-[2.5px] font-bold opacity-100',
                    // !match &&
                    'stroke-[1.6px] font-normal  hover:stroke-[2.5px]  tracking-wide hover:tracking-tighter transition-all  ',
                    isLight
                        ? 'hover:bg-woodyBrown hover:bg-opacity-5  py-2'
                        : 'hover:bg-white hover:bg-opacity-5 hover:text-white py-4',
                )}
            >
                {leadingIcon && <TextIcon SVG={leadingIcon} />}
                <p className="tracking-tight  align-middle text-left flex-1  whitespace-nowrap">
                    {label}
                </p>
                {trailingIcon && (
                    <TextIcon onClick={onClick} SVG={trailingIcon} />
                )}
            </button>
        </WrapIf>
    );
};
