import { NavLink } from 'react-router-dom';
import { COMPANY_NAME } from 'src/config';
import { Screen } from 'src/view/routes/Router';
import { OutBoundLink } from './outboundLink';

type Props = {
    supportEmail?: string;
    textColor: string;
    backgroundColor: string;
};

export const Footer = ({
    supportEmail = 'support@mundu.no',
    textColor,
    backgroundColor,
}: Props) => {
    return (
        <div className={`relative z-8 bg-${backgroundColor}`}>
            <footer
                className={`font-body text-right text-18px xl:text-20px text-${textColor} pr-6 `}
            >
                <div className="space-x-6">
                    <a href={`mailto:${supportEmail}`}>Support</a>
                    <NavLink
                        exact
                        to={Screen.EULA}
                        className="hover:underline"
                        activeClassName="text-mongooseGray no-underline"
                    >
                        EULA
                    </NavLink>
                    <NavLink
                        exact
                        to={Screen.Privacy}
                        className="hover:underline"
                        activeClassName="text-mongooseGray no-underline"
                    >
                        Personvern
                    </NavLink>
                </div>
            </footer>
            <div
                className={`text-right text-12px sm:text-14px font-body text-${textColor} pt-12 pb-4 pr-6`}
            >
                All rights reserved –{' '}
                <span className="font-bold">{COMPANY_NAME}</span>
            </div>
            <div className="prose-peach pb-6">
                <div className=" grid justify-center w-full  max-w-none">
                    <hr className="w-full border-woodyBrown" />
                    <div className="legalAttribution text-center pt-0 px-2 ">
                        Denne tjenesten tilbys av {COMPANY_NAME}. Musikken er
                        gjengitt med tillatelse fra{' '}
                        <OutBoundLink href="https://tono.no" iconSize={12}>
                            TONO
                        </OutBoundLink>{' '}
                        . Uautorisert fremføring eller kopiering er ulovlig.
                    </div>
                </div>
            </div>
        </div>
    );
};
