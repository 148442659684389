import { ChangeEvent, forwardRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { Label, Error } from './Input';
import { PhoneIcon } from 'src/components/Icons/icons';
import { InputIcon } from 'src/components/Icons/InputIcon';
import cx from 'classnames';

type PhoneInputProps = React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
> & {
    debounce?: number;
    label?: string;
    error?: FieldError;
    onLeadingClick?: () => void;
    textColor?: 'lightPeach' | 'woodyBrown';
};

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
    (
        {
            label = 'Telefonnummer',
            onLeadingClick,
            className,
            error,
            disabled,
            textColor = 'lightPeach',
            onChange,
            ...props
        },
        ref,
    ) => {
        const [text, setText] = useState<string>('');

        const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
            const phoneNumber = deformat(e.target.value);
            if (phoneNumber.length > 8) return;
            setText(formatPhoneNumber(phoneNumber));
            e.target.value = phoneNumber;
            onChange && onChange(e);
        };

        const deformat = (text: string) => {
            return text.replace(/[^\d]/g, '');
        };

        const formatPhoneNumber = (value: string) => {
            if (!value) return '';
            const phoneNumber = deformat(value);
            const phoneNumberLength = phoneNumber.length;

            if (phoneNumberLength < 4) return phoneNumber;
            if (phoneNumberLength < 6) {
                return `${phoneNumber.slice(0, 3)}${phoneNumber.slice(3)}`;
            }

            return `${phoneNumber.slice(0, 3)}${phoneNumber.slice(
                3,
                5,
            )}${phoneNumber.slice(5, 8)}`;
        };

        return (
            <div>
                <Label label={label} />
                <div
                    className={cx(
                        `relative flex justify-start items-center bg-transparent`,
                        disabled ? 'text-mongooseGray' : `text-${textColor}`,
                    )}
                >
                    <input
                        ref={ref}
                        className={cx(className, 'pl-[2.2em] ')}
                        value={text}
                        placeholder=""
                        onChange={handleOnChange}
                        {...props}
                    />

                    <InputIcon
                        type="leading"
                        onClick={onLeadingClick}
                        SVG={PhoneIcon}
                    />
                </div>

                <Error error={error} />
            </div>
        );
    },
);

PhoneInput.displayName = 'PhoneInput';
export default PhoneInput;
