import Input from './Input';
import { useUploadAvatar } from 'src/view/hooks/useUploadAvatar';
import { Consumer } from 'src/domain/consumer';
import { EditIcon } from 'src/assets/svg';
import { forwardRef, useImperativeHandle } from 'react';

export type HandleUploadAvatar = {
    uploadAvatar: (consumer: Consumer) => Promise<void>;
};

type AvatarInputProps = {
    consumer?: Consumer;
};

const AvatarInput = forwardRef<HandleUploadAvatar, AvatarInputProps>(
    ({ consumer }, ref) => {
        const { uploadAvatar, onChange, preview, file, removeAvatar } =
            useUploadAvatar(consumer);

        useImperativeHandle(ref, () => ({
            uploadAvatar: async (consumer: Consumer) =>
                uploadAvatar(consumer, file),
        }));

        return (
            <div className="flex flex-col">
                <Input
                    id="avatar"
                    className="hidden"
                    type="file"
                    accept="image/jpeg,image/jpg,image/png,image/gif,image/webp"
                    onChange={onChange}
                />

                <div className="flex flex-col items-center gap-3 ">
                    <label
                        htmlFor="avatar"
                        className="cursor-pointer justify-self-center col-span-2 group relative hover:opacity-80 transition-all"
                    >
                        <img
                            src={preview}
                            alt="avatar default"
                            className="aspect-square w-36 rounded-full object-cover"
                        />

                        <EditIcon className="w-8 h-8 absolute bottom-0 right-3 bg-lightPeach text-woodyBrown group-hover:bg-woodyBrown group-hover:text-lightPeach transition-all rounded-full p-1" />
                    </label>
                    <div className="flex gap-3">
                        {(file || consumer?.profile.avatar) && (
                            <button
                                title="Fjern bilde"
                                className="px-6 py-1 rounded-full border-1.6px border-woodyBrown cursor-pointer hover:bg-woodyBrown hover:text-white self-end"
                                onClick={removeAvatar}
                                type="button"
                            >
                                Fjern bilde
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    },
);

AvatarInput.displayName = 'AvatarInput';

export default AvatarInput;
