interface Image {
    id: string;
    containerType: string;
}

interface SessionAudio {
    trackId: string;
    releaseId: number;
}

interface Media {
    audios: SessionAudio[];
    imageDuration: number;
    images: Image[];
    video?: string;
}

export enum PlaybackMode {
    LOOP = 'LOOP',
    NORMAL = 'NORMAL',
}

export interface SessionPayload {
    consumerIds: string[];
    title: string;
    media: Media;
    playbackMode: PlaybackMode;
    duration: number;
}
export interface Session {
    createdDate: string;
    updatedDate?: string;
    creatorId: string;
    author: string;
    id: string;
    media: Media;
    title: string;
    playbackMode: PlaybackMode;
    duration: number;
}
