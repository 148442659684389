import { SpinnerIconSVG } from 'src/assets/svg';
import { TrackList } from '../BrowseMusic/components/Track/list';
import { AddToPlaylist } from '../BrowseMusic/PlaylistView/components/addToPlaylist';
import { usePlaylistTracks } from 'src/view/hooks/swr/usePlaylistTracks';
import { usePaginateIfVisible } from 'src/view/hooks/usePaginateIfVisible';
import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { useSurveyContext } from './reducer';
import { getFullName } from 'src/domain/user';

type Props = {
    editable: boolean;
};

export const FavoriteTracks = ({ editable }: Props) => {
    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();
    const {
        state: { playlist },
    } = useSurveyContext();

    const {
        playlistTracks,
        isEmpty,
        hasMore,
        isLoadingNext,
        isLoading,
        nextPage,
        addTrack,
        deleteTrack,
    } = usePlaylistTracks(playlist?.id, currentConsumer?.id);

    const PaginateIfVisible = usePaginateIfVisible(nextPage);
    const consumerName = currentConsumer ? getFullName(currentConsumer) : '';
    return (
        <div className="space-y-4 ">
            <h1 className="font-bold text-3xl font-logo">
                Favorittlåter{' '}
                <span
                    id="entity"
                    className=" font-body text-[8px] lg:text-sm font-extralight"
                >
                    SPILLELISTE
                </span>
            </h1>

            <TrackList
                displayOption="LIST"
                onAddTrack={addTrack}
                onDeleteTrack={deleteTrack}
                tracks={playlistTracks}
                editable={editable}
            />
            {isLoading && (
                <div className="grid justify-center">
                    <SpinnerIconSVG className="animate-spin h-8 w-8 text-woodyBrown" />
                </div>
            )}
            {!isLoadingNext && hasMore && <PaginateIfVisible />}
            <div className="flex flex-col w-full">
                {!isLoading && isEmpty && (
                    <div className="text-lg w-full md:w-1/2 text-center self-center py-6">
                        {editable
                            ? `Spillelisten til ${consumerName} er tom, bruk søkefeltet nedenfor for å finne sanger å legge til.`
                            : `Spillelisten til ${consumerName} er tom. Gjennomfør musikalsk kartlegging for å legge til sanger.`}
                    </div>
                )}
                {editable && <AddToPlaylist onAddTrack={addTrack} />}
            </div>
        </div>
    );
};
