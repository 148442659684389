import './index.css';
import './tailwind.generated.css';
import * as serviceWorker from './serviceWorker';
import App from './view/App';
import * as Sentry from '@sentry/react';
import { SENTRY_CONFIG } from 'src/config';
import { createRoot } from 'react-dom/client';

Sentry.init(SENTRY_CONFIG);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
