import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSessionForm } from '../SessionFormState/hooks';
import { updateStep } from '../SessionFormState/actions';
import { SendButton } from '../step4/components/SendButton';
import { SessionProgress } from './SessionProgress';
import { NextButton } from 'src/components/Buttons/NextButton';
import { BackButton } from 'src/components/Buttons/BackButton';
import { HomeButton } from '../step4/components/HomeButton';
import { EditIcon, SpinnerIconSVG } from 'src/assets/svg';
import { IconButton } from 'src/components/Buttons/IconButton';
import { useNotification } from 'src/components/Notification/useNotfication';

const useStepNavigation = () => {
    const [maxStep, setMaxStep] = useState(4);
    const history = useHistory();
    const { state, dispatch } = useSessionForm();
    const current = state.step;
    const isFirstStep = current === 1;
    const isFinalStep = current === 3;
    const completeSteps = [
        !!state.trackSelection.length,
        !!state.images.length || state.imageDuration >= 5,
        !!state.title.length,
    ];

    const firstIncompleteStep = completeSteps.findIndex(x => x === false);

    useEffect(() => {
        if (firstIncompleteStep !== -1) {
            setMaxStep(firstIncompleteStep + 1);
        } else {
            setMaxStep(3);
        }
    }, [firstIncompleteStep]);

    const isDisabledStep = (step: number) => {
        return step > 1 && step > maxStep;
    };

    const nextStep = (): void => {
        if (isFinalStep) {
            dispatch(updateStep(4));
            history.push(`./step4`);
            return;
        }
        if (isDisabledStep(current + 1)) return;
        const next = Math.min(current + 1, maxStep);
        dispatch(updateStep(next));
        history.push(`./step${next}`);
    };
    const previousStep = (): void => {
        if (isFirstStep) {
            history.push('/app/home');
            return;
        }
        if (isDisabledStep(current - 1)) return;
        const prev = Math.max(current - 1, 1);
        dispatch(updateStep(prev));
        history.push(`./step${prev}`);
    };
    const selectStep = (step: number): void => {
        if (isDisabledStep(step)) return;
        dispatch(updateStep(step));
        history.push(`./step${step}`);
    };

    return {
        nextStep,
        previousStep,
        selectStep,
        isDisabledStep,
        isFirstStep,
        isFinalStep,
    };
};
export const StepNavigator = () => {
    const { nextStep, previousStep, isDisabledStep, isFinalStep, isFirstStep } =
        useStepNavigation();
    const {
        state,
        submit,
        submitting,
        submitted,
        sessionStatus: { formValid, hasUnsavedChanges },
    } = useSessionForm();

    const wasUpdated = submitted === true && hasUnsavedChanges === false;
    const nextDisabled = isDisabledStep(state.step + 1);
    const notify = useNotification();

    useEffect(() => {
        if (!wasUpdated) return;
        notify(`Endringene ble lagret`, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wasUpdated]);

    return (
        <div className="flex items-center justify-between w-full">
            <div className="inline-flex items-center">
                {isFirstStep ? (
                    <HomeButton title="Hjem" />
                ) : (
                    <BackButton onClick={previousStep} />
                )}
                <SessionProgress />
            </div>

            <div className="inline-flex space-x-2 items-center ">
                <Link
                    to={
                        state.editMode
                            ? `/app/users/profile/${state.recipient?.id}`
                            : '/app/home'
                    }
                >
                    <p className="text-sm hover:underline whitespace-nowrap hidden sm:flex">
                        Avslutt
                    </p>
                </Link>
                {submitting && (
                    <div className="flex text-sm gap-2 items-center">
                        <SpinnerIconSVG className=" text-woodyBrown w-4 animate-spin" />
                        <span>Lagrer...</span>
                    </div>
                )}
                {state.editMode && (
                    <IconButton
                        Icon={EditIcon}
                        onClick={submit}
                        disabled={!formValid || !hasUnsavedChanges}
                        label="Lagre endringer"
                    />
                )}

                {!isFinalStep && (
                    <span className={state.step === 2 ? 'hidden md:flex' : ''}>
                        <NextButton
                            disabled={nextDisabled}
                            active={!nextDisabled}
                            onClick={nextStep}
                        />
                    </span>
                )}
                {isFinalStep && !state.editMode && (
                    <SendButton
                        onClick={nextStep}
                        title={'Send'}
                        disabled={!formValid}
                    />
                )}
            </div>
        </div>
    );
};
