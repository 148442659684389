import useSWRInfinite from 'swr/infinite';
import container from 'src/container';
import { CustomPlaylistTrack, Track } from 'src/domain/music';
import { PatchPlaylistTracksDto } from 'src/api/music.params';

const {
    cradle: { musicService },
} = container;

export const usePlaylistTracks = (
    playlistId?: string,
    userId?: string,
    pageSize = 15,
) => {
    const { data, size, setSize, mutate, isValidating, error } = useSWRInfinite(
        page =>
            userId && playlistId
                ? ['playlist/tracks', userId, playlistId, page + 1, pageSize]
                : null,
        (_, userId, playlistId, page, pageSize) =>
            musicService.listPlaylistTracksAsTracks({
                userId,
                playlistId,
                page,
                pageSize,
            }),
        { revalidateOnFocus: false },
    );

    const addTrack = async (track: Track) => {
        if (!userId || !playlistId) return;
        const data = {
            trackId: track.id,
            trackTitle: track.title,
            trackVersion: track.version,
            artistAppearsAs: track.artist.appearsAs,
            releaseId: track.release.id.toString(),
            releaseTitle: track.release.title,
            releaseArtistAppearsAs: track.release.artist.appearsAs,
            releaseVersion: track.release.version,
        } as PatchPlaylistTracksDto;
        await musicService.addPlaylistTracks(userId, playlistId, [data]);
        mutate();
    };
    const deleteTrack = async (track: CustomPlaylistTrack) => {
        if (!userId || !playlistId) return;
        await musicService.removePlaylistTrack(
            userId,
            playlistId,
            track.playlistItemId,
        );
        mutate();
    };
    const playlistTracks = data ? data.flat() : [];
    const isLoading = !data && !error;
    const isLoadingNext =
        isValidating ||
        (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.length === 0;
    const hasMore = !(
        isEmpty ||
        (data && data[data.length - 1]?.length < pageSize)
    );

    return {
        playlistTracks,
        isEmpty,
        nextPage: () => setSize(size + 1),
        setPageSize: setSize,
        refresh: mutate,
        addTrack,
        deleteTrack,
        isLoading,
        isLoadingNext: isLoadingNext ? isLoadingNext : false,
        error,
        hasMore,
    };
};
