import { ReactNode } from 'react';
import cx from 'classnames';

type ContainerProps = {
    children?: ReactNode | ReactNode[];
};

export const CardGrid = ({ children }: ContainerProps) => {
    return (
        <div className="flex flex-wrap items-center gap-y-4 gap-x-5 ">
            {children}
        </div>
    );
};

export const BottomLane = ({ children }: ContainerProps) => {
    return (
        <div
            id="bottom-lane"
            className={cx(
                'flex flex-col w-full transition-all duration-500 bg-woodyBrown shadow-top z-40 ',
                !children ? 'h-0' : 'h-32',
            )}
        >
            {children}
        </div>
    );
};

export const MidLane = ({ children }: ContainerProps) => {
    return (
        <div id="primary-lane" className="flex h-full w-full overflow-y-hidden">
            {children}
        </div>
    );
};

export const ContentBox = ({ children }: ContainerProps) => {
    return (
        <div id="content-view" className="overflow-y-auto h-full w-full p-6 ">
            {children}
        </div>
    );
};

export const ContentViewContainer = ({ children }: ContainerProps) => {
    return (
        <div
            id="content-view-container"
            className="flex flex-col h-full w-full overflow-hidden min-w-[376px] "
        >
            {children}
        </div>
    );
};

export const ScreenContainer = ({ children }: ContainerProps) => {
    return (
        <div
            id="app-container"
            key="app"
            className="flex flex-col w-screen border-box h-screen bg-lightPeach "
        >
            {children}
        </div>
    );
};
