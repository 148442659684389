import { useEffect, useState } from 'react';
import { EventError, InteractionStatus } from '@azure/msal-browser';
import { captureException as reportToSentry } from '@sentry/react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { WarningIconSVG } from 'src/assets/svg';
import { Button } from 'src/components/Buttons/Button';
import { User, UserRole } from 'src/domain/user';
import { UserActions } from 'src/state/_actions';
import { Screen } from 'src/view/routes/Router';
import container from 'src/container';
import { LandingPageHeader } from 'src/view/screens/Mundu/components';
import { useMsal } from '@azure/msal-react';
import { SlideList } from 'src/components/onboarding/Onboarding';
import { Modal } from 'src/components/Modals/Modal';

const {
    cradle: { authService, munduClient },
} = container;

export const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { inProgress } = useMsal();
    const [onboardingComplete, setOnboardingComplete] = useState(true);
    const [user, setUser] = useState<User>();

    useEffect(() => {
        if (inProgress !== InteractionStatus.None) return;
        const account = authService.getActiveAccount();
        if (account) {
            if (account.idTokenClaims?.showOnboarding === true) {
                setOnboardingComplete(false);
            }
            munduClient.getUser().then(user => {
                dispatch(UserActions.setUser(user));
                setUser(user);
            });
        } else {
            authService.login();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgress]);

    useEffect(() => {
        if (!user) return;
        if (!onboardingComplete) return;
        const timeout = setTimeout(() => {
            switch (user.role) {
                case UserRole.organization:
                case UserRole.creator:
                    history.replace('/app/home');
                    break;
                case UserRole.nextOfKin:
                    history.replace('/app/nextofkin-image-list');
                    break;
                case UserRole.admin:
                    history.replace('/app/admin');
                    break;
                default:
                    sessionStorage.clear();
                    history.replace('/');
            }
        }, 500);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, onboardingComplete]);

    return (
        <>
            {authService.error ? (
                <LoginError error={authService.error} />
            ) : (
                <Modal
                    show={onboardingComplete === false}
                    fadeDuration={500}
                    appear={false}
                    className="fixed inset-0 z-70 h-screen w-screen bg-woodyBrown"
                >
                    <SlideList onComplete={() => setOnboardingComplete(true)} />
                </Modal>
            )}
        </>
    );
};

const LoginError = ({ error }: { error: EventError }) => {
    //  Report login error to sentry
    useEffect(() => {
        reportToSentry(error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const headerColors = {
        textColor: 'woodyBrown',
        activeTextColor: 'mongooseGray',
        backgroundColor: 'lightPeach',
    };

    return (
        <div className="bg-fixed h-screen flex flex-col">
            <LandingPageHeader
                colors={headerColors}
                scrollItems={false}
                navItems={[{ id: Screen.Mundu, name: 'Hjem' }]}
            />
            <div className="grow h-full flex justify-center items-center">
                <div className="flex flex-col gap-8 justify-center items-center p-10 sm:shadow-md max-w-lg rounded-2xl">
                    <div className="flex flex-col items-center gap-4">
                        <WarningIconSVG className="text-woodyBrown fill-current w-8 h-8" />
                        <div className="font-bold text-2xl text-center">
                            Noe gikk galt under innlogging!
                        </div>
                    </div>
                    <div className="flex flex-col items-center gap-2 text-center">
                        <div>
                            Mundu support blir automatisk varslet om feilen.
                        </div>
                        <div className="text-center">
                            Om feilen vedvarer og du ønsker hjelp, ta kontakt på{' '}
                            <a
                                href="mailto:support@mundu.no"
                                className="underline hover:text-peachOrange active:text-peachOrange"
                            >
                                support@mundu.no
                            </a>
                        </div>
                    </div>
                    <div className="flex gap-8">
                        <Button
                            title="Prøv igjen"
                            onClick={() => window.location.reload()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
