import { CardGrid } from 'src/components/AppScreen/AppLayout';
import Card from 'src/components/Card';
import { DesktopIcon, GroupIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { Section } from 'src/components/Section';

import { AdminList } from './components';

export const Admin = () => {
    return (
        <div className="flex flex-col space-y-10 ">
            <CardGrid>
                <Card
                    label=" Ny organisasjon"
                    shape="circle"
                    to="/app/admin/organizations/new"
                >
                    <TextIcon SVG={GroupIcon} />
                </Card>
                <Card
                    label="Ny administrator"
                    shape="circle"
                    to="/app/admin/admins/new"
                >
                    <TextIcon SVG={DesktopIcon} />
                </Card>
            </CardGrid>
            <Section title="Administratorer">
                <AdminList />
            </Section>
        </div>
    );
};
