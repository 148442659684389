import { PauseIconSVG, PlayIconSVG, TrashIconSVG } from 'src/assets/svg';
import { Track, CustomPlaylistTrack } from 'src/domain/music';
import {
    convertURLToHttps,
    formatDate,
    formatDuration,
} from 'src/utils/stringUtils';
import { ArtistLink } from '../../artistLink';
import { ReleaseLink } from '../../releaseLink';
import { isPlaylistTrack } from '../list';
import { ToggleButton } from '../../toggleButton';

type TrackRowProps = {
    track: Track | CustomPlaylistTrack;
    index: number;
    isPlaying: boolean;
    isSelected: boolean;
    editable?: boolean;
    isUnavailable?: boolean;
    showReleaseCols?: boolean;
    showIndexCol?: boolean;
    isPlaylistSearch?: boolean;
    onSelect?: (track: Track | CustomPlaylistTrack) => void;
    togglePlay: () => void;
    onDeleteTrack?: (track: CustomPlaylistTrack) => void;
    onAddTrack?: (track: Track) => void;
};

export const TrackRow = ({
    index,
    track,
    isPlaying,
    isSelected,
    isUnavailable = false,
    editable = false,
    showReleaseCols = true,
    showIndexCol = true,
    isPlaylistSearch = false,
    onSelect,
    togglePlay,
    onDeleteTrack,
    onAddTrack,
}: TrackRowProps) => {
    const { title, release, artist, subscriptionStreaming: info } = track;
    const duration = formatDuration(track.duration);
    const releaseDate = info ? formatDate(info.releaseDate) : '';

    return (
        <div className="relative col-span-32 grid-cols-32 inline-grid gap-4 items-center group">
            <div
                onClick={isUnavailable ? undefined : togglePlay}
                className="absolute h-16 p-4 z-10 w-full bg-peachOrange bg-opacity-0 hover:bg-opacity-10 cursor-pointer"
            />
            <div
                className={`col-span-16 ${
                    showReleaseCols ? 'md:col-span-12' : 'md:col-span-16'
                } ${isUnavailable && 'opacity-70'} inline-flex items-center`}
            >
                {showIndexCol && (
                    <div className="w-8 pr-4 text-right flex-shrink-0 tabular-nums font-matterSQ tracking-tighter">
                        {index + 1}
                    </div>
                )}
                <div className="relative h-12 w-12 flex-shrink-0 group">
                    <img
                        className="absolute inset-0 rounded-sm"
                        src={convertURLToHttps(release.image)}
                        alt={`albumcover for ${title} by ${artist.name}`}
                    />
                    {isPlaying && !isUnavailable ? (
                        <div
                            id="pause"
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
                            aria-label="Pause"
                        >
                            <PauseIconSVG className="w-6 h-6 fill-current text-lightPeach" />
                        </div>
                    ) : (
                        <div
                            className="centered opacity-0 group-hover:opacity-100 transition-all duration-75"
                            aria-label="Play"
                        >
                            <PlayIconSVG className="w-6 h-6 fill-current text-lightPeach" />
                        </div>
                    )}
                </div>

                <div className="pl-2 truncate ">
                    <span className="font-bold">{title}</span> <br />
                    <ArtistLink artist={artist} />
                </div>
            </div>
            {showReleaseCols && (
                <ReleaseLink
                    className=" md:col-span-9  text-left invisible md:visible truncate text-sm hover:underline relative z-20"
                    release={release}
                />
            )}
            <div className="col-span-3 tabular-nums text-sm font-matterSQ">
                {duration}
            </div>
            {showReleaseCols && (
                <div className="md:col-span-4 tabular-nums text-sm font-matterSQ tracking-tighter truncate invisible md:visible">
                    {releaseDate}
                </div>
            )}
            <div className="relative col-auto flex space-x-4 flex-shrink-1 ">
                {isPlaylistSearch ? (
                    <button
                        className="secondary-btn-small z-40"
                        disabled={isUnavailable}
                        onClick={
                            onAddTrack ? () => onAddTrack(track) : undefined
                        }
                    >
                        Legg til
                    </button>
                ) : (
                    onSelect && (
                        <ToggleButton
                            active={isSelected}
                            disabled={isUnavailable}
                            onClick={
                                onSelect ? () => onSelect(track) : undefined
                            }
                        >
                            {isSelected ? 'Valgt' : 'Velg'}
                        </ToggleButton>
                    )
                )}

                {isPlaylistTrack(track) && editable && (
                    <button
                        className="z-40 p-3 rounded-md hover:bg-woodyBrown hover:text-lightPeach transition-all"
                        onClick={
                            onDeleteTrack
                                ? () => onDeleteTrack(track)
                                : undefined
                        }
                    >
                        <TrashIconSVG />
                    </button>
                )}
            </div>
        </div>
    );
};
