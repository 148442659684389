import container from 'src/container';
import { CreateOrganizationPayload } from 'src/domain/organization';
import { Button } from 'src/components/Buttons/Button';
import Input from 'src/components/FormComponents/Input';
import PhoneInput from 'src/components/FormComponents/PhoneInput';
import Form from 'src/components/FormComponents/Form';
import {
    IsString,
    IsNotEmpty,
    IsOptional,
    IsPhoneNumber,
    IsEmail,
    MaxLength,
    ValidateIf,
    Matches,
    MinLength,
} from 'class-validator';
import { checkIfEmailExist, checkIfPhoneNumberExist } from 'src/domain/user';
import { useNotification } from 'src/components/Notification/useNotfication';
import { mutate } from 'swr';
import { useHistory } from 'react-router-dom';
import { GroupIcon, MessageIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';

const {
    cradle: { munduClient },
} = container;
class NewOrganizationSchema {
    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    firstName: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    lastName: string;

    @IsOptional()
    @ValidateIf(o => o.phoneNumber?.length !== 0)
    @IsPhoneNumber('NO', { message: 'Telefonnummer er ikke gyldig' })
    phoneNumber?: string;

    @IsEmail({}, { message: 'Ugyldig epostadresse' })
    @IsNotEmpty({ message: 'Må fylles ut.' })
    email: string;

    @IsNotEmpty({ message: 'Må fylles ut.' })
    licence: number;

    @IsString()
    @MinLength(3, { message: 'Minimum 3 tegn' })
    @MaxLength(23, { message: 'Max 23 tegn' })
    @Matches(/^((?!\-\-).)*$/, {
        message: 'Bindstrek etterfulgt av bindestrek ("--") er ikke tillatt!',
    })
    @Matches(/^[a-z0-9]+$/, {
        message: 'Kan kun inneholde små bokstaver, tall og bindestrek.',
    })
    @Matches(/^[a-z0-9]{1}[a-z0-9\-]+[a-z0-9]{1}$/, {
        message: 'Må slutte og starte på en bokstav eller et tall.',
    })
    @IsNotEmpty({ message: 'Må fylles ut.' })
    organizationName: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    organizationDisplayName: string;
}

export const CreateOrganizationForm = () => {
    const notify = useNotification();
    const history = useHistory();

    const onSubmit = async (data: NewOrganizationSchema): Promise<void> => {
        const payload: CreateOrganizationPayload = {
            organizationName: data.organizationName,
            organizationDisplayName: data.organizationDisplayName,
            profile: {
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber ? data.phoneNumber : undefined,
            },
            licence: Number(data.licence),
            email: data.email,
        };

        try {
            await checkIfEmailExist(data.email);
            if (data.phoneNumber)
                await checkIfPhoneNumberExist(data.phoneNumber);
            await munduClient.createOrganization(payload);
            notify('Organisasjon opprettet');
            mutate('organizations');
            history.replace('/app/admin');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return (
        <Form
            schema={NewOrganizationSchema}
            className="w-full h-full"
            mode="onChange"
            onSubmit={onSubmit}
        >
            {({ register, formState: { errors } }) => (
                <>
                    <div className="col-span-full flex justify-center">
                        {/* TODO: Replace with Role icon when ready */}
                        <div className="rounded-full w-36 h-36 bg-starkWhite flex justify-center items-center mb-4 text-[18px] stroke-2 ">
                            <TextIcon SVG={GroupIcon} />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3">
                        <Input
                            className="text-input"
                            label="Fornavn (Superbruker)*"
                            error={errors.firstName}
                            {...register('firstName')}
                        />
                        <Input
                            className="text-input"
                            label="Etternavn (Superbruker)*"
                            error={errors.lastName}
                            {...register('lastName')}
                        />
                        <PhoneInput
                            className="text-input"
                            type="tel"
                            error={errors.phoneNumber}
                            label={'Telefonnummer'}
                            {...register('phoneNumber')}
                        />

                        <Input
                            className="text-input"
                            type="email"
                            leadingIcon={MessageIcon}
                            label="Epost*"
                            error={errors.email}
                            {...register('email')}
                        />

                        <Input
                            className="text-input"
                            leadingIcon={GroupIcon}
                            type="text"
                            label="Org. kortnavn*"
                            error={errors.organizationName}
                            {...register('organizationName')}
                        />
                        <Input
                            className="text-input"
                            type="text"
                            leadingIcon={GroupIcon}
                            label="Org. visningsnavn*"
                            error={errors.organizationDisplayName}
                            {...register('organizationDisplayName')}
                        />

                        <Input
                            className="text-input"
                            type="number"
                            label="Antall lisenser*"
                            error={errors.licence}
                            {...register('licence')}
                        />
                    </div>
                    <div className="inline-flex justify-end py-6 items-end w-full">
                        <Button submit title="Opprett" />
                    </div>
                </>
            )}
        </Form>
    );
};
