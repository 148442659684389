import { Creator } from 'src/domain/creator';
import { Organization } from 'src/domain/organization';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { CreatorProfile } from './CreatorProfile';
import { NextOfKin } from 'src/domain/next-of-kin';
import { NextofkinProfile } from './NextofkinProfile';
import { OrganizationProfile } from './OrganizationProfile';
import { Admin } from 'src/domain/admin';
import { AdminProfile } from './AdminProfile';

export const MyProfile = () => {
    const user = useSignedInUser();

    switch (user?.role) {
        case 'organization':
            return <OrganizationProfile user={user as Organization} />;
        case 'creator':
            return <CreatorProfile user={user as Creator} />;
        case 'next-of-kin':
            return <NextofkinProfile user={user as NextOfKin} />;
        case 'admin':
            return <AdminProfile user={user as unknown as Admin} />;
        default:
            return null;
    }
};
