import { useState } from 'react';
import { CloseIconSVG } from 'src/assets/svg';
import container from 'src/container';
import { Media } from 'src/domain/personalMedia';
import {
    useCommonMediaSas,
    usePersonalMediaSas,
} from 'src/view/hooks/consumer';
import { LogoIcon } from '../Icons/LogoIcon';
import cx from 'classnames';
import { BackIcon, ForwardIcon } from '../Icons/icons';
import { useKeyboard } from 'src/view/hooks/useKeyboard/useKeyboard';
import { useCursorIsIdle } from 'src/view/hooks/useCursorIsIdle';
import { Modal } from './Modal';

type Props = {
    index: number;
    numItems: number;
    consumerId: string;
    onClose: () => void;
    onPrevious: () => void;
    onNext: () => void;
    picture: Media;
};

const {
    cradle: { storageService },
} = container;

export const ModalPreviewPicture = ({
    index,
    numItems,
    consumerId,
    onClose,
    onPrevious,
    onNext,
    picture,
}: Props) => {
    const { sas: personalMediaSAS } = usePersonalMediaSas(consumerId);
    const { sas: commonMediaSAS } = useCommonMediaSas();

    const props = useKeyboard({
        Escape: () => setShowModal(false),
        ArrowLeft: () => onPrevious(),
        ArrowRight: () => onNext(),
    });
    const { isIdle } = useCursorIsIdle(2000);
    const [showModal, setShowModal] = useState(true);
    return (
        <Modal
            show={showModal}
            className={cx(
                'fixed inset-0 w-screen h-screen z-70 bg-black bg-opacity-[0.92]',
                isIdle && 'cursor-none ',
            )}
            onClose={onClose}
        >
            <div {...props} className="flex flex-col w-full h-full p-4">
                <div className="inline-flex w-full justify-between text-3xl text-starkWhite items-center  ">
                    <LogoIcon />
                    <button
                        onClick={() => setShowModal(false)}
                        className="p-4 bg-transparent transition-all rounded-lg text-lightPeach hover:bg-lightPeach hover:text-woodyBrown"
                    >
                        <CloseIconSVG />
                    </button>
                </div>

                <div className="relative h-full w-full flex flex-col justify-center items-center object-contain ">
                    <img
                        src={storageService.getImageUrl(
                            picture.id,
                            picture.containerType === 'pm'
                                ? personalMediaSAS
                                : commonMediaSAS,
                        )}
                        className="absolute rounded-lg max-h-full max-w-full shadow-md "
                        alt="preview"
                    />

                    <div
                        className={cx(
                            'absolute h-16 w-full text-right bottom-0 px-4 pt-2 transition-all bg-black ',
                            picture.description
                                ? 'bg-opacity-30'
                                : 'bg-opacity-0',
                        )}
                    >
                        <h1
                            className={cx(
                                'text-lightPeach centered text-2xl  transition-all',
                                picture.description
                                    ? 'opacity-100'
                                    : 'opacity-0',
                            )}
                        >
                            {picture.description}
                        </h1>
                        <p className="right-0 align-middle py-2 tabular-nums  text-lightPeach font-matterSQ opacity-90">
                            {index} / {numItems}
                        </p>
                    </div>
                    <div
                        id="right"
                        className={cx(
                            'absolute w-1/3 h-full top-0 right-0 z-80 opacity-0 rounded-l-3xl transition-all duration-300',
                            isIdle
                                ? 'cursor-none'
                                : 'hover:opacity-50 cursor-pointer',
                        )}
                        onClick={onNext}
                    >
                        <div className="absolute transform top-1/2 -translate-y-1/2  right-4 w-1/5 aspect-square text-lightPeach ">
                            <ForwardIcon />
                        </div>
                    </div>
                    <div
                        className={cx(
                            'absolute w-1/3 h-full left-0 z-80  opacity-0 transition-all ',
                            isIdle
                                ? 'cursor-none opacity-0'
                                : 'hover:opacity-50 cursor-pointer',
                        )}
                        onClick={onPrevious}
                    >
                        <div className="absolute transform top-1/2 -translate-y-1/2  left-4 w-1/5 aspect-square text-lightPeach">
                            <BackIcon />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
