import { Track } from 'src/domain/music';
import { convertURLToHttps } from 'src/utils/stringUtils';
import { FALLBACK_COVER_IMAGE } from 'src/view/screens/BrowseMusic';
import { ArtistLink } from '../../artistLink';
type Props = {
    track?: Track;
    unavailable: boolean;
};
export const TrackInfo = ({ track, unavailable }: Props) => {
    return (
        <div
            className={`col-span-2 flex justify-start items-center space-x-4 ${
                unavailable && 'opacity-70'
            }`}
        >
            {track && (
                <div className="flex-shrink-0 relative h-16 w-16 md:h-20 md:w-20">
                    <img
                        id="artwork"
                        className="absolute inset-0 shadow-xl rounded-8px"
                        src={convertURLToHttps(track.release.image)}
                        onError={e =>
                            (e.currentTarget.src = FALLBACK_COVER_IMAGE)
                        }
                        alt={`track artwork for ${track.title} by ${track.artist.appearsAs}`}
                    />
                </div>
            )}
            {track && (
                <div className="min-w-0 pr-2 ">
                    <div
                        id="title"
                        className="text-md md:text-lg font-bold truncate"
                    >
                        {track.title}
                    </div>
                    <div id="artist" className="text-xs md:text-sm truncate">
                        <ArtistLink artist={track.artist} />
                    </div>
                </div>
            )}
        </div>
    );
};
