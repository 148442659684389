import { ReactNode } from 'react';
import cx from 'classnames';

type HeaderProps = {
    left?: ReactNode | ReactNode[];
    center?: ReactNode | ReactNode[];
    right?: ReactNode | ReactNode[];
} & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
>;
export const Header = ({
    left,
    center,
    right,
    className,
    ...props
}: HeaderProps) => {
    return (
        <div
            className={cx(
                'flex sticky left-0 right-0 justify-between items-center text-woodyBrown bg-lightPeach pt-3 px-2 xs:px-4 z-40',
                className,
            )}
            {...props}
        >
            <nav className="inline-flex justify-start items-center space-x-2 w-1/3">
                {left}
            </nav>
            <nav className="inline-flex justify-center items-center space-x-2">
                {center}
            </nav>
            <div className="inline-flex justify-end items-center space-x-2 w-1/3">
                {right}
            </div>
        </div>
    );
};
