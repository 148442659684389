import ky, { ResponsePromise, SearchParamsOption } from 'ky';
import { Options } from 'ky/distribution/types/options';
import { SERVER_URL } from 'src/config';
import { AuthService } from 'src/api/auth.service';

export type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'HEAD' | 'DELETE';

type DeserializeCallback<T> = (res: ResponsePromise) => Promise<any>;

const defaultDeserialize = (res: ResponsePromise) => res.json() as any;

export abstract class BaseHTTPService {
    ky: typeof ky;
    constructor({ options }: { options: Options }) {
        this.ky = ky.extend(options);
    }

    request = async <T>(
        method: HTTPMethod,
        endpoint: string,
        {
            params,
            payload,
            options,
            deserialize = defaultDeserialize,
        }: Request<T>,
    ) => {
        const res = this.ky(endpoint, {
            method,
            searchParams: params,
            json: payload,
            ...options,
        });

        const response = await deserialize(res);
        return response?.data ? (response.data as T) : (response as T);
    };
}

export type RequestOptions<T> = {
    params?: SearchParamsOption;
    payload?: unknown;
    options?: Options;
    deserialize?: DeserializeCallback<T>;
};

export type Request<T> = {
    params?: SearchParamsOption;
    payload?: unknown;
    options?: Options;
    deserialize?: DeserializeCallback<T>;
};

export class HTTPService extends BaseHTTPService {
    constructor({ authService }: { authService: AuthService }) {
        super({
            options: {
                hooks: {
                    beforeRequest: [
                        async request => {
                            request.headers.set(
                                'authorization',
                                `Bearer ${await authService.getUserToken()}`,
                            );
                        },
                    ],
                },
                timeout: 10000,
                headers: {
                    'content-type': 'application/json',
                },
                prefixUrl: SERVER_URL,
                retry: 0,
            },
        });
    }
    get = <T>(endpoint: string, options?: RequestOptions<T>) =>
        this.request<T>('GET', endpoint, { ...options });

    post = <T>(endpoint: string, options?: RequestOptions<T>) =>
        this.request<T>('POST', endpoint, { ...options });

    delete = <T>(endpoint: string, options?: RequestOptions<T>) =>
        this.request<T>('DELETE', endpoint, { ...options });

    patch = <T>(endpoint: string, options?: RequestOptions<T>) =>
        this.request<T>('PATCH', endpoint, { ...options });
}
