export const ProgressBar = ({ percentage }: { percentage: number }) => {
    return (
        <div className="relative w-full  h-10 bg-lightPeach">
            <div className="w-full h-full rounded-full bg-peachOrange bg-opacity-20 overflow-hidden ">
                <div
                    className="h-full top-0 left-0 bg-peachOrange transition-[width]"
                    style={{
                        width: `${percentage}%`,
                    }}
                />
            </div>
            <div className="absolute top-0 left-0 h-full w-full flex justify-center items-center text-woodyBrown">
                {percentage}%
            </div>
        </div>
    );
};
