import { useEffect } from 'react';

export const useUnloadBlocker = (condition = true) => {
    useEffect(() => {
        if (!condition) return;
        const block = (e: BeforeUnloadEvent) => {
            e.returnValue =
                'Obs! Du har ulagrede endringer. Er du sikker på at du vil forlate siden?';
        };
        window.addEventListener('beforeunload', block);
        return () => {
            window.removeEventListener('beforeunload', block);
        };
    }, [condition]);
};
