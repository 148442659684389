import { QuestionMarkIcon } from './Icons/icons';
import { TextIcon } from './Icons/TextIcon';
import { Popover } from './Modals/Popover';
type SectionProps = {
    title: string;
    children: React.ReactNode | React.ReactNode[];
    helpText?: string;
};
export const Section = ({ title, children, helpText }: SectionProps) => {
    return (
        <div className="flex flex-col gap-4">
            <div>
                <div className="flex items-center text-xl font-semibold">
                    <p>{title}</p>
                    {helpText && (
                        <Popover message={helpText}>
                            <TextIcon SVG={QuestionMarkIcon} />
                        </Popover>
                    )}
                </div>
                <hr className="border-woodyBrown border-1 mt-2 opacity-30" />
            </div>
            {children}
        </div>
    );
};
