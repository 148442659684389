import cx from 'classnames';
import { ArrowRightIcon } from '../Icons/icons';

type Props = {
    active?: boolean;
    onClick?: () => void;
    disabled?: boolean;
    className?: string;
    submit?: boolean;
};
export const NextButton = ({
    active,
    onClick,
    disabled,
    className,
    submit,
}: Props) => {
    return (
        // The button has 3 states: Active, Disabled and Normal
        <button
            type={submit ? 'submit' : 'button'}
            onClick={onClick}
            disabled={disabled}
            className={cx(
                'group border-1.6px w-16 h-8 relative flex-shrink-0 ',
                ' rounded-full overflow-hidden disabled:pointer-events-none disabled:border-starkWhite',
                active
                    ? 'bg-peachOrange border-peachOrange'
                    : 'border-woodyBrown',
                disabled && 'border-vanillaWhite text-mongooseGray ',
                className,
            )}
        >
            <div className="absolute top-0  h-full w-full -left-1/2 -translate-x-1/2  group-hover:left-1/2 duration-500  ">
                <ArrowRightIcon />
            </div>
            <div className="absolute  top-0 h-full w-full right-1/2 translate-x-1/2  group-hover:-right-1/2 duration-500 ">
                <ArrowRightIcon />
            </div>
        </button>
    );
};
