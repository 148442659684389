import { PlaybackMode } from 'src/domain/session';
import {
    removeImage,
    togglePlaybackMode,
    updateImageDuration,
} from 'src/view/screens/NewVideo/SessionFormState/actions';
import { useSessionForm } from 'src/view/screens/NewVideo/SessionFormState/hooks';
import { Duration } from '../Duration';
import { MusicIcon } from '../Icons/icons';
import { SelectionDropdown } from './SelectionDropdown';
import { ImageSelectionItem } from './SelectionItems/ImageSelectionItem';

export const ImageSelectionDropdown = () => {
    const {
        state: { images, playbackMode, duration, imageDuration, step },
        dispatch,
    } = useSessionForm();

    return (
        <SelectionDropdown
            selection={images}
            name="Bilde"
            namePlural="Bilder"
            icon={MusicIcon}
            disabled={step === 3}
            ItemComponent={ImageSelectionItem}
            onRemove={id => {
                dispatch(removeImage(id));
            }}
            onChange={selection => {
                if (selection.length <= 1) {
                    if (playbackMode === PlaybackMode.LOOP)
                        dispatch(togglePlaybackMode());
                    if (selection.length === 0)
                        dispatch(updateImageDuration(0));
                }
                if (playbackMode === PlaybackMode.LOOP) return;
                dispatch(updateImageDuration(duration / images.length));
            }}
        >
            <div className="flex gap-2 w-full py-4 px-4 ">
                <div className="w-2/3 text-sm font-bold text-right whitespace-nowrap ">
                    Bildevarighet:
                </div>
                <div className="w-1/3 tabular-nums text-sm font-matterSQ ">
                    <Duration value={imageDuration} />
                </div>
            </div>
        </SelectionDropdown>
    );
};
