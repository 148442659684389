import { VideoTitle } from '../components/Title';
import { PreviewVideo } from './components/PreviewVideo';

export const CreateNewVideoStep3 = () => {
    return (
        <div className="flex flex-col h-full w-full items-center relative text-woodyBrown">
            <div className="space-y-4">
                <PreviewVideo />
                <VideoTitle />
            </div>
        </div>
    );
};
