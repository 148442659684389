import { useState } from 'react';
import { Creator } from 'src/domain/creator';
import { getFullName } from 'src/domain/user';
import { useAvatarFetch } from 'src/view/hooks/useImageFetch';
import { useCurrentConsumerContext } from '../../Home/NewHome/reducer';
import { AssignConsumer } from './AssignConsumer';
import { CardGrid } from 'src/components/AppScreen/AppLayout';
import { PlusIcon } from 'src/components/Icons/icons';
import { ScrollableModal } from 'src/components/Modals/ScrollableModal';
import { TextIcon } from 'src/components/Icons/TextIcon';
import Card from 'src/components/Card';
import container from 'src/container';
import useSWR from 'swr';

const { munduClient } = container.cradle;

export const ConsumerList = ({ creator }: { creator: Creator }) => {
    const [assigningConsumer, setAssigningConsumer] = useState(false);
    const getAvatar = useAvatarFetch();
    const { dispatch } = useCurrentConsumerContext();

    // TODO useCreatorsConsumers
    const { data: consumers } = useSWR(
        creator ? [{ creatorId: creator.id }, 'consumers'] : null,
        munduClient.listConsumers.bind(munduClient),
    );

    return (
        <>
            <ScrollableModal
                visible={assigningConsumer}
                onClose={() => setAssigningConsumer(false)}
            >
                <AssignConsumer assignTo={creator} />
            </ScrollableModal>
            <CardGrid>
                <Card
                    onClick={() => setAssigningConsumer(true)}
                    label="Legg til / fjern"
                    shape="circle"
                >
                    <TextIcon SVG={PlusIcon} />
                </Card>
                {consumers &&
                    consumers.map(consumer => (
                        <Card
                            fetchImage={() =>
                                getAvatar(consumer.profile.avatar)
                            }
                            key={consumer.id}
                            onClick={() =>
                                dispatch({
                                    type: 'SELECT_CONSUMER',
                                    payload: consumer,
                                })
                            }
                            to={`/app/users/profile/${consumer.id}`}
                            label={getFullName(consumer)}
                            shape="circle"
                        >
                            <img
                                id={consumer.profile.avatar}
                                alt="consumer-avatar"
                            />
                        </Card>
                    ))}
            </CardGrid>
        </>
    );
};
