import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getFullName } from 'src/domain/user';
import { mutate } from 'swr';
import container from 'src/container';
import Input from 'src/components/FormComponents/Input';
import AvatarInput from 'src/components/FormComponents/AvatarInput';
import Form from 'src/components/FormComponents/Form';
import { Consumer } from 'src/domain/consumer';
import { IsString, Length, IsNotEmpty } from 'class-validator';
import { Button } from 'src/components/Buttons/Button';
import { UserIcon } from 'src/components/Icons/icons';
import { useNotification } from 'src/components/Notification/useNotfication';
import { Section } from 'src/components/Section';
import cx from 'classnames';
import _ from 'lodash';
import { useCurrentConsumerContext } from '../../Home/NewHome/reducer';

const {
    cradle: { munduClient },
} = container;

class ConsumerEditSchema {
    @IsString()
    @Length(2, 30, { message: 'Må være mellom 2 og 30 tegn' })
    @IsNotEmpty({ message: 'Må fylles ut.' })
    firstName: string;

    @IsString()
    @IsNotEmpty({ message: 'Må fylles ut.' })
    lastName: string;
}

export const ConsumerProfileEdit = ({ consumer }: { consumer: Consumer }) => {
    const notify = useNotification();
    const [newPassword, setNewPassword] = useState<string | undefined>(
        undefined,
    );

    const handleUpdate = async (data: ConsumerEditSchema) => {
        try {
            await munduClient.updateConsumer(consumer.id, data);
            mutate([consumer.id, 'consumer/consumerId']);
            mutate('consumers');
            notify('Profil er oppdatert');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    const resetPassword = async () => {
        try {
            const res = await munduClient.resetConsumerPassword(consumer.id);
            setNewPassword(res.password);
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return (
        <div className="bg-lightPeach flex flex-col gap-8">
            <Section title="Rediger profil">
                <Form
                    className="bg-lightPeach text-woodyBrown "
                    schema={ConsumerEditSchema}
                    onSubmit={handleUpdate}
                    mode="onChange"
                    defaultValues={{
                        firstName: consumer.profile.firstName,
                        lastName: consumer.profile.lastName,
                    }}
                >
                    {({ register, formState: { isValid, errors } }) => (
                        <>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="col-span-full">
                                    <AvatarInput consumer={consumer} />
                                </div>
                                <Input
                                    className="text-input"
                                    label="Fornavn*"
                                    error={errors.firstName}
                                    type="text"
                                    {...register('firstName')}
                                />
                                <Input
                                    className="text-input"
                                    label="Etternavn*"
                                    error={errors.lastName}
                                    type="text"
                                    {...register('lastName')}
                                />
                            </div>

                            <div className="mt-4">
                                <Input
                                    value="Lagre"
                                    className="primary-btn-small"
                                    disabled={!isValid}
                                    type="submit"
                                />
                            </div>
                        </>
                    )}
                </Form>
            </Section>
            <Section title="Glemt passord?">
                <div>
                    Passordet til {getFullName(consumer)} tilbakestilles og
                    midlertidig passord vil dukke opp her.
                </div>
                <div>
                    <Button
                        title="Tilbakestill passord"
                        onClick={resetPassword}
                    />
                </div>

                <div
                    className={cx(
                        'flex flex-col gap-3 transition-all',
                        newPassword ? 'h-full visible' : 'h-0 invisible',
                    )}
                >
                    <div>
                        Noter deg det midlertidige passordet. Neste gang du
                        logger inn på {getFullName(consumer)} sin konto,
                        benytter du dette. Nytt passord må settes under
                        innlogging{' '}
                    </div>
                    <div>
                        Midlertidig passord:{' '}
                        <span className="font-semibold text-2xl">
                            {newPassword}
                        </span>
                    </div>
                </div>
            </Section>

            <Section title="Slette brukerkontakt?">
                <DeleteConsumer consumer={consumer} />
            </Section>
        </div>
    );
};

const DeleteConsumer = ({ consumer }: { consumer: Consumer }) => {
    const [confirmationUsername, setConfirmationUsername] = useState('');
    const [canDelete, setCanDelete] = useState(false);
    const history = useHistory();
    const notify = useNotification();
    const { dispatch } = useCurrentConsumerContext();

    const onDelete = _.debounce(async () => {
        munduClient
            .deleteConsumer(consumer.id)
            .then(() => {
                notify(`${getFullName(consumer)} er slettet`);
                dispatch({ type: 'RESET' });
                mutate('consumers');
                history.push('/app/home');
            })
            .catch((error: Error) => notify(error.message, true));
    }, 500);

    useEffect(() => {
        if (confirmationUsername === consumer.username) {
            setCanDelete(true);
        } else {
            setCanDelete(false);
        }
    }, [confirmationUsername, consumer.username]);

    return (
        <>
            <div>
                Ved å slette brukerkontakt, mister bruker tilgang til Mundu og
                bruker kan ikke gjenopprettes
            </div>
            <div className="self-start flex items-end gap-3 flex-wrap">
                <Input
                    className="text-input"
                    type="text"
                    onChange={e =>
                        setConfirmationUsername(e.currentTarget.value)
                    }
                    label={`Skriv inn '${consumer.username}' for å slette`}
                    leadingIcon={UserIcon}
                />
                <Button
                    title="Slett bruker"
                    onClick={onDelete}
                    disabled={!canDelete}
                />
            </div>
        </>
    );
};
