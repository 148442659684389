import { isAfter } from 'date-fns';
import * as R from 'ramda';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import container from 'src/container';
import { Consumer } from 'src/domain/consumer';
import { Media } from 'src/domain/personalMedia';
import { UserRedux } from 'src/state/reducers';
import { UserThunks } from 'src/state/thunks';
import { getReducerState } from 'src/state/User/UserReducer';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

const {
    cradle: { munduClient, storageService },
} = container;

const getConsumerImages = async (
    consumerId: string,
    uploaderId?: string,
): Promise<Media[]> => {
    const res = await munduClient.listPersonalMedia({ consumerId, uploaderId });
    const result = res.map(item =>
        item.medias.map(media => {
            return { ...media, containerType: 'pm' };
        }),
    );
    return result.flat();
};
export const useCommonMediaSas = () => {
    const dispatch = useDispatch();
    const sas = useSelector(
        R.pipe(getReducerState, UserRedux.selectors.getCommonMediaSas),
    );
    const sasIsUpdated = sas && !isAfter(new Date(), new Date(sas.expiresOn));

    useEffect(() => {
        if (!sasIsUpdated) {
            dispatch(UserThunks.updateCommonMediaSAS());
        }
    }, [sasIsUpdated, dispatch]);

    return { sas: sasIsUpdated ? sas : undefined };
};

export const useAvatarSas = () => {
    const dispatch = useDispatch();
    const sas = useSelector(
        R.pipe(getReducerState, UserRedux.selectors.getAvatarSas),
    );
    const sasIsUpdated = sas && !isAfter(new Date(), new Date(sas.expiresOn));

    useEffect(() => {
        if (!sasIsUpdated) {
            dispatch(UserThunks.updateAvatarSAS());
        }
    }, [sasIsUpdated, dispatch]);

    return { sas: sasIsUpdated ? sas : undefined };
};

export const usePersonalMediaSas = (consumerId?: string) => {
    const dispatch = useDispatch();
    const sas = useSelector(
        R.pipe(getReducerState, state =>
            UserRedux.selectors.getPersonalMediaSas(state, consumerId),
        ),
    );
    const sasIsUpdated =
        consumerId !== undefined &&
        sas &&
        !isAfter(new Date(), new Date(sas.expiresOn)) &&
        sas.containerName.endsWith(consumerId);

    useEffect(() => {
        if (!sasIsUpdated && consumerId) {
            dispatch(UserThunks.updatePersonalMediaSas(consumerId));
        }
    }, [sasIsUpdated, consumerId, dispatch]);

    return { sas: sasIsUpdated ? sas : undefined };
};

export const useConsumer = (consumerId?: string) => {
    const { data, mutate } = useSWR(
        consumerId ? [consumerId, 'consumer/consumerId'] : null,
        munduClient.getConsumer.bind(munduClient),
    );
    return { consumer: data, refresh: mutate };
};

//TODO: fix duplicate caching
export const useConsumersImages = (
    consumerId?: string,
    uploaderId?: string,
) => {
    const { sas } = usePersonalMediaSas(consumerId);
    const {
        data,
        error,
        mutate: refresh,
    } = useSWR(
        consumerId && sas ? [consumerId, uploaderId, 'personalMedias'] : null,
        getConsumerImages,
    );
    const mediaItems = data || [];

    const urls =
        sas && data
            ? data.map(
                  image =>
                      storageService.getImageUrl(
                          image.thumbnail ? image.thumbnail.id : image.id,
                          sas,
                      ) || '',
              )
            : [];
    const isLoading = !data && !error;
    const images = mediaItems.map((item, index) => {
        return { media: item, url: urls[index] };
    });

    return {
        images,
        urls,
        isLoading,
        refresh,
    };
};

export const useConsumerSession = (sessionId: string) => {
    return useSWR([sessionId, 'sessions/consumerSessions'], () =>
        munduClient.getConsumerSession(sessionId),
    );
};

export const useImages = (consumerId?: Consumer['id'], pageSize = 15) => {
    // const { cache } = useSWRConfig();

    const dispatch = useDispatch();
    const sas = useSelector(
        R.pipe(getReducerState, state =>
            UserRedux.selectors.getPersonalMediaSas(state, consumerId),
        ),
    );

    const sasIsUpdated =
        consumerId !== undefined &&
        sas &&
        !isAfter(new Date(), new Date(sas.expiresOn)) &&
        sas.containerName.endsWith(consumerId);

    useEffect(() => {
        if (!sasIsUpdated && consumerId) {
            dispatch(UserThunks.updatePersonalMediaSas(consumerId));
        }
    }, [sasIsUpdated, consumerId, dispatch]);

    const {
        data,
        size,
        setSize,
        mutate: refresh,
        error,
    } = useSWRInfinite(
        (pageIndex, previousPageData) => {
            if (!sasIsUpdated || !consumerId) return null;
            if (pageIndex === 0 && !previousPageData)
                return ['personalmedia', consumerId, pageSize];
            if (
                previousPageData &&
                previousPageData.images &&
                previousPageData.images.length < pageSize
            )
                return null;
            if (
                pageIndex > 0 &&
                previousPageData &&
                previousPageData.continuationToken === undefined
            )
                return null;

            return [
                'personalmedia',
                consumerId,
                pageSize,
                previousPageData.continuationToken,
            ];
        },
        async (_, consumerId, pageSize, continuationToken) =>
            await storageService.list(pageSize, continuationToken),

        {
            revalidateOnFocus: false,
        },
    );

    const images = data ? data.flat().map(x => x.images) : [];

    const isLoading = !data && !error;
    const isLoadingNext =
        isLoading ||
        (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.images.length === 0;
    const hasMore = !(
        isEmpty ||
        (data && data[data.length - 1]?.images?.length < pageSize)
    );
    // const isRefreshing = isValidating && data && data.length === size;

    return {
        images: images.flat(),
        isLoading,
        nextPage: () => setSize(size + 1),
        setPageSize: setSize,
        isLoadingNext,
        hasMore,
        refresh,
        // isRefreshing,
    };
};
