import { useState, ChangeEvent, useMemo, useEffect } from 'react';
import { debounce } from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Input from 'src/components/FormComponents/Input';
import { SearchIcon } from 'src/components/Icons/icons';
type SearchBarProps = {
    placeholder?: string;
    searchPath?: string;
};

export const SearchBar = ({
    placeholder = 'Søk etter musikk',
    searchPath,
}: SearchBarProps) => {
    const history = useHistory();
    const { path } = useRouteMatch();
    const [query, setQuery] = useState<string | null>();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 700);
    }, []);

    useEffect(() => {
        if (!query) return;
        history.push(searchPath || `${path}/search`, {
            search: '?q=' + query,
        });
        return () => {
            debouncedResults.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    return (
        <>
            <div className="flex flex-col text-[14px] w-full">
                <Input
                    key="SearchBar"
                    type="text"
                    leadingIcon={SearchIcon}
                    className="inline-flex w-full max-w-max truncate rounded-full py-2  placeholder-woodyBrown border-woodyBrown border-[1px]  bg-lightPeach text-woodyBrown focus:outline-none outline-none leading-none"
                    placeholder={placeholder}
                    onChange={debouncedResults}
                    textColor="woodyBrown"
                />
            </div>
        </>
    );
};
