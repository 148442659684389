import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PlaylistDetails } from 'src/domain/music';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { FALLBACK_COVER_IMAGE } from '../..';
import { Modal } from '../../components/modal';
import { DeleteButton } from './deletePlaylistButton';
import { Author } from './playlistAuthor';
import { UpdatePlaylistForm } from './updatePlaylistForm';

type PlaylistBannerProps = {
    playlist: PlaylistDetails;
    // topColor: string;
    // bottomColor: string;
    editable: boolean;
};

export const PlaylistBanner = ({
    playlist,
    // topColor,
    // bottomColor,
    editable,
}: PlaylistBannerProps) => {
    const [showModal, setShowModal] = useState(false);
    let authorName = '';
    const { pathname } = useLocation();
    const user = useSignedInUser();
    if (pathname.endsWith('munduPlaylist')) {
        authorName = 'Mundu';
    } else {
        authorName = user
            ? [user?.profile.firstName, user?.profile.lastName].join(' ')
            : '';
    }

    // const bannerGradient = `bg-gradient-to-b from-${topColor} to-${bottomColor}`;
    // const onEdit = () => {};

    return (
        // <div className={`${bannerGradient} px-10 pt-16 bg-opacity-75`}>
        <div className="bg-opacity pb-2">
            <div className="flex w-full justify-between gap-3">
                <div className="flex space-x-8 items-start ">
                    <div className="flex-shrink-0 relative w-24 lg:w-32 xl:w-36 aspect-square ">
                        <img
                            className="absolute inset-0 rounded-xl xl:rounded-2xl shadow-lg"
                            src={
                                playlist.image
                                    ? playlist.image
                                    : FALLBACK_COVER_IMAGE
                            }
                            alt={`for playlist ${playlist.name}`}
                        />
                    </div>
                    <div className="flex flex-col justify-between h-24 lg:h-32 xl:h-36">
                        <div
                            id="entity"
                            className=" text-[8px] lg:text-sm font-extralight"
                        >
                            SPILLELISTE
                        </div>
                        <button
                            className="font-bold font-logo text-left font-white text-xl xl:text-3xl lg:leading-normal whitespace-nowrap truncate"
                            onClick={() => setShowModal(true)}
                            disabled={!editable}
                        >
                            {playlist.name}
                        </button>
                        {playlist.description && (
                            <button
                                id="description"
                                onClick={() => setShowModal(true)}
                                disabled={!editable}
                            >
                                <p className="flex-none block text-[10px] lg:text-sm text-mongooseGray text-left line-clamp-2 lg:line-clamp-3">
                                    {playlist.description}
                                </p>
                            </button>
                        )}
                        <Author name={authorName} />
                    </div>
                </div>
                {editable && (
                    <DeleteButton id={playlist.id} name={playlist.name} />
                )}
            </div>

            <Modal isVisible={showModal}>
                <UpdatePlaylistForm
                    playlist={playlist}
                    setShowModal={setShowModal}
                />
            </Modal>
        </div>
    );
};
