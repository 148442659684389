import cx from 'classnames';

type Props = {
    onClick: () => void;
    isSelected: boolean;
    entity: string;
    name: string;
    src: string;
    status: string;
    rounded?: boolean;
    isLight?: boolean;
};
export const SelectorOption = ({
    onClick,
    isSelected,
    entity,
    name,
    src,
    rounded = false,
    isLight = false,
}: Props) => {
    return (
        <button
            onClick={onClick}
            className={cx(
                'inline-flex space-x-[16px] w-full items-center px-[16px]  py-2 transition-all',
                isSelected
                    ? isLight
                        ? 'text-[18.5px] text-lightPeach bg-woodyBrown  hover:bg-opacity-90'
                        : 'text-[18.5px] text-woodyBrown bg-lightPeach '
                    : isLight
                    ? 'hover:bg-woodyBrown text-woodyBrown hover:bg-opacity-5'
                    : 'hover:bg-white hover:text-white hover:bg-opacity-5',
            )}
        >
            <img
                className={cx(
                    'w-14 h-14 shadow-md object-cover',
                    rounded ? 'rounded-full' : 'rounded-[8px]',
                )}
                src={src}
                alt="avatar consumer"
            />
            <div className="text-left h-[54px] truncate ">
                <div>
                    <p className="text-[14px] leading-none ">{name}</p>
                    <p className="text-[10px] font-extralight">{entity}</p>
                </div>
                {/* <div className="inline-flex items-center space-x-[4px] mb-[2px] ">
                    <div className="bg-palmleafGreen w-[8px] h-[8px] mt-[2px] rounded-full " />
                    <p className="text-[12px] align-middle leading-none ">
                        {status}
                    </p>
                </div> */}
            </div>
        </button>
    );
};
