import { ReactNode, useCallback, useState } from 'react';
import cx from 'classnames';
import { Sidebar } from '../Sidebar';
import { Header } from '../Header';
import { useAudioPlayerContext } from 'src/view/screens/BrowseMusic/audioPlayerReducer';
import { useIsSelectingMusic } from 'src/components/ModuleSelector/ModuleSelector';
import { useHistory } from 'react-router-dom';
import {
    updateImages,
    updateMusic,
    updateStep,
} from 'src/view/screens/NewVideo/SessionFormState/actions';
import { useSessionForm } from 'src/view/screens/NewVideo/SessionFormState/hooks';

import {
    useIsImageSelection,
    useIsImageUpload,
    useIsNewVideo,
    useIsSelectingImages,
} from 'src/view/hooks/useIsSelectingMusic';
import { NextButton } from 'src/components/Buttons/NextButton';
import {
    HamburgerIcon,
    SlidersIcon,
    UploadIcon,
} from 'src/components/Icons/icons';
import { IconComponent, TextIcon } from 'src/components/Icons/TextIcon';
import { useImageSelectorContext } from 'src/state/reducers/ImageSelectorReducer';
import { ContentViewContainer, ContentBox } from '../AppLayout';
import { WrapIf } from 'src/components/WrapIf';

type Props = {
    children?: ReactNode | ReactNode[];
    right?: boolean;
    NavItems?: ReactNode;
    HeaderLeft?: ReactNode;
    HeaderRight?: ReactNode;
    HeaderCenter?: ReactNode;
    Selector?: ReactNode;
    SubHeader?: ReactNode;
    ContentHeader?: ReactNode;
    toggleIcon?: IconComponent;
    sidebarIsNextStep?: boolean;
};

export const Navigation = ({
    children,
    right = false,
    NavItems,
    Selector,
    SubHeader,
    ContentHeader,
    HeaderLeft,
    HeaderRight,
    HeaderCenter,
    toggleIcon = HamburgerIcon,
    sidebarIsNextStep = false,
}: Props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => setSidebarOpen(prev => !prev);
    const isSelectingMusic = useIsSelectingMusic();
    const isSelectingImages = useIsSelectingImages();
    const isImageSelection = useIsImageSelection();
    const isUploadingImages = useIsImageUpload();
    const isNewVideo = useIsNewVideo();

    return (
        <div
            id="collapsable-sidebar-container"
            className={cx(
                'flex w-full relative overflow-x-hidden',
                right && 'flex-row-reverse',
            )}
        >
            <Sidebar
                isOpen={sidebarOpen}
                toggle={toggleSidebar}
                right={false}
                closed={NavItems === undefined}
            >
                {NavItems}
                {NavItems !== undefined && Selector}
            </Sidebar>

            <div id="main-view" className="flex flex-col w-full h-full">
                <Header
                    id="top-navigation"
                    left={HeaderLeft}
                    center={HeaderCenter}
                    right={
                        <>
                            {HeaderRight}
                            {sidebarIsNextStep && NavItems && (
                                <NextButton
                                    className={cx(
                                        'md:hidden max-h-5',
                                        !NavItems && !Selector && 'hidden',
                                    )}
                                    active={true}
                                    onClick={toggleSidebar}
                                />
                            )}

                            <button
                                className={cx(
                                    'primary-btn border-0 rounded-[4px] p-0 text-xl md:hidden',
                                    sidebarIsNextStep && 'hidden',
                                    !NavItems && !Selector && 'hidden',
                                )}
                                onClick={toggleSidebar}
                            >
                                <TextIcon SVG={toggleIcon} />
                            </button>
                        </>
                    }
                />

                <>
                    <WrapIf
                        condition={
                            isNewVideo &&
                            !isSelectingMusic &&
                            !isSelectingImages
                        }
                        wrapper={children => (
                            <div className="flex flex-col max-w-full items-center mx-4 ">
                                <div className="inline-flex items-center w-full space-x-2 ">
                                    {children}
                                    {isImageSelection && (
                                        <ImageSelectionNextButton
                                            onToggle={toggleSidebar}
                                        />
                                    )}
                                </div>
                                <hr className="w-full border-woodyBrown border-1 opacity-30" />
                            </div>
                        )}
                    >
                        <>{SubHeader}</>
                    </WrapIf>

                    <div
                        className={cx(
                            !NavItems && Selector
                                ? 'flex relative overflow-x-hidden overflow-y-auto w-full h-full '
                                : 'contents',
                            !NavItems &&
                                Selector &&
                                right &&
                                'flex-row-reverse',
                        )}
                    >
                        {!NavItems && Selector && (
                            <Sidebar
                                isOpen={sidebarOpen}
                                toggle={toggleSidebar}
                                right={right}
                                closed={Selector === undefined}
                            >
                                {Selector}
                            </Sidebar>
                        )}
                        <div
                            className={
                                !NavItems && Selector
                                    ? 'flex flex-col h-full w-full overflow-y-hidden'
                                    : 'contents'
                            }
                        >
                            {!NavItems && Selector ? (
                                <div className="inline-flex space-x-2 w-full rounded-bl-lg items-center sticky  bg-lightPeach px-4 md:px-6">
                                    {ContentHeader}
                                    {isSelectingMusic ? (
                                        <UpdateMusicSelectionButton
                                            onToggle={toggleSidebar}
                                        />
                                    ) : isSelectingImages ? (
                                        <UpdateImageSelectionButton
                                            onToggle={toggleSidebar}
                                        />
                                    ) : isImageSelection ? null : isUploadingImages ? (
                                        <ConsentPanelButton
                                            onToggle={toggleSidebar}
                                        />
                                    ) : (
                                        <button
                                            className={cx(
                                                'primary-btn border-0 rounded-[4px] p-0 text-xl md:hidden bg-peachOrange md:bg-transparent hover:bg-woodyBrown ',
                                                !NavItems &&
                                                    !Selector &&
                                                    'hidden',
                                            )}
                                            onClick={toggleSidebar}
                                        >
                                            <TextIcon SVG={toggleIcon} />
                                        </button>
                                    )}
                                </div>
                            ) : (
                                ContentHeader && (
                                    <div
                                        id="ContentHeader"
                                        className="inline-flex px-6"
                                    >
                                        {ContentHeader}
                                    </div>
                                )
                            )}
                            <ContentViewContainer>
                                <ContentBox>{children}</ContentBox>
                            </ContentViewContainer>
                        </div>
                    </div>
                </>
            </div>
        </div>
    );
};

const UpdateMusicSelectionButton = ({ onToggle }: { onToggle: () => void }) => {
    const { dispatch: sessionFormDispatch } = useSessionForm();
    const {
        state: { selection },
    } = useAudioPlayerContext();
    const history = useHistory();
    const updateSelection = useCallback(() => {
        sessionFormDispatch(updateMusic(selection));
        history.replace('/app/new-video/step1');
    }, [sessionFormDispatch, history, selection]);

    return (
        <div className="flex  text-sm   items-center stroke-[1.8px]  mr-6 md:hidden max-h-1 ">
            <button
                type="button"
                onClick={updateSelection}
                className="secondary-btn-small rounded-0 rounded-r-none rounded-l-full py-2 text-sm  flex  md:hidden bg-peachOrange hover:bg-woodyBrown md:rounded-full pr-2 pl-3  "
            >
                {'Legg til'}
                <span className="font-bold">
                    &nbsp;{selection.length}
                    &nbsp;
                </span>
                <span className="whitespace-nowrap">
                    {selection.length === 1 ? 'sang' : 'sanger'}
                </span>
            </button>
            <div className="flex items-center gap-2 ">
                {/* <button
                    className="flex items-center gap-2 hover:bg-peachOrange hover:bg-opacity-25 p-2"
                    onClick={updateSelection}
                    disabled={selection.length === 0}
                >
                    <div className="inline-block ">
                        <span className="font-bold">{`${selection.length}`}</span>
                        <span className="ml-1 whitespace-nowrap">
                            {selection.length === 1 ? 'sang' : 'sanger'}
                        </span>
                    </div>
                </button> */}
                <button
                    className="bg-peachOrange border-l-[1.4px] border-lightPeach pr-[0.2rem] py-1 rounded-r-full hover:bg-woodyBrown hover:text-lightPeach md:hidden"
                    onClick={onToggle}
                >
                    <TextIcon SVG={HamburgerIcon} />
                </button>
            </div>
        </div>
    );
};

const UpdateImageSelectionButton = ({ onToggle }: { onToggle: () => void }) => {
    const { dispatch: sessionFormDispatch } = useSessionForm();
    const {
        state: { selection },
    } = useImageSelectorContext();
    const history = useHistory();
    const updateSelection = useCallback(() => {
        sessionFormDispatch(updateImages(selection));
        history.replace('/app/new-video/step2');
    }, [sessionFormDispatch, history, selection]);

    return (
        <div className="flex  text-sm   items-center stroke-[1.8px] md:hidden pt-2 ">
            <button
                type="button"
                onClick={updateSelection}
                className="secondary-btn-small rounded-0 rounded-r-none rounded-l-full py-2 text-sm  bg-peachOrange hover:bg-woodyBrown md:rounded-full pr-2 pl-3  "
            >
                {'Legg til'}
                <span className="font-bold">
                    &nbsp;{selection.length}
                    &nbsp;
                </span>
                <span className="whitespace-nowrap">
                    {selection.length === 1 ? 'bilder' : 'bilder'}
                </span>
            </button>
            <div className="flex items-center gap-2 ">
                {/* <button
                    className="flex items-center gap-2 hover:bg-peachOrange hover:bg-opacity-25 p-2"
                    onClick={updateSelection}
                    disabled={selection.length === 0}
                >
                    <div className="inline-block ">
                        <span className="font-bold">{`${selection.length}`}</span>
                        <span className="ml-1 whitespace-nowrap">
                            {selection.length === 1 ? 'sang' : 'sanger'}
                        </span>
                    </div>
                </button> */}
                <button
                    className="bg-peachOrange border-l-[1.4px] border-lightPeach pr-[0.2rem] py-1 rounded-r-full hover:bg-woodyBrown hover:text-lightPeach md:hidden"
                    onClick={onToggle}
                >
                    <TextIcon SVG={HamburgerIcon} />
                </button>
            </div>
        </div>
    );
};
const ConsentPanelButton = ({ onToggle }: { onToggle: () => void }) => {
    return (
        <div className="flex text-sm   items-center stroke-[1.8px] md:hidden ">
            <button className="flex secondary-btn-small hover:text-woodyBrown border-none rounded-0 rounded-r-none rounded-l-full py-0.5 px-3 text-sm  bg-peachOrange md:rounded-full whitespace-nowrap items-center ">
                <div className="inline-flex items-center ">
                    <TextIcon SVG={UploadIcon} />
                    <p>Last opp</p>
                </div>
            </button>

            <div className="flex items-center gap-2 ">
                {/* <button
                    className="flex items-center gap-2 hover:bg-peachOrange hover:bg-opacity-25 p-2"
                    onClick={updateSelection}
                    disabled={selection.length === 0}
                >
                    <div className="inline-block ">
                        <span className="font-bold">{`${selection.length}`}</span>
                        <span className="ml-1 whitespace-nowrap">
                            {selection.length === 1 ? 'sang' : 'sanger'}
                        </span>
                    </div>
                </button> */}
                <button
                    className="bg-peachOrange border-l-[1.4px] border-lightPeach pr-[0.2rem] py-0.5 rounded-r-full hover:bg-woodyBrown hover:text-lightPeach md:hidden"
                    onClick={onToggle}
                >
                    <TextIcon SVG={SlidersIcon} />
                </button>
            </div>
        </div>
    );
};

const ImageSelectionNextButton = ({ onToggle }: { onToggle: () => void }) => {
    const {
        state: { images },
        dispatch,
    } = useSessionForm();

    const history = useHistory();
    const disabled = images.length === 0;
    const nextStep = useCallback(() => {
        if (disabled) return;
        dispatch(updateStep(3));
        history.push('/app/new-video/step3');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, disabled]);

    return (
        <div className="flex  text-sm   items-center stroke-[1.8px] md:hidden  ">
            <NextButton
                onClick={nextStep}
                disabled={disabled}
                className="secondary-btn-small hover:text-woodyBrown border-peachOrange rounded-0 rounded-r-none rounded-l-full py-2 text-sm  bg-peachOrange disabled:bg-lightPeach disabled:border-mongooseGray  md:rounded-full pr-2 pl-3  "
            />

            <div className="flex items-center gap-2 ">
                {/* <button
                    className="flex items-center gap-2 hover:bg-peachOrange hover:bg-opacity-25 p-2"
                    onClick={updateSelection}
                    disabled={selection.length === 0}
                >
                    <div className="inline-block ">
                        <span className="font-bold">{`${selection.length}`}</span>
                        <span className="ml-1 whitespace-nowrap">
                            {selection.length === 1 ? 'sang' : 'sanger'}
                        </span>
                    </div>
                </button> */}
                <button
                    className="bg-peachOrange border-l-[1.4px] border-lightPeach pr-[0.2rem] py-0.5 rounded-r-full hover:bg-woodyBrown hover:text-lightPeach md:hidden"
                    onClick={onToggle}
                >
                    <TextIcon SVG={SlidersIcon} />
                </button>
            </div>
        </div>
    );
};
