import { IsOptional, IsString, Length, IsNotEmpty } from 'class-validator';
import { useDispatch } from 'react-redux';
import { PeopleSVG } from 'src/assets/svg';
import { LinkButton } from 'src/components/Buttons/LinkButton';
import Form from 'src/components/FormComponents/Form';
import { PhoneIcon, MessageIcon } from 'src/components/Icons/icons';
import { useNotification } from 'src/components/Notification/useNotfication';
import { Section } from 'src/components/Section';
import { SUPPORT_EMAIL } from 'src/config';
import container from 'src/container';
import { Organization } from 'src/domain/organization';
import { UserThunks } from 'src/state/thunks';
import { useLicenceInfo } from 'src/view/hooks/organization';
import { useSignInIdentities } from 'src/view/hooks/useSignInIdentities';
import Input from 'src/components/FormComponents/Input';

const {
    cradle: { munduClient },
} = container;

class EditOrganizationSchema {
    @IsOptional()
    @IsString()
    @Length(2, 30, { message: 'Må være mellom 2 og 30 tegn' })
    @IsNotEmpty({ message: 'Må fylles ut.' })
    firstName?: string;

    @IsOptional()
    @IsString()
    @Length(2, 30, { message: 'Må være mellom 2 og 30 tegn' })
    @IsNotEmpty({ message: 'Må fylles ut.' })
    lastName?: string;
}
export const OrganizationProfile = ({ user }: { user: Organization }) => {
    const { licenceInfo } = useLicenceInfo();
    const dispatch = useDispatch();
    const notify = useNotification();
    const { phoneNumber } = useSignInIdentities(user.id);

    const handleUpdate = async (data: EditOrganizationSchema) => {
        try {
            await munduClient.updateOganization(user.id, data);
            dispatch(UserThunks.refreshUser());
            notify('Profil ble oppdatert');
        } catch (e) {
            if (e instanceof Error) notify(e.message, true);
        }
    };

    return (
        <div className="flex flex-col gap-8 max-w-3xl mx-auto">
            <Form
                className="bg-lightPeach text-woodyBrown"
                schema={EditOrganizationSchema}
                onSubmit={handleUpdate}
                mode="onChange"
                defaultValues={{
                    firstName: user.profile.firstName,
                    lastName: user.profile.lastName,
                }}
            >
                {({
                    register,
                    formState: { isValid, errors, isSubmitting, isDirty },
                }) => (
                    <div className="grid grid-cols-2 gap-4 h-full">
                        <div className="col-span-full flex justify-center">
                            {/* TODO: Replace with Role icon when ready */}
                            <div className="rounded-full w-36 h-36 bg-starkWhite flex justify-center items-center mb-4">
                                <PeopleSVG className="w-[18px] h-[18px]" />
                            </div>
                        </div>
                        <Input
                            className="text-input"
                            type="text"
                            label="Fornavn*"
                            error={errors.firstName}
                            {...register('firstName')}
                        />
                        <Input
                            className="text-input"
                            type="text"
                            label="Etternavn*"
                            error={errors.lastName}
                            {...register('lastName')}
                        />
                        <Input
                            className="text-input"
                            type="tel"
                            label="Telefonnr"
                            leadingIcon={PhoneIcon}
                            value={phoneNumber ? phoneNumber : ''}
                            readOnly
                        />
                        <Input
                            className="text-input"
                            type="email"
                            label="Epost*"
                            value={user.email}
                            leadingIcon={MessageIcon}
                            readOnly
                        />
                        <div className="flex items-center gap-6">
                            <Input
                                value="Lagre"
                                className="primary-btn-small"
                                disabled={!isValid || isSubmitting || !isDirty}
                                type="submit"
                            />
                        </div>
                    </div>
                )}
            </Form>

            <Section title="Lisenser">
                <div
                    id="Licences"
                    className="w-75 items-center rounded-16px py-12 text-center text-white bg-woodyBrown h-333px"
                >
                    <div className="pb-16">
                        <span className="text-peachOrange text-3xl">
                            {licenceInfo?.active}
                        </span>
                        <span className="text-3xl mx-1">/</span>
                        <span className="text-2xl">{licenceInfo?.total}</span>
                        <br />i bruk
                    </div>
                    <div className="w-full inline-block text-20px pb-12">
                        Ønsker du flere lisenser?
                    </div>
                    {/* TODO: Use Sendgrid to notify Mundu of upgrade request */}
                    <LinkButton
                        title="Kontakt oss"
                        fill="peachOrange"
                        textColor="woodyBrown"
                        to={`mailto:${SUPPORT_EMAIL}`}
                    />
                </div>
            </Section>
        </div>
    );
};
