import useSWRInfinite from 'swr/infinite';
import container from 'src/container';
import { ImageSize } from 'src/api/music.params';
import { ReleaseType } from 'src/domain/music';

const {
    cradle: { musicService },
} = container;

export const useReleaseSearch = (
    query?: string | null,
    type?: ReleaseType,
    imageSize: ImageSize = 180,
    pageSize = 5,
) => {
    const { data, size, setSize, mutate, error, isValidating } = useSWRInfinite(
        page =>
            query
                ? ['release/search', query, type, imageSize, page + 1, pageSize]
                : null,
        (_, query, type, imageSize, page, pageSize) =>
            musicService.releaseSearch({
                query,
                type,
                imageSize,
                page,
                pageSize,
            }),
        { revalidateOnFocus: false },
    );
    const releases = data
        ? data
              .flat()
              .map(page => page.searchResults.searchResult)
              .flat()
              .map(result => result.release)
        : undefined;
    const isLoading = !data && !error;
    const isLoadingNext =
        isValidating ||
        (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.searchResults.searchResult.length === 0;
    const hasMore = !(
        isEmpty ||
        (data &&
            data[data.length - 1]?.searchResults.searchResult.length < pageSize)
    );

    return {
        releases,
        nextPage: () => setSize(size + 1),
        setPageSize: setSize,
        refresh: mutate,
        isLoading,
        isLoadingNext,
        error,
        hasMore,
        isEmpty,
    };
};
