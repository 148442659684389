import { TrashIconSVG } from 'src/assets/svg';
import { LoadingCircle } from 'src/view/components/loading/Circle';

type Props = {
    opacity?: number;
};

export const CardVideoLoading = ({ opacity = 1 }: Props) => {
    return (
        <div
            style={{ opacity: opacity }}
            className="mb-10 w-75 justify-self-center lg:justify-self-start"
        >
            <div className=" w-75 h-56 rounded-26px bg-starkWhite overflow-hidden">
                <div className="relative h-full w-full px-6 py-3">
                    <div className="relative h-full w-full bg0black bg-opacity-75">
                        <LoadingCircle loading={true} isGlobal={!false} />
                    </div>
                    <div className="absolute bottom-0 right-0 left-0 mb-3 mx-6 h-12 bg-gradient-to-t from-blackGradient"></div>
                </div>
            </div>
            <div className="py-4 animate-pulse">
                <div className="h-5 bg-woodyBrown bg-opacity-50 rounded w-5/6" />
            </div>
            <div className="grid grid-cols-2 gap-4 text-sm">
                <div>
                    <p>Minutter sett:</p>
                    <p>Antall ganger sett:</p>
                    <p>Laget av:</p>
                </div>
                <div className="animate-pulse">
                    <div className="h-4 mb-1 bg-woodyBrown bg-opacity-50 rounded w-16" />
                    <div className="h-4 mb-1 bg-woodyBrown bg-opacity-50 rounded w-12" />
                    <div className="h-4 bg-woodyBrown bg-opacity-50 rounded w-24" />
                </div>
            </div>
            <button
                type="button"
                className="flex mt-4 items-center text-sm outline-none focus:outline-none"
            >
                <span className="mr-2 underline">Slett video</span>
                <TrashIconSVG />
            </button>
        </div>
    );
};
