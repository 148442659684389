import { CardGrid } from 'src/components/AppScreen/AppLayout';
import Card from 'src/components/Card';
import { PlusIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { CardUserLoading } from 'src/view/components/loading/skeleton/CardUserLoading';
import { useCreators } from 'src/view/hooks/creator';
import { useAvatarFetch } from 'src/view/hooks/useImageFetch';

export const Creators = () => {
    const { creators, isLoading } = useCreators();
    const getAvatar = useAvatarFetch();

    return (
        <div className="h-full w-full relative text-woodyBrown">
            <CardGrid>
                <Card
                    to={'/app/creators/new'}
                    label="Ny brukerkontakt"
                    shape="circle"
                >
                    <TextIcon SVG={PlusIcon} />
                </Card>
                {creators.map(
                    ({ id, profile: { avatar, firstName, lastName } }) => (
                        <Card
                            fetchImage={() => getAvatar(avatar)}
                            key={id}
                            to={`/app/creators/profile/${id}`}
                            label={`${firstName} ${lastName}`}
                            shape="circle"
                        >
                            <img id={avatar} alt="consumer-avatar" />
                        </Card>
                    ),
                )}
                {isLoading && (
                    <>
                        <CardUserLoading />
                        <CardUserLoading opacity={0.75} />
                        <CardUserLoading opacity={0.5} />
                    </>
                )}
            </CardGrid>
        </div>
    );
};
