import { Consumer } from 'src/domain/consumer';
import { useAvatar } from 'src/view/hooks/useAvatar';
import { LockIcon, CaretIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';
import cx from 'classnames';

type Props = {
    onClick: () => void;
    consumer: Consumer;
    disabled?: boolean;
    isLight?: boolean;
};
export const ConsumerSelectOption = ({
    onClick,
    consumer,
    disabled,
    isLight = false,
}: Props) => {
    const {
        profile: { firstName, lastName, avatar },
    } = consumer;

    const { src, isDefault } = useAvatar(avatar);
    const initials = `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;

    return (
        <button
            onClick={onClick}
            disabled={disabled}
            className={cx(
                'inline-flex w-full items-center pl-2 rounded-md py-2 disabled:pointer-events-none transition-all',
                // isSelected && 'bg-woodyBrown text-lightPeach text-[18.5px] ',

                isLight
                    ? 'hover:bg-woodyBrown hover:bg-opacity-5 text-woodyBrown'
                    : 'hover:bg-white hover:bg-opacity-5 hover:text-white',
            )}
        >
            <div
                className={cx(
                    'w-8 h-8 shadow-md object-cover overflow-hidden rounded-md  flex-shrink-0 flex items-center justify-center',
                    isLight && isDefault && 'bg-woodyBrown text-lightPeach',
                    !isLight && isDefault && 'bg-lightPeach text-woodyBrown',
                )}
            >
                {isDefault ? (
                    <div className="text-sm">{initials}</div>
                ) : (
                    <img
                        className="object-cover h-8 w-8"
                        src={src}
                        alt="avatar consumer"
                    />
                )}
            </div>

            <div className="hidden sm:flex sm:flex-col pl-2 h-8 justify-between text-left">
                <p className="text-[13px] leading-none whitespace-nowrap ">{`${firstName} ${lastName}`}</p>
                <p className="text-[9px] font-extralight">BRUKER</p>

                {/* <div className="inline-flex items-center space-x-[4px] mb-[2px] ">
				<div className="bg-palmleafGreen w-[8px] h-[8px] mt-[2px] rounded-full " />
				<p className="text-[12px] align-middle leading-none ">
					{status}
				</p>
			</div> */}
            </div>
            <TextIcon SVG={disabled ? LockIcon : CaretIcon} />
        </button>
    );
};
