import { ArrowRightIcon } from 'src/components/Icons/icons';

type Props = {
    onClick?: () => void;
    title?: string;
    disabled?: boolean;
};
export const SendButton = ({ onClick, title, disabled = false }: Props) => {
    return (
        <button
            className="border-1.6px bg-peachOrange border-peachOrange text-woodyBrown rounded-full h-8 px-4 group relative flex items-center overflow-hidden disabled:bg-lightPeach disabled:border-vanillaWhite disabled:text-mongooseGray"
            type="submit"
            onClick={onClick}
            disabled={disabled}
        >
            <div className="absolute top-0  h-full w-full -left-1/2 -translate-x-1/2  group-hover:left-1/2 duration-500  ">
                <ArrowRightIcon />
            </div>
            <span className="group-hover:translate-x-[150%] duration-500">
                {title}
            </span>
        </button>
    );
};
