import { Footer } from '../components/footer';
import { ScrollToTopOnMount, LandingPageHeader } from '../components';
import { Link } from 'react-router-dom';
import { Screen } from 'src/view/routes/Router';

export const EULA = () => {
    return (
        <div>
            <ScrollToTopOnMount />
            <LandingPageHeader
                colors={{
                    textColor: 'starkWhite',
                    activeTextColor: 'mongooseGray',
                    backgroundColor: 'woodyBrown',
                }}
                scrollItems={false}
                navItems={[{ id: Screen.Mundu, name: 'Hjem' }]}
            />
            <div className="grid justify-center w-full bg-woodyBrown pt-32 pb-16 px-6 sm:px-32 lg:px-56">
                <div className="prose md:prose-lg xl:prose-xl">
                    <h1>Tjenesteavtale</h1>
                    <h2>1. Bakgrunn</h2>
                    <h2>2. Lisenser</h2>
                    <p>
                        Tjenesteleverandøren benytter seg av flere lisenser i
                        forbindelse med sin tjenesteleveranse. For en komplett
                        oversikt besøk{' '}
                        <Link to={Screen.Licences}>www.mundu.no/lisenser</Link>
                    </p>
                    <h2>3. Generelle vilkår</h2>
                    <h3>3.1 Avtaledokumenter</h3>
                    <p>Følgende vedlegg utgjør en integrert del av avtalen:</p>
                    <ol>
                        <li>Priser og betalingsbetingelser</li>
                        <li>Beskrivelse av funksjonaliteten</li>
                        <li>Tjenestenivåer</li>
                        <li>Tekniske krav</li>
                        <li>Databehandlingsavtale</li>
                        <li>Retningslinjer for akseptabel bruk</li>
                    </ol>
                    <p>
                        I tilfelle det foreligger konflikt mellom denne Avtalen
                        og vedleggene skal følgende tolkning legges til grunn:
                    </p>
                    <ol>
                        <li>Ordlyden i vedleggene.</li>
                        <li>
                            Den generelle ordlyden i denne Avtalen (Den
                            generelle ordlyden i Avtalen)
                        </li>
                        <li>Vedlegg 3 går foran de øvrige vedleggene.</li>
                    </ol>
                    <p>
                        Vedlegg 5 går foran ordlyden i denne Avtalen og
                        eventuelle vedlegg uavhengig av ovennevnte
                        tolkningsprinsipp.
                    </p>
                    <h3>3.2 Beskrivelse av tjenesten</h3>
                    <p>
                        Denne Avtalen definerer tjenesten som
                        Tjenesteleverandøren skal levere til Kunden (i tråd med
                        punkt 1 i Avtalen), de vilkårene som gjelder for
                        leveransen av tjenesten og partenes rettigheter og
                        plikter innbyrdes.
                    </p>
                    <p>
                        Tjenesteleverandøren skal tilby Kunden tjenesten “Mundu”
                        i forbindelse med behandling av personer med mild - til
                        moderat kognitiv svikt som følge av demens, med mulighet
                        for anvendelse innenfor andre områder av psykisk helse
                        (“Tjenesten”). Tjenesteleverandøren tilbyr kun
                        programvare. Kunden er selv ansvarlig for å fremskaffe
                        eget nettbrett for sine brukere. Pårørende benytter egen
                        smarttelefon eller nettbrett for digital overføring av
                        bilder. Tjenesten muliggjør for pasienter og deres
                        pårørende å se og interagere med digitale
                        musikkopplevelses-sesjoner - enten på institusjon eller
                        hjemme. Med tillegg av den dedikerte mobilapplikasjonen
                        tilbys det i Tjenesten en nettbasert plattform/
                        webklient som muliggjør for musikkterapeuter og annet
                        helsepersonell å registrere en pasient, kartlegge
                        pasientens musikalske livshistorie, samt lage og sende
                        digitale musikkopplevelses-sesjoner, bestående av bilder
                        av livsreise og musikk.
                    </p>
                    <p>
                        Tjenesteleverandøren skal levere Tjenesten i samsvar med
                        de tekniske kravene, som angitt i Vedlegg 4.
                    </p>
                    <p>
                        Tjenestene vil inkludere teknisk drift og vedlikehold av
                        Tjenesten, og vil også garantere Tjenestens oppetid, i
                        samsvar med Vedlegg 3.
                    </p>
                    <p>
                        Tjenesteleverandøren vil fungere som et support-team,
                        dette vil innebære support for Tjenesten i løpet
                        avtaleperioden. Dette inkluderer også
                        Tjenesteleverandørens egne utvikling av Tjenesten,
                        herunder nye og endrede funksjoner til Tjenesten. Dette
                        gir imidlertid ikke Kunden eller dens slutt-kunder rett
                        til å kreve tilleggs-utvikling av Tjenesten. Støtte gitt
                        av Tjenesteleverandøren vil være begrenset til å løse
                        problemer som påvirker funksjonaliteten til Tjenestene i
                        seg selv, og inkluderer ikke sluttbrukerstøtte, bl.a.
                        telefonsupport.
                    </p>
                    <p>
                        Tjenesteleverandøren vil også identifisere og løse
                        problemer uoppfordret av varsler eller problemer.
                    </p>
                    <p>
                        Tjenesteleverandøren forbeholder seg retten til å gjøre
                        endringer i Tjenestene. Tjenesteleverandøren vil ved
                        større endringer i så fall informere Kunden om en
                        endring er planlagt innen rimelig tid, samt tilby ekstra
                        bistand i form av rådgivning etter behov.
                    </p>
                    <p>
                        Tjenesteleverandøren vil også tilby forhåndsvisning av
                        nye tilleggstjenester og funksjoner. Disse Tjenestene og
                        funksjonene dekkes ikke av tjenestenivåavtalen, vedlegg
                        3.
                    </p>
                    <h3>3.3 Kundens ansvar</h3>
                    <p>
                        Kunden er forpliktet til å betale den avtalte prisen i
                        henhold til punkt 1 etter avtalt frekvens og i henhold
                        til Vedlegg 1. Kunden må også utpeke en representant som
                        har det tekniske ansvaret for varsling av feil eller
                        forespørsler fra Kundens side.
                    </p>
                    Kunden må gi Tjenesteleverandøren all relevant og nødvendig
                    informasjon og data som er nødvendig for å oppfylle
                    Tjenesteleverandørens forpliktelser i henhold til denne
                    avtalen.
                    <p></p>
                    <p>
                        Kunden forplikter seg til å sikre at de tekniske
                        kravene, som angitt i Vedlegg 4, er oppfylt under hele
                        avtaleperioden.
                    </p>
                    <p>
                        Varsel om feil eller begrenset tilgjengelighet skal
                        sendes av Kunden til Tjenesteleverandøren per e-post
                        til: support@mundu.no og skal gi en beskrivelse av
                        problemet på en detaljert måte. Tjenesteleverandøren er
                        ikke under noen omstendigheter ansvarlig for forsinket
                        tjenesteleveranse relatert til feil der Kunden ikke gir
                        detaljert informasjon.
                    </p>
                    <p>
                        Kunden er ansvarlig for tilgangsstyring i forbindelse
                        med Tjenesten, dette inkluderer, men er ikke begrenset
                        til, ansatte, tilknyttede selskaper, utviklere og
                        sluttbrukere.
                    </p>
                    <p>
                        Kunden og dens sluttbrukere må til enhver tid opptre i
                        samsvar med Tjenesteleverandørens retningslinjer for
                        akseptabel bruk, som beskrevet i Vedlegg 6.
                    </p>
                    <p>
                        Kunden samtykker herved til å la Tjenesteleverandøren
                        gjøre endringer i Tjenestene uten skriftlig samtykke,
                        som en del av den pågående utviklingen av Tjenestene.
                    </p>
                    <p>
                        Kunden tillater herved Tjenesteleverandøren å bruke
                        uidentifisert og samlet statistikk vedrørende kundens
                        bruk av Tjenesten. “Uidentifisert og samlet” innebærer
                        at Tjenesteleverandøren anonymiserer statistikken, og
                        dermed gjør det umulig å oppdage informasjon om Kunden
                        eller Kundens sluttbruker.
                    </p>
                    <p>
                        Kunden og/eller dens sluttbrukere må påse at Tjenesten
                        er koblet til nettverk (online) minst én (1) gang per
                        30. dag regnet fra siste gang Tjenesten var frakoblet
                        nettverk (“offline”) for at Tjenesten skal være
                        tilgjengelig for Kundens sluttbrukere. For det tilfelle
                        Kunden eller dens sluttbrukere ikke overholder
                        ovennevnte vil Tjenesten ikke være tilgjengelig før
                        Tjenesten kobles til nettverk på nytt (“online”). En
                        slik begrensning medfører ikke noen ansvar for
                        Tjenesteleverandøren overfor Kunden.
                    </p>
                    <h4>3.3.1 Begrensninger</h4>
                    <p>
                        Kunden kan kun bruke Tjenesten i tråd med denne Avtalen
                        og i tråd med Tjenesteleverandørens retningslinjer for
                        akseptabel bruk, slik disse følger av Vedlegg 6.
                    </p>
                    <p>
                        For det tilfelle at Kunden ikke opptrer i tråd med denne
                        Avtalen eller Tjenesteleverandørens retningslinjer for
                        akseptabel bruk, forbeholder Tjenesteleverandøren seg
                        retten til å suspendere Kundens tilgang til Tjenesten. I
                        slike tilfeller skal Tjenesteleverandøren gi Kunden
                        varsel før suspensjon dersom dette er mulig. Brudd på
                        denne Avtalen eller Tjenesteleverandørens retningslinjer
                        for akseptabel bruk, kan regnes for å være vesentlig
                        mislighold av Avtalen og danne grunnlag for
                        Tjenesteleverandøren å heve avtalen i tråd med punkt 4.3
                        i denne Avtalen.
                    </p>
                    <p>
                        Dette punktet gjelder i alle tilfeller også for Kundens
                        slutt-brukere.
                    </p>
                    <h3>3.4 Tjenesteleverandørens ansvar</h3>
                    <p>
                        Tjenesteleverandøren er ansvarlig for å levere Tjenesten
                        i henhold til denne Avtalen, samt oppfylle kravene og
                        beskrivelsene slik disse er spesifisert under punkt 3.2
                        og Vedlegg 2, og er i samsvar med relevante regelverk
                        fastsatt av myndighetene i Norge og EU.
                    </p>
                    <p>
                        Tjenesteleverandøren er også ansvarlig for at Tjenesten
                        som leveres er i samsvar med tjenestenivåene spesifisert
                        i Vedlegg 3.
                    </p>
                    <p>
                        I tilfeller der nedetid er rimelig forutsigbar, vil
                        Tjenesteleverandøren varsle Kunden.
                    </p>
                    <h3>3.5 Tjenesteleverandørens begrensede garanti</h3>
                    <p>
                        Tjenesteleverandøren garanterer for Tjenesten, herunder
                        å tilby support og reparere feil som påvirker Tjenesten
                        som forhindrer Kunden i å bruke Tjenester i samsvar med
                        denne Avtalen. Support og reparasjon av feil vil tilbys
                        i henhold til denne Avtalen og Vedlegg 3.
                    </p>
                    <p>
                        Tjenesteleverandøren er ikke ansvarlig for Kundens egne
                        applikasjoner, inkludert Kundens egen kode, og
                        eventuelle tredjepartskoder eller applikasjoner som
                        brukes av Kunden.
                    </p>
                    <p>
                        For at Tjenesteleverandøren skal kunne yte support og
                        reparere feil, må Kunden varsle Tjenesteleverandøren
                        innen rimelig tid.
                    </p>
                    <p>
                        Kunden samtykker i at denne Avtalen inneholder Kundens
                        eneste misligholdsbeføyelser for avbrudd, mangler,
                        delvis utilgjengelighet og fullstendig utilgjengelighet
                        av Tjenesten.
                    </p>
                    <h3>3.6 Immaterielle rettigheter</h3>
                    <h4>3.6.1 Avtalepartenes immaterielle rettigheter</h4>
                    <p>
                        Med mindre særlig inntatt i dette punkt, eller følger av
                        lisensene, vil denne Avtalen ikke overføre eller
                        lisensiere immaterielle rettigheter fra Kunden til
                        Tjenesteleverandøren eller fra Tjenesteleverandøren til
                        Kunden, hva gjelder teknologi eller programvare,
                        herunder men ikke begrenset til, design, dataprogrammer,
                        beskrivelser, kildekoder, brukergrensesnitt,
                        modifikasjoner og forretningshemmeligheter hentet fra
                        Tjenestene, inkludert den delen av Tjenesten som ble
                        utviklet før datoen for denne Avtalen og fremtidig
                        utvikling av Tjenesten.
                    </p>
                    <p>
                        Alle rettigheter, tittel og interesse i og til
                        Tjenesteleverandørens programvare forblir utelukkende
                        hos Tjenesteleverandøren.
                    </p>
                    <p>
                        Dersom Tjenesteleverandøren utvikler, eller tilpasser,
                        applikasjoner, verktøy, prosedyrer, rapporter, design
                        eller annen leveranse eller programvare, skal alle
                        opphavsrettigheter og andre immaterielle rettigheter til
                        leveransen tillegges Tjenesteleverandøren.
                    </p>
                    <p>
                        All generell kunnskap og “know how” utviklet av
                        Tjenesteleverandøren under utførelsen av forpliktelsene
                        i henhold til Avtalen, skal tillegges
                        Tjenesteleverandøren.
                    </p>
                    <h4>3.6.2 Partenes innbyrdes skadesløsholdelse</h4>
                    <p>
                        Tjenesteleverandøren skal holde Kunden skadesløs for
                        alle krav og utgifter knyttet til Tjenesteleverandørens
                        brudd på immaterielle rettigheter i forhold til en
                        tredjepart. Denne bestemmelsen omfatter ikke tredjeparts
                        programvare eller applikasjoner integrert i
                        Tjenesteleverandørens Tjeneste.
                    </p>
                    <p>
                        Kunden skal holde Tjenesteleverandøren, dens aksjonærer,
                        ansatte, agenter og tilknyttede selskaper skadesløs for
                        ethvert krav og utgifter knyttet til Kundens brudd på
                        tredjeparts immaterielle rettigheter.{' '}
                    </p>
                    <h3>3.7 Avbestilling og fornyelse</h3>
                    <h4>3.7.1 Varsel og utløp</h4>
                    <p>
                        Denne Avtalen gjelder til og med betaperiodens utløp og
                        fornyes automatisk for 1 (en) måned om gangen, med
                        mindre en av partene sender et oppsigelsesvarsel senest
                        1 (en) måneds varsel, før utløpet av avtalens første
                        periode eller fornyelsesperiode.
                    </p>
                    <h4>3.7.2 Sletting av data</h4>
                    <p> Når denne Avtalen heves blir Kundens data slettet. </p>
                    <h3>3.8 Begrensning av ansvar</h3>
                    <h4>3.8.1 Force Majeure</h4>
                    <p>
                        {' '}
                        Ingen av partene skal holdes ansvarlig for svikt i
                        ytelse i tilfeller hvor avtalebrudd oppstår som følge av
                        forhold som ikke partene har rimelig kontroll over (som
                        brann, eksplosjon, strømavbrudd, naturkatastrofer, krig,
                        pandemi og lignende). Dette punktet gjelder imidlertid
                        ikke for Kundens betalingsplikt, slik det følger av
                        denne Avtalen.
                    </p>
                    <h4>3.8.2 Indirekte skade som følge av Kundens bruk</h4>
                    <p>
                        Tjenesteleverandøren skal ikke holdes ansvarlig for
                        skader (inkludert, men ikke begrenset til tap av data og
                        økonomisk tap) som følge av Kundens bruk av Tjenestene,
                        med mindre slik skade utelukkende kan tilskrives
                        Tjenestens funksjonsfeil.
                    </p>
                    <h4>3.8.3 Indirekte og direkte tap</h4>
                    <p>
                        Ingen av partene holdes ansvarlig for tap av inntekter
                        eller indirekte, spesielle, tilfeldige, følgeskader,
                        straff eller ekstraordinære skader, eller skader for
                        tapt fortjeneste, inntekter, forretningsavbrudd eller
                        tap av forretningsinformasjon, selv om den ansvarlige
                        parten visste at slike skader var mulig eller kunne bli
                        rimelig forutsett.
                    </p>
                    <h2>4. Mislighold og misligholdsbeføyelser</h2>
                    <h3>4.1 Avtalebrudd</h3>
                    <p>
                        Tjenesteleverandøren vil opptre i strid med kontrakten
                        hvis Tjenesten ikke samsvarer med de avtalte funksjonene
                        og servicenivået i samsvar med denne Avtalen. I slike
                        tilfeller kan Kunden ha rett til å kreve prisavslag i
                        samsvar med punkt 4.2 eller kansellere Avtalen i samsvar
                        med punkt 4.3.
                    </p>
                    <p>
                        Ingen brudd fra Tjenesteleverandøren vil imidlertid
                        eksistere hvis situasjonen skyldes Kundens forhold eller
                        force majeure i samsvar med denne Avtalen. Dette
                        inkluderer, men er ikke begrenset til, omstendigheter
                        som Kunden er ansvarlig for i samsvar med punkt 3.3
                        eller kommer fra skyinfrastrukturleverandøren.
                    </p>
                    <h4>4.1.1 Varslingsplikt</h4>
                    <p>
                        Hvis en av partene ikke kan oppfylle sine plikter som
                        avtalt, må denne parten skriftlig varsle den andre
                        parten uten ugrunnet opphold. Varselet må inneholde
                        begrunnelsen for hvorfor parten ikke klarer å oppfylle
                        sine forpliktelser, så langt det er mulig når problemet
                        kan rettes. Ovennevnte vil også gjelde dersom det kan
                        antas at ytterligere forsinkelser vil skje etter at
                        første varsel er gitt.
                    </p>
                    <h3>4.2 Prisavslag</h3>
                    <p>
                        Hvis de garanterte oppetidskravene beskrevet i vedlegg 3
                        ikke blir oppfylt, skal Tjenesteleverandøren gi Kunden
                        et prisavslag i samsvar med vedlegg 3.
                    </p>
                    <p>
                        Det samlede maksimale prisavslaget som
                        Tjenesteleverandøren skal utstede til Kunden i en enkelt
                        faktureringsmåned, vil ikke overstige 25 % av det
                        beløpet som Kunden skal betale for Tjenestene for den
                        aktuelle måneden. Kreditt vil bli gitt i form av en
                        monetær kreditt som brukes på fremtidig bruk av
                        Tjenesten, og vil bli brukt innen 30 dager etter at
                        kreditten ble bedt om.
                    </p>
                    <h3>4.3 Heving</h3>
                    <p>
                        For det tilfelle at det foreligger vesentlig brudd på
                        denne Avtale fra en av partene, etter å ha gitt den
                        andre parten skriftlig varsel og en rimelig frist for å
                        avhjelpe situasjonen, kan denne parten si opp Avtalen
                        med umiddelbar virkning.
                    </p>
                    <p>
                        Hver av partene kan når som helst ved skriftlig varsel
                        umiddelbart si opp Avtalen uten kompensasjon til den
                        andre parten dersom den andre parten skal treffe en
                        beslutning, eller en kompetent domstol skal gi et pålegg
                        om at den andre parten skal avvikles eller hvis en
                        tillitsmann i konkurs, skal likvidasjon, mottaker eller
                        leder på vegne av en kreditor utnevnes, og slik
                        ordre/avtale blir ikke opphevet innen 30 dager.
                    </p>
                    <h2>5. Samlet ansvarsgrense</h2>
                    <p>
                        Tjenesteleverandørens totale samlede ansvar for ethvert
                        krav som springer ut av eller i sammenheng med denne
                        Avtale, er oppad begrenset til et maksimumsbeløp som
                        tilsvarer 25 % av den månedlige / årlige prisen som er
                        avtalt i henhold til avsnitt 1 ovenfor og vedlegg 1.
                        Ovennevnte ansvarsgrense skal inkludere eventuelle
                        prisreduksjoner som er gitt til Kunden i henhold til
                        avsnitt 4.2 og / eller vedlegg 3. Under ingen
                        omstendigheter vil en parts samlede ansvar under denne
                        Avtale overstige det ovennevnte beløpet.
                    </p>
                    <h2>6. Konfidensielt</h2>
                    <p>
                        Partene og deres respektive ansatte, representanter og
                        underleverandører skal holde all informasjon, inkludert,
                        men ikke begrenset til, teknisk og kommersiell
                        informasjon, konfidensielt eller som de blir kjent med
                        som et resultat av Avtalen i streng konfidensialitet,
                        uten tidsbegrensning, med mindre slik informasjon er
                        allerede offentlig kjent.
                    </p>
                    <h2>7. Generell skadeløsholdelse </h2>
                    <p>
                        Partene her er enige om å skadesløse og holde hverandre
                        og deres respektive ansatte og representanter uskadelige
                        for og mot alle tap, forpliktelser, mangler, kostnader,
                        skader og utgifter som kreves av en tredjepart som
                        direkte eller indirekte oppstår som følge av slik parts
                        forsettlige eller grovt uaktsomme handlinger.
                    </p>
                    <h2>8. Endringer til Avtalen</h2>
                    <p>
                        Tjenesteleverandøren forbeholder seg retten til å gjøre
                        endringer til Avtalen. Kunden vil bli varslet i slike
                        tilfeller senest 1 (én) måned før endringen trer i
                        kraft. Hvis Kunden ikke ønsker å godta endringene eller
                        endringene i denne Avtalen, kan Kunden kansellere denne
                        Avtalen i samsvar med avsnitt 3.7.1.
                    </p>
                    <p>
                        Tjenesteleverandøren forbeholder seg retten til å gjøre
                        endringer til Avtalen for å være i samsvar med
                        tredjeparts lisenser og vilkår som Tjenesteleverandøren
                        stoler på eller som en konsekvens av regulatoriske
                        endringer. I slike tilfeller vil Kunden bli varslet
                        senest 14 dager før endringen trer i kraft.
                    </p>
                    <h2>9. Lovvalg</h2>
                    <p>
                        Partenes rettigheter og forpliktelser i henhold til
                        denne Avtale skal i sin helhet være underlagt norsk
                        rett.
                    </p>
                    <h2>10. Tvisteløsning</h2>
                    <p>
                        Dersom det oppstår tvist mellom partene hva gjelder
                        fortolkningen eller virkningene av denne Avtale, skal
                        partene forsøke å løse tvisten gjennom forhandling. Hvis
                        slike forhandlinger ikke lykkes innen ti (10)
                        virkedager, kan hver av partene be om at tvisten blir
                        underlagt mekling.
                    </p>
                    <h3>10.1 Mekling</h3>
                    <p>
                        For det tilfelle at tvist springer ut av denne Avtalen,
                        ikke klarer å løses etter forhandlinger, skal partene
                        forsøke å løse tvisten gjennom mekling.
                    </p>
                    <p>
                        Partene samtykker til å bruke Advokatforeningens regler
                        for mekling av advokat, modifisert, hvis aktuelt, for å
                        passe partenes preferanser. Partene skal foreslå
                        hverandre, en megler de mener holder slike
                        kvalifikasjoner som er mest passende i forhold til
                        tvistens art. Hvis en part ikke er enig i forslaget, må
                        denne parten foreslå en mekler den mener å passe bedre.
                    </p>
                    <p>
                        Hvis partene ikke kan bli enige om en felles mekler,
                        skal mekleren oppnevnes av Advokatforeningen, på
                        anmodning fra partene.
                    </p>
                    <p>
                        Den detaljerte tilnærmingen til meklingen skal bestemmes
                        av megleren, i samråd med partene.
                    </p>
                    <h3>10.2 Domstolsbehandling/voldgift</h3>
                    <p>
                        For det tilfelle at tvisten ikke blir løst gjennom
                        forhandling, eller ved mekling, skal slik tvist være
                        underlagt Drammen tingrett.
                    </p>
                    <p>
                        Partene kan alternativt avtale at tvisten skal løses med
                        endelig virkning gjennom voldgift i samsvar med norsk
                        voldgiftslov.
                    </p>
                    <h2>
                        11. Informasjonssikkerhet og beskyttelse av
                        personopplysninger
                    </h2>
                    <h3>11.1 Informasjonssikkerhet</h3>
                    <p>
                        Tjenesteleverandøren vil treffe passende tiltak for å
                        adressere krav til informasjonssikkerhet knyttet til
                        Tjenesteytelsen, som nevnt i avsnitt 3.2. Dette
                        innebærer at Tjenesteleverandøren vil treffe passende
                        tiltak for å sikre konfidensialiteten til Kundens data,
                        samt tiltak for å sikre at dataene ikke havner hos
                        uautoriserte personer. Videre vil Tjenesteleverandøren
                        treffe passende tiltak for å beskytte mot utilsiktet
                        modifisering og sletting av data, og mot virus- og andre
                        malware-angrep, i samsvar med Tjenesteleverandørens
                        retningslinjer for akseptabel bruk. Mer detaljert
                        beskrivelse av Tjenesteleverandørens
                        informasjonssikkerhet, se vedlegg 7.
                    </p>
                    <p>
                        Kunden godtar herved at Tjenesteleverandøren kun er
                        ansvarlig for informasjonssikkerhetsrisikoen som kommer
                        fra Tjenesteleverandørens Tjeneste i samsvar med vedlegg
                        7.
                    </p>
                    <p>
                        Kunden, eller en kompetent myndighet, har rett til å
                        kreve regelmessige sikkerhetsrevisjoner eller revisjoner
                        i henhold til gjeldende lovgivning.
                    </p>
                    <h4>11.1.1 Kontraktsbrudd</h4>
                    <p>
                        For det tilfelle Tjenesteleverandøren ikke opptrer i
                        samsvar med dette punktet, kan Tjenesteleverandøren være
                        i strid med kontrakten, i samsvar med avsnitt 4, eller
                        Kunden kan be om en prisreduksjon i samsvar med avsnitt
                        4.2 eller i tilfeller av vesentlig kontraktsbrudd, si
                        opp Avtalen i samsvar med avsnitt 4.3.
                    </p>
                    <h2>12. GDPR</h2>
                    <p>
                        Kunden samtykker at Tjenesteleverandøren vil behandle og
                        lagre Kundens data i samsvar med databehandlingsavtalen
                        vedlagt i vedlegg 2. Databehandlingsavtalen vil ha
                        forrang i tilfelle konflikt med de enkelte punkt i denne
                        Avtalen om behandling av personopplysninger.
                    </p>
                    <h2>13. Erklæring om samfunnsansvar</h2>
                    <p>
                        Tjenesteleverandøren garanterer å overholde gjeldende
                        lovgivning vedrørende arbeidsrett og har implementert
                        interne retningslinjer for å overvåke og ivareta de
                        ansattes fysisk og mental helse og velferd.
                    </p>
                    <h2>14. Etiske arbeidsforhold</h2>
                    <p>
                        Tjenesteleverandøren garanterer at både den og dets
                        underleverandører avstår fra enhver form for
                        diskriminering på arbeidsplassen og ikke på noe
                        tidspunkt i produksjonsprosessene bruker slavearbeid
                        eller barnearbeid. Tjenesteleverandøren garanterer
                        videre at arbeid utført av de ansatte og
                        underleverandørers ansatte er ordnet slik at de ansattes
                        integritet og verdighet respekteres.
                    </p>
                    <h2>14.1 Antikorrupsjon</h2>
                    <p>
                        Hverken Tjenesteleverandøren eller noen av dens
                        datterselskap, ledere og tilhørende, har til partenes
                        viten tilknyttet, ansatt, agent eller representant for
                        noen av Tjenesteleverandørens underleverandører har i
                        løpet av sin virksomhet tatt eller iverksatt tiltak i
                        videreføring av et tilbud, betaling, løfte om å betale
                        eller gi eiendom, gaver eller noe av verdi, direkte
                        eller indirekte, til noen som helst statlig ansatt
                        (inkludert enhver offiser eller ansatt i en regjering
                        eller statlig eid eller kontrollert enhet eller av noen
                        politisk del eller partitjenestemann eller kandidat til
                        politisk verv) for å påvirke en handling eller for å
                        sikre en utilbørlig fordel.
                    </p>
                    <p>
                        Tjenesteleverandøren har innført og vil fortsette å
                        opprettholde retningslinjer og prosedyrer designet for å
                        fremme og oppnå samsvar med ovenstående.
                    </p>
                </div>
            </div>
            <Footer textColor="starkWhite" backgroundColor="woodyBrown" />
        </div>
    );
};
