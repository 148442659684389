import { useHistory, useLocation } from 'react-router-dom';
import { BackIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';

export const SmallBackButton = () => {
    const history = useHistory();
    const location = useLocation();
    const disabled = location.pathname.endsWith('browse');

    const onGoBack = () => {
        history.goBack();
    };

    return (
        <button
            onClick={onGoBack}
            className="text-woodyBrown text-[6px] p-[6px] bg-lightPeach rounded-full border-[1.2px] border-woodyBrown disabled:text-mongooseGray disabled:border-mongooseGray"
            disabled={disabled}
        >
            <TextIcon SVG={BackIcon} />
        </button>
    );
};
