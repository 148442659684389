import { useHistory } from 'react-router-dom';
import { useSignedInUser } from 'src/view/hooks/useSignedInUser';
import { useCurrentConsumerContext } from '../Home/NewHome/reducer';
import { ImageList } from '../Users/Image/list';

export const UploadHistory = () => {
    const user = useSignedInUser();
    const history = useHistory();
    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();
    if (!currentConsumer) return null;
    return (
        <div className="flex flex-col items-center h-full w-full">
            <ImageList
                consumerId={currentConsumer.id}
                uploaderId={user?.id}
                onAddImages={() => history.push('/app/nextofkin-image-upload')}
            />
        </div>
    );
};
