import { Dot } from './Dot';

type Props = {
    isLight: boolean;
    numPages: number;
    currentPage: number;
};
export const Dots = ({ isLight, numPages, currentPage }: Props) => (
    <div className="flex flex-shrink-0 items-center justify-between">
        {[...Array(numPages)].map((_, index) => (
            <Dot
                key={`${index}/${numPages}`}
                isCurrent={index === currentPage}
                isLight={isLight}
            />
        ))}
    </div>
);
