import Tabs from 'src/components/Navigation/Tabs/Tabs';
import { FavoriteArtists } from './favoriteArtists';
import { FavoriteTracks } from './favoriteTracks';

type Props = {
    editable: boolean;
};

export const Favorites = ({ editable = true }: Props) => {
    return (
        <div className=" ">
            {/* {currentConsumer && playlist && (
                <FavoritesBanner
                    author={currentConsumer}
                    playlist={playlist}
                    editable={editable}
                />
            )} */}
            <Tabs>
                <div id="Sanger">
                    <FavoriteTracks editable={editable} />
                </div>

                <div id="Artister">
                    <FavoriteArtists editable={editable} author={'Mundu'} />
                </div>
            </Tabs>
        </div>
    );
};
