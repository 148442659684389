import { useHistory } from 'react-router-dom';
import { Artist } from 'src/domain/music';
import { useAudioPlayerContext } from '../audioPlayerReducer';

export const ArtistLink = ({ artist }: { artist: Artist }) => {
    const { dispatch } = useAudioPlayerContext();
    const history = useHistory();
    const onClick = async () => {
        dispatch({
            type: 'SET_ARTIST',
            payload: artist,
        });
        history.push('./artist');
    };
    return (
        <button
            className="text-sm hover:underline relative z-20"
            onClick={onClick}
        >
            {artist.name}
        </button>
    );
};
