import { CustomPlaylistTrack, Playlist, Track } from 'src/domain/music';
import { SelectedList, useAudioPlayerContext } from '../../audioPlayerReducer';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ReleaseDateIcon } from '../icons/releaseDateIcon';
import { DurationIcon } from '../icons/durationIcon';
import { TrackItem } from './item';
import { convertURLToHttps } from 'src/utils/stringUtils';
import { useIsSelectingMusic } from 'src/view/hooks/useIsSelectingMusic';
import { CardGrid } from 'src/components/AppScreen/AppLayout';

export const isPlaylistTrack = (
    x: Track | CustomPlaylistTrack,
): x is CustomPlaylistTrack =>
    Object.prototype.hasOwnProperty.call(x, 'playlistItemId');

type TrackListProps = {
    tracks: (Track | CustomPlaylistTrack)[];
    displayOption: 'CARD' | 'LIST';
    playlistId?: Playlist['id'];
    editable?: boolean;
    showHeader?: boolean;
    showReleaseCols?: boolean;
    showIndexCol?: boolean;
    isPlaylistSearch?: boolean;
    onDeleteTrack?: (track: CustomPlaylistTrack) => void;
    onAddTrack?: (track: Track) => void;
};
export const TrackList = ({
    tracks,
    displayOption,
    editable,
    showHeader = true,
    showReleaseCols = true,
    showIndexCol = true,
    isPlaylistSearch = false,
    onDeleteTrack,
    onAddTrack,
}: TrackListProps) => {
    const { state, dispatch } = useAudioPlayerContext();
    const isSelectingMusic = useIsSelectingMusic();

    useDeepCompareEffect(() => {
        if (tracks.length === 0) return;
        if (state.selectedList === SelectedList.search) {
            dispatch({ type: 'SET_QUEUE', payload: tracks });
        }
    }, [tracks]);

    const renderItem = (
        track: Track | CustomPlaylistTrack,
        index: number,
        displayOption: 'LIST' | 'CARD',
    ) => {
        const { isPlaying, currentTrack, selection, unavailableTracks } = state;
        const isCurrentTrack = currentTrack?.id === track.id;
        const trackIsPlaying = isCurrentTrack && isPlaying;
        const isSelected = selection.some(item => item.id === track.id);
        const isUnavailable = unavailableTracks.some(id => id === track.id);
        const isSearchResults = state.selectedList !== SelectedList.search;
        const key = isPlaylistTrack(track) ? track.playlistItemId : track.id;

        const onSelect = (track: Track | CustomPlaylistTrack) => {
            dispatch({
                type: 'TOGGLE_SELECTED',
                payload: {
                    id: track.id,
                    releaseId: track.release.id,
                    artist: track.artist.appearsAs,
                    title: track.title,
                    duration: track.duration,
                    cover: convertURLToHttps(track.release.image),
                },
            });
        };

        const togglePlay = () => {
            if (isCurrentTrack) {
                dispatch({ type: 'TOGGLE_PLAY' });
            } else {
                if (isSearchResults) {
                    dispatch({ type: 'SET_QUEUE', payload: tracks });
                }
                dispatch({ type: 'PLAY_TRACK', payload: index });
            }
        };

        return (
            <TrackItem
                key={key}
                track={track}
                index={index}
                isUnavailable={isUnavailable}
                displayAs={displayOption}
                isPlaying={trackIsPlaying}
                isSelected={isSelected}
                togglePlay={togglePlay}
                onSelect={isSelectingMusic ? onSelect : undefined}
                onAdd={onAddTrack}
                onDelete={onDeleteTrack}
                editable={editable}
                showReleaseCols={showReleaseCols}
                showIndexCol={showIndexCol}
                isPlaylistSearch={isPlaylistSearch}
            />
        );
    };

    return displayOption === 'LIST' ? (
        <div className="grid grid-cols-32 gap-4 items-center max-w-max">
            {showHeader && tracks.length > 0 && (
                <>
                    <div
                        className={`col-span-16 ${
                            showReleaseCols
                                ? 'md:col-span-12'
                                : 'md:col-span-16'
                        } inline-flex`}
                    >
                        {showIndexCol && (
                            <button className="w-8 pr-4 text-right flex-shrink-0 tabular-nums font-matterSQ tracking-tighter">
                                #
                            </button>
                        )}
                        <button className="text-left">TITTEL</button>
                    </div>
                    {showReleaseCols && (
                        <button className="md:col-span-9 text-left invisible md:visible">
                            UTGIVELSE
                        </button>
                    )}
                    <button className="col-span-3  ">
                        <div className="w-4 h-4">
                            <DurationIcon />
                        </div>
                    </button>
                    {showReleaseCols && (
                        <button className="md:col-span-4 invisible md:visible">
                            <div className="w-4 h-4">
                                <ReleaseDateIcon />
                            </div>
                        </button>
                    )}
                    <div className="col-auto flex flex-shrink-1" />
                </>
            )}
            {tracks.map((track, index) => renderItem(track, index, 'LIST'))}
        </div>
    ) : (
        <CardGrid>
            {tracks.map((track, index) => renderItem(track, index, 'CARD'))}
        </CardGrid>
    );
};
