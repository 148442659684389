import { SearchWhiteIconSVG, TrashIconSVG } from 'src/assets/svg';
import cx from 'classnames';
import { ConfirmationButton } from 'src/components/Modals/ConfirmationModal/ConfirmationButton';
import ConfimationModalProvider from 'src/components/Modals/ConfirmationModal/ConfirmationModalProvider';

type Props = {
    src: string;
    id: string;
    description?: string;
    onDelete: () => void;
    onPreview: () => void;
    index: number;
};

export const CardPicture = ({
    src,
    description,
    onDelete,
    onPreview,
}: Props) => {
    return (
        <div className="w-32 xl:w-36 animate-fade-in-fast group">
            <div
                className={cx(
                    'relative w-32 xl:w-36 h-32 xl:h-36 rounded-xl overflow-hidden',
                )}
            >
                <img src={src} className="w-full h-full object-cover" alt="" />
                <ConfimationModalProvider
                    message={'Er du sikker på at du vil slette dette bildet?'}
                >
                    <ConfirmationButton
                        type="button"
                        onClick={onDelete}
                        className="absolute text-lightPeach bottom-0 right-0 p-3
                        hover:text-woodyBrown transition-all"
                    >
                        <TrashIconSVG className="w-5 h-5" />
                    </ConfirmationButton>
                </ConfimationModalProvider>
                <button
                    type="button"
                    onClick={onPreview}
                    className="opacity-0 group-hover:opacity-100 transition duration-200 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 lg:w-12 lg:h-12 w-10 h-10 flex items-center justify-center rounded-xl bg-black bg-opacity-75"
                >
                    <SearchWhiteIconSVG className="lg:w-5 lg:h-5" />
                </button>
            </div>

            <div className="text-sm py-2 px-2">
                <p className="line-clamp-2 italic">
                    {description ? (
                        <>
                            &quot;
                            {description}
                            &quot;
                        </>
                    ) : null}
                </p>
            </div>
        </div>
    );
};
