import { useEffect, useRef, ReactNode } from 'react';
import { createPortal } from 'react-dom';
type ModalProps = {
    children: ReactNode | ReactNode[];
    isVisible: boolean;
};
export const Modal = ({ isVisible, children }: ModalProps) => {
    const el = useRef(document.createElement('div'));
    useEffect(() => {
        const current = el.current;
        const modalRoot = document.querySelector('#modal-root') as HTMLElement;
        modalRoot.appendChild(current);
        return () => void modalRoot.removeChild(current);
    }, []);

    // Instead of `el`, the container is the `ref.current`
    return createPortal(
        <>
            {isVisible ? (
                <div className="fixed z-50 inset-0 bg-black  bg-opacity-50 overflow-y-auto h-full w-full">
                    <div className="transform z-50 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                        {children}
                    </div>
                </div>
            ) : null}
        </>,
        el.current,
    );
};
