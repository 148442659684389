import { PeopleSVG, PersonSVG } from 'src/assets/svg';
import { CardGrid } from 'src/components/AppScreen/AppLayout';
import Card from 'src/components/Card';
import { VideoIcon, ImagesIcon, MusicIcon } from 'src/components/Icons/icons';
import { TextIcon } from 'src/components/Icons/TextIcon';
import { IncompleteSessions } from './IncompleteSessions';
import { useCurrentConsumerContext } from './NewHome/reducer';

export const Home = () => {
    const {
        state: { currentConsumer },
    } = useCurrentConsumerContext();

    const shortCutsDisabled = currentConsumer === undefined;

    return (
        <div className="flex flex-col space-y-10 text-xl">
            <CardGrid>
                <Card label="Ny bruker" shape="circle" to="/app/users/new">
                    <PersonSVG className="w-[18px] h-[18px]" />
                </Card>
                <Card
                    label="Inviter pårørende"
                    shape="circle"
                    disabled={shortCutsDisabled}
                    to="/app/nextofkins/new"
                >
                    <PeopleSVG className="w-[18px] h-[18px]" />
                </Card>
                <Card
                    label="Musikalsk kartlegging"
                    shape="circle"
                    disabled={shortCutsDisabled}
                    to="/app/musical-survey"
                >
                    <TextIcon SVG={MusicIcon} />
                </Card>
                <Card
                    label="Lag ny video"
                    shape="circle"
                    disabled={shortCutsDisabled}
                    to="/app/new-video/step1"
                >
                    <TextIcon SVG={VideoIcon} />
                </Card>
                <Card
                    label="Last opp bilder"
                    shape="circle"
                    disabled={shortCutsDisabled}
                    to="/app/image-upload"
                >
                    <TextIcon SVG={ImagesIcon} />
                </Card>
            </CardGrid>

            <IncompleteSessions />
        </div>
    );
};
