import { Consumer } from 'src/domain/consumer';
import { Creator } from 'src/domain/creator';
import { Organization } from 'src/domain/organization';
import { getUserRole } from 'src/domain/user';
import { useAvatar } from 'src/view/hooks/useAvatar';
import { SelectorOption } from '../SelectorOption';

type Props = {
    onClick: () => void;
    isSelected: boolean;
    isLight?: boolean;
    user: Consumer | Creator | Organization;
    name?: string;
    entity?: string;
};
export const UserSelectorOption = ({
    onClick,
    isSelected,
    user,
    isLight = false,
    name,
    entity,
}: Props) => {
    const {
        role,
        profile: { firstName, lastName, avatar },
    } = user;
    const { src } = useAvatar(avatar);
    return (
        <SelectorOption
            onClick={onClick}
            entity={entity || getUserRole(role).toUpperCase()}
            name={name || `${firstName} ${lastName}`}
            src={src}
            status="Aktiv"
            isLight={isLight}
            isSelected={isSelected}
            rounded
        />
    );
};
