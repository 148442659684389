import { useState } from 'react';
import { checkDuplicatesWithId } from 'src/utils/generalUtils';
import { useConsumersImages } from 'src/view/hooks/consumer';
import { useImageSelectorContext } from 'src/state/reducers/ImageSelectorReducer';
import { useSessionFormState } from '../../SessionFormState/hooks';
import { CardPicturePopup } from './CardPicturePopup';
import { PicturePopupLoading } from './PicturePopupLoading';
import { ModalPreviewPicture } from 'src/components/Modals/ModalPreviewPicture';
import { CardGrid } from 'src/components/AppScreen/AppLayout';

export const PicturePopup = () => {
    const {
        dispatch,
        state: { selection },
    } = useImageSelectorContext();

    const [previewIndex, setPreviewIndex] = useState(-1);
    const { recipient } = useSessionFormState();

    const { images } = useConsumersImages(recipient?.id);
    const currentImage = images[previewIndex]?.media;

    const onPrev = () => {
        if (previewIndex === 0) {
            setPreviewIndex(images.length - 1);
        } else {
            setPreviewIndex(prev => prev - 1);
        }
    };

    const onNext = () => {
        if (previewIndex === images.length - 1) {
            setPreviewIndex(0);
        } else {
            setPreviewIndex(prev => prev + 1);
        }
    };

    return (
        <div className="">
            {recipient && currentImage && (
                <ModalPreviewPicture
                    index={previewIndex + 1}
                    numItems={images.length}
                    onClose={() => setPreviewIndex(-1)}
                    onPrevious={onPrev}
                    consumerId={recipient.id}
                    onNext={onNext}
                    picture={currentImage}
                />
            )}

            <div id={`${recipient?.profile.firstName}'s bilder`}>
                <CardGrid>
                    {images ? (
                        images.map(({ media, url }, index) => {
                            const isSelected = checkDuplicatesWithId(
                                media.id,
                                selection,
                            );
                            return (
                                <CardPicturePopup
                                    key={media.id}
                                    index={index}
                                    src={url}
                                    picture={media}
                                    isSelected={isSelected}
                                    onSelected={() =>
                                        dispatch({
                                            type: 'TOGGLE_SELECTED',
                                            payload: media,
                                        })
                                    }
                                    onPicturePreview={() =>
                                        setPreviewIndex(index)
                                    }
                                />
                            );
                        })
                    ) : (
                        <PicturePopupLoading />
                    )}
                </CardGrid>
            </div>
        </div>
    );
};
