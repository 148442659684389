import cx from 'classnames';
import { Link } from 'react-router-dom';
import { CloseIconSVG, TickBrownIconSVG, WarningIconSVG } from 'src/assets/svg';

type Props = {
    isSuccess: boolean;
    message: string | undefined;
    clearMessage?: () => void;
    redirectUrl?: string;
    className?: string;
    canClose?: boolean;
};

export const Alert = ({
    isSuccess,
    message,
    clearMessage,
    redirectUrl,
    className = 'my-4',
    canClose = true,
}: Props) => {
    return (
        <>
            {message && (
                <div
                    className={cx(
                        'rounded-full bg-peachOrange p-1 inline-flex justify-between items-center px-3',
                        className,
                    )}
                    role="alert"
                >
                    <div className="flex items-center  relative">
                        <div>
                            {isSuccess ? (
                                <TickBrownIconSVG />
                            ) : (
                                <WarningIconSVG className="fill-woodyBrown" />
                            )}
                        </div>
                        <div className="block sm:inline text-woodyBrown px-3">
                            <span className="">{message}</span>
                            {isSuccess && redirectUrl && (
                                <>
                                    <span>.&nbsp;Klikk&nbsp;</span>
                                    <Link to={redirectUrl ?? ''}>
                                        <span className="underline">her</span>
                                    </Link>
                                    <span>&nbsp;for å lukke vindu.</span>
                                </>
                            )}
                        </div>
                    </div>
                    {canClose && (
                        <button
                            className="focus:outline-none"
                            type="button"
                            onClick={clearMessage}
                        >
                            <CloseIconSVG />
                        </button>
                    )}
                </div>
            )}
        </>
    );
};
