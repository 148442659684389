import { useHistory } from 'react-router-dom';
import { Release } from 'src/domain/music';
import { convertURLToHttps } from 'src/utils/stringUtils';
import { useAudioPlayerContext } from '../../audioPlayerReducer';
import { ArtistLink } from '../artistLink';
import { ReleaseLink } from '../releaseLink';

export const ReleaseItem = ({
    release,
    onSelect,
}: {
    release: Release;
    onSelect?: () => void;
}) => {
    const { dispatch } = useAudioPlayerContext();
    const history = useHistory();

    const onClick = async () => {
        dispatch({
            type: 'SET_RELEASE',
            payload: release,
        });
        history.push('./release');
    };

    return (
        <div className="flex flex-col relative w-24 md:w-32 xl:w-36 rounded-md md:rounded-lg xl:rounded-2xl ">
            <button onClick={onSelect ? onSelect : onClick}>
                <div className="relative w-full aspect-square">
                    <img
                        className="card-image"
                        src={convertURLToHttps(release.image)}
                        alt={`artwork for release ${release.title}}`}
                    />
                </div>
            </button>

            <ReleaseLink
                className="mt-2 text-left font-bold truncate text-sm hover:underline relative z-20"
                release={release}
            />

            <p className="truncate">
                <ArtistLink artist={release.artist} />
            </p>
        </div>
    );
};
