import { useRef, useState } from 'react';
import useWindowDimensions from './useWindowDimensions';

export const useAnimatedPagination = (maxIndex: number) => {
    const { width } = useWindowDimensions();
    const ref = useRef<HTMLDivElement>(null);
    const easing = (x: number) => -(Math.cos(Math.PI * x) - 1) / 2;
    const [index, setIndex] = useState(0);
    const [isScrolling, setIsScrolling] = useState(false);

    function onScroll(
        direction: -1 | 1,
        duration = 800,
        startTime = performance.now(),
    ) {
        setIsScrolling(true);
        const element = ref.current;
        if (!element) return;
        const startX = element.scrollLeft;
        const difference = width * direction;
        function tick() {
            requestAnimationFrame(function (timestamp) {
                if (!element) return;
                const progress = (timestamp - startTime) / duration;
                const isComplete = 1 <= progress;
                const delta = isComplete ? 1 : easing(progress);
                element.scrollTo({ left: difference * delta + startX });

                isComplete ? setIsScrolling(false) : tick();
            });
        }
        return tick();
    }

    const onPreviousPage = () => {
        if (index > 0) {
            onScroll(-1);
            setIndex(prev => prev - 1);
        }
    };
    const onNextPage = () => {
        if (index < maxIndex) {
            onScroll(1);
            setIndex(prev => prev + 1);
        }
    };

    return {
        currentIndex: index,
        onNextPage,
        onPreviousPage,
        ref,
        isScrolling,
    };
};
