import { Consumer } from 'src/domain/consumer';
import { useSessions } from 'src/view/hooks/session';
import { CardVideo } from '../../MyClients/detail/components/CardVideo';
import { CardVideoLoading } from '../../MyClients/detail/components/CardVideoLoading';
import { Session } from 'src/domain/session';
import ConfimationModalProvider from '../../../../components/Modals/ConfirmationModal/ConfirmationModalProvider';
import {
    usePersonalMediaSas,
    useCommonMediaSas,
} from 'src/view/hooks/consumer';
import container from 'src/container';
import { AddButton } from 'src/components/Buttons/AddButton';

type Props = {
    recipientId: Consumer['id'];
    onDelete: (id: Session['id']) => void;
};

const {
    cradle: { storageService },
} = container;

export const SessionList = ({ recipientId, onDelete }: Props) => {
    const { sessions, isLoading } = useSessions(recipientId);
    const { sas: personalMediaSAS } = usePersonalMediaSas(recipientId);
    const { sas: commonMediaSAS } = useCommonMediaSas();

    return (
        <ConfimationModalProvider message="Er du sikker på at du vil slette denne videoen?">
            <div className="flex gap-6 flex-wrap">
                <AddButton
                    to={'/app/new-video/step1'}
                    label="Ny video"
                    aspect="video"
                />
                {sessions.map(session => {
                    const thumb = session.media.images[0];
                    const sas =
                        thumb.containerType === 'pm'
                            ? personalMediaSAS
                            : commonMediaSAS;
                    const thumbnail =
                        storageService.getImageUrl(thumb.id, sas) || '';
                    return (
                        <CardVideo
                            onDelete={id => onDelete(id)}
                            thumbnail={thumbnail}
                            key={session.id}
                            session={session}
                        />
                    );
                })}
                {isLoading && (
                    <>
                        <CardVideoLoading />
                        <CardVideoLoading opacity={0.5} />
                    </>
                )}
            </div>
        </ConfimationModalProvider>
    );
};
