import { RefObject, useEffect, useRef, ReactNode } from 'react';

type Props = {
    children: ReactNode;
    show?: boolean;
    clickOutside: () => void;
};

const useOutsideAlerter = (
    ref: RefObject<HTMLDivElement>,
    clickOutside: () => void,
): void => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent): void {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                clickOutside();
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return (): void => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [clickOutside, ref]);
};

export const AppModal = ({ children, clickOutside, show }: Props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, clickOutside);
    if (!show) {
        return null;
    }
    return <div ref={wrapperRef}>{children}</div>;
};
