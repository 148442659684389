import { FadeImage } from './FadeImage';

interface ImagePairProps {
    images: { current: string | undefined; next: string | undefined };
    startAnimation: boolean;
}

export const ImagePair = ({ images, startAnimation }: ImagePairProps) => {
    const firstImage = images.current;
    const secondImage = images.next;
    return (
        <>
            {secondImage && (
                <FadeImage
                    key={secondImage}
                    startAnimation={startAnimation}
                    source={secondImage}
                    fade={'fadein'}
                />
            )}
            <FadeImage
                key={firstImage}
                startAnimation={startAnimation}
                source={firstImage}
                fade={'fadeout'}
            />
        </>
    );
};
